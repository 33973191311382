import React, {useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/HostConfig.css';

// reactstrap
import {Row} from 'reactstrap';

// components
import {ColumnMenu, NoWrapCell, TextAlignMiddleCell} from '../common/Grid.js';
import HostConfigHamburger from './HostConfigHamburger.js';
import ShowLicensesModal from './ShowLicensesModal.js';
import EditHostModal from './EditHostModal.js';
import RemoveHostModal from './RemoveHostModal.js';

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../common/MultilingualText.js';
import {
    aliasKey,
    assetInfoKey,
    cityKey,
    countryKey,
    hostIdKey,
    notesKey,
    stateKey,
    loadingPeriodsKey,
    mainMessages,
    noHostsAvailableKey,
    myHostsKey,
} from '../../assets/text/MultilingualText.js';


// consts for host config table
const initialSort = [
    {
        field: "host_id",
        dir: "asc",
    }
];
const initialGridState = {
    skip: 0,
    take: 20
};

function HostConfigGrid(props) {
    const {
        hosts,
        getHosts,
        hostGridState,
        setHostGridState,
        setIsLoading
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [host, setHost] = useState("");
    const [licenses, setLicenses] = useState([]);

    const [hostState, setHostState] = useState(
        process(
            hosts.map((host) => ({...host})),
            initialGridState
        )
    );

    const [isVisible, setIsVisible] = useState({
        showLicenses: false,
        editHost: false,
        removeHost: false
    });

    const defaultGridMessage = localization.toLanguageString(
        noHostsAvailableKey,
        mainMessages[siteLanguageDefault][noHostsAvailableKey]
    );
    const defaultLoadingMessage = localization.toLanguageString(
        loadingPeriodsKey,
        mainMessages[siteLanguageDefault][loadingPeriodsKey]
    );
    const [gridNoRecordsMessage, setGridNoRecordsMessage] = useState(defaultGridMessage);

    /*
     * onHostStateChange(event) sets the host grid state and host state on grid's state change
     * @param {event} the grid selection change event
    */
    const onHostStateChange = useCallback((event) => {
        const newHostState = process(
            hosts.map((host) => ({...host})),
            event.dataState
        );
        setHostGridState(event.dataState);
        setHostState(newHostState);
    }, [hosts]); // eslint-disable-line react-hooks/exhaustive-deps

    /*
    * showLicenses() opens the show licenses modal and gets a host's licenses
    * @param {hostData} an object containing the row's host data
    */
    const showLicenses = (hostData) => {
        hostData.host_conf_id ? setHost(hostData) : setHost("");

        setIsVisible(isVisible => ({...isVisible, showLicenses: true}));

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        setIsLoading(true);
        setGridNoRecordsMessage(defaultLoadingMessage);

            axios.get(
                `${config.host_config.LICENSES}?module=EA&host=`+hostData.host_conf_id,
                {headers: headers}
            )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.ea_host_license_list || [];

                    data.forEach((license, index) => {
                        if (license.license_expiration_date !== null) {
                            license.license_expiration_date = new Date(license.license_expiration_date.replace(/-/g, "/"));
                        }
                        if (license.support_expiration_date !== null) {
                            license.support_expiration_date = new Date(license.support_expiration_date.replace(/-/g, "/"));
                        }
                    });

                    setIsLoading(false);
                    setLicenses(data);

                    setGridNoRecordsMessage(defaultGridMessage);
                    setIsVisible(isVisible => ({...isVisible, showLicenses: true}));
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Host Licenses", error);
                setIsLoading(false);
                setGridNoRecordsMessage(defaultGridMessage);
            });
    }

    /*
    * showEditHost() opens the edit host modal
    * @param {hostData} an object containing the row's host data
    */
    const showEditHost = (hostData) => {
        hostData.host_id ? setHost(hostData) : setHost("");
        setIsVisible(isVisible => ({...isVisible, editHost: true}));
    }

    /*
    * showRemoveHost() opens the remove host modal
    * @param {hostData} an object containing the row's host data
    */
    const showRemoveHost = (hostData) => {
        hostData.host_id ? setHost(hostData) : setHost("");
        setIsVisible(isVisible => ({...isVisible, removeHost: true}));
    }

    // host config grid hamburger menu cell
    const HostConfigHamburgerCell = (props) => {
        return <HostConfigHamburger
            showLicenses={showLicenses}
            showEditHost={showEditHost}
            showRemoveHost={showRemoveHost}
            hostData={props.dataItem}
            {...props}
        />
    }

    // initialize my hosts table
    useEffect(() => {
        if (hosts.length) {
            setHostState(process(hosts, initialGridState));
        }
    }, [hosts]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Row>
                <div
                    className={"k-h4"}
                >
                    <Text
                        textkey={myHostsKey}
                        textdefault={mainMessages[siteLanguageDefault][myHostsKey]}
                    />
                </div>
            </Row>
            <Grid
                className={"host-config-grid"}
                data={hostState}
                sortable={true}
                scrollable={"none"}
                pageable={(hosts.length <= 20) ? false : {
                    buttonCount: 5,
                    pageSizes: [10, 20, 50, 100],
                }}
                total={hostState.total}
                sort={initialSort}
                onDataStateChange={onHostStateChange}
                {...hostGridState}
            >
                <GridNoRecords>
                    {gridNoRecordsMessage}
                </GridNoRecords>
                <GridColumn
                    cell={HostConfigHamburgerCell}
                    width={"1.25rem"}
                />
                <GridColumn
                    field="host_id"
                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                    cell={NoWrapCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="alias"
                    title={localization.toLanguageString(aliasKey, mainMessages[siteLanguageDefault][aliasKey])}
                    cell={NoWrapCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="asset_info"
                    title={localization.toLanguageString(assetInfoKey, mainMessages[siteLanguageDefault][assetInfoKey])}
                    cell={NoWrapCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="city"
                    title={localization.toLanguageString(cityKey, mainMessages[siteLanguageDefault][cityKey])}
                    cell={NoWrapCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="state"
                    title={localization.toLanguageString(stateKey, mainMessages[siteLanguageDefault][stateKey])}
                    cell={TextAlignMiddleCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="country"
                    title={localization.toLanguageString(countryKey, mainMessages[siteLanguageDefault][countryKey])}
                    cell={NoWrapCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="notes"
                    title={localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey])}
                    columnMenu={ColumnMenu}
                />
            </Grid>
            {isVisible.showLicenses && host && <ShowLicensesModal
                host={host}
                setHost={setHost}
                licenses={licenses}
                setIsVisible={setIsVisible}
                gridNoRecordsMessage={gridNoRecordsMessage}
            />}
            {isVisible.editHost && host && <EditHostModal
                host={host}
                setHost={setHost}
                getHosts={getHosts}
                setIsVisible={setIsVisible}
                setIsLoading={setIsLoading}
            />}
            {isVisible.removeHost && host && <RemoveHostModal
                host={host}
                setHost={setHost}
                getHosts={getHosts}
                setIsVisible={setIsVisible}
                setIsLoading={setIsLoading}
            />}
        </>
    );
}

export default HostConfigGrid;
