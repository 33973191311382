import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import NavigationImg from '../assets/img/Help/ksm-request-license-navigation.png';
import SelectProductsImgOne from '../assets/img/Help/ksm-request-license-select-products1.png';
import SelectProductsImgTwo from '../assets/img/Help/ksm-request-license-select-products2.png';
import AssignProductImgOne from '../assets/img/Help/ksm-request-license-assign-products1.png';
import AssignProductImgTwo from '../assets/img/Help/ksm-request-license-assign-products2.png';
import AssignProductImgThree from '../assets/img/Help/ksm-request-license-assign-products3.png';
import PreviewLicenseImg from '../assets/img/Help/ksm-request-license-preview-license.png';
import ChangeProductImgOne from '../assets/img/Help/ksm-request-license-change-products1.png';
import ChangeProductImgTwo from '../assets/img/Help/ksm-request-license-change-products2.png';
import GenerateLicenseImgOne from '../assets/img/Help/ksm-request-license-generate-license1.png';
import GenerateLicenseImgTwo from '../assets/img/Help/ksm-request-license-generate-license4.png';
import GenerateLicenseImgThree from '../assets/img/Help/ksm-request-license-generate-license3.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    assigningProductsToHostKey,
    helpRequestAssignProductSectionKey,
    helpRequestChangeProductSectionKey,
    changingProductAssignmentsKey,
    entitlementCertificatesKey,
    helpRequestEntitlementCertificateFaqQAKey,
    errorHandlingKey,
    helpRequestErroHandlingSectionKey,
    frequentlyAskedQuestionsKey,
    generatingDownloadingKey,
    helpRequestGeneratingLicenseSectionKey,
    ksmRequestNewLicenseKey,
    mainMessages,
    licensesKey,
    helpRequestLicensesFaqQAKey,
    navigationKey,
    helpRequestNavigationSectionKey,
    previewingLicensesKey,
    helpRequestPreviewLicenseSectionKey,
    quickStepsObtainLicensesKey,
    helpRequestQuickStepsSectionKey,
    requestingNewLicenses,
    helpRequestRequestingNewLicensesSectionKey,
    selectingProductsKey,
    helpRequestSelectingProductSectionKey
} from '../assets/text/MultilingualText.js';


function RequestLicenseHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;

        if (item.substeps && item.substeps.length) {
            children = <ol type={type}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        return (
            <li>
                {toHtml(item.step)}
                {children}
            </li>
        );
    }

    const helpContent = {
        'header': {
            key: ksmRequestNewLicenseKey,
            default: mainMessages[siteLanguageDefault][ksmRequestNewLicenseKey]
        },
        'topics': [
            {
                key: quickStepsObtainLicensesKey,
                default: mainMessages[siteLanguageDefault][quickStepsObtainLicensesKey],
                hash: "#quick-steps"
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: "#navigation"
            },
            {
                key: requestingNewLicenses,
                default: mainMessages[siteLanguageDefault][requestingNewLicenses],
                hash: '#request-license'
            },
            {
                key: selectingProductsKey,
                default: mainMessages[siteLanguageDefault][selectingProductsKey],
                hash: "#select-product"
            },
            {
                key: assigningProductsToHostKey,
                default: mainMessages[siteLanguageDefault][assigningProductsToHostKey],
                hash: "#assign-product"
            },
            {
                key: previewingLicensesKey,
                default: mainMessages[siteLanguageDefault][previewingLicensesKey],
                hash: "#preview-license"
            },
            {
                key: changingProductAssignmentsKey,
                default: mainMessages[siteLanguageDefault][changingProductAssignmentsKey],
                hash: "#change-product"
            },
            {
                key: generatingDownloadingKey,
                default: mainMessages[siteLanguageDefault][generatingDownloadingKey],
                hash: "#generate-license"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handle"
            }
        ],
        'faq': [
            {
                key: entitlementCertificatesKey,
                default: mainMessages[siteLanguageDefault][entitlementCertificatesKey],
                hash: "#entitlement-cert-faq"
            },
            {
                key: licensesKey,
                default: mainMessages[siteLanguageDefault][licensesKey],
                hash: "#licenses-faq"
            }
        ]
    };

    const quickStepsContent = localization.toLanguageString(
        helpRequestQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpRequestQuickStepsSectionKey]
    );

    const navigationContent = localization.toLanguageString(
        helpRequestNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpRequestNavigationSectionKey]
    );

    const requestLicenseContent = localization.toLanguageString(
        helpRequestRequestingNewLicensesSectionKey,
        mainMessages[siteLanguageDefault][helpRequestRequestingNewLicensesSectionKey]
    );

    const selectProductContent = localization.toLanguageString(
        helpRequestSelectingProductSectionKey,
        mainMessages[siteLanguageDefault][helpRequestSelectingProductSectionKey]
    );

    const assignProductContent = localization.toLanguageString(
        helpRequestAssignProductSectionKey,
        mainMessages[siteLanguageDefault][helpRequestAssignProductSectionKey]
    );

    const previewLicenseContent = localization.toLanguageString(
        helpRequestPreviewLicenseSectionKey,
        mainMessages[siteLanguageDefault][helpRequestPreviewLicenseSectionKey]
    );

    const productChangeContent = localization.toLanguageString(
        helpRequestChangeProductSectionKey,
        mainMessages[siteLanguageDefault][helpRequestChangeProductSectionKey]
    );

    const generatingLicenseContent = localization.toLanguageString(
        helpRequestGeneratingLicenseSectionKey,
        mainMessages[siteLanguageDefault][helpRequestGeneratingLicenseSectionKey]
    );

    const errorHandlingContent = localization.toLanguageString(
        helpRequestErroHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpRequestErroHandlingSectionKey]
    );

    const entitlementQAContent = localization.toLanguageString(
        helpRequestEntitlementCertificateFaqQAKey,
        mainMessages[siteLanguageDefault][helpRequestEntitlementCertificateFaqQAKey]
    );

    const licensesQAContent = localization.toLanguageString(
        helpRequestLicensesFaqQAKey,
        mainMessages[siteLanguageDefault][helpRequestLicensesFaqQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu
                            siteLanguageDefault={siteLanguageDefault}
                            helpContent={helpContent}
                        />
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsObtainLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsObtainLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {quickStepsContent.section_one}
                            </p>
                            <ol>
                                {quickStepsContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>
                        <div
                            className={"k-h2"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {navigationContent.section_one}
                            </p>
                            <ul>
                                {
                                    navigationContent.section_one_bullets?.map((bullet, idx) => {
                                        return <li key={`navigation-steps-${idx}`}>
                                            {toHtml(bullet)}
                                            {
                                                idx === 1 &&
                                                <img src={NavigationImg} alt={'request-license-navigation'}/>
                                            }
                                        </li>;
                                    })
                                }
                            </ul>
                        </div>
                        <div
                            className={"k-h2"}
                            id="request-license"
                        >
                            <Text
                                textkey={requestingNewLicenses}
                                textdefault={mainMessages[siteLanguageDefault][requestingNewLicenses]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {requestLicenseContent.section_one}
                            </p>
                            <ul>
                                {requestLicenseContent.section_one_bullets?.map((bullet, index) => {
                                    return <li key={'request-license-bullet-' + index}>
                                        {bullet}
                                    </li>
                                })}
                            </ul>
                            <div className="paragraph">
                                {toHtml(requestLicenseContent.section_two)}
                            </div>
                        </div>
                        <div
                            className={"k-h2"}
                            id="select-product"
                        >
                            <Text
                                textkey={selectingProductsKey}
                                textdefault={mainMessages[siteLanguageDefault][selectingProductsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(selectProductContent.section_one)}
                            <ul>
                                {
                                    selectProductContent.section_one_bullets?.map((bullet, idx) => {
                                        return <li key={`select-products-${idx}`}><p>{bullet}</p></li>;
                                    })
                                }
                            </ul>
                            <img src={SelectProductsImgOne} alt={'request-license-select-products1'}/>
                            <p>
                                {selectProductContent.section_two}
                            </p>
                            <ol>
                                {
                                    selectProductContent.section_two_bullets?.map((bullet, idx) => {
                                        return <li key={`select-products-${idx}`}>
                                            {toHtml(bullet)}
                                        </li>;
                                    })
                                }
                            </ol>
                            <img className='img-layout' src={SelectProductsImgTwo}
                                 alt={'request-license-select-products2'}/>
                        </div>
                        <div
                            className={"k-h2"}
                            id="assign-product"
                        >
                            <Text
                                textkey={assigningProductsToHostKey}
                                textdefault={mainMessages[siteLanguageDefault][assigningProductsToHostKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(assignProductContent.section_one)}
                            <ol>
                                {
                                    assignProductContent.section_one_bullets?.map((bullet, idx) => {
                                        return (
                                            <li key={`assign-products-${idx}`}>
                                                {toHtml(bullet)}
                                                {
                                                    idx === 0 && <>
                                                        <img src={AssignProductImgOne}
                                                             alt={'request-license-assign-products1'}/>
                                                        <p>{assignProductContent.section_one_sub_bullet}</p>
                                                        <img src={AssignProductImgTwo}
                                                             alt={'request-license-assign-products2'}/>
                                                    </>
                                                }
                                                {
                                                    idx === 1 && <img src={AssignProductImgThree}
                                                                      alt={'request-license-assign-products3'}/>
                                                }
                                            </li>
                                        );
                                    })
                                }
                            </ol>
                            <p>
                                {assignProductContent.section_two}
                            </p>
                            <ol>
                                {
                                    assignProductContent.section_two_bullets?.map((bullet, idx) => {
                                        return <li key={`assign-products-${idx}`}>
                                            {toHtml(bullet)}
                                        </li>;
                                    })
                                }
                            </ol>
                        </div>
                        <div
                            className={"k-h2"}
                            id="preview-license"
                        >
                            <Text
                                textkey={previewingLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][previewingLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(previewLicenseContent.section_one)}
                            <ul>
                                {
                                    previewLicenseContent.section_one_bullets?.map((bullet, idx) => (
                                        <li key={`preview-license-${idx}`}>
                                            {toHtml(bullet)}
                                            {idx === 1 && <img src={PreviewLicenseImg}
                                                               alt={'request-license-preview-license'}/>}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div
                            className={"k-h2"}
                            id="change-product"
                        >
                            <Text
                                textkey={changingProductAssignmentsKey}
                                textdefault={mainMessages[siteLanguageDefault][changingProductAssignmentsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {productChangeContent.section_one}
                            </p>
                            <ol>
                                {
                                    productChangeContent.section_one_bullets?.map((bullet, idx) => (
                                        <li key={`product-change-${idx}`}>
                                            {toHtml(bullet)}
                                            {idx === 0 && <img src={ChangeProductImgOne}
                                                               alt={'request-license-change-product1'}/>}
                                            {idx === 2 && <img src={ChangeProductImgTwo}
                                                               alt={'request-license-change-product2'}/>}
                                        </li>
                                    ))
                                }
                            </ol>
                            <p>
                                {productChangeContent.section_two}
                            </p>
                        </div>
                        <div
                            className={"k-h2"}
                            id="generate-license"
                        >
                            <Text
                                textkey={generatingDownloadingKey}
                                textdefault={mainMessages[siteLanguageDefault][generatingDownloadingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {generatingLicenseContent.section_one}
                            </p>
                            <ul>
                                <ol type='a'>
                                    {

                                        generatingLicenseContent.section_one_bullets?.map((bullet, idx) => (
                                            <li key={`generate-license-${idx}`}>
                                                {toHtml(bullet)}
                                            </li>
                                        ))
                                     }
                                </ol>
                            </ul>
                            {
                                 <img src={GenerateLicenseImgOne}
                                  alt={'request-license-generate-license1'}/>
                            }
                            <p>
                                {generatingLicenseContent.section_two}
                            </p>
                            <ul>
                                <ol type='a'>
                                    {

                                        generatingLicenseContent.section_two_bullets?.map((bullet, idx) => (
                                            <li key={`generate-license-${idx}`}>
                                                {toHtml(bullet)}
                                                {
                                                    idx === 2 && (
                                                        <>
                                                            <ol type='i'>
                                                                {
                                                                generatingLicenseContent.section_two_sub_bullets_c?.map((sub_bullet, sub_index) => (
                                                                    <li key={`generate-license-${sub_index}`}>
                                                                            {sub_bullet}
                                                                            {
                                                                                sub_index === 0 && (
                                                                                    <>
                                                                                        <ol type='1'>
                                                                                            {
                                                                                            generatingLicenseContent.section_two_sub_bullets_c_i?.map((sub_bullet, sub_index) => (
                                                                                                <li key={`generate-license-${sub_index}`}>
                                                                                                        {sub_bullet}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ol>

                                                                                    </>
                                                                                )
                                                                            }
                                                                            {
                                                                                sub_index === 1 && (
                                                                                    <>
                                                                                        <ol type='1'>
                                                                                            {
                                                                                            generatingLicenseContent.section_two_sub_bullets_c_ii?.map((sub_bullet, sub_index) => (
                                                                                                <li key={`generate-license-${sub_index}`}>
                                                                                                        {sub_bullet}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ol>

                                                                                    </>
                                                                                )
                                                                            }
                                                                    </li>
                                                                ))}
                                                            </ol>

                                                        </>
                                                    )
                                                }

                                            </li>
                                        ))
                                    }
                                </ol>
                            </ul>
                        {
                             <img src={GenerateLicenseImgTwo}
                                  alt={'request-license-generate-license1'}/>
                        }
                        </div>
                        <div
                            className={"k-h2"}
                            id="error-handle"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {errorHandlingContent.section_one}
                            </p>
                            <ol>
                                {
                                    errorHandlingContent.section_one_bullets?.map((bullet, idx) => (
                                        <li key={`error-handle-${idx}`}>
                                            <p>{bullet}</p>
                                        </li>
                                    ))
                                }
                            </ol>
                            <p>
                                {errorHandlingContent.section_two}
                            </p>
                        </div>
                        <div
                            className={"k-h2"}
                            id="error-handle"
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <h2 id='entitlement-cert-faq'>
                                <Text
                                    textkey={entitlementCertificatesKey}
                                    textdefault={mainMessages[siteLanguageDefault][entitlementCertificatesKey]}
                                />
                            </h2>
                            <ul>
                                {
                                    entitlementQAContent?.map((entitlementQAObj, idx) => {
                                        return <li key={`entitlement-qa-${idx}`}>
                                            <p>{entitlementQAObj.Q}</p>
                                            <p className="top indent">{entitlementQAObj.A}</p>
                                        </li>;
                                    })
                                }
                            </ul>
                            <h2 id='licenses-faq'>
                                <Text
                                    textkey={licensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][licensesKey]}
                                />
                            </h2>
                            <ul>
                                {
                                    licensesQAContent?.map((licenseQAObj, idx) => {
                                        return <li key={`license-qa-${idx}`}>
                                            <p>{licenseQAObj.Q}</p>
                                            <p className="top indent">{licenseQAObj.A}</p>
                                        </li>;
                                    })
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default RequestLicenseHelp;

