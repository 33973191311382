import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMEARequestLicenseNavigation from '../assets/img/Help/ksm-ea-request-license-navigation.png';
import KSMEARequestLicenseSelectProducts from '../assets/img/Help/ksm-ea-request-license-select-products.png';
import KSMEARequestLicenseAddToCart from '../assets/img/Help/ksm-ea-request-license-add-to-cart.png';
import KSMEARequestLicenseAddToCartDetails from '../assets/img/Help/ksm-ea-request-license-add-to-cart-details.png';
import KSMEARequestLicenseSelectProductsDelete
    from '../assets/img/Help/ksm-ea-request-license-select-products-delete.png';
import KSMEARequestLicenseAssignProductsNavigation
    from '../assets/img/Help/ksm-ea-request-license-assign-products-navigation.png';
import KSMEARequestLicenseAssignProducts from '../assets/img/Help/ksm-ea-request-license-assign-products.png';
import KSMEARequestLicenseAssignProductsSave from '../assets/img/Help/ksm-ea-request-license-assign-products-save.png';
import KSMEARequestLicenseAssignProductsDelete
    from '../assets/img/Help/ksm-ea-request-license-assign-products-delete.png';
import KSMEARequestLicenseAssignProductsDetails
    from '../assets/img/Help/ksm-ea-request-license-assign-products-details.png';
import KSMEARequestLicenseChangeEndDate from '../assets/img/Help/ksm-ea-request-license-change-end-date.png';
import KSMEARequestLicenseRequest from '../assets/img/Help/ksm-ea-request-license-request.png';
import KSMEARequestLicenseGenerate from '../assets/img/Help/ksm-ea-request-license-generate.png';


// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    helpEARequestLicenseAssigningProductsSectionKey,
    assigningProductsToHostsKey,
    eaAccessKey,
    helpEARequestLicenseEAAccessQAKey,
    helpEARequestLicenseEndDateChangeSectionKey,
    changingLicenseEndDatesKey,
    enterpriseAgreementsKey,
    helpEARequestLicenseEnterpriseAgreementsQAKey,
    helpEARequestLicenseErrorHandlingSectionKey,
    errorHandlingKey,
    frequentlyAskedQuestionsKey,
    helpEARequestLicenseGettingLicensesSectionKey,
    gettingLicensesKey,
    eaLicenseRequestsKey,
    hostsKey,
    helpEARequestLicenseHostsQAKey,
    licensesKey,
    helpEARequestLicenseLicensesQAKey,
    mainMessages,
    helpEARequestLicenseNavigationSectionKey,
    navigationKey,
    quickStepToGetLicensesKey,
    helpEARequestLicenseQuickStepsSectionKey,
    quickStepsGetLicensesKey,
    selectingProductsKey,
    helpEARequestLicenseSelectingProductsSectionKey,
} from '../assets/text/MultilingualText.js';

function EARequestLicenseHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;

        let img;
        let imgInlineStyle = (item.img === "ksm-ea-request-license-add-to-cart" || item.img === "ksm-ea-request-license-assign-products-delete") ? {
            display: 'flex',
            whiteSpace: "nowrap"
        } : {};

        if (item.img) {
            switch (item.img) {
                case 'ksm-ea-request-license-add-to-cart':
                    img = <img src={KSMEARequestLicenseAddToCart} alt={item.img}/>
                    break;
                case 'ksm-ea-request-license-assign-products-delete':
                    img = <img src={KSMEARequestLicenseAssignProductsDelete} alt={item.img}/>
                    break;
                default:
                    img = <></>
                    break;
            }
        }

        if (item.substeps && item.substeps.length) {
            let types = item.substeps.map(i => i.type);
            let isDashed = types.every(t => t === "dash");

            children = isDashed ? <>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </> : type === "bullet" ? <ul style={{listStyleType: 'disc'}}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ul> : <ol type={type}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        if (item.type === "dash") {
            return (
                <div
                    style={{
                        ...bulletStyle,
                        ...{display: 'flex'}
                    }}
                >
                    &ndash;&nbsp;{toHtml(item.step)}
                </div>
            )
        } else if (item.img === 'ksm-ea-request-license-add-to-cart' || item.img === 'ksm-ea-request-license-assign-products-delete') {
            return (
                <li>
                    <div style={imgInlineStyle}>
                        {toHtml(item.step_prefix)}
                        {item.img ? <span className="img-container">
                            {img}
                        </span> : <></>}
                        {toHtml(item.step_suffix)}
                    </div>
                    {toHtml(item.step_suffix_two)}
                    {children}
                </li>
            )
        } else if (item.img === 'ksm-ea-request-license-select-products' ||
            item.img === 'ksm-ea-request-license-assign-products' ||
            item.img === 'ksm-ea-request-license-assign-products-save' ||
            item.img === 'ksm-ea-request-license-assign-products-details' ||
            item.img === 'ksm-ea-request-license-request' ||
            item.img === 'ksm-ea-request-license-generate'
        ) {
            let source = item.img === 'ksm-ea-request-license-select-products' ? KSMEARequestLicenseSelectProducts
                : item.img === 'ksm-ea-request-license-assign-products' ? KSMEARequestLicenseAssignProducts
                    : item.img === 'ksm-ea-request-license-assign-products-save' ? KSMEARequestLicenseAssignProductsSave
                        : item.img === 'ksm-ea-request-license-assign-products-details' ? KSMEARequestLicenseAssignProductsDetails
                            : item.img === 'ksm-ea-request-license-request' ? KSMEARequestLicenseRequest
                                : item.img === 'ksm-ea-request-license-generate' ? KSMEARequestLicenseGenerate
                                    : "";
            return (
                <>
                    <li>
                        <div style={children ? {paddingBottom: 6} : {}}>
                            {toHtml(item.step)}
                        </div>
                    </li>
                    <span style={{marginLeft: -30}}>
                        <img src={source} alt={item.img}/>
                    </span>
                </>
            )
        } else if (item.img === 'ksm-ea-request-license-assign-products-navigation') {
            return (
                <>
                    <li>
                        <div style={children ? {paddingBottom: 6} : {}}>
                            {toHtml(item.step)}
                        </div>
                    </li>
                    <img src={KSMEARequestLicenseAssignProductsNavigation} alt={item.img}/>
                </>
            )
        } else {
            return (
                <li>
                    <div style={children ? {paddingBottom: 6} : {}}>
                        {toHtml(item.step)}
                        {item.img ? <span className="img-container">
                            {img}
                        </span> : <></>}
                    </div>
                    {children}
                </li>
            );
        }
    }

    const bulletStyle = {
        paddingTop: 6,
        paddingBottom: 6
    };

    const helpContent = {
        'header': {
            key: eaLicenseRequestsKey,
            default: mainMessages[siteLanguageDefault][eaLicenseRequestsKey]
        },
        'topics': [
            {
                key: quickStepToGetLicensesKey,
                default: mainMessages[siteLanguageDefault][quickStepToGetLicensesKey],
                hash: '#quick-steps'
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: '#navigation'
            },
            {
                key: selectingProductsKey,
                default: mainMessages[siteLanguageDefault][selectingProductsKey],
                hash: '#selecting-products'
            },
            {
                key: assigningProductsToHostsKey,
                default: mainMessages[siteLanguageDefault][assigningProductsToHostsKey],
                hash: '#assigning-products'
            },
            {
                key: changingLicenseEndDatesKey,
                default: mainMessages[siteLanguageDefault][changingLicenseEndDatesKey],
                hash: '#change-end-date'
            },
            {
                key: gettingLicensesKey,
                default: mainMessages[siteLanguageDefault][gettingLicensesKey],
                hash: '#getting-licenses'
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: '#error-handling'
            }
        ],
        'faq': [
            {
                key: enterpriseAgreementsKey,
                default: mainMessages[siteLanguageDefault][enterpriseAgreementsKey],
                hash: '#enterprise-agreements-faq'
            },
            {
                key: licensesKey,
                default: mainMessages[siteLanguageDefault][licensesKey],
                hash: '#licenses-faq'
            },
            {
                key: hostsKey,
                default: mainMessages[siteLanguageDefault][hostsKey],
                hash: '#hosts-faq'
            },
            {
                key: eaAccessKey,
                default: mainMessages[siteLanguageDefault][eaAccessKey],
                hash: '#ea-access-faq'
            }
        ]
    };

    const quickStepsContent = localization.toLanguageString(
        helpEARequestLicenseQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseQuickStepsSectionKey]
    );

    const navigationContent = localization.toLanguageString(
        helpEARequestLicenseNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseNavigationSectionKey]
    );

    const selectingProductsContent = localization.toLanguageString(
        helpEARequestLicenseSelectingProductsSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseSelectingProductsSectionKey]
    );

    const assigningProductsContent = localization.toLanguageString(
        helpEARequestLicenseAssigningProductsSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseAssigningProductsSectionKey]
    );

    const endDateChangeContent = localization.toLanguageString(
        helpEARequestLicenseEndDateChangeSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseEndDateChangeSectionKey]
    );

    const gettingLicensesContent = localization.toLanguageString(
        helpEARequestLicenseGettingLicensesSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseGettingLicensesSectionKey]
    );

    const errorHandlingContent = localization.toLanguageString(
        helpEARequestLicenseErrorHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseErrorHandlingSectionKey]
    );

    const enterpriseAgreementsQA = localization.toLanguageString(
        helpEARequestLicenseEnterpriseAgreementsQAKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseEnterpriseAgreementsQAKey]
    );

    const licensesQA = localization.toLanguageString(
        helpEARequestLicenseLicensesQAKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseLicensesQAKey]
    );

    const hostsQA = localization.toLanguageString(
        helpEARequestLicenseHostsQAKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseHostsQAKey]
    );

    const eAAccessQA = localization.toLanguageString(
        helpEARequestLicenseEAAccessQAKey,
        mainMessages[siteLanguageDefault][helpEARequestLicenseEAAccessQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsGetLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsGetLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(quickStepsContent.section_one)}
                            <ol type="1">
                                {quickStepsContent.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(navigationContent.section_one)}
                            {navigationContent.section_one_bullets ? <ul>
                                {navigationContent.section_one_bullets.map((bullet, index) => <li
                                    key={'ea-request-license-navigation-bullet-' + index}
                                >
                                    {toHtml(bullet)}
                                    {index === 1 ? <img src={KSMEARequestLicenseNavigation} alt="ksm-ea-request-license-navigation"/> : <></>}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="selecting-products"
                        >
                            <Text
                                textkey={selectingProductsKey}
                                textdefault={mainMessages[siteLanguageDefault][selectingProductsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(selectingProductsContent.section_one)}
                            <ol type="1">
                                {selectingProductsContent.section_one_steps.map((step, index) => <div
                                    key={"ea-request-license-selecting-products-step-" + index}
                                >
                                    <OrderedListItem
                                        item={step}
                                        key={step.order}
                                    />
                                    {index === 0 ? <>
                                        <p>
                                            {selectingProductsContent.section_one_step_1b}
                                        </p>
                                        {selectingProductsContent.section_one_step_1b_bullets.map((bullet, index) =>
                                            <div
                                                key={'ea-request-license-selecting-products-bullet-' + index}
                                                className="section"
                                                style={{
                                                    ...bulletStyle,
                                                    ...{display: 'flex'}
                                                }}
                                            >
                                                &ndash;&nbsp;{toHtml(bullet)}
                                            </div>)}
                                    </> : <></>}
                                    {index === 1 ? <>
                                        {toHtml(selectingProductsContent.section_one_step_2d1)}
                                        <img src={KSMEARequestLicenseAddToCartDetails}
                                             alt="ksm-ea-request-license-add-to-cart-details"/>
                                        <p>
                                            {selectingProductsContent.section_one_step_2d2}
                                        </p>
                                        {selectingProductsContent.section_one_step_2d_bullets.map((bullet, index) =>
                                            <div
                                                key={'ea-request-license-selecting-products-bullet-' + index}
                                                className="section"
                                                style={{
                                                    ...bulletStyle,
                                                    ...{display: 'flex'}
                                                }}
                                            >
                                                &ndash;&nbsp;{toHtml(bullet)}
                                            </div>)}
                                    </> : <></>}
                                </div>)}
                            </ol>
                            {toHtml(selectingProductsContent.section_two)}
                            <h3>
                                <b>{selectingProductsContent.section_three}</b>
                            </h3>
                            {toHtml(selectingProductsContent.section_four)}
                            <p>
                                {selectingProductsContent.section_five}
                            </p>
                            <p>
                                {selectingProductsContent.section_six}
                            </p>
                            <img src={KSMEARequestLicenseSelectProductsDelete} alt="ksm-ea-request-license-select-products-delete"/>
                            <h3>
                                <b>{selectingProductsContent.section_seven}</b>
                            </h3>
                            {toHtml(selectingProductsContent.section_eight)}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="assigning-products"
                        >
                            <Text
                                textkey={assigningProductsToHostsKey}
                                textdefault={mainMessages[siteLanguageDefault][assigningProductsToHostsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {assigningProductsContent.section_one}
                            </p>
                            <p>
                                {assigningProductsContent.section_two}
                            </p>
                            <ol type="1">
                                {assigningProductsContent.section_two_steps.map((step, index) => <div
                                    key={"ea-request-license-assigning-products-step-" + index}
                                >
                                    <OrderedListItem
                                        item={step}
                                        key={step.order}
                                    />
                                    {index === 0 ? toHtml(assigningProductsContent.section_two_step_1) : <></>}
                                </div>)}
                            </ol>
                            {toHtml(assigningProductsContent.section_three)}
                            {assigningProductsContent.section_three_bullets ? <ul>
                                {assigningProductsContent.section_three_bullets.map((bullet, index) => <li
                                    key={'ea-request-license-assign-products-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="change-end-date"
                        >
                            <Text
                                textkey={changingLicenseEndDatesKey}
                                textdefault={mainMessages[siteLanguageDefault][changingLicenseEndDatesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {endDateChangeContent.section_one}
                            </p>
                            {toHtml(endDateChangeContent.section_two)}
                            {endDateChangeContent.section_two_bullets ? <ul>
                                {endDateChangeContent.section_two_bullets.map((bullet, index) => <li
                                    key={'ea-request-license-change-end-date-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                            <img src={KSMEARequestLicenseChangeEndDate} alt='ksm-ea-request-license-change-end-date'/>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="getting-licenses"
                        >
                            <Text
                                textkey={gettingLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][gettingLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {gettingLicensesContent.section_one}
                            </p>
                            <p>
                                {gettingLicensesContent.section_two}
                            </p>
                            <ol type="1">
                                {gettingLicensesContent.section_two_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                            <p>
                                {gettingLicensesContent.section_three}
                            </p>
                            {gettingLicensesContent.section_three_bullets ? <ul>
                                {gettingLicensesContent.section_three_bullets.map((bullet, index) => <li
                                    key={'ea-request-license-getting-licenses-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                            <p>
                                {gettingLicensesContent.section_four}
                            </p>
                            <p>
                                <b>{gettingLicensesContent.section_five}</b>
                            </p>
                            {gettingLicensesContent.section_five_bullets ? <ul>
                                {gettingLicensesContent.section_five_bullets.map((bullet, index) => <li
                                    key={'ea-request-license-getting-licenses-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                            <p>
                                <b>{gettingLicensesContent.section_six}</b>
                            </p>
                            {toHtml(gettingLicensesContent.section_seven)}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {errorHandlingContent.section_one}
                            </p>
                            {errorHandlingContent.section_one_bullets ? <ul>
                                {errorHandlingContent.section_one_bullets.map((bullet, index) => <li
                                    key={'ea-request-license-error-handling-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div className={"k-h2 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="enterprise-agreements-faq">
                            <h3>
                                <Text
                                    textkey={enterpriseAgreementsKey}
                                    textdefault={mainMessages[siteLanguageDefault][enterpriseAgreementsKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {enterpriseAgreementsQA ? enterpriseAgreementsQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"enterprise-agreements-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="licenses-faq">
                            <h3>
                                <Text
                                    textkey={licensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][licensesKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {licensesQA ? licensesQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"licenses-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="hosts-faq">
                            <h3>
                                <Text
                                    textkey={hostsKey}
                                    textdefault={mainMessages[siteLanguageDefault][hostsKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {hostsQA ? hostsQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"hosts-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="ea-access-faq">
                            <h3>
                                <Text
                                    textkey={eaAccessKey}
                                    textdefault={mainMessages[siteLanguageDefault][eaAccessKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {eAAccessQA ? eAAccessQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"ea-access-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default EARequestLicenseHelp;

