import React, {useContext, useRef, useState} from "react";
import UserContext from "../UserContext";

//kendo
import {Checkbox} from "@progress/kendo-react-inputs";
import {useLocalization} from "@progress/kendo-react-intl";
import {Popover} from "@progress/kendo-react-tooltip";

//multilingual
import {incompatibleProductsSelectedKey, infoKey, mainMessages} from "../../../assets/text/MultilingualText";


export default function SelectCell(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const anchor = useRef(null);
    const [popoverShow, setPopoverShow] = useState(false);

    // show popover if there is an error on mouse over
    const onMouseOver = (ev) => {
        setPopoverShow(true);
    };

    // remove popover on mouse leave
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) setPopoverShow(false);
    };

    return <td
        ref={anchor}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
    >
        {props.dataItem.disabled && //Need CSS pointer-events: auto;
            <Popover
                show={popoverShow}
                anchor={anchor.current}
                position={'right'}
                className={'ksm-popover ksm-popover-alert-info'}
                animate={false}
            >
                <div style={{
                    display: 'flex'
                }}>
                        <span
                            className="k-icon k-i-information"
                            style={{
                                color: 'var(--keysight-purple)',
                                fontSize: '2.5rem',
                                marginRight: '0.938rem'
                            }}
                        />
                    <div>
                        <b>{localization.toLanguageString(infoKey, mainMessages[siteLanguageDefault][infoKey])}</b>
                        <br/>
                        {localization.toLanguageString(incompatibleProductsSelectedKey, mainMessages[siteLanguageDefault][incompatibleProductsSelectedKey])}
                    </div>
                </div>
            </Popover>
        }
        <Checkbox
            type="checkbox"
            onChange={props.selectionChange}
            value={props.dataItem.selected}
            disabled={props.dataItem.disabled}
        />
    </td>
}