import React, {useContext, useRef, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// kendo react
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    mainMessages,
    accountNoSupportProductKey,
    cannotSupportSubscriptionKey
} from '../../../assets/text/MultilingualText.js';


// order number cell for standalone renewal grid
export const OrderNumberCell = (props) => {
    const {getStandaloneRenewalLicenses} = props

    const anchor = useRef(null);
    const [showPopover, setShowPopover] = useState(false);

    let disabled = props.dataItem.disabled || false;
    let style = {
        whiteSpace: "nowrap"
    };

    if (!disabled) {
        style["fontWeight"] = "bold";
        style["color"] = "var(--keysight-blue)";
    }

    //Show popover if there is an error on mouse over
    const onMouseOver = (ev) => {
        setShowPopover(true);
    };

    //Remove popover on mouse leave
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) setShowPopover(false);
    };

    /*
     * onProductNumberClick(props) takes the user to step 2 to select licenses
    */
    const onProductNumberClick = () => {
        if (!disabled) {
            getStandaloneRenewalLicenses(props.dataItem);
        }
    }

    return (
        <td
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            style={style}
        >
            <span
                className={disabled ? "order-number-cell disabled" : "order-number-cell"}
                ref={anchor}
                onClick={onProductNumberClick}
            >
                {disabled ? <NoRenewalPopover
                        showPopover={showPopover}
                        anchor={anchor}
                    />
                    :
                    <></>}
                {props.dataItem[props.field]}
            </span>
        </td>
    )
}

const NoRenewalPopover = (props) => {
    const {
        showPopover,
        anchor
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let title;
    let message;

    title = localization.toLanguageString(
        cannotSupportSubscriptionKey,
        mainMessages[siteLanguageDefault][cannotSupportSubscriptionKey]
    );

    message = localization.toLanguageString(
        accountNoSupportProductKey,
        mainMessages[siteLanguageDefault][accountNoSupportProductKey]
    );

    return (
        <Popover
            show={showPopover}
            anchor={anchor.current}
            position={'top'}
            className={'ksm-popover ksm-popover-alert-info'}
            animate={false}
        >
            <div
                style={{
                    display: 'flex'
                }}
            >
                <span
                    className={'k-icon k-i-information'}
                    style={{
                        color: 'var(--keysight-purple)',
                        fontSize: '2.5rem',
                        marginRight: '0.938rem',
                    }}
                />
                <div>
                    <b>{title}</b>
                    <br/>
                    {message}
                </div>
            </div>
        </Popover>
    )
}