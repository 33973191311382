import React, {useContext, useRef, useState} from 'react';
import UserContext from '../../common/UserContext';

// kendo react
import {Popover} from '@progress/kendo-react-tooltip';
import {NumericTextBox} from '@progress/kendo-react-inputs';
import {useLocalization} from "@progress/kendo-react-intl";
import {
    mainMessages, noQuantityKey, noQuantityMessageKey
} from "../../../assets/text/MultilingualText";

//Custom cell and logic for Assign Qty
export const AssignQtyCell = (props) => {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const anchor = useRef(null);
    const [popoverShow, setPopoverShow] = useState(false)

    const localization = useLocalization();

    //bound input events and values to the Grid
    const onChange = (ev) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: ev.syntheticEvent,
                value: ev.target.value,
            });
        }
    };

    //Set unchangeable element when qty is 1 and for products that are 1 per host
    if (Number(props.dataItem.qty_available) === 1 || props.dataItem.redeem_one_per_host === "Y") {
        return (
            <td style={{
                textAlign: 'center',
                verticalAlign: 'middle'
            }}>
                {props.dataItem.qty_requested}
            </td>
        )
    }

    const field = props.field;
    const dataValue = props.dataItem[field];
    let style = {}

    //Show error handling when assign qty is empty
    let isError = props.dataItem.selected && (
        props.dataItem.qty_requested === '' ||
        props.dataItem.qty_requested === null)

    //Outline border as red
    if (isError) {
        style = {
            border: '2px solid rgb(233, 0, 41)'
        }
    }

    //Show popover if there is an error on mouse over
    const onMouseOver = (ev) => {
        if (isError) setPopoverShow(true);
    };

    //Remove popover on mouse leave
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) setPopoverShow(false);
    };


    return (
        <td ref={anchor} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            {isError && (
                <Popover
                    show={popoverShow}
                    anchor={anchor.current}
                    position={'top'}
                    className={'ksm-popover ksm-popover-error'}
                    animate={false}
                >
                    <div style={{
                        display: 'flex'
                    }}>
                        <span
                            className="k-icon k-i-close-outline"
                            style={{
                                color: 'var(--keysight-primary)',
                                fontSize: '2.5rem',
                                marginRight: '0.938rem'
                            }}
                        />
                        <div>
                            <b>{localization.toLanguageString(noQuantityKey, mainMessages[siteLanguageDefault][noQuantityKey])}</b>
                            <br/>
                            {localization.toLanguageString(noQuantityMessageKey, mainMessages[siteLanguageDefault][noQuantityMessageKey])}
                        </div>
                    </div>
                </Popover>
            )}
            <NumericTextBox
                className={'license-grid-assign-qty'}
                style={style}
                onChange={onChange}
                value={dataValue}
                spinners={false}
                max={Number(props.dataItem.qty_available)}
                min={1}
            />
        </td>
    );
};