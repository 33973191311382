import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import config from '../../../../config';
import UserContext from '../../UserContext';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {toHtml} from '../../utilities';
import DialogLayout from "./DialogLayout";

// kendo react
import {Label} from '@progress/kendo-react-labels';
import {AutoComplete} from '@progress/kendo-react-dropdowns';
import {Popover} from '@progress/kendo-react-tooltip';
import {filterBy} from '@progress/kendo-data-query';
import {Button} from "@progress/kendo-react-buttons";

// multilingual
import {
    hostIdFormattingKey,
    mainMessages,
    serialNumberFormattingKey,
} from "../../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";


function HostSerialModal(props) {
    const {
        setIsLoading,
        hostDetails,
        assignedProducts,
        selectedProducts,
        onePerHost = 'redeem_one_per_host',
        productNum = 'product_number',
        uniqueID = 'unique_id',
        errors,
        setErrors,
        isValid,
        setIsValid,
        showError,
        createHost,
        resetSelectedProducts,
        closeModal,
        children,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let hostIDType = hostDetails.host_id_type || ""
    let hostIsSerial = hostDetails.get_serial.toUpperCase() || "";

    let hostIDLabel = hostDetails.host_id_info.asl_prompt || "";
    let hostIDHint = hostDetails.host_id_info.input_hint || "";
    let hostIDPatterns = hostDetails.host_id_info.patterns || [];
    let hostIDSuggestions = hostDetails.user_profile_tagged_host_ids || [];

    let serialNumberLabel = hostDetails.serial_number_info.asl_prompt || "";
    let serialNumberHint = hostDetails.serial_number_info.input_hint || "";
    let serialNumberPatterns = hostDetails.serial_number_info.patterns || [];

    const [hostID, setHostID] = useState("");
    const hostIDPopover = useRef(null);

    const [serialNumber, setSerialNumber] = useState("");
    const serialNumberPopover = useRef(null);

    const [isVisible, setIsVisible] = useState({
        hostIDHint: false,
        serialNumberHint: false
    })

    /*
     * validateHostPatterns() validates the user host id || serial number input on the frontend prior to validating the
     * host id || serial number input on the backend
    */
    const validate = () => {
        setErrors([]);
        setIsValid({
            hostID: true,
            serialNumber: true,
        });
        setIsVisible(isVisible => ({
            ...isVisible,
            hostIDHint: false,
            serialNumberHint: false
        }));

        let isValidHostID = !hostID ? false : hostIDPatterns.some(regex => regex.test(hostID.trim()));
        let isValidSerialNumber = (!serialNumber && hostIsSerial === "Y") ? false : serialNumberPatterns.some(regex => regex.test(serialNumber.trim()));

        // show errors for invalid host ids and serial numbers
        if (!isValidHostID) {
            showError("hostID");
        }
        if (!isValidSerialNumber && (hostIsSerial === "Y" || hostIsSerial === "O")) {
            showError("serialNumber");
        }

        // call regex validation api for the following cases
        if (isValidHostID && isValidSerialNumber && (hostIsSerial === "Y" || hostIsSerial === "O")) {
            validateHostRegEx();
        }
        if (isValidHostID && hostIsSerial === "N") {
            validateHostRegEx();
        }
    };

    /*
     * validateHostRegEx() validates the user host id || serial number input on the backend
    */
    const validateHostRegEx = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let data = {
            "host_id_type": hostIDType,
            "host_or_imei_values": [hostID.trim()],
            "serial_id_value": serialNumber.trim()
        };

        setIsLoading(true);

        axios.post(
            config.request_license.HOST_VALIDATION_REGEX,
            data,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setErrors([]);
                    saveHostID();
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Validation Regex", error);
                let status = error?.response?.status
                let errorCode = error?.response?.data?.error_code

                if (status === 400) {
                    if (errorCode === "MOD_HSVAL_211") {
                        showError('hostID')
                    } else if (errorCode === "MOD_HSVAL_212") {
                        showError('serialNumber')
                    } else {
                        showError('generic')
                    }
                } else {
                    showError('generic')
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    /*
     * saveHostID() saves the assigned host
    */
    const saveHostID = () => {
        let host;
        const hostId = hostID.trim()
        const serialNumberTrim = serialNumber.trim()

        let panelBarTitle = "Host " + hostId;
        if (hostIsSerial === "Y" || hostIsSerial === "O") {
            host = assignedProducts.find(host =>
                host.host_id &&
                hostId &&
                host.host_id === hostId &&
                host.serial_id === serialNumberTrim
            );
            panelBarTitle += serialNumberTrim ? ", " + serialNumberTrim : "";
        }
        if (hostIsSerial === "N") {
            host = assignedProducts.find(host =>
                host.host_id &&
                hostId &&
                host.host_id === hostId
            )
        }

        // case: products that are 1 per host can only be assigned to a single host
        if (host) {
            let unAssignable = [];
            selectedProducts.forEach((item) => {
                if (item.selected) {
                    let product = host.products.find(product => item[uniqueID] === product[uniqueID]);
                    if (item[onePerHost].toUpperCase() === "Y" && product) {
                        unAssignable.push(product[productNum]);
                    }
                }
            })

            if (unAssignable.length > 0) {
                unAssignable.forEach((item) => {
                    showError('onePerHost', item);
                })
                return null
            }
        }

        createHost(panelBarTitle, host, hostId, serialNumberTrim)
        resetSelectedProducts()
    }

    // Filter the Host ID suggestion dropdown based on user's input
    const [filteredHostIDSuggestions, setFilteredHostIDSuggestions] = useState([...hostIDSuggestions])
    const filterData = (value) => {
        const data = hostIDSuggestions
        const filter = {
            value: value,
            operator: "contains",
            ignoreCase: true,
        };
        if (value) {
            setFilteredHostIDSuggestions(filterBy(data, filter))
        } else {
            setFilteredHostIDSuggestions(data)
        }
    };

    //set focus to host id input field when host modal is HOST
    const hostIDField = useRef(null)
    useEffect(() => {
        hostIDField.current._input.focus();
    }, []);


    return (
        <DialogLayout
            closeModal={closeModal}
            productTable={children}
            errors={errors}
            validate={validate}
        >
            <Row>
                <Col>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.2rem',
                        lineHeight: '2rem'
                    }}>
                        <Label>{toHtml(hostIDLabel)}</Label>
                        <div>
                            <span
                                onClick={() => {
                                    setIsVisible(isVisible => ({
                                        ...isVisible,
                                        hostIDHint: !isVisible.hostIDHint,
                                        serialNumberHint: false
                                    }));
                                }}
                                className={'k-icon k-i-information k-i-info'}
                                style={{
                                    color: 'var(--keysight-blue)',
                                    cursor: 'pointer',
                                    fontSize: '1.3rem',
                                    fontWeight: '550'
                                }}
                            />
                        </div>
                    </div>
                    <Popover
                        show={isVisible.hostIDHint}
                        anchor={hostIDPopover.current}
                        position={'right'}
                        className={'ksm-popover ksm-popover-warning'}
                        animate={false}
                        style={{maxWidth: 600}}
                    >
                        <Row>
                            <Col xs={"auto"}>
                                    <span
                                        className="k-icon k-i-warning"
                                        style={{
                                            color: 'var(--keysight-yellow)',
                                            fontSize: '2.5rem'
                                        }}
                                    />
                            </Col>
                            <Col>
                                <b>
                                    {localization.toLanguageString(hostIdFormattingKey, mainMessages[siteLanguageDefault][hostIdFormattingKey])}
                                </b>
                                <br/>
                                {toHtml(hostIDHint)}
                            </Col>
                            <Col xs={1}>
                                <Button
                                    icon="close"
                                    className={"alert-close-button"}
                                    onClick={() => {
                                        setIsVisible(isVisible => ({
                                            ...isVisible,
                                            hostIDHint: false,
                                        }));
                                    }}
                                />
                            </Col>
                        </Row>
                    </Popover>
                    <div ref={hostIDPopover}>
                        <AutoComplete
                            ref={hostIDField}
                            value={hostID}
                            data={filteredHostIDSuggestions}
                            onChange={(e) => {
                                setIsValid(isValid => ({
                                    ...isValid,
                                    hostID: true
                                }));

                                filterData(e.value)
                                setHostID(e.value);
                            }}
                            valid={isValid.hostID}
                            listNoDataRender={(e) => null}
                        />
                    </div>
                </Col>
                {(hostIsSerial === "Y" || hostIsSerial === "O") ?
                    <Col style={{
                        paddingLeft: 0
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.2rem',
                            lineHeight: '2rem'
                        }}>
                            <Label>
                                {hostIsSerial === "O" ? toHtml(serialNumberLabel + " (Optional)") : toHtml(serialNumberLabel)}
                            </Label>
                            <div>
                                    <span
                                        onClick={() => {
                                            setIsVisible(isVisible => ({
                                                ...isVisible,
                                                hostIDHint: false,
                                                serialNumberHint: !isVisible.serialNumberHint
                                            }));
                                        }}
                                        className={'k-icon k-i-information k-i-info'}
                                        style={{
                                            color: 'var(--keysight-blue)',
                                            cursor: 'pointer',
                                            fontSize: '1.3rem',
                                            fontWeight: '550'
                                        }}
                                    />
                            </div>
                        </div>
                        <Popover
                            show={isVisible.serialNumberHint}
                            anchor={serialNumberPopover.current}
                            position={'left'}
                            className={'ksm-popover ksm-popover-warning'}
                            animate={false}
                            style={{maxWidth: 600}}
                        >
                            <Row>
                                <Col xs={"auto"}>
                                    <span
                                        className="k-icon k-i-warning"
                                        style={{
                                            color: 'var(--keysight-yellow)',
                                            fontSize: '2.5rem'
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <b>
                                        {localization.toLanguageString(serialNumberFormattingKey, mainMessages[siteLanguageDefault][serialNumberFormattingKey])}
                                    </b>
                                    <br/>
                                    {toHtml(serialNumberHint)}
                                </Col>
                                <Col xs={1}>
                                    <Button
                                        icon="close"
                                        className={"alert-close-button"}
                                        onClick={() => {
                                            setIsVisible(isVisible => ({
                                                ...isVisible,
                                                serialNumberHint: false,
                                            }));
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Popover>
                        <div ref={serialNumberPopover}>
                            <AutoComplete
                                value={serialNumber}
                                onChange={(e) => {
                                    setIsValid(isValid => ({
                                        ...isValid,
                                        serialNumber: true
                                    }));
                                    setSerialNumber(e.value);
                                }}
                                valid={isValid.serialNumber}
                                listNoDataRender={(e) => null}
                            />
                        </div>
                    </Col>
                    :
                    <Col/>
                }
            </Row>
        </DialogLayout>
    )
}

export default HostSerialModal;