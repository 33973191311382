import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/RequestLicense.css';

// react router
import {useHistory, useLocation} from 'react-router-dom';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Spinner from '../components/common/Spinner.js';
import DocumentationGrid from '../components/SoftwareUpdate/DocumentationGrid.js';
import SoftwareFirmwareGrid from '../components/SoftwareUpdate/SoftwareFirmwareGrid.js';
import ExternalGrid from '../components/SoftwareUpdate/ExternalGrid.js';
import LicensesGrid from '../components/SoftwareUpdate/LicensesGrid.js';
import PageHeader from '../components/common/PageHeader.js';
import ShipmentModal from '../components/SoftwareUpdate/SoftwareShipments/ShipmentModal.js'
import {Text} from '../components/common/MultilingualText.js'
import Messages from '../components/SoftwareUpdate/SoftwareShipments/Messages.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {
    mainMessages,
    softwareUpdatesKey,
    updateKey
} from '../assets/text/MultilingualText.js';


function SoftwareUpdateDetail(props) {
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);

    let history = useHistory();
    const redirect = (actionLink) => {
        history.push(actionLink);
    }

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [isModal, setModal] = useState(false);
    const [documentation, setDocumentation] = useState([]);
    const [software, setSoftware] = useState([]);
    const [external, setExternal] = useState([]);
    const [license, setLicense] = useState([]);
    const [requestStatus, setRequestStatus] = useState();
    const [orderId, setOrderId] = useState();
    const [softwareFamily, setSoftwareFamily] = useState("");
    const [update, setUpdate] = useState("");

    let params = (new URL(document.location)).searchParams;
    let updateId = params.get('uuid');

    useEffect(() => {
        setSoftwareFamily(softwareFamily);
        if (location?.state?.softwareFamily && location?.state?.update) {
            setSoftwareFamily(location.state.softwareFamily);
            setUpdate(location.state.update)
        } else {
            setIsLoading(true);
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };

            let url = `${config.software_updates.SOFTWARE_SERVICE}?module=update`;

            axios.get(
                url,
                {headers: headers}
            )
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data || [];
                        let current_obj = data.filter((obj) => {
                            return String(obj.UPDATE_TABLE_ID).valueOf() === updateId
                        })[0];
                        setSoftwareFamily(current_obj.PRODUCT_FAMILY);
                        setUpdate(current_obj.DESCRIPTION);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("ERROR: Failed to GET Software Family data", error);
                    setSoftwareFamily("");
                    setUpdate("");
                    setIsLoading(false);
                });
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (location.hash === '#physical-shipment') {
            setModal(!isModal);
        } else {
            async function reloadSoftwareUpdateItems() {
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                };

                setIsLoading(true);
                let url = `${config.software_updates.SOFTWARE_SERVICE}?module=update-item`;
                axios.get(
                    url,
                    {
                        headers: headers,
                        params: {
                            filter: updateId
                        }
                    }
                )
                    .then((response) => {
                        if (response.status === 200) {
                            let data = response.data || [];
                            setDocumentation(data['documentation']);
                            setSoftware(data['software']);
                            setExternal(data['external']);
                            setLicense(data['license']);
                            setIsLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.log("ERROR: Failed to GET Software Family data", error);
                        setIsLoading(false);
                    });
            }

            reloadSoftwareUpdateItems();
        }
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getSoftwareUpdateItems() {
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };

            setIsLoading(true);
            let url = config.software_updates.SOFTWARE_SERVICE;

            axios.get(
                url,
                {
                    headers: headers,
                    params: {
                        module:'update-item',
                        filter: updateId
                    }
                }
            )
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data || [];
                        setDocumentation(data['documentation']);
                        setSoftware(data['software']);
                        setExternal(data['external']);
                        setLicense(data['license']);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("ERROR: Failed to GET Software Family data", error);
                    setIsLoading(false);
                });
        }

        getSoftwareUpdateItems();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col>
                        <div className={"k-h2"}>
                            <Text
                                textkey={softwareUpdatesKey}
                                textdefault={softwareUpdatesKey}
                            />
                        </div>
                        <div
                            className={"k-h4"}
                            style={{
                                width: "90vw",
                                marginBottom: "20px"
                            }}
                        >
                            <Text
                                textkey={updateKey}
                                textdefault={mainMessages[siteLanguageDefault][updateKey]}
                            />
                            {": " + update}
                        </div>
                    </Col>
                    <Col>
                        <div
                            style={{
                                width: "110%"
                            }}
                        >
                        </div>
                    </Col>
                    <Col/>
                </Row>
                {(documentation.length === 0) ? "" : <h4 className="k-h4">Documentation</h4>}
                {(documentation.length === 0) ? "" : <DocumentationGrid setIsLoading={setIsLoading} documentation={documentation}/>}
                {(software.length === 0) ? "" : <h4 className="k-h4">Software/Firmware</h4>}
                {(software.length === 0) ? "" : <SoftwareFirmwareGrid
                    software={software}
                    setIsLoading={setIsLoading}
                />}
                {(external.length === 0) ? "" : <h4 className="k-h4">External Links And Kits</h4>}
                {(external.length === 0) ? "" : <ExternalGrid setIsLoading={setIsLoading} external={external}/>}
                <Messages
                    showPopover={requestStatus}
                    setShowPopover={setRequestStatus}
                    orderId={orderId}
                />
                {(license.length === 0) ? "" : <h4 className="k-h4">Licenses</h4>}
                {(license.length === 0) ? "" : <LicensesGrid license={license}/>}
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    shape={"rectangle"}
                    fillMode={"outline"}
                    rounded={"small"}
                    type={"button"}
                    name="Search"
                    onClick={() => {
                        redirect('/software-updates');
                    }}
                    className={"add-cert-field"}
                    style={{
                        "marginTop": ".9em",
                        "marginBottom": ".9em"
                    }}
                >
                    <Text
                        textkey={'Back to Software Updates List'}
                        textdefault={'Back to Software Updates List'}
                    />
                </Button>
                <ShipmentModal
                    setRequestStatus={setRequestStatus}
                    isActive={isModal}
                    setActive={setModal}
                    setOrderId={setOrderId}
                />
            </div>
        </>
    );
}

export default SoftwareUpdateDetail;
