import React, {useCallback, useContext, useEffect, useState} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/DeactivateLicense.css';

// react router
import {useHistory} from 'react-router-dom';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import KSMStepper from '../components/common/Stepper.js';
import PageHeader from '../components/common/PageHeader.js';
import {Text} from '../components/common/MultilingualText.js';
import EnterHost from '../components/DeactivateLicense/EnterHost.js';
import SelectLicense from '../components/DeactivateLicense/SelectLicenses.js';
import RequestLicense from '../components/DeactivateLicense/RequestLicense.js';
import DownloadConfirm from '../components/DeactivateLicense/DownloadConfirm.js';
import Spinner from '../components/common/Spinner.js';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    downloadAndConfirmKey,
    enterHostIdKey,
    deactivateLicensesKey,
    mainMessages,
    navigationAlertKey,
    requestLicensesKey,
    selectLicensesKey,
} from '../assets/text/MultilingualText.js';

function DeactivateLicense(props) {
    const {
        siteLanguage,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [isLoading, setIsLoading] = useState(false);

    const defaultStepperItems = [
        {
            label: localization.toLanguageString(enterHostIdKey, mainMessages[siteLanguageDefault][enterHostIdKey]),
            number: 1, //number icon
            disabled: false
        },
        {
            label: localization.toLanguageString(selectLicensesKey, mainMessages[siteLanguageDefault][selectLicensesKey]),
            number: 2,
            disabled: true
        },
        {
            label: localization.toLanguageString(requestLicensesKey, mainMessages[siteLanguageDefault][requestLicensesKey]),
            number: 3,
            disabled: true
        },
        {
            label: localization.toLanguageString(downloadAndConfirmKey, mainMessages[siteLanguageDefault][downloadAndConfirmKey]),
            number: 4,
            disabled: true
        }
    ]

    const [stepperItems, setStepperItems] = useState(defaultStepperItems);
    const [stepValue, setStepValue] = useState(0);

    //Changes step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {
        if (stepValue === 0) {
            if (stepChange === 1) {
                stepperItems[stepChange].disabled = false;
            }
            if (stepChange === 3) {
                stepperItems[stepChange].disabled = false;
                stepperItems[1].disabled = true;
                stepperItems[2].disabled = true;
            }
        }

        if (stepValue === 1) {
            if (stepChange === 2) {
                stepperItems[stepChange].disabled = false;
            }
        }

        if (stepValue === 2) {
            if (stepChange === 3) {
                stepperItems[stepChange].disabled = false;
                stepperItems[1].disabled = true;
                stepperItems[2].disabled = true;
            }
        }

        if (stepChange === 0) {
            stepperItems[1].disabled = true;
            stepperItems[2].disabled = true;
            stepperItems[3].disabled = true;
        }

        setStepValue(stepChange);
        setStepperItems(stepperItems);
    }

    // on language change, refresh stepper labels
    useEffect(() => {
        setStepperItems(defaultStepperItems);
    }, [siteLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    //Confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {
        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
            )) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [hostID, setHostID] = useState('');
    const [hostFile, setHostFile] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [deactivateProducts, setDeactivateProducts] = useState([]);
    const [keepProducts, setKeepProducts] = useState([]);
    const [transactionID, setTransactionID] = useState("");

    //Calculates requested deactivating and keep products states when selected products changes
    useEffect(() => {
        let newDeactivateProducts = [];
        let newKeepProducts = [];

        selectedProducts.forEach(item => {
            if (item.qty_deactivate !== null) {
                const keep = Number(item.qty_redeemed) - Number(item.qty_deactivate);
                item.qty_keep = keep;
                if (keep > 0) {
                    newKeepProducts.push(item);
                }
                newDeactivateProducts.push(item);
            } else {
                item.qty_keep = item.qty_redeemed;
                newKeepProducts.push(item);
            }
        })
        setDeactivateProducts(newDeactivateProducts);
        setKeepProducts(newKeepProducts);
    }, [selectedProducts])

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row style={{
                    marginBottom: '0.938rem'
                }}>
                    <Col>
                        <div className={"k-h2"}>
                            <Text
                                textkey={deactivateLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][deactivateLicensesKey]}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div style={{
                            width: "130%"
                        }}>
                            <KSMStepper
                                stepperItems={stepperItems}
                                stepValue={stepValue}
                                changeStepActivation={changeStepActivation}
                            />
                        </div>
                    </Col>
                    <Col/>
                </Row>
                {stepValue === 0 && (
                    <EnterHost
                        setIsLoading={setIsLoading}
                        changeStepActivation={changeStepActivation}
                        setHostID={setHostID}
                        setSelectedProducts={setSelectedProducts}
                        setDeactivateProducts={setDeactivateProducts}
                        setKeepProducts={setKeepProducts}
                        setHostFile={setHostFile}
                        setTransactionID={setTransactionID}
                    />
                )}
                {stepValue === 1 && (
                    <SelectLicense
                        changeStepActivation={changeStepActivation}
                        hostID={hostID}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                    />
                )}
                {stepValue === 2 && (
                    <RequestLicense
                        setIsLoading={setIsLoading}
                        changeStepActivation={changeStepActivation}
                        hostID={hostID}
                        deactivateProducts={deactivateProducts}
                        keepProducts={keepProducts}
                        hostFile={hostFile}
                        transactionID={transactionID}
                    />
                )}
                {stepValue === 3 && (
                    <DownloadConfirm
                        setIsLoading={setIsLoading}
                        changeStepActivation={changeStepActivation}
                        hostID={hostID}
                        deactivateProducts={deactivateProducts}
                        transactionID={transactionID}
                        handleBeforeUnload={handleBeforeUnload}
                    />
                )}
                <br/>
            </div>
        </>
    );
}

export default DeactivateLicense;

