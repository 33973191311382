import React, {cloneElement, useContext, useState} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/App.css';

// components
import LinkCell from './LinkCell.js';

// kendo react
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    descriptionKey,
    mainMessages,
    notesKey
} from '../../assets/text/MultilingualText.js'


function LicensesGrid(props) {
    const {license} = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let items = license;

    const SoftwareUpdatesDescription = localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey]);
    const SoftwareUpdatesNotes = localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey]);

    const initialSort = [
        {
            field: "Description",
            dir: "asc"
        },
    ];

    const [currentSort] = useState(initialSort);

    let allItems = [];

    items.forEach((item, index) => {
        allItems.push({
            "Type": {"text": "", "link": `/request-update-licenses?uuid=${item['update_id']}`, "type": "link"},
            "Description": {
                "text": item['description'],
                "link": `/request-update-licenses?uuid=${item['update_id']}`
            },
            "Notes": `${item?.notes ? item.notes : ""} ${item?.available_qty ? item.available_qty : 0} Available ${(item.requested_qty > 0) ? "(" + item.requested_qty + " already requested)" : ""}`,
            "QtyAvailable": item['available_qty']
        })
    });

    const rowRender = (tr, props) => {
        let extraStyle = {};
        let extraClasses = "";
        let extraProps = {};
        if (props.dataItem.QtyAvailable <= 0) {
            extraStyle = {'color': 'grey'};
            extraClasses = ' disabled';
            extraProps = {disabled: true, gridType: 'license'};
        }

        let copy = tr.props.children.map((child) => {
            return cloneElement(
                child,
                {...{...child.props, ...extraProps}, ...{style: extraStyle}},
                child.props.children
            )
        });

        return cloneElement(
            tr,
            {...tr.props, ...{style: extraStyle, className: [tr.props.className + extraClasses]}},
            copy
        );
    };

    return (
        <div>
            <Grid
                className={'software-updates-licenses-grid'}
                data={orderBy(allItems, currentSort)}
                scrollable={'none'}
                rowRender={rowRender}
                style={{
                    "marginTop": ".9em",
                    "marginBottom": ".9em"
                }}
            >
                <GridColumn
                    field="Type"
                    title=" "
                    width="40px"
                    cell={LinkCell}
                />
                <GridColumn
                    field="Description"
                    title={SoftwareUpdatesDescription}
                    cell={LinkCell}
                />
                <GridColumn
                    field="Notes"
                    title={SoftwareUpdatesNotes}
                />
            </Grid>
        </div>
    );
}

export default LicensesGrid;