import React, {useState} from 'react';

// css
import '../../assets/css/App.css';

// kendo react
import {GRID_COL_INDEX_ATTRIBUTE} from '@progress/kendo-react-grid';
import {useTableKeyboardNavigation} from '@progress/kendo-react-data-tools';

function IconCell(props) {
    const field = props.field || "";
    let value = props.dataItem[field];
    const hasParent = (value?.type) ? true : false;
    const disabled = (props?.disabled) ? props?.disabled : false;
    const [iconColor] = useState(
        () => () => ((disabled) ? '#19416d' : "#808080")
    );
    value = (value?.type) ? value.type : value;
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    let icon = "";
    switch (value) {
        case 'zip':
        case 'zipx':
        case 'tbz':
        case 'z01':
        case 'z06':
        case 'gz':
        case 'tgz':
        case 'bit':
        case 'ffw':
            icon = 'k-icon k-i-file-zip';
            break;
        case 'exe':
        case 'msi':
        case 'asp':
        case 'apk':
        case 'sh':
        case 'com':
        case 'chm':
        case 'pbp':
        case 'bz2':
        case 'ova':
        case 'swu':
        case 'mib':
            icon = 'k-icon k-i-file-config';
            break;
        case 'html':
        case 'xml':
        case 'txt':
        case 'rtf':
        case 'mht':
        case 'oad':
            icon = 'k-icon k-i-file-txt';
            break;
        case 'doc':
        case 'docx':
            icon = "k-icon k-i-file-word";
            break;
        case 'xls':
        case 'xlsx':
            icon = "k-icon k-i-file-excel";
            break;
        case 'ppt':
            icon = "k-icon k-i-ppt";
            break;
        case 'csv':
            icon = "k-icon k-i-file-csv";
            break;
        case 'link':
            icon = "k-icon k-i-hyperlink-open";
            break;
        case 'pdf':
            icon = 'k-icon k-i-file-pdf';
            break;
        case 'mp4':
        case 'wmv':
            icon = 'k-icon k-i-film';
            break;
        case 'iso':
        case 'wim':
            icon = 'k-icon k-i-save';
            break;
        case 'kit':
            icon = 'k-icon k-i-envelop';
            break;
        default:
            icon = "k-icon k-i-file";
            break;
    }
    return !hasParent ? (
        <td
            style={{}}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{[GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex}}
            {...navigationAttributes}
        >
            <center>
                <div
                    style={{
                        'fontSize': '1.25rem',
                        'color': iconColor
                    }}
                    className={icon}
                >
                </div>
            </center>
        </td>
    ) : (<div
        style={{}}
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{[GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex}}
        {...navigationAttributes}
    >
        <center>
            <div
                style={{
                    'fontSize': '1.25rem',
                    'color': iconColor
                }}
                className={icon}
            >
            </div>
        </center>
    </div>);
}

export default IconCell;