import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// components
import SortableLinkCell from './SortableLinkCell.js';
import {convertDateFormat} from '../common/Grid.js';
import NotesHtmlCell from './NotesHtmlCell.js';

// kendo react
import {getSelectedState, Grid, GridColumn} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';
import {orderBy} from '@progress/kendo-data-query';
import {getter} from '@progress/kendo-react-common';
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../common/MultilingualText.js';
import {
    descriptionKey,
    limitDownloadThenRequestKey,
    browserLimitConcurrentDownloadKey,
    limitsMultipleConcurrentDownloadsKey,
    downloadSelectedKey,
    fileSizeKey,
    mainMessages,
    notesKey,
    platformKey,
    releaseDateKey
} from '../../assets/text/MultilingualText.js';

const DATA_ITEM_KEY = "update_item_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);


function SoftwareFirmwareGrid(props) {
    const {
        software,
        setIsLoading
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let items = software;
    const alertAnchor = useRef(null);

    const SoftwareUpdatesDescription = localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey]);
    const SoftwareUpdatesPlatform = localization.toLanguageString(platformKey, mainMessages[siteLanguageDefault][platformKey]);
    const SoftwareUpdatesNotes = localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey]);
    const SoftwareUpdatesReleaseDate = localization.toLanguageString(releaseDateKey, mainMessages[siteLanguageDefault][releaseDateKey]);
    const SoftwareUpdatesFileSize = localization.toLanguageString(fileSizeKey, mainMessages[siteLanguageDefault][fileSizeKey]);
    const SoftwareUpdatesDownloadSelected = localization.toLanguageString(downloadSelectedKey, mainMessages[siteLanguageDefault][downloadSelectedKey]);
    const SoftwareUpdatesDownloadMessagesInfo = localization.toLanguageString(limitsMultipleConcurrentDownloadsKey, mainMessages[siteLanguageDefault][limitsMultipleConcurrentDownloadsKey])
    const SoftwareUpdatesDownloadMessagesTitle = localization.toLanguageString(browserLimitConcurrentDownloadKey, mainMessages[siteLanguageDefault][browserLimitConcurrentDownloadKey])
    const SoftwareUpdatesDownloadMessagesBody = localization.toLanguageString(limitDownloadThenRequestKey, mainMessages[siteLanguageDefault][limitDownloadThenRequestKey])

    const initialSort = [
        {
            field: "Description",
            dir: "asc"
        }
    ];
    const [alertShow, setAlertShow] = useState(false);
    const dataState = items.map((dataItem) =>
        Object.assign(
            {
                selected: false
            },
            {
                ...dataItem,
                ReleaseDate: convertDateFormat(typeof dataItem['ReleaseDate'] === 'string' ? dataItem['ReleaseDate'].replace(/-/g, "/") : dataItem['ReleaseDate'])
            }
        )
    );

    const [selectedState, setSelectedState] = useState({});
    const [downloadDisabled, setDownloadDisabled] = useState(true);
    const onSelectionChange = useCallback((event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
    }, [selectedState]);

    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    useEffect(() => {
        if (Object.values(selectedState).includes(true)) {
            setDownloadDisabled(false);
        } else {
            setDownloadDisabled(true);
        }
    }, [selectedState]);

    const onSoftwareDownload = () => {
        setIsLoading(true);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let urls = [];
        let update_item_ids = [];
        let computedUrls = [];
        let params = (new URL(document.location)).searchParams;
        let updateId = params.get('uuid');
        items.forEach((item, index) => {
            let update_item_id = item['update_item_id'];
            if (selectedState.hasOwnProperty(update_item_id)) {
                if (selectedState[update_item_id] === true) {
                    urls.push(item["url"]);
                    update_item_ids.push(item["update_item_id"]);
                }
            }
        });
        let data = {
            "urls": urls,
            "update_item_ids": update_item_ids,
            "update_id": updateId
        };
        let url = config.software_updates.GET_SOFTWARE_UPDATE_URL;

        axios.post(
            url,
            data,
            {
                headers: headers
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    let presigned_urls = response.data || "";
                    computedUrls = presigned_urls;
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to get presigned url", error);
            })
            .then(() => {
                let delay = 0;
                const delayIncrement = 4000;

                computedUrls.map(async (item) => {
                    delay += delayIncrement;
                    return new Promise(resolve => setTimeout(async () => {
                        await downloadFile(item);
                    }, delay)).then(() => {
                    });
                });
                return new Promise(resolve => setTimeout(() => {
                    setIsLoading(false);
                }, delay));
            });
    };

    /*
    * downloadFile() downloads files from url
    * @param {url} string url of download path/link
    */
    const downloadFile = async (url) => {
        return new Promise((res, rej) => {
            try {
                let a = document.createElement("a");
                a.setAttribute('href', url);
                a.setAttribute('download', '');
                a.setAttribute('target', '_blank');
                a.click();
                res(true);
            } catch (error) {
                console.log('err', error);
                rej(error);
            }
        });
    }

    return (
        <div>
            <Grid
                className={'software-updates-software-firmware-grid'}
                data={orderBy(dataState.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                    "Description": JSON.stringify({
                        "text": item['Description'],
                        "link": `${item['url']}`,
                        "accessToken": accessToken
                    })
                })), initialSort)}
                scrollable={'none'}
                style={{
                    "marginTop": ".9em",
                    "marginBottom": ".9em"
                }}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
            >
                <GridColumn
                    field={SELECTED_FIELD}
                    width="40px"
                    headerSelectionValue={dataState.findIndex((item) => !selectedState[idGetter(item)]) === -1}
                />
                <GridColumn
                    field="Description"
                    title={SoftwareUpdatesDescription}
                    cell={(props)=>
                        <SortableLinkCell
                            setIsLoading={setIsLoading}
                            props={props}
                        />
                    }
                />
                <GridColumn
                    field="Platform"
                    title={SoftwareUpdatesPlatform}
                />
                <GridColumn
                    field="Notes"
                    title={SoftwareUpdatesNotes}
                    cell={NotesHtmlCell}
                />
                <GridColumn
                    field="ReleaseDate"
                    title={SoftwareUpdatesReleaseDate}
                />
                <GridColumn
                    field="FileSize"
                    title={SoftwareUpdatesFileSize}
                />
            </Grid>
            <Button
                themeColor={"primary"}
                size={"large"}
                shape={"rectangle"}
                fillMode={"solid"}
                rounded={"small"}
                type={"button"}
                name="Search"
                onClick={onSoftwareDownload}
                className={"add-cert-field"}
                disabled={downloadDisabled}
                style={{
                    "marginTop": ".9em",
                    "marginBottom": ".9em"
                }}
            >
                {SoftwareUpdatesDownloadSelected}
            </Button>
            {Object.values(selectedState).filter((x) => x === true).length >= 7 && (
                <>
                    <button
                        ref={alertAnchor}
                        className={"ksm-anchor-button"}
                        style={{
                            marginLeft: '0.938rem'
                        }}
                        onMouseOver={() => {
                            setAlertShow(true);
                        }}
                        onMouseLeave={() => {
                            setAlertShow(false);
                        }}>
                        <Text
                            textkey={limitsMultipleConcurrentDownloadsKey}
                            textdefault={SoftwareUpdatesDownloadMessagesInfo}
                        />
                    </button>
                    {alertShow && (<Popover
                        show={alertShow}
                        anchor={alertAnchor.current}
                        position={'right'}
                        className={'ksm-popover ksm-popover-warning'}
                        animate={false}
                    >
                        <div
                            style={{
                                display: 'flex',
                                width: '35rem'
                            }}
                        >
                            <span
                                className="k-icon k-i-warning"
                                style={{
                                    color: 'var(--keysight-yellow)',
                                    fontSize: '2.5rem',
                                    marginRight: '0.938rem'
                                }}
                            />
                            <div>
                                <b>{SoftwareUpdatesDownloadMessagesTitle}</b>
                                <br/>
                                {SoftwareUpdatesDownloadMessagesBody}
                            </div>
                        </div>
                    </Popover>)}
                </>
            )}
        </div>
    );
}

export default SoftwareFirmwareGrid;