import React, {useCallback, useContext, useRef} from 'react';
import UserContext from '../../common/UserContext.js';

// react strap
import {Col, Row} from 'reactstrap';

// components
import {PopoverContext} from '../LicenseDownload.js';
import {downloadLicenses} from '../../common/utilities.js';

// kendo react
import {Tooltip} from '@progress/kendo-react-tooltip';
import {Loader} from '@progress/kendo-react-indicators';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    downloadThisLicenseKey,
    hostKey,
    loadingGenerationKey,
    mainMessages,
} from '../../../assets/text/MultilingualText.js';


export const PanelBarHeader = (props) => {
    const {
        host,
        setIsLoading,
        handleBeforeUnload
    } = props
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    //Sets the popover reference based on mouse over
    const popoverRef = useRef(null);
    const {onMouseOver} = useContext(PopoverContext);

    const handleMouseOver = useCallback(() => {
        const icons = host.icons;
        onMouseOver({
            show: true,
            popoverRef: popoverRef,
            generalError: icons.generalError,
            networkError: icons.networkError,
            cloudAlert: icons.cloudAlert
        });
    }, [onMouseOver, host.icons]);

    const handleMouseOut = useCallback(() => {
        const icons = host.icons;
        onMouseOver({
            show: false,
            popoverRef: popoverRef,
            generalError: icons.generalError,
            networkError: icons.networkError,
            cloudAlert: icons.cloudAlert
        });
    }, [onMouseOver, host.icons]);

    return (
        <>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        className={"k-h4"}
                        style={{
                            color: 'white',
                            margin: '0'
                        }}
                    >
                        {localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey])} {host.host_id}
                    </div>
                </Col>
                <Col/>
                <Col>
                    <div
                        style={{
                            float: 'right'
                        }}
                    >
                        <div>
                            {host.icons.loading && (
                                <Tooltip
                                    anchorElement="pointer"
                                    showCallout={false}
                                    parentTitle={true}
                                    openDelay={0}
                                    filter={(e) => {
                                        const classNames = ['k-loader k-loader-md k-loader-primary k-loader-pulsing-2', 'k-loader-segment', 'k-loader-canvas'];
                                        if (classNames.includes(e.className)) {
                                            return true;
                                        }
                                    }}
                                >
                                    <div
                                        title={localization.toLanguageString(loadingGenerationKey, mainMessages[siteLanguageDefault][loadingGenerationKey])}
                                        style={{paddingBottom: '1px'}}
                                    >
                                        <Loader
                                            type={'pulsing'}
                                            style={{
                                                color: 'white'
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {host.icons.download && (
                                <Tooltip
                                    anchorElement="pointer"
                                    showCallout={false}
                                    parentTitle={true}
                                    openDelay={0}
                                >
                            <span
                                title={localization.toLanguageString(downloadThisLicenseKey, mainMessages[siteLanguageDefault][downloadThisLicenseKey])}
                                className="k-icon k-i-download"
                                style={{
                                    color: 'white',
                                    marginLeft: '0.35rem',
                                    marginRight: '0.35rem',
                                    fontSize: '1.3rem',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    downloadLicenses([host['transaction_id']], accessToken, setIsLoading, handleBeforeUnload, 'EESOF_RENEWAL');
                                }}
                            />
                                </Tooltip>
                            )}
                            {(host.icons.generalError || host.icons.networkError) && (
                                <span
                                    className="k-icon k-i-close-outline"
                                    style={{
                                        color: 'var(--keysight-pink)',
                                        marginLeft: '0.35rem',
                                        marginRight: '0.35rem',
                                        fontSize: '1.3rem'
                                    }}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                    ref={popoverRef}
                                />
                            )}
                            {host.icons.cloudAlert && (
                                <span
                                    className="k-icon k-i-warning"
                                    style={{
                                        marginLeft: '0.35rem',
                                        marginRight: '0.35rem',
                                        fontSize: '1.3rem'
                                    }}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                    ref={popoverRef}
                                />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}