// react
import {useHistory} from "react-router-dom";
import axios from "axios";

// kendo
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";

// multilingual
import {
    copyProductsOnlyKey,
    ELCopyProductsOnlyWarning1Key,
    ELCopyProductsOnlyWarning2Key,
    ELCopyProductsWarning1Key,
    ELCopyProductsWarning2Key,
    ELCopyProductsWarning3Key,
    cancelKey,
    confirmKey,
    mainMessages
} from "../../assets/text/MultilingualText";

// ksm
import config from "../../config";

export const CopyProductsOnlyModal = (props) => {
    const {
        accessToken,
        localization,
        siteLanguageDefault,
        setShowCopyProductsOnlyModal,
        setAlertJSON,
        setShowAlertModal,
        modalType,
        eaId,
        setIsLoading,
    } = props;

    let history = useHistory();

    const titleStyle = {
        fontWeight: "normal",
        margin: 0,
        color: "#424242"
    };

    const onCloseCopyProductsOnlyModal = () => {
        setShowCopyProductsOnlyModal(false);
    }

    const onClickConfirm = () => {
        let period = (modalType === "currentPeriodCopyProductsOnly") ?
            "PRODUCTSELECTIONCURRENTPERIOD" : "PRODUCTSELECTIONNEXTPERIOD"
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let data = {
                module: "EA",
                sub_module: "allocations",
                action: "COPY",
                input_json: [
                    {
                        "ea_id": eaId,
                        "products_period_selection_input": period,
                        "copy_selection_input": "COPYLASTPERIODPRODUCTSONLY"
                    }
                ]
        }
        setIsLoading(true);
        axios.post(
            config.ea_summary.COPY_PRODUCTS,
            data,
            {headers: headers}
        )
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    let data = response.data || [];
                    if (data['display_alert'].toLowerCase() === "yes") {
                        setAlertJSON({
                            eaID: eaId,
                            modalType: modalType,
                            show: 1,
                            period: period
                        })
                        setShowAlertModal(true)
                    }
                    else {
                        let params = '?eaid=' + eaId + '&show=' + period
                        history.push('/ea-request-license'+params)
                    }
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Data", error);
                setIsLoading(false);
            });
    }

    return (
        <Dialog
            className={"start-from-scratch-modal ksm-dialog"}
            title={
                <h2 style={titleStyle}>
                    {localization.toLanguageString(copyProductsOnlyKey, mainMessages[siteLanguageDefault][copyProductsOnlyKey])}
                </h2>
            }
            onClose={onCloseCopyProductsOnlyModal}
            width={"38.25rem"}
        >
            <div
                style={{
                    'display': 'flex'
                }}
            >
                <div
                    style={{
                        'fontSize': "3.125rem",
                        'color': "var(--keysight-yellow)",
                    }}
                    className={"k-icon k-i-warning"}>
                </div>
                <div
                    style={{
                        marginLeft: '1rem',
                        fontSize: '1rem',
                        wordBreak: 'normal',
                    }}
                >
                    <div className={"start-from-scratch-modal-text"}>
                        {localization.toLanguageString(ELCopyProductsOnlyWarning1Key, mainMessages[siteLanguageDefault][ELCopyProductsOnlyWarning1Key])}
                    </div>
                    <ul>
                        <li>
                            <div className={"start-from-scratch-modal-text"}>
                                {localization.toLanguageString(ELCopyProductsOnlyWarning2Key, mainMessages[siteLanguageDefault][ELCopyProductsOnlyWarning2Key])}
                            </div>
                        </li>
                        <li>
                            <div className={"start-from-scratch-modal-text"}>
                                {localization.toLanguageString(ELCopyProductsWarning1Key, mainMessages[siteLanguageDefault][ELCopyProductsWarning1Key])}
                            </div>
                        </li>
                        <li>
                            <div className={"start-from-scratch-modal-text"}>
                                {localization.toLanguageString(ELCopyProductsWarning2Key, mainMessages[siteLanguageDefault][ELCopyProductsWarning2Key])}
                            </div>
                        </li>
                    </ul>
                    <b>
                        {localization.toLanguageString(ELCopyProductsWarning3Key, mainMessages[siteLanguageDefault][ELCopyProductsWarning3Key])}
                    </b>
                </div>
            </div>
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    shape={"rectangle"}
                    fillMode={"outline"}
                    rounded={"small"}
                    type={"button"}
                    onClick={onCloseCopyProductsOnlyModal}
                >
                    {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    shape={"rectangle"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={onClickConfirm}
                >
                    {localization.toLanguageString(confirmKey, mainMessages[siteLanguageDefault][confirmKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}