import * as React from "react";
import {useContext} from "react";
import UserContext from "../common/UserContext";

// kendo
import {
    Chart,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartTooltip
} from "@progress/kendo-react-charts";
import {Group, Text} from "@progress/kendo-drawing";
import {Tooltip} from '@progress/kendo-react-tooltip';

//helper functions
import {formatCurrency} from "../common/utilities";

//css
import '../../assets/css/SummaryChart.css';

//multilingual
import {
    mainMessages,
    totalPoolKey,
    availableKey,
    consumedKey,
    myCartKey
} from "../../assets/text/MultilingualText";
import { useLocalization} from "@progress/kendo-react-intl";


const labelContent = (e) => e.category;

const labelVisual = (e) => {
    const defaultLabel = e.createVisual();
    const bbox = defaultLabel.bbox();
    const path = new Text(e.text, bbox.topLeft(), {
        stroke: {
            color: e.dataItem.fontColor
        }
    });
    const group = new Group();
    group.append(defaultLabel, path);
    return group;
};

const SummaryChart = (props) => {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {totalValue, data, title, startDate, endDate, currency_code, currency_locale, style} = props;

    const PillarLabel = ({category}) => {

        let border = category?.color
        if (category?.color === '#FFFFFF') {
            border = '#000000'
        }

        let fontColor = '#4E5969'
        if (category?.name === 'selected') {
            if (category?.exceedSubPool) {
                fontColor = '#E90029'
            }
        }
        return (
            <div className="k-display-flex k-gap-2">
                <div style={{
                    width: '8px',
                    height: '82px',
                    borderRadius: '100px',
                    backgroundColor: category?.color,
                    border: '1px solid ' + border,
                }}/>
                <div className="label-container k-display-flex k-wizard-horizontal k-justify-content-space-between">

                    <span style={{
                        whiteSpace: 'nowrap'
                    }}>
                        {category?.label}
                    </span>

                    <span style={{
                        color: fontColor,
                        fontSize: '0.875rem'
                    }}>
                        {formatCurrency(category?.value, currency_locale, currency_code)}
                    </span>

                    <span style={{
                        color: fontColor,
                        fontSize: '1.25rem'
                    }}>
                        {category?.pillar_percentage}
                    </span>
                </div>
            </div>
        )
    };

    const generateChartJson = (data, currency_code, currency_locale) => {
        const fieldLanguageMapper = {
            consumed: localization.toLanguageString(consumedKey, mainMessages[siteLanguageDefault][consumedKey]),
            available: localization.toLanguageString(availableKey, mainMessages[siteLanguageDefault][availableKey]),
            selected: localization.toLanguageString(myCartKey, mainMessages[siteLanguageDefault][myCartKey])
        }

        let exceedSubPool = false
        for (const category of data) {
            if (category.category === 'available') {
                if (category.value < 0) {
                    exceedSubPool = true
                }
            }
        }

        return data?.map((category) => {
            if (category?.value < 0) {
                category.value = 0
            }
            let percValue = (category?.value / totalValue) * 100;
            let displayPerc = Number.isInteger(percValue) ? percValue : percValue?.toFixed(2)

            if (displayPerc === '0.00' && category.value !== 0) {
                displayPerc = '0.01'
            }

            if (displayPerc === '100.00' && category.value !== totalValue) {
                displayPerc = '99.00'
            }

            if (isNaN(displayPerc)) {
                displayPerc = '0.00'
            }

            return {
                "name": category?.category,
                "label": fieldLanguageMapper[category?.category] || '',
                "value": category?.value,
                "amount": formatCurrency(category?.value, currency_locale, currency_code),
                "pillar_percentage": `${displayPerc}%`,
                "percentage": displayPerc > 10 ? `${displayPerc}%` : '',
                "color": category?.backgroundColor,
                "fontColor": category?.fontColor,
                "exceedSubPool": exceedSubPool
            }
        });
    };

    const chartData = generateChartJson(data, currency_code, currency_locale);

    return (<div
            className="k-display-flex k-align-items-center"
            style={style ? style : {}}
        >
        <div>
            <Chart
                className="chart_container"
                transitions={false}
            >
                <ChartTooltip
                    visible={false}
                />
                <ChartSeries>
                    <ChartSeriesItem
                        type="donut"
                        data={chartData}
                        categoryField="percentage"
                        field="value"
                        colorField="color"
                        padding={1}
                        border={{
                            color: "#676767",
                            width: 0.5
                        }}
                    >
                        <ChartSeriesLabels
                            align={"right"}
                            color="none"
                            background="none"
                            content={labelContent}
                            visual={labelVisual}
                        />
                    </ChartSeriesItem>
                </ChartSeries>
                <ChartLegend visible={false}/>
            </Chart>
        </div>

        <div className="k-display-flex k-wizard-horizontal k-gap-4">
            <div className="chart-header k-display-flex k-wizard-horizontal k-align-items-start k-gap-1">
                {title && (
                    <Tooltip
                        anchorElement="pointer"
                        showCallout={false}
                        parentTitle={true}
                        openDelay={0}
                    >
                    <span
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                        title={title}
                    >
                        {title}
                    </span>
                    </Tooltip>
                )}
                {startDate && endDate && (
                    <span style={{
                        whiteSpace: 'nowrap'
                    }}>
                        {startDate} - {endDate}
                    </span>
                )}
                <span>
                    {localization.toLanguageString(totalPoolKey, mainMessages[siteLanguageDefault][totalPoolKey])}: <b>{formatCurrency(totalValue, currency_locale, currency_code)}</b>
                </span>
            </div>
            <div className="k-display-flex k-gap-8">
                {
                    chartData?.map((category, index) => {
                        return (<PillarLabel
                            key={index}
                            category={category}
                        />)
                    })
                }
            </div>
        </div>
    </div>);
};

export default SummaryChart;