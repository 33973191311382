import React, {useContext, useEffect, useState, useCallback} from 'react';
import UserContext from '../components/common/UserContext.js';
import {useHistory} from "react-router-dom";

// kendo react
import {Stepper} from '@progress/kendo-react-layout';

// components
import PageHeader from '../components/common/PageHeader.js';
import Spinner from '../components/common/Spinner.js';
import '../assets/css/SACheckin.css';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    checkInKey,
    selectHostsParenthesisKey,
    selectLicensesParenthesisKey,
    checkInLicensesKey,
    navigationAlertKey,
} from "../assets/text/MultilingualText";
import SelectHosts from "../components/SACheckin/SelectHosts";
import SelectLicenses from "../components/SACheckin/SelectLicenses";
import Checkin from "../components/SACheckin/Checkin";


function SACheckin() {
    const {
        siteLanguageDefault,
    } = useContext(UserContext);

    const localization = useLocalization();
    const [isLoading, setIsLoading] = useState(false);

    const [stepperItems, setStepperItems] = useState([
        {
            label: localization.toLanguageString(selectHostsParenthesisKey,
                mainMessages[siteLanguageDefault][selectHostsParenthesisKey]),
            number: 1,
            disabled: false
        },
        {
            label: localization.toLanguageString(selectLicensesParenthesisKey,
                mainMessages[siteLanguageDefault][selectLicensesParenthesisKey]),
            number: 2,
            disabled: true
        },
        {
            label: localization.toLanguageString(checkInKey,
                mainMessages[siteLanguageDefault][checkInKey]),
            number: 3,
            disabled: true
        },
    ]);
    const [stepValue, setStepValue] = useState(0);
    //Changes and step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {
        let newStepperItems = stepperItems
        //Change from Select Hosts step
        if (stepValue === 0) {
        }
        //Change from Select Licenses Step
        if (stepValue === 1) {
        }
        //Change from Checkin Step
        if (stepValue === 2) {
            let newHostLicenses = hostLicenses;
            newHostLicenses.forEach((host) => {
                host.licenses.forEach((license) => license.selected = false)
            })
            setHostLicenses(newHostLicenses);
            newStepperItems[2].disabled = true;
        }
        setStepValue(stepChange);
        setStepperItems(stepperItems);
    }

    const [myHosts, setMyHosts] = useState([]);
    const [hostLicenses, setHostLicenses] = useState([]);
    const [checkInLicenses, setCheckInLicenses] = useState([]);

    useEffect(() => {
        let newStepperItems = stepperItems;
        newStepperItems[1].disabled = !(myHosts.filter(item => item.selected).length > 0);
        setStepperItems([...newStepperItems])
    }, [myHosts]) // eslint-disable-line react-hooks/exhaustive-deps

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    //Confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {
        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
            )) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <div className={"k-h2"}>
                    {localization.toLanguageString(checkInLicensesKey, mainMessages[siteLanguageDefault][checkInLicensesKey])}
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    }}
                >
                    <div style={{width: "45%"}}>
                        <Stepper
                            value={stepValue}
                            onChange={(e)=>{
                                changeStepActivation(e.value)
                            }}
                            items={stepperItems}
                            style={{fontSize: '0.875rem'}}
                        />
                    </div>
                </div>
                {stepValue === 0 && (
                    <SelectHosts
                        setIsLoading={setIsLoading}
                        changeStepActivation={changeStepActivation}
                        myHosts={myHosts}
                        setMyHosts={setMyHosts}
                    />
                )}
                {stepValue === 1 && (
                    <SelectLicenses
                        setIsLoading={setIsLoading}
                        changeStepActivation={changeStepActivation}
                        myHosts={myHosts}
                        hostLicenses={hostLicenses}
                        setHostLicenses={setHostLicenses}
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                    />
                )}
                {stepValue === 2 && (
                    <Checkin
                        setIsLoading={setIsLoading}
                        changeStepActivation={changeStepActivation}
                        hostLicenses={hostLicenses}
                        setHostLicenses={setHostLicenses}
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                    />
                )}
            </div>
        </>
    );

}

export default SACheckin;