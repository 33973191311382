import React, {useContext, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// components
import {NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';

// kendo react
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    activationCodeKey,
    iAmDeactivatingKey,
    descriptionKey,
    iAmKeepingKey,
    mainMessages,
    productKey,
    qtyDeactivateKey,
    qtyKeepKey
} from '../../../assets/text/MultilingualText.js';


export const DeactivateGrid = (props) => {
    const {deactivateProducts} = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const sort = [
        {
            field: "product_number",
            dir: "desc",
        },
    ];

    //Used for pagination
    const initialDataState = {
        skip: 0,
        take: 10,
    };

    const [page, setPage] = useState(initialDataState);

    const pageChange = (event) => {
        setPage(event.page);
    };

    return (
        <>
            <div
                className={"k-h4"}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(iAmDeactivatingKey, mainMessages[siteLanguageDefault][iAmDeactivatingKey])}
            </div>
            <Grid
                className={'deactivate-keep-license-grid'}
                scrollable={'none'}
                style={{
                    marginBottom: '0.938rem'
                }}
                data={orderBy(deactivateProducts, sort).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={deactivateProducts.length}
                pageable={(deactivateProducts.length <= initialDataState.take) ? false : {
                    buttonCount: 5,
                    pageSizes: [10, 20, 50, 100],
                }}
                onPageChange={pageChange}
            >
                <GridColumn
                    field="activation_code"
                    title={localization.toLanguageString(activationCodeKey, mainMessages[siteLanguageDefault][activationCodeKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="product_number"
                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="product_desc"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                />
                <GridColumn
                    field="qty_deactivate"
                    title={localization.toLanguageString(qtyDeactivateKey, mainMessages[siteLanguageDefault][qtyDeactivateKey])}
                    cell={TextAlignMiddleCell}
                    width={"169px"}
                />
            </Grid>
        </>
    )
}

export const KeepGrid = (props) => {
    const {keepProducts} = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const sort = [
        {
            field: "product_number",
            dir: "desc",
        },
    ];

    const initialDataState = {
        skip: 0,
        take: 10,
    };

    const [page, setPage] = useState(initialDataState);

    const pageChange = (event) => {
        setPage(event.page);
    };

    return (
        <>
            <div
                className={"k-h4"}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(iAmKeepingKey, mainMessages[siteLanguageDefault][iAmKeepingKey])}
            </div>
            <Grid
                className={'deactivate-keep-license-grid'}
                scrollable={'none'}
                style={{
                    marginBottom: '0.938rem'
                }}
                data={orderBy(keepProducts, sort).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={keepProducts.length}
                pageable={(keepProducts.length <= initialDataState.take) ? false : {
                    buttonCount: 5,
                    pageSizes: [10, 20, 50, 100],
                }}
                onPageChange={pageChange}
            >
                <GridColumn
                    field="activation_code"
                    title={localization.toLanguageString(activationCodeKey, mainMessages[siteLanguageDefault][activationCodeKey])}
                />
                <GridColumn
                    field="product_number"
                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                />
                <GridColumn
                    field="product_desc"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                />
                <GridColumn
                    field="qty_keep"
                    title={localization.toLanguageString(qtyKeepKey, mainMessages[siteLanguageDefault][qtyKeepKey])}
                    cell={TextAlignMiddleCell}
                />
            </Grid>
        </>
    )
}