import React, { useState, useContext, useRef } from 'react';
import UserContext from '../common/UserContext.js';

// components
import {downloadLicenses} from '../common/utilities.js';

// kendo react
import {Popover, Tooltip} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cloudLicenseKey,
    cloudLicenseMessageKey,
    mainMessages,
    downloadLicensesKey,
} from '../../assets/text/MultilingualText.js';


export const DownloadCell = (props) => {
    const {
        dataItem,
        setIsLoading,
        handleBeforeUnload
    } = props;
    const {
        siteLanguageDefault,
        accessToken,
    } = useContext(UserContext);
    const localization = useLocalization();

    const anchor = useRef(null);
    const [popoverShow, setPopoverShow] = useState(false);

    // show popover if there is an error on mouse over
    const onMouseOver = () => {
        setPopoverShow(true);
    };

    // remove popover on mouse leave
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) setPopoverShow(false);
    };
    return (
        <>
            <td
                ref={anchor}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            >
                <div>
                    { dataItem.license_type === "CLOUD" && (
                        <>
                            <span
                                title={localization.toLanguageString(downloadLicensesKey, mainMessages[siteLanguageDefault][downloadLicensesKey])}
                                className="k-icon k-i-information"
                                style={{
                                    color: 'var(--keysight-gray-4)',
                                    marginLeft: '0.35rem',
                                    marginRight: '0.35rem',
                                    fontSize: '1.3rem',
                                    cursor: 'pointer'
                                }}
                            />
                            <Popover
                                show={popoverShow}
                                anchor={anchor.current}
                                position={'left'}
                                className={'ksm-popover ksm-popover-alert-info'}
                                animate={false}
                            >

                                <div style={{display: 'flex', maxWidth: '36.25rem'}}>
                                    <span
                                        className="k-icon k-i-information"
                                        style={{
                                            color: 'var(--keysight-purple)',
                                            fontSize: '2.5rem',
                                            marginRight: '0.938rem'
                                        }}
                                    />
                                    <div>
                                        <b>{localization.toLanguageString(cloudLicenseKey, mainMessages[siteLanguageDefault][cloudLicenseKey])}</b>
                                        <br/>
                                        {localization.toLanguageString(cloudLicenseMessageKey, mainMessages[siteLanguageDefault][cloudLicenseMessageKey])}
                                    </div>
                                </div>
                            </Popover>
                        </>
                    )}
                    { dataItem.license_type === "FILE" && dataItem.license_id !== null && (
                        <Tooltip
                            anchorElement="pointer"
                            showCallout={false}
                            parentTitle={true}
                            openDelay={0}
                            position={'left'}
                        >
                            <span
                                title={localization.toLanguageString(downloadLicensesKey, mainMessages[siteLanguageDefault][downloadLicensesKey])}
                                className="k-icon k-i-download"
                                style={{
                                    color: 'var(--keysight-gray-4)',
                                    marginLeft: '0.35rem',
                                    marginRight: '0.35rem',
                                    fontSize: '1.3rem',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    downloadLicenses([dataItem['license_id']], accessToken, setIsLoading, handleBeforeUnload, "SWA_VIEW_DOWNLOAD");
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            </td>
        </>
    )
}