import React, {useCallback, useContext, useState, useRef} from 'react';
import axios from 'axios';

// components
import {ColumnMenu} from "../common/Grid";
import config from '../../config.js';
import UserContext from '../../components/common/UserContext.js';

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {orderBy, process} from "@progress/kendo-data-query";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {InputClearValue, TextBox} from "@progress/kendo-react-inputs";
import {Icon} from "@progress/kendo-react-common";
import {Button} from "@progress/kendo-react-buttons";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    myCartKey,
    continueKey,
    removeKey,
    addToCartKey,
    searchProductsKey,
    searchKey,
    productNumberKey,
    descriptionKey,
    qtyAvailableKey,
    enterProductNumberDescriptionKey,
    emptySearchKey,
    genericEmptyGridKey
} from "../../assets/text/MultilingualText";

// consts for my ea table
const initialGridState = {
    take: 10, skip: 0,
};

function SelectProducts(props) {
    const {
        siteLanguageDefault,
        accessToken,
        timeout
    } = useContext(UserContext);
    const {
        changeStepActivation,
        setIsLoading,
        searchProducts,
        setSearchProducts,
        cartProducts,
        setCartProducts,
        assignedProducts
    } = props;

    const localization = useLocalization();
    const [userSearched, setUserSearched] = useState(false);

    const [searchProductsGridState, setSearchProductsGridState] = useState(initialGridState);
    const [searchProductsState, setSearchProductsState] = useState(
        process(searchProducts.map((searchProduct) => ({
            ...searchProduct,
        })), initialGridState)
    );

    /*
     * onSearchProductsStateChange(event) sets the searchProducts grid state and searchProducts state on grid's state change
     * @param {event} the grid selection change event
    */
    const onSearchProductsStateChange = useCallback((event) => {
        const newSearchProductsState = process(
            searchProducts.map((searchProduct) => ({
                ...searchProduct,
            })),
            event.dataState
        );
        setSearchProductsGridState(event.dataState);
        setSearchProductsState(newSearchProductsState);
    }, [searchProducts]);


    const [cartProductsGridState, setCartProductsGridState] = useState(initialGridState);
    const [cartProductsState, setCartProductsState] = useState(
        process(cartProducts.map((cartProduct) => ({
            ...cartProduct,
        })), initialGridState)
    );

    /*
     * onCartProductsStateChange(event) sets the cartProducts grid state and cartProducts state on grid's state change
     * @param {event} the grid selection change event
    */
    const onCartProductsStateChange = useCallback((event) => {
        const newCartProductsState = process(
            cartProducts.map((cartProduct) => ({
                ...cartProduct,
            })),
            event.dataState
        );
        setCartProductsGridState(event.dataState);
        setCartProductsState(newCartProductsState);
    }, [cartProducts]);

    const [searchValue, setSearchValue] = useState('');
    const onChangeSearchValue = useCallback(event => {
        setSearchValue(event.target.value);
    }, []);
    const handleClear = useCallback(() => {
        setSearchValue("");
    }, []);

    const onClickSearchProduct = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        setIsLoading(true);
        axios.get(
            `${config.software_access.SOFTWARE_ACCESS}?module=checkout-search&search=${searchValue}`,
            {headers: headers, timeout: timeout}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];

                    // iterate over my cart products
                    cartProducts.forEach((cartProduct) => {
                        let searchProduct = data.find((product) => product.line_id === cartProduct.line_id)
                        let searchIndex = searchProduct ? data.findIndex((update) => update.line_id === searchProduct.line_id) : null
                        if (searchProduct && searchIndex !== null) data[searchIndex].qty_available = parseInt(cartProduct.qty_available)
                    })

                    let assignedProds = []
                    // iterate over assigned products, first creating list of assigned products from hosts' products list
                    assignedProducts.forEach((host) => {
                        assignedProds.push(...host.products)
                    })
                    assignedProds.forEach((assignedProduct) => {
                        let searchProduct = data.find((product) => product.line_id === assignedProduct.line_id)
                        let searchIndex = searchProduct ? data.findIndex((update) => update.line_id === searchProduct.line_id) : null
                        if (searchProduct && searchIndex >= 0) {
                            data[searchIndex].qty_available = parseInt(assignedProduct.qty_available)
                            if (data[searchIndex].qty_available === 0) data.splice(searchIndex, 1)
                        }
                    })

                    // sort the data by product number
                    let orderByProductNum = orderBy(data, [{
                        field: "product_number",
                        dir: "asc"
                    }]);
                    // handle initial load/async data states for downloads
                    setSearchProducts(orderByProductNum);
                    const newSearchProductsState = process(
                        orderByProductNum.map((product) => ({
                            ...product,
                        })),
                        initialGridState
                    );
                    setSearchProductsState(newSearchProductsState);
                }
                setIsLoading(false);
                setUserSearched(true);
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Data", error);
                setIsLoading(false);
            });
    };

    const AddToCartCell = (props) => {
        const {
            dataItem,
        } = props;
        const localization = useLocalization();
        const anchor = useRef(null);

        // adds row to My Cart grid
        const onClickAddToCart = () => {
            // make sure row does not exist in cart products table
            let doesExist = cartProducts.find(row => row.product_id === dataItem.product_id)
            if (!doesExist) {
                let newCartProducts = cartProducts.map((product) => product.selected ? {...product, selected: false} : product)
                newCartProducts.push(dataItem)
                // sort the data by product number
                let orderByProductNum = orderBy(newCartProducts, [{
                    field: "product_number",
                    dir: "asc"
                }]);
                // update cart states
                setCartProducts(orderByProductNum);
                const newCartProductsState = process(
                    orderByProductNum.map((product) => ({
                        ...product,
                    })),
                    cartProductsGridState
                );
                setCartProductsState(newCartProductsState);
            }
        }

        return (
            <>
                <td
                    ref={anchor}
                >
                    <Tooltip
                        anchorElement="pointer"
                        showCallout={false}
                        parentTitle={true}
                        openDelay={0}
                        position={'left'}
                    >
                            <span
                                title={localization.toLanguageString(addToCartKey, mainMessages[siteLanguageDefault][addToCartKey])}
                                className="k-icon k-i-cart"
                                style={{
                                    color: 'var(--keysight-blue)',
                                    marginLeft: '0.35rem',
                                    marginRight: '0.35rem',
                                    fontSize: '1.3rem',
                                    cursor: 'pointer'
                                }}
                                onClick={onClickAddToCart}
                            />
                    </Tooltip>
                </td>
            </>
        )
    };

    const DeleteCell = (props) => {
        const {
            dataItem,
        } = props;
        const localization = useLocalization();
        const anchor = useRef(null);

        // removes the row from grid
        const onClickDelete = () => {
            let index = cartProducts.findIndex(product => product.product_id === dataItem.product_id)
            let newCartProducts = cartProducts
            newCartProducts.splice(index, 1)
            // sort the data by product number
            let orderByProductNum = orderBy(newCartProducts, [{
                field: "product_number",
                dir: "asc"
            }]);
            // update cart states
            setCartProducts(orderByProductNum);
            const newCartProductsState = process(
                orderByProductNum.map((product) => ({
                    ...product,
                })),
                cartProductsGridState
            );
            setCartProductsState(newCartProductsState);
        }

        return (
            <>
                <td
                    ref={anchor}
                >
                    <Tooltip
                        anchorElement="pointer"
                        showCallout={false}
                        parentTitle={true}
                        openDelay={0}
                        position={'left'}
                    >
                            <span
                                title={localization.toLanguageString(removeKey, mainMessages[siteLanguageDefault][removeKey])}
                                className="k-icon k-i-delete"
                                style={{
                                    color: 'var(--keysight-blue)',
                                    marginLeft: '0.35rem',
                                    marginRight: '0.35rem',
                                    fontSize: '1.3rem',
                                    cursor: 'pointer'
                                }}
                                onClick={onClickDelete}
                            />
                    </Tooltip>
                </td>
            </>
        )
    };

    return (
        <>
            {cartProducts.length > 0 && (
                <>
                    <div className={"k-h4"}>
                        {localization.toLanguageString(myCartKey, mainMessages[siteLanguageDefault][myCartKey])}
                    </div>
                    <div
                        style={{
                            "marginBottom": "1.25rem"
                        }}
                    >
                        <Grid
                            className={'cart-products-grid'}
                            scrollable={"none"}
                            sortable={true}
                            pageable={(cartProducts.length <= 10) ? false : {
                                buttonCount: 5, pageSizes: [10, 20, 50, 100],
                            }}
                            total={cartProductsState.total}
                            data={cartProductsState}
                            onDataStateChange={onCartProductsStateChange}
                            sort={[{
                                field: "product_number",
                                dir: "asc",
                            }]}
                            {...cartProductsGridState}
                        >
                            <GridNoRecords>
                                {localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey])}
                            </GridNoRecords>
                            <GridColumn
                                field="product_number"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="product_desc"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                cell={(props) =>
                                    <DeleteCell {...props} />
                                }
                                width={"1.25rem"}
                                sortable={false}
                            />
                        </Grid>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                marginTop: '0.938rem',
                            }}
                        >
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                                style={{
                                    width: '7.188rem'
                                }}
                                onClick={() => {
                                    changeStepActivation(1)
                                }}
                            >
                                {localization.toLanguageString(continueKey, mainMessages[siteLanguageDefault][continueKey])}
                            </Button>
                        </div>
                    </div>
                </>
            )}
            <div
                className={"k-h4"}
            >
                {localization.toLanguageString(searchProductsKey, mainMessages[siteLanguageDefault][searchProductsKey])}
            </div>
            <div
                style={{
                    display: 'flex',
                    gap: '0.938rem',
                    alignItems: 'baseline',
                    marginBottom: '0.938rem',
                }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.125rem',
                }}>
                    <TextBox
                        value={searchValue}
                        onChange={onChangeSearchValue}
                        placeholder={localization.toLanguageString(enterProductNumberDescriptionKey, mainMessages[siteLanguageDefault][enterProductNumberDescriptionKey])}
                        suffix={
                            () =>
                                <>
                                    {searchValue !== "" &&
                                        <InputClearValue onClick={handleClear}>
                                            <Icon name="x"/>
                                        </InputClearValue>
                                    }
                                </>
                        }
                        style={{
                            width: '25rem',
                            height: '1.875rem',
                            borderColor: '#AAAAAA'
                        }}
                    />
                </div>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    style={{
                        width: '7.188rem'
                    }}
                    onClick={onClickSearchProduct}
                >
                    {localization.toLanguageString(searchKey, mainMessages[siteLanguageDefault][searchKey])}
                </Button>
            </div>
            <div
                style={{
                    "marginBottom": "1.25rem"
                }}
            >
                <Grid
                    className={'search-products-grid'}
                    scrollable={"none"}
                    sortable={true}
                    pageable={(searchProducts.length <= 10) ? false : {
                        buttonCount: 5, pageSizes: [10, 20, 50, 100],
                    }}
                    total={searchProductsState.total}
                    data={searchProductsState}
                    onDataStateChange={onSearchProductsStateChange}
                    sort={[{
                        field: "product_number",
                        dir: "asc",
                    }]}
                    {...searchProductsGridState}
                >
                    <GridNoRecords>
                        {userSearched ? localization.toLanguageString(emptySearchKey, mainMessages[siteLanguageDefault][emptySearchKey])
                            : localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey])}
                    </GridNoRecords>
                    <GridColumn
                        field="product_number"
                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="product_desc"
                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="qty_available"
                        title={localization.toLanguageString(qtyAvailableKey, mainMessages[siteLanguageDefault][qtyAvailableKey])}
                    />
                    <GridColumn
                        cell={(props) =>
                            <AddToCartCell {...props} />
                        }
                        width={"1.25rem"}
                        sortable={false}
                    />
                </Grid>
            </div>
        </>
    );

}

export default SelectProducts;