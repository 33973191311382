import React, {useContext, useEffect, useRef, useState, useCallback} from 'react';
import axios from 'axios';
import uuid from 'react-uuid';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';
import {useHistory} from "react-router-dom";

// css
import '../assets/css/RenewStandalone.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Spinner from '../components/common/Spinner.js';
import PageHeader from '../components/common/PageHeader.js';
import KSMStepper from '../components/common/Stepper.js';
import Alert from '../components/common/Alert.js';
import SelectSubscription from '../components/RenewStandalone/SelectSubscription.js';
import SelectLicenses from '../components/RenewStandalone/SelectLicenses.js';
import DownloadLicenses from '../components/RenewStandalone/DownloadLicenses.js';
import {EmailDialog} from '../components/common/EmailModal.js';
import {downloadLicenses} from '../components/common/utilities.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    mainMessages,
    emailReceiveKey,
    emailSuccessKey,
    downloadAllLicensesKey,
    downloadLicensesUpperKey,
    mailAllKey,
    notGeneratedAvailableRedeemV2Key,
    generatedRetrieveLicensesKey,
    genericErrorTitleKey,
    requestSuccessfullyKey,
    loadingPeriodsKey,
    noSupportRenewalKey,
    renewStandaloneNoSubscriptionErrorSubtitleSubscriptionKey,
    renewStandaloneNoSubscriptionErrorSubtitleToChangeKey,
    renewStandaloneNoSubscriptionErrorSubtitleYouHaveKey,
    noSupportAvailableProductKey,
    selectAllRenewalsKey,
    supportRenewTogetherKey,
    renewMoreSubKey,
    renewalSelectedKey,
    selectLicensesKey,
    selectSubscriptionKey,
    generationTimeoutMessageV2Key,
    generationTimeoutKey,
    standaloneSubscriptionKey,
    noLicenseFileForRenewalKey,
    youAreAllDoneKey,
    navigationAlertKey
} from '../assets/text/MultilingualText.js';


function RenewStandalone(props) {
    const {
        accessToken,
        siteLanguage,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const defaultGridMessage = localization.toLanguageString(
        noSupportRenewalKey,
        mainMessages[siteLanguageDefault][noSupportRenewalKey]
    );
    const defaultLoadingMessage = localization.toLanguageString(
        loadingPeriodsKey,
        mainMessages[siteLanguageDefault][loadingPeriodsKey]
    );

    const [isLoading, setIsLoading] = useState(false);
    const [gridNoRecordsMessage, setGridNoRecordsMessage] = useState(defaultGridMessage);

    const [alerts, setAlerts] = useState([]);
    const alertsRef = useRef(alerts);
    const [showGeneralSuccess, setShowGeneralSuccess] = useState(true);

    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [disableEmailDownloadButton, setDisableEmailDownloadButton] = useState(true);
    const [disableRenewMoreSubscription, setDisableRenewMoreSubscription] = useState(true);

    const [renewals, setRenewals] = useState([]);
    const [renewalLicenses, setRenewalLicenses] = useState([]);
    const [subscription, setSubscription] = useState({});
    const [productID, setProductID] = useState("");
    const [orderID, setOrderID] = useState("");
    const [downloadableTransactionIDs, setDownloadableTransactionIDs] = useState([]);
    const [partials, setPartials] = useState([]);

    const [disabled, setDisabled] = useState(true);

    const [stepperItems, setStepperItems] = useState([
        {
            label: localization.toLanguageString(selectSubscriptionKey, mainMessages[siteLanguageDefault][selectSubscriptionKey]),
            number: 1,
            disabled: false,
        },
        {
            label: localization.toLanguageString(selectLicensesKey, mainMessages[siteLanguageDefault][selectLicensesKey]),
            number: 2,
            disabled: true,
        },
        {
            label: localization.toLanguageString(downloadLicensesUpperKey, mainMessages[siteLanguageDefault][downloadLicensesUpperKey]),
            number: 3,
            disabled: true,
        }
    ]);

    const [stepValue, setStepValue] = useState(0);

    //Changes step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {
        setAlerts([]);
        setShowEmailSuccess(false);

        //Change from Select Subscription to Select Licenses step
        if (stepValue === 0) {
            if (stepChange === 1) {
                stepperItems[stepChange].disabled = false;
            }
            stepperItems[2].disabled = true;
        }

        //Change from Select Licenses to Download License Step
        if (stepValue === 1) {
            if (stepChange === 2) {
                stepperItems[stepChange].disabled = false;
                stepperItems[1].disabled = true;
            }
        }

        //Change from License Download Step
        if (stepValue === 2) {
            if (stepChange === 0) {
                stepperItems[2].disabled = true;
            }
        }

        // get standalone renewal subscriptions when changing to select subscription step
        if (stepChange === 0) {
            getStandaloneRenewalSubscriptions();
        }

        setStepValue(stepChange);
        setStepperItems(stepperItems);
    }

    // on language change, refresh stepper labels
    useEffect(() => {
        setStepperItems([
            {
                label: localization.toLanguageString(selectSubscriptionKey, mainMessages[siteLanguageDefault][selectSubscriptionKey]),
                number: 1,
                disabled: false,
            },
            {
                label: localization.toLanguageString(selectLicensesKey, mainMessages[siteLanguageDefault][selectLicensesKey]),
                number: 2,
                disabled: true,
            },
            {
                label: localization.toLanguageString(downloadLicensesUpperKey, mainMessages[siteLanguageDefault][downloadLicensesUpperKey]),
                number: 3,
                disabled: true,
            }
        ])
    }, [siteLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    //Closes the success general alert
    const alertGeneralSuccessHandler = () => {
        setShowGeneralSuccess(false);
    }

    //Closes the success email alert
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false)
    }

    /*
    * getStandaloneRenewalSubscriptions() gets all standalone renewal subscriptions
   */
    const getStandaloneRenewalSubscriptions = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        setIsLoading(true);
        setGridNoRecordsMessage(defaultLoadingMessage);

        axios.get(
            config.renew_standalone.RENEWALS,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];

                    setIsLoading(false);
                    setRenewals(data);
                    setGridNoRecordsMessage(defaultGridMessage);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Standalone Renewals Subscriptions", error);
                setIsLoading(false);
                setGridNoRecordsMessage(defaultGridMessage);
            });
    }

    /*
    * getStandaloneRenewalLicenses() gets all licenses for a standalone renewal subscription
    * @param {props} the props of the license row
   */
    const getStandaloneRenewalLicenses = (props) => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let productId = props.product_id || "";
        let orderId = props.order_id || "";
        setProductID(productId);
        setOrderID(orderId);

        setSubscription(props);
        setIsLoading(true);

        axios.get(
            config.renew_standalone.RENEWAL_PRODUCTS + productId,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    data.forEach(renewal => {
                        renewal.licenses.forEach(license => {
                            if (license.host_id === null) {
                                license.host_id = "Unassigned";
                            }
                            // handle grid column menu date filter
                            if (license.current_support_end_date) {
                                license.current_support_end_date_display = new Date(license.current_support_end_date);
                            }
                        })
                    });
                    setRenewalLicenses(data);
                    setIsLoading(false);
                    changeStepActivation(1);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Standalone Renewal Licenses", error);
                showError("generic", undefined, undefined);
                setIsLoading(false);
            });
    }

    /*
    * allPartialsSelected() validates if all partial licenses are selected for each standalone renewal
    * @return true if all partials are selected
   */
    const allPartialsSelected = () => {
        let allPartialsSelected;
        let allSelected = [];
        let partials = [];
        let hostIds = [];

        // get all partials licenses' renewal index, host id, selected, and quantity that create a complete standalone renewal
        renewalLicenses.forEach((renewal, renewalIndex) =>
            renewal.licenses.forEach(license => {
                let hostId = license.host_id;
                let subId = license.subscription_id;
                let partial = {
                    "renewal": renewalIndex,
                    "host_id": hostId,
                    "subscription_id": subId,
                    "selected": 0,
                    "quantity": 0
                };

                // ignore host id's that are null or unassigned
                if (hostId !== "Unassigned") {
                    let index = partials.findIndex(partial =>
                        partial.host_id === hostId &&
                        partial.renewal === renewalIndex
                    );
                    if (index > -1) {
                        partials[index]["quantity"] = (partials[index]["quantity"] || 0) + 1;
                        if (license.selected) {
                            partials[index]["selected"] = (partials[index]["selected"] || 0) + 1;
                        }
                    } else {
                        partial["quantity"] = 1;
                        if (license.selected) {
                            partial["selected"] = 1;
                        }
                        partials.push(partial);
                    }
                }
            })
        );

        // all partial licenses are selected if the number of host ids selected equals the quantity that create a complete standalone renewal
        renewalLicenses.forEach((renewal, renewalIndex) => {
            let partialRenewals = partials.filter(partial =>
                partial.renewal === renewalIndex &&
                partial.selected > 0
            );

            if (partialRenewals.length > 0) {
                let selected = partialRenewals.every(partialRenewal => partialRenewal.selected === partialRenewal.quantity);
                if (!selected) {
                    let ids = partialRenewals
                        .filter(partialRenewal => partialRenewal.selected !== partialRenewal.quantity)
                        .map(partialRenewal => ({
                            host_id: partialRenewal.host_id,
                            sub_id: partialRenewal.subscription_id,
                            renewal_index: renewalIndex
                        }));
                    hostIds.push(...ids);
                }
                allSelected.push(selected);
            }
        });
        // all partial licenses in a standalone renewal are selected if every element in allPartialsSelected is true
        allPartialsSelected = allSelected.every(element => element === true);

        // show error for all incomplete selected partials
        if (!allPartialsSelected) {
            showError("partials", undefined, hostIds.map(id => id.host_id));
            setPartials(hostIds);
        }

        return allPartialsSelected;
    }

    /*
    * requestRenewalKey() requests a renewal for the selected hosts across all licenses for a standalone renewal subscription
   */
    const requestRenewalKey = () => {
        if (allPartialsSelected()) {
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            };

            // call request renewal api for each renewal or panel bar
            renewalLicenses.forEach((renewal, index) => {
                let updates = [...renewalLicenses];
                let products = [];
                let transactionId = uuid();

                renewal.licenses.forEach(license => {
                    if (license.selected) {
                        let hostId = license.host_id;
                        let subId = license.subscription_id;

                        if (hostId === "Unassigned") hostId = null;

                        products.push({
                            host_id: hostId,
                            subscription_id: subId
                        });
                    }
                })

                // call request renewal api if products are selected for a renewal or panel bar
                if (products.length > 0) {
                    let data = {
                        "transaction_id": transactionId,
                        "product_id": productID,
                        "order_id": orderID,
                        "products": products
                    };
                    let formData = new FormData();
                    formData.append('Data', JSON.stringify(data));

                    updates[index]['transaction_id'] = transactionId;
                    updates[index]['download_icon'] = "loading";
                    setIsLoading(true);

                    axios.post(
                        config.renew_standalone.REQUEST_RENEWAL,
                        formData,
                        {headers: headers}
                    )
                        .then((response) => {
                            if (response.status === 200) {
                                let data = response.data || [];

                                if (data.renewal_count > 0) {
                                    setDisableRenewMoreSubscription(false);
                                }

                                if (data.transaction_id) {
                                    updates[index]['transaction_id'] = data.transaction_id;
                                    setDownloadableTransactionIDs(downloadableTransactionIDs =>
                                        [...downloadableTransactionIDs, data.transaction_id]
                                    );
                                }

                                // update stepper, enable or disable buttons, show success
                                changeStepActivation(2);
                                setIsLoading(false);

                                if (data.license_type === "N/A") {
                                    updates[index]['download_icon'] = "none";
                                    setDisableEmailDownloadButton(true);
                                    showUnassignedSuccess();
                                } else {
                                    updates[index]['download_icon'] = "download";
                                    setDisableEmailDownloadButton(false);
                                    showSuccess();
                                }
                            }
                        })
                        .catch((error) => {
                            console.log("ERROR: Failed to POST Request Standalone Renewal", error);

                            setDisableEmailDownloadButton(true);
                            setDisableRenewMoreSubscription(false);
                            setIsLoading(false);

                            let status = typeof error.response?.status === 'undefined' ? "" : error.response.status;
                            let errorCode = typeof error.response?.data.error_code === 'undefined' ? "" : error.response.data.error_code;

                            switch (status) {
                                case 400:
                                    changeStepActivation(2);
                                    showSuccess();
                                    if (errorCode === "MOD_LICENSE_228") {
                                        updates[index]['download_icon'] = "support-agent-error";
                                    } else {
                                        // handle error codes: MOD_LICENSE_234, MOD_SUBS_RENEWAL_001
                                        updates[index]['download_icon'] = "error";
                                    }
                                    break;
                                case 408:
                                    showError("timeout", undefined, undefined);
                                    break;
                                case 500:
                                    showError("generic", undefined, undefined);
                                    break;
                                default:
                                    showError("generic", undefined, undefined);
                                    break;
                            }

                            // handle no network
                            if (!error.response) {
                                setIsLoading(false);
                                showError("generic", undefined, undefined);
                            }
                        })
                        .finally(() => {
                            setRenewalLicenses(updates);
                        });
                }
            })
        }
    }

    /*
     * showError() shows an error notification
     * @param {type} the type of error
     * @param {qty} the qty for a noSubscription error
     * @param {hosts} the partial hosts for a partial error
     *  type: noSubscription, generic
    */
    const showError = (type, qty = 0, hosts = []) => {
        let errorTitleKey = type === "noSubscription" ? noSupportAvailableProductKey
            : type === "partials" ? supportRenewTogetherKey
                : type === "timeout" ? generationTimeoutKey
                    : genericErrorTitleKey;
        let errorTitle = localization.toLanguageString(errorTitleKey,
            mainMessages[siteLanguageDefault][errorTitleKey]) || "";

        let errorMessageKey = type === "partials" ? selectAllRenewalsKey
            : type === "timeout" ? generationTimeoutMessageV2Key
                : notGeneratedAvailableRedeemV2Key;
        let errorMessage = localization.toLanguageString(errorMessageKey,
            mainMessages[siteLanguageDefault][errorMessageKey]) || "";

        if (type === "noSubscription") {
            let messageYouOnlyHave = localization.toLanguageString(
                renewStandaloneNoSubscriptionErrorSubtitleYouHaveKey,
                mainMessages[siteLanguageDefault][renewStandaloneNoSubscriptionErrorSubtitleYouHaveKey]
            );
            let messageSubscriptionRenewals = localization.toLanguageString(
                renewStandaloneNoSubscriptionErrorSubtitleSubscriptionKey,
                mainMessages[siteLanguageDefault][renewStandaloneNoSubscriptionErrorSubtitleSubscriptionKey]
            );
            let messageRowsToChange = localization.toLanguageString(
                renewStandaloneNoSubscriptionErrorSubtitleToChangeKey,
                mainMessages[siteLanguageDefault][renewStandaloneNoSubscriptionErrorSubtitleToChangeKey]
            );

            errorMessage = messageYouOnlyHave + qty + messageSubscriptionRenewals + qty + messageRowsToChange;
        } else if (type === "partials") {
            errorMessage = <>
                {errorMessage}
                <br/>
                {hosts.join(', ')}
            </>
        }

        let error = {
            type: "error",
            category: type,
            title: errorTitle,
            message: errorMessage
        }

        let categories = alertsRef.current.map(alert => alert.category);
        categories.push(type);

        let set = new Set(categories);

        // if all values in the error object are not null and no duplicates errors exist, render the error
        if (!Object.values(error).every(e => !e) && !(set.size < categories.length)) {
            setAlerts(alerts => [...alerts, error]);
        }
    }

    /*
     * showUnassignedSuccess() shows a success notification for unassigned licenses
    */
    const showUnassignedSuccess = () => {
        let successTitle = localization.toLanguageString(youAreAllDoneKey,
            mainMessages[siteLanguageDefault][youAreAllDoneKey]) || "";
        let successMessage = localization.toLanguageString(noLicenseFileForRenewalKey,
            mainMessages[siteLanguageDefault][noLicenseFileForRenewalKey]) || "";

        let success = {
            type: "success",
            title: successTitle,
            message: successMessage
        }

        setAlerts(alerts => [...alerts, success]);
    }

    /*
     * showSuccess() shows a success notification
    */
    const showSuccess = () => {
        let successTitle = localization.toLanguageString(requestSuccessfullyKey,
            mainMessages[siteLanguageDefault][requestSuccessfullyKey]) || "";
        let successMessage = localization.toLanguageString(generatedRetrieveLicensesKey,
            mainMessages[siteLanguageDefault][generatedRetrieveLicensesKey]) || "";

        let success = {
            type: "success",
            title: successTitle,
            message: successMessage
        }

        setAlerts(alerts => [...alerts, success]);
    }

    useEffect(() => {
        getStandaloneRenewalSubscriptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // enable or disable request renewal button if there are selected licenses
        let selected = [];
        renewalLicenses.forEach(renewal =>
            renewal.licenses.forEach(license => {
                if (license.selected) {
                    if (stepValue === 1 && navigator.onLine) {
                        setAlerts([]);
                    }
                    selected.push(license.subscription_id);
                }
            })
        );
        selected.length ? setDisabled(false) : setDisabled(true);
    }, [renewalLicenses]); // eslint-disable-line react-hooks/exhaustive-deps

    // update downloadableTransactionIDs for download all license(s)
    useEffect(() => {
        let transactionIds = [];
        renewalLicenses.forEach(renewal => {
            if (renewal.transaction_id) {
                transactionIds.push(renewal.transaction_id);
            }
        });

        setDownloadableTransactionIDs([...new Set(transactionIds)]);
    }, [renewalLicenses]);

    // alerts ref is the same as alerts
    useEffect(() => {
        alertsRef.current = alerts;
    }, [alerts]);

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    //Confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {
        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
            )) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <Col/>
                    <Col>
                        <div style={{
                            width: "100%"
                        }}>
                            <KSMStepper
                                stepperItems={stepperItems}
                                stepValue={stepValue}
                                changeStepActivation={changeStepActivation}
                            />
                        </div>
                    </Col>
                    <Col/>
                </Row>
                <Row>
                    <div className={"k-h2"}>
                        <Text
                            textkey={standaloneSubscriptionKey}
                            textdefault={mainMessages[siteLanguageDefault][standaloneSubscriptionKey]}
                        />
                    </div>
                </Row>
                <div
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    {stepValue === 0 && <SelectSubscription
                        renewals={renewals}
                        getStandaloneRenewalLicenses={getStandaloneRenewalLicenses}
                        gridNoRecordsMessage={gridNoRecordsMessage}
                    />}
                    {stepValue === 1 && <SelectLicenses
                        subscription={subscription}
                        renewalLicenses={renewalLicenses}
                        setRenewalLicenses={setRenewalLicenses}
                        partials={partials}
                        setPartials={setPartials}
                        gridNoRecordsMessage={gridNoRecordsMessage}
                        showError={showError}
                    />}
                    {stepValue === 2 && <DownloadLicenses
                        subscription={subscription}
                        renewalLicenses={renewalLicenses}
                        setIsLoading={setIsLoading}
                        gridNoRecordsMessage={gridNoRecordsMessage}
                        handleBeforeUnload={handleBeforeUnload}
                    />}
                </div>
                <Row
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <Col>
                        {(stepValue === 1) && (
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                shape={"rectangle"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                                disabled={disabled}
                                onClick={requestRenewalKey}
                            >
                                {localization.toLanguageString(renewalSelectedKey, mainMessages[siteLanguageDefault][renewalSelectedKey])}
                            </Button>
                        )}
                        {(stepValue === 2) && (
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                shape={"rectangle"}
                                fillMode={"outline"}
                                rounded={"small"}
                                type={"button"}
                                disabled={disableRenewMoreSubscription}
                                onClick={() => {
                                    changeStepActivation(0);
                                }}
                            >
                                {localization.toLanguageString(renewMoreSubKey, mainMessages[siteLanguageDefault][renewMoreSubKey])}
                            </Button>
                        )}
                    </Col>
                    <Col>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0.938rem',
                            }}
                        >
                            {alerts.length ? (alerts.map((alert, index) => {
                                if (alert.type === "error") {
                                    return (
                                        <div key={'error-' + index}>
                                            <Alert
                                                type={'error'}
                                                title={alert.title}
                                                message={alert.message}
                                            />
                                        </div>
                                    )
                                } else if (alert.type === "success" && showGeneralSuccess) {
                                    return (
                                        <div key={'success-' + index}>
                                            <Alert
                                                type={'success'}
                                                showHandler={alertGeneralSuccessHandler}
                                                title={alert.title}
                                                message={alert.message}
                                            />
                                        </div>
                                    )
                                } else {
                                    return <></>
                                }
                            }))
                            :
                            <></>}
                            {showEmailSuccess && (
                                <Alert
                                    type={'success'}
                                    showHandler={alertEmailSuccessHandler}
                                    title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                                    message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                                />
                            )}
                        </div>
                    </Col>
                    <Col>
                        {stepValue === 2 && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    flexWrap: 'wrap',
                                    gap: '0.938rem'
                                }}
                            >
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    shape={"rectangle"}
                                    fillMode={"solid"}
                                    rounded={"small"}
                                    icon={"email"}
                                    disabled={disableEmailDownloadButton}
                                    onClick={() => {
                                        setShowEmailModal(true)
                                    }}
                                >
                                    {localization.toLanguageString(mailAllKey, mainMessages[siteLanguageDefault][mailAllKey])}
                                </Button>
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    shape={"rectangle"}
                                    fillMode={"solid"}
                                    rounded={"small"}
                                    icon={"download"}
                                    onClick={() => {
                                        downloadLicenses(downloadableTransactionIDs, accessToken, setIsLoading, handleBeforeUnload);
                                    }}
                                    disabled={disableEmailDownloadButton}
                                >
                                    {localization.toLanguageString(downloadAllLicensesKey, mainMessages[siteLanguageDefault][downloadAllLicensesKey])}
                                </Button>
                            </div>
                        )}
                    </Col>
                    {showEmailModal && (
                        <EmailDialog
                            setShowEmailModal={setShowEmailModal}
                            setShowEmailSuccess={setShowEmailSuccess}
                            transactionIDs={downloadableTransactionIDs}
                            setIsLoading={setIsLoading}
                            request_source='RENEW_STANDALONE'
                        />
                    )}
                </Row>
                <br/>
            </div>
        </>
    );
}

export default RenewStandalone;
