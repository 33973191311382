import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// reactstrap
import {Row} from 'reactstrap';

// components
import SelectLicensesGrid from './SelectLicenses/SelectLicensesGrid.js';

// kendo react
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';

// multilingual
import {Text} from '../common/MultilingualText.js';
import {mainMessages, qtyKey} from '../../assets/text/MultilingualText.js';


function SelectLicenses(props) {
    const {
        subscription,
        renewalLicenses,
        setRenewalLicenses,
        partials,
        setPartials,
        gridNoRecordsMessage,
        showError
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);

    const initialQty = parseInt(subscription.qty);
    const [totalQty, setTotalQty] = useState(initialQty);

    // set initial qty on license change
    useEffect(() => {
        isNaN(initialQty) ? setTotalQty(0) : setTotalQty(initialQty);
    }, [subscription]); // eslint-disable-line react-hooks/exhaustive-deps

    // update qty subheader on renewal license selection
    useEffect(() => {
        let selected = [];
        renewalLicenses.forEach((renewal) => {
            renewal.licenses.forEach((license) => {
                if (license.selected && !selected.includes(license.subscription_id)) {
                    selected.push(license.subscription_id);
                }
            })
        });
        setTotalQty(initialQty - selected.length);
    }, [renewalLicenses]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Row>
                <div className={"k-h4"}>
                    {subscription.product_number && subscription.order_number && subscription.description ? subscription.product_number + " - " + subscription.description : ""}
                    &nbsp;
                    (<Text
                    textkey={qtyKey}
                    textdefault={mainMessages[siteLanguageDefault][qtyKey]}
                /> {totalQty})
                </div>
            </Row>
            {renewalLicenses.map((license, index) => {
                let licenseName = license.license_name || "";
                let licenseDescription = license.license_description || "";

                let panelBarTitle = licenseName && licenseDescription ? licenseName + " - " + licenseDescription : "";

                return (
                    <React.Fragment key={"select-license-" + license.license_id + "-" + index}>
                        <PanelBar
                            className={'ksm-panelbar-default'}
                            expanded={['.0']}
                            expandMode={'single'}
                            keepItemsMounted={true}
                            key={"select-license-panel-bar-" + license.license_id + "-" + index}
                        >
                            <PanelBarItem
                                title={
                                    <div
                                        className={"k-h4"}
                                        style={{
                                            color: 'white',
                                            margin: '0 0 0 2px',
                                        }}
                                    >
                                        {panelBarTitle}
                                    </div>
                                }
                            >
                                <SelectLicensesGrid
                                    index={index}
                                    renewalLicenses={renewalLicenses}
                                    setRenewalLicenses={setRenewalLicenses}
                                    initialQty={initialQty}
                                    partials={partials}
                                    setPartials={setPartials}
                                    gridNoRecordsMessage={gridNoRecordsMessage}
                                    showError={showError}
                                />
                            </PanelBarItem>
                        </PanelBar>
                    </React.Fragment>
                )
            })}
        </>
    );
}

export default SelectLicenses;
