import React, {useCallback, useContext, useRef} from 'react';
import UserContext from '../../common/UserContext';

// react strap
import {Col, Row} from 'reactstrap';

// kendo react
import {Tooltip} from '@progress/kendo-react-tooltip';
import {Checkbox} from "@progress/kendo-react-inputs";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    downloadLicensesKey, loadingGenerationKey,
} from '../../../assets/text/MultilingualText';
import {PopoverContext} from "./LicenseDownload";
import {Loader} from "@progress/kendo-react-indicators";
import {downloadLicenses} from "../../common/utilities";


export const DownloadPanelBarHeader = (props) => {
    const {
        handleBeforeUnload,
        requestedProducts,
        setRequestedProducts,
        index,
        setIsLoading,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    //Sets the popover reference based on mouse over
    const popoverRef = useRef(null);
    const {onMouseOver} = useContext(PopoverContext);

    let host = requestedProducts[index]

    const handleMouseOver = useCallback(() => {
        const icons = host.icons
        onMouseOver({
            show: true,
            popoverRef: popoverRef,
            cloudAlert: icons.cloudAlert,
            specialAlert: icons.specialAlert,
            supportError: icons.supportError,
            generationError: icons.generationError,
            qtyError: icons.qtyError,
            entitlementError: icons.entitlementError,
        });
    }, [onMouseOver, host.icons]);
    const handleMouseOut = useCallback(() => {
        const icons = host.icons;
        onMouseOver({
            show: false,
            popoverRef: popoverRef,
            cloudAlert: icons.cloudAlert,
            specialAlert: icons.specialAlert,
            supportError: icons.supportError,
            generationError: icons.generationError,
            qtyError: icons.qtyError,
            entitlementError: icons.entitlementError,
        });
    }, [onMouseOver, host.icons]);

    return (
        <>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        className={"k-h4"}
                        style={{
                            color: 'white',
                            margin: '0'
                        }}
                    >
                        {host.panel_bar_title}
                    </div>
                </Col>
                <Col>
                </Col>
                <Col>
                    <div
                        style={{
                            float: 'right'
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.6rem',
                        }}>
                            {(host.icons.loading || host.icons.download) && (
                                <Checkbox
                                    className={'ksm-panelbar-checkbox'}
                                    value={host.icons.checked}
                                    onClick={(e) => {
                                        requestedProducts[index].icons.checked = e.target.checked
                                        setRequestedProducts(JSON.parse(JSON.stringify(requestedProducts)))
                                    }}
                                />
                            )}

                            {host.icons.loading && (
                                <Tooltip
                                    anchorElement="pointer"
                                    showCallout={false}
                                    parentTitle={true}
                                    openDelay={0}
                                    filter={(e) => {
                                        const classNames = ['k-loader k-loader-md k-loader-primary k-loader-pulsing-2', 'k-loader-segment', 'k-loader-canvas'];
                                        if (classNames.includes(e.className)) {
                                            return true;
                                        }
                                    }}
                                >
                                    <div
                                        title={localization.toLanguageString(loadingGenerationKey, mainMessages[siteLanguageDefault][loadingGenerationKey])}
                                        style={{
                                            paddingBottom: '1px'
                                        }}
                                    >
                                        <Loader
                                            type={'pulsing'}
                                            style={{
                                                color: 'white'
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {host.icons.download && (
                                <Tooltip
                                    anchorElement="pointer"
                                    showCallout={false}
                                    parentTitle={true}
                                    openDelay={0}
                                >
                            <span
                                title={localization.toLanguageString(downloadLicensesKey, mainMessages[siteLanguageDefault][downloadLicensesKey])}
                                className="k-icon k-i-download"
                                style={{
                                    color: 'white',
                                    marginLeft: '0.35rem',
                                    marginRight: '0.35rem',
                                    fontSize: '1.3rem',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    let eaAllocIds = []
                                    host.products.forEach(product => {
                                        eaAllocIds.push(product.ea_alloc_id.toString())
                                    })
                                    downloadLicenses(eaAllocIds, accessToken, setIsLoading, handleBeforeUnload, "ENTERPRISE_AGREEMENTS");
                                }}
                            />
                                </Tooltip>
                            )}
                            {(host.icons.supportError || host.icons.generationError || host.icons.qtyError || host.icons.entitlementError) &&
                                !host.icons.download && !host.icons.cloudAlert && (
                                    <span
                                        className="k-icon k-i-close-outline"
                                        style={{
                                            color: 'var(--keysight-pink)',
                                            marginLeft: '0.35rem',
                                            marginRight: '0.35rem',
                                            fontSize: '1.3rem'
                                        }}
                                        onMouseOver={handleMouseOver}
                                        onMouseOut={handleMouseOut}
                                        ref={popoverRef}
                                    />
                                )}
                            {(host.icons.cloudAlert || host.icons.specialAlert) && (
                                <span
                                    className="k-icon k-i-warning"
                                    style={{
                                        marginLeft: '0.35rem',
                                        marginRight: '0.35rem',
                                        fontSize: '1.3rem'
                                    }}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                    ref={popoverRef}
                                />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}