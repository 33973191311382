import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import UserContext from '../../common/UserContext.js';

// components
import Alert from '../../common/Alert.js';
import config from "../../../config";

// kendo react
import {TextArea} from '@progress/kendo-react-inputs';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cancelKey,
    sendEmailKey,
    emailValidationMessageKey,
    invalidEmailKey,
    mainMessages,
    sendConfirmationEmailKey,
    sendConfirmationEmailToSeparatedKey
} from '../../../assets/text/MultilingualText.js';



export const EmailDialog = (props) => {
    const {
        setShowEmailModal,
        setIsLoading,
        licenseIds,
        setGenericError,
        setShowEmailSuccess,
    } = props;
    const {
        accessToken,
        userEmail,
        siteLanguageDefault,
        timeout
    } = useContext(UserContext);
    const localization = useLocalization();

    const [emails, setEmails] = useState(userEmail);
    const [showValidationError, setShowValidationError] = useState(false);
    const [textAreaStyling, setTextAreaStyling] = useState({});

    //Sets and remove the textarea error red lining
    useEffect(() => {
        if (showValidationError) {
            setTextAreaStyling({
                border: 'var(--keysight-primary)'
            });
        } else {
            setTextAreaStyling({});
        }
    }, [showValidationError])

    //Close the email modal
    const closeEmailModal = () => {
        setShowEmailModal(false);
    }

    //Validate emails and sets the validate error state
    const validateEmails = () => {
        let emailList = emails.split(",");
        emailList = emailList.map(item => {
            return item.toLowerCase().trim();
        })

        let mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

        let invalid = false;

        emailList.forEach(item => {
            if (item.match(mailFormat) === null) {
                invalid = true;
            }
        })
        if (invalid) {
            setShowValidationError(true);
            return [];
        } else {
            setShowValidationError(false);
            return emailList;
        }
    }

    //On click function for send email button
    const sendEmail = () => {
        const emailList = validateEmails();
        if (emailList.length > 0) {
            setShowValidationError(false);

            setIsLoading(true);
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };
            let payload = {
                "module": "SOFTWARE/ACCESS",
                "sub_module": "CHECKIN",
                "request_from": "",
                "action": "SAVE",
                "input_json": {
                    "license_ids": licenseIds,
                    "send_email": "Y",
                    "to_email": emails,
                }
            }

            axios.post(
                config.software_access.SOFTWARE_ACCESS,
                payload,
                {
                    headers: headers, timeout: timeout
                },
            )
                .then((response) => {
                    if (response.status === 200) {
                        setShowEmailModal(false);
                        setShowEmailSuccess(true);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("ERROR: Failed to GET Host Type Details", error);
                    setGenericError(true);
                    setShowEmailModal(false);
                    setIsLoading(false);
                });
        }
    }

    //closes the validation error alert
    const alertErrorHandler = () => {
        setShowValidationError(false);
    }

    return (
        <Dialog
            title={localization.toLanguageString(sendConfirmationEmailKey, mainMessages[siteLanguageDefault][sendConfirmationEmailKey])}
            className={'ksm-dialog'}
            onClose={closeEmailModal}
            width={"29.375rem"}
        >
            <div>
                <div
                    style={{
                        color: '#424242',
                        marginBottom: '3px'
                    }}
                >
                    {localization.toLanguageString(sendConfirmationEmailToSeparatedKey, mainMessages[siteLanguageDefault][sendConfirmationEmailToSeparatedKey])}
                </div>
                <TextArea
                    defaultValue={emails}
                    onChange={(e) => {
                        setEmails(e.value);
                    }}
                    value={emails}
                    style={textAreaStyling}
                    rows={3}
                    autoFocus={true}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.938rem',
                    marginTop: '0.938rem'
                }}
            >
                {showValidationError &&(
                    <div>
                        <Alert
                            type={'error'}
                            showHandler={alertErrorHandler}
                            title={localization.toLanguageString(invalidEmailKey, mainMessages[siteLanguageDefault][invalidEmailKey])}
                            message={localization.toLanguageString(emailValidationMessageKey, mainMessages[siteLanguageDefault][emailValidationMessageKey])}
                        />
                    </div>
                )}
            </div>
            <DialogActionsBar
                style={{
                    justifyContent: 'center'
                }}
            >
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    rounded={"small"}
                    type={"button"}
                    onClick={closeEmailModal}
                    style={{
                        maxWidth: '9.375rem',
                        border: '2px solid var(--keysight-primary)'
                    }}
                >
                    {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={sendEmail}
                    disabled={emails.trim() === ""}
                    style={{
                        maxWidth: '9.375rem'
                    }}
                >
                    {localization.toLanguageString(sendEmailKey, mainMessages[siteLanguageDefault][sendEmailKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}