import React, {cloneElement, useRef, useState} from 'react';

// utilities
import {capitalizeSentence, formatCurrency} from './utilities.js';

// kendo react data grid custom column filter
import {GridColumnMenuFilter, GridColumnMenuSort} from "@progress/kendo-react-grid";
import {Popover, Tooltip} from '@progress/kendo-react-tooltip';
import {Button} from '@progress/kendo-react-buttons';

// kendo react custom column menu
export const ColumnMenu = props => {
    return <div className={"column-menu"}>
        <GridColumnMenuSort {...props} />
        <GridColumnMenuFilter
            {...props}
            hideSecondFilter={true}
            expanded={true}
        />
    </div>;
};

//Kendo react custom column menu with sort only
export const ColumnSortOnlyMenu = props => {
    return <div className={"column-menu"}>
        <GridColumnMenuSort {...props} />
    </div>;
};

// kendo react custom row render
export const RowRender = (tr, props) => {
    if (props.dataItem.disabled) {
        return cloneElement(
            tr,
            {...tr.props, className: 'k-state-disabled'},
            tr.props.children
        );
    }
    return tr
};

// text align middle cell
export const TextAlignMiddleCell = (props) => {
    const field = props.field;
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle'
            }}
        >
            {props.dataItem[field]}
        </td>
    );
}

// Capitalize the first letter of every word cell
export const CapitalLettersCell = (props) => {
    const field = props.field;
    return (
        <td className={'capital-letters'}>
            {props.dataItem[field]}
        </td>
    );
}

/* action Icon cell
    iconClass : for displying icon on cell
    onHandleClick : to handle click on icon
    showActionIcon : accepts boolean as value. if true it will display icon or else nothing
    title : title then passed to Tooltip component to display tooltip message
*/
export const ActionIconCell = ({onHandleClick, iconClass, showActionIcon, title, ...props}) => {
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle'
            }}
        >
            {showActionIcon &&
                <Tooltip
                    anchorElement="target"
                    parentTitle={true}
                    position="left"
                >
                    <span
                        onClick={onHandleClick}
                        title={title}
                        style={{
                            fontSize: 20
                        }}
                        className={`${iconClass} k-cursor-pointer`}
                    >
                    </span>
                </Tooltip>
            }
        </td>
    );
}

/* Link button cell with Icon
    iconClass : for displying icon on cell
    onHandleClick : to handle click on icon
    showActionIcon : accepts boolean as value. if true it will display icon or else nothing
    title : title then passed to Tooltip component to display tooltip message
    truncate : truncate will reduce number of text to display on UI
*/
export const HostOrderNumberCel = ({onHandleClick, iconClass, showActionIcon, title, truncate, ...props}) => {
    const field = props.field;
    const MAX_CHARS_TO_TRUNCATE = 20;
    const truncatedText = (data) => {
        if (data?.length > MAX_CHARS_TO_TRUNCATE) {
            return `${data?.slice(0, 20)}...`;
        }
        return data;
    }
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle',
                color: "#19416D"
            }}>
            <div className='k-display-flex'>
                <Tooltip
                    anchorElement="target"
                    parentTitle={true}
                    position="left"
                >
                    <span
                        style={{
                            whiteSpace: "nowrap"
                        }}
                        title={props.dataItem[field]}
                        className='k-cursor-pointer'
                    >
                        {truncate ? truncatedText(props.dataItem[field]) : props.dataItem[field]}
                    </span>
                </Tooltip>
                {showActionIcon &&
                    <Tooltip
                        anchorElement="target"
                        parentTitle={true}
                        position="left"
                    >
                        <span
                            onClick={onHandleClick}
                            title={title}
                            style={{
                                fontSize: 20
                            }}
                            className={`${iconClass} k-link k-cursor-pointer`}
                        >
                        </span>
                    </Tooltip>
                }
            </div>
        </td>
    );
}

//Purchase order number Cell
export const PurchaseOrderNumberCell = ({title, truncate, ...props}) => {
    const field = props.field;
    const MAX_CHARS_TO_TRUNCATE = 20;
    const truncatedText = (data) => {
        if (data?.length > MAX_CHARS_TO_TRUNCATE) {
            return `${data?.slice(0, 20)}...`;
        }
        return data;
    }
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle',
                color: "#19416D"
            }}>
            <div className='k-display-flex'>
                <Tooltip
                    anchorElement="target"
                    parentTitle={true}
                    position="left"
                >
                    {props.dataItem[field] &&
                        <span
                            title={props.dataItem[field]}
                            className='k-cursor-pointer k-font-weight-bold'
                        >
                            {truncate ? truncatedText(props.dataItem[field]) : props.dataItem[field]}
                        </span>
                    }
                </Tooltip>
            </div>
        </td>
    );
}

// Custom info Icon cell 
// title ::  tooltip title
// subtitle :: tooltip subtitle 
export const InfoIconCell = ({title, subtitle}) => {
    const anchor = useRef(null);
    const [show, setShow] = useState(false);
    return (
        <td
            style={{
                textAlign: 'center',
                verticalAlign: 'middle'
            }}
        >
            <div>
                <Button
                    className='custom-icon-button'
                    fillMode={"link"}
                    onClick={() => setShow(!show)}
                    ref={anchor}
                >
                    <span
                        onMouseOver={() => setShow(true)}
                        onMouseLeave={() => setShow(false)}
                        style={{
                            fontSize: 20
                        }}
                        className={`k-icon k-link k-i-info k-cursor-pointer`}>
                    </span>
                </Button>
                {show && <Popover
                    popoverClass="custom_info_tooltip"
                    show={true}
                    anchor={anchor.current && anchor.current.element}
                    position={"left"}
                >
                    <div
                        style={{
                            gap: 12
                        }}
                        className="k-display-flex"
                    >
                        <span
                            style={{
                                fontSize: 42
                            }}
                            className={`k-icon k-i-info k-cursor-pointer`}>
                        </span>
                        <div className={'k-d-flex k-flex-column'}>
                            <span
                                style={{
                                    fontSize: 16
                                }}
                                className={'k-font-weight-bold'}
                            >
                                {title}
                            </span>
                            <span
                                style={{
                                    fontSize: 16
                                }}
                                className={'k-font-weight-normal'}
                            >
                                {subtitle}
                            </span>
                        </div>
                    </div>
                </Popover>}
            </div>
        </td>
    )
}

// convert date/string to into KSM date format (e.g. March 9, 2024)
export const convertDateFormat = (date) => {
    if (date === null || date === undefined || date === '' || date.toString() === "Invalid Date") {
        return null;
    }

    date = new Date(date);

    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

// formatted date cell
export const DateCell = (props) => {
    const field = props.field;
    return (
        <td>
            {convertDateFormat(props.dataItem[field])}
        </td>
    );
}

// formatted date cell that turns text red if expired
export const DateIsExpiredCell = (props) => {
    const field = props.field;
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return (
        (new Date(convertDateFormat(currentDate)) <= new Date(convertDateFormat(props.dataItem[field]))) ?
            <td>
                {convertDateFormat(props.dataItem[field])}
            </td>
            :
            <td
                style={{
                    color: "var(--keysight-primary)",
                }}
            >
                {convertDateFormat(props.dataItem[field])}
            </td>
    );
}

// whitespace no wrap for a cell (noticed prod nums get wrapped on spec. chars. -+)
export const NoWrapCell = (props) => {
    return (
        <td
            style={{
                whiteSpace: "nowrap",
            }}
        >
            {props.dataItem[props.field]}
        </td>
    )
}

// TextCapitalizeCell :  convert any text to Capitalized text Ex : KEYSIGHT -> Keysight
export const TextCapitalizeCell = (props) => {
    return (
        <td
            style={{
                whiteSpace: "nowrap",
                textAlign: 'center',
                verticalAlign: 'middle'
            }}
        >
            {capitalizeSentence(props.dataItem[props.field] || '')}
        </td>
    )
}

export const CurrencyCell = (props) => {
    return (
        <td style={{
            textAlign: 'right',
        }}>
            {formatCurrency(props.dataItem[props.field],
                props.dataItem['currency_locale'], props.dataItem['currency_code'])}
        </td>
    )
}