import React, {useState} from "react";

// react router
import {useHistory} from 'react-router-dom';

// kendo
import {DropDownButton, DropDownButtonItem} from '@progress/kendo-react-buttons';
import {Tooltip} from '@progress/kendo-react-tooltip';

// custom
import {StartFromScratchModal} from "./StartFromScratchModal";
import {CopyProductsOnlyModal} from "./CopyProductsOnlyModal";
import {CopyProductsAndHostsModal} from "./CopyProductsAndHostsModal";

// multilingual
import {
    eaLicenseViewKey,
    workOnCurrentPeriodKey,
    workOnNextPeriodKey,
    remixNextPeriodKey,
    remixCurrentPeriodKey,
    copyProductsAndHostsKey,
    copyProductsOnlyKey,
    startFromScratchKey,
    requestAgainKey,
    requestAgainTooltipKey,
    getLicensesKey,
    mainMessages
} from "../../assets/text/MultilingualText.js";


export const HamburgerMenuCell = (props) => {
    const {
        localization,
        siteLanguageDefault,
        setIsLoading,
        accessToken,
        setAlertJSON,
        setShowAlertModal,
    } = props;

    let history = useHistory();

    // Menu Option Flags
    const [menuOptionFlags, setMenuOptionFlags] = useState({
        getLicenses: false,
        workOnCurrentPeriod: false,
        workOnNextPeriod: false,
        remixCurrentPeriod: false,
        remixNextPeriod: false,
        requestAgain: false
    });
    const [expiredFlag, setExpiredFlag] = useState(false);

    // Modal states
    const [showStartFromScratchModal, setShowStartFromScratchModal] = useState(false);
    const [showCopyProductsOnlyModal, setShowCopyProductsOnlyModal] = useState(false);
    const [showCopyProductsAndHostsModal, setShowCopyProductsAndHostsModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [eaId, setEaId] = useState("");

    // Render Logic for Menu Options
    const onClickMenuButton = () => {
        // reset menu option states
        setMenuOptionFlags(
            menuOptionFlags => ({
                ...menuOptionFlags,
                getLicenses: false,
                workOnCurrentPeriod: false,
                workOnNextPeriod: false,
                remixCurrentPeriod: false,
                remixNextPeriod: false,
                requestAgain: false
            })
        )
        // perform menu option logic
        let rowData = props.dataItem;
        setExpiredFlag(calcExpiredFlag(rowData));

        // handle if Variable EA
        if (rowData.remix_type.toString().toLowerCase() === "variable") {
            // setGetLicensesFlag(true);
            setMenuOptionFlags(
                menuOptionFlags => ({
                    ...menuOptionFlags,
                    getLicenses: true,
                    requestAgain: true
                })
            )
        }
        else {
            setMenuOptionFlags(
                menuOptionFlags => ({
                    ...menuOptionFlags,
                    workOnCurrentPeriod: calcWorkOnCurrentPeriodFlag(rowData),
                    workOnNextPeriod: calcWorkOnNextPeriodFlag(rowData),
                    remixCurrentPeriod: calcRemixCurrentPeriodFlag(rowData),
                    remixNextPeriod: calcRemixNextPeriodFlag(rowData)
                })
            )
        }
    };

    // Menu Styling
    const [opened, setOpened] = useState(false);
    const openedStyle = {
        padding: 0,
        color: "var(--keysight-red)"
    };
    const closedStyle = {
        padding: 0,
        color: "var(--keysight-blue)"
    };
    const anchorAlign = {
        horizontal: "right",
        vertical: "top"
    };
    const popupAlign = {
        horizontal: "left",
        vertical: "top"
    };

    // Custom Render Menu Item for Indentation
    const itemRenderIndent = (props) => {
        return (
            <div>
                <span className={"ea-hamburger-indent"} role="presentation" />
                {props.item.text}
            </div>
        );
    };

    // Custom Render Menu Item for Disabled Text
    const itemRenderDisabled = (props) => {
        return (
            <div>
                <span className={"ea-hamburger-disabled"} role="presentation" />
                {props.item.text}
            </div>
        );
    };

    // Methods for Conditionally Rendering Menu Option
    const calcExpiredFlag = (rowData) => {
        if (rowData.next_remix_date === null) return false
        else return rowData.next_remix_date.toString().toLowerCase() === "expired"
    }
    const calcWorkOnCurrentPeriodFlag = (rowData) => {
        return (
            rowData.productselectioncurrentperiod === "TRUE" &&
            (
                rowData.currentperiodeaexists === "TRUE" ||
                rowData.prevtocurrentcopy === "FALSE" ||
                rowData.previousperiodeaexists === "FALSE"
            )
        )
    };
    const calcWorkOnNextPeriodFlag = (rowData) => {
        return (
            rowData.productselectionnextperiod === "TRUE" &&
            (
                rowData.nextperiodeaexists === "TRUE" ||
                rowData.currenttonextcopy === "FALSE" ||
                rowData.currentperiodeaexists === "FALSE"
            )
        )
    };
    const calcRemixCurrentPeriodFlag = (rowData) => {
        return (
            rowData.productselectioncurrentperiod === "TRUE" &&
            rowData.currentperiodeaexists === "FALSE" &&
            rowData.previousperiodeaexists === "TRUE" &&
            rowData.prevtocurrentcopy === "TRUE"
        )
    };
    const calcRemixNextPeriodFlag = (rowData) => {
        return (
            rowData.productselectionnextperiod === "TRUE" &&
            rowData.nextperiodeaexists === "FALSE" &&
            rowData.currentperiodeaexists === "TRUE" &&
            rowData.currenttonextcopy === "TRUE"
        )
    };

    // Menu Option Click Handling
    const onItemClick = (e) => {
        let option = e.item.id
        if (option === "eaLicenseView") {
            history.push('/ea-detailed-view?ea_id=' + props.dataItem.ea_id)
        }
        else if (option === "currentPeriodStartFromScratch" || option === "nextPeriodStartFromScratch") {
            setShowStartFromScratchModal(true);
            setModalType(option);
            setEaId(props.dataItem.ea_id);
        }
        else if (option === "currentPeriodCopyProductsOnly" || option === "nextPeriodCopyProductsOnly") {
            setShowCopyProductsOnlyModal(true);
            setModalType(option);
            setEaId(props.dataItem.ea_id);
        }
        else if (option === "currentPeriodCopyProductsAndHosts" || option === "nextPeriodCopyProductsAndHosts") {
            setShowCopyProductsAndHostsModal(true);
            setModalType(option);
            setEaId(props.dataItem.ea_id);
        }
        else if (option === "workOnCurrentPeriod" || option === "getLicenses") {
            let params = '?eaid=' + props.dataItem.ea_id + '&show=PRODUCTSELECTIONCURRENTPERIOD'
            history.push('/ea-request-license'+params)
        }
        else if (option === "workOnNextPeriod") {
            let params = '?eaid=' + props.dataItem.ea_id + '&show=PRODUCTSELECTIONNEXTPERIOD'
            history.push('/ea-request-license'+params)
        }
        else if (option === "requestAgain") {
            let params = '?eaid=' + props.dataItem.ea_id
            history.push('/ea-request-again'+params)
        }
        else {
            let params = '?eaid=' + props.dataItem.ea_id
            history.push('/ea-request-license'+params)
        }
    }

    return (
        <td
            className="ksm-hamburger"
            style={opened ? openedStyle : closedStyle}
        >
            <DropDownButton
                iconClass={'k-icon k-i-menu'}
                fillMode={"flat"}
                rounded={null}
                opened={opened}
                popupSettings={{
                    animate: false,
                    popupClass: "ksm-hamburger-dropdown",
                    anchorAlign: anchorAlign,
                    popupAlign: popupAlign
                }}
                onItemClick={(event) => {onItemClick(event)}}
                onOpen={() => {
                    setOpened(!opened)
                    onClickMenuButton()
                }}
                onBlur={() => {setOpened(false)}}
            >
                <DropDownButtonItem id={'eaLicenseView'} text={localization.toLanguageString(eaLicenseViewKey, mainMessages[siteLanguageDefault][eaLicenseViewKey])} />
                {menuOptionFlags.getLicenses && !expiredFlag && (
                    <DropDownButtonItem
                        id={'getLicenses'}
                        text={localization.toLanguageString(getLicensesKey, mainMessages[siteLanguageDefault][getLicensesKey])}
                    />
                )}
                {menuOptionFlags.workOnCurrentPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        id={'workOnCurrentPeriod'}
                        text={localization.toLanguageString(workOnCurrentPeriodKey, mainMessages[siteLanguageDefault][workOnCurrentPeriodKey])}
                    />
                )}
                {menuOptionFlags.remixCurrentPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        render={itemRenderDisabled}
                        disabled={true}
                        text={localization.toLanguageString(remixCurrentPeriodKey, mainMessages[siteLanguageDefault][remixCurrentPeriodKey])}
                    />
                )}
                {menuOptionFlags.remixCurrentPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        id={'currentPeriodCopyProductsAndHosts'}
                        render={itemRenderIndent}
                        text={localization.toLanguageString(copyProductsAndHostsKey, mainMessages[siteLanguageDefault][copyProductsAndHostsKey])}
                    />
                )}
                {menuOptionFlags.remixCurrentPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        id={'currentPeriodCopyProductsOnly'}
                        render={itemRenderIndent}
                        text={localization.toLanguageString(copyProductsOnlyKey, mainMessages[siteLanguageDefault][copyProductsOnlyKey])}
                    />
                )}
                {menuOptionFlags.remixCurrentPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        id={'currentPeriodStartFromScratch'}
                        render={itemRenderIndent}
                        text={localization.toLanguageString(startFromScratchKey, mainMessages[siteLanguageDefault][startFromScratchKey])}
                    />
                )}
                {menuOptionFlags.workOnNextPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        id={'workOnNextPeriod'}
                        text={localization.toLanguageString(workOnNextPeriodKey, mainMessages[siteLanguageDefault][workOnNextPeriodKey])}
                    />
                )}
                {menuOptionFlags.remixNextPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        disabled={true}
                        text={localization.toLanguageString(remixNextPeriodKey, mainMessages[siteLanguageDefault][remixNextPeriodKey])}
                    />
                )}
                {menuOptionFlags.remixNextPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        id={'nextPeriodCopyProductsAndHosts'}
                        render={itemRenderIndent}
                        text={localization.toLanguageString(copyProductsAndHostsKey, mainMessages[siteLanguageDefault][copyProductsAndHostsKey])}
                    />
                )}
                {menuOptionFlags.remixNextPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        id={'nextPeriodCopyProductsOnly'}
                        render={itemRenderIndent}
                        text={localization.toLanguageString(copyProductsOnlyKey, mainMessages[siteLanguageDefault][copyProductsOnlyKey])}
                    />
                )}
                {menuOptionFlags.remixNextPeriod && !expiredFlag && (
                    <DropDownButtonItem
                        id={'nextPeriodStartFromScratch'}
                        render={itemRenderIndent}
                        text={localization.toLanguageString(startFromScratchKey, mainMessages[siteLanguageDefault][startFromScratchKey])}
                    />
                )}
                {menuOptionFlags.requestAgain && !expiredFlag && (
                    <DropDownButtonItem
                        id={'requestAgain'}
                        text={<Tooltip anchorElement="target" parentTitle={true} position="right">
                            <span title={localization.toLanguageString(requestAgainTooltipKey, mainMessages[siteLanguageDefault][requestAgainTooltipKey])}>
                                {localization.toLanguageString(requestAgainKey, mainMessages[siteLanguageDefault][requestAgainKey])}
                            </span>
                        </Tooltip>}
                    />
                )}
            </DropDownButton>
            {showStartFromScratchModal && (
                <StartFromScratchModal
                    localization={localization}
                    siteLanguageDefault={siteLanguageDefault}
                    setShowStartFromScratchModal={setShowStartFromScratchModal}
                    modalType={modalType}
                    eaId={eaId}
                />
            )}
            {showCopyProductsOnlyModal && (
                <CopyProductsOnlyModal
                    accessToken={accessToken}
                    localization={localization}
                    siteLanguageDefault={siteLanguageDefault}
                    setShowCopyProductsOnlyModal={setShowCopyProductsOnlyModal}
                    setAlertJSON={setAlertJSON}
                    setShowAlertModal={setShowAlertModal}
                    modalType={modalType}
                    eaId={eaId}
                    setIsLoading={setIsLoading}
                />
            )}
            {showCopyProductsAndHostsModal && (
                <CopyProductsAndHostsModal
                    accessToken={accessToken}
                    localization={localization}
                    siteLanguageDefault={siteLanguageDefault}
                    setShowCopyProductsAndHostsModal={setShowCopyProductsAndHostsModal}
                    setAlertJSON={setAlertJSON}
                    setShowAlertModal={setShowAlertModal}
                    modalType={modalType}
                    eaId={eaId}
                    setIsLoading={setIsLoading}
                />
            )}
        </td>
    )
}