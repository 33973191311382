import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// multilingual
import {AnchorWrap, Text} from '../components/common/MultilingualText.js';
import {
    pageNotFoundClickHereKey,
    pageNotFoundGoToKey,
    pageNotFoundKey,
    mainMessages,
    pageNotFoundPleaseKey,
} from '../assets/text/MultilingualText.js';


function PageNotFound(props) {
    const {siteLanguageDefault} = useContext(UserContext);

    const history = useHistory();

    const AnchorText = AnchorWrap(Text);

    return (
        <>
            <div
                className={"k-page-container"}
                style={{
                    marginTop: "10vh"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "9.375rem",
                        fontWeight: "900",
                        color: 'var(--keysight-gray-6)'
                    }}
                >
                    404
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "4rem",
                        fontWeight: "900",
                        color: 'var(--keysight-gray-6)'
                    }}
                >
                    <Text
                        textkey={pageNotFoundKey}
                        textdefault={mainMessages[siteLanguageDefault][pageNotFoundKey]}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem"
                    }}
                >
                    <div>
                        <Text
                            textkey={pageNotFoundPleaseKey}
                            textdefault={mainMessages[siteLanguageDefault][pageNotFoundPleaseKey]}
                        />
                        <b>
                            <AnchorText
                                style={{
                                    color: 'var(--keysight-alternate)',
                                    textUnderlineOffset: '0.188rem',
                                    textDecoration: 'underline'
                                }}
                                onClick={() => {
                                    history.push('/');
                                }}
                                textkey={pageNotFoundClickHereKey}
                                textdefault={mainMessages[siteLanguageDefault][pageNotFoundClickHereKey]}
                            />
                        </b>
                        <Text
                            textkey={pageNotFoundGoToKey}
                            textdefault={mainMessages[siteLanguageDefault][pageNotFoundGoToKey]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageNotFound;