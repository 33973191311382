import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from "reactstrap";

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import NavigationImg from '../assets/img/Help/ksm-renew-standalone-navigation.png';
import RenewalsImg from '../assets/img/Help/ksm-renew-standalone-renewals.png';
import SelectLicensesImg from '../assets/img/Help/ksm-renew-standalone-select-licenses.png';
import DownloadButtonImg from '../assets/img/Help/ksm-renew-standalone-download-button.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    licenseRenewalKey,
    helpRenewStandaloneLicenseRenewalQAKey,
    helpRenewStandaloneErrorHandlingSectionKey,
    errorHandlingKey,
    frequentlyAskedQuestionsKey,
    mainMessages,
    helpRenewStandaloneNavigationSectionKey,
    navigationKey,
    helpRenewStandaloneQuickStepsSectionKey,
    quickStepsRenewSubscriptionsKey,
    helpRenewStandaloneRenewSupportSectionKey,
    renewSoftwareSubscriptionsKey
} from '../assets/text/MultilingualText.js';


function RenewStandaloneHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;

        let img;
        let imgInlineStyle = item.img === "ksm-renew-standalone-download-button" ? {display: 'flex'} : {};


        if (item.img) {
            switch (item.img) {
                case 'ksm-renew-standalone-renewals':
                    img = <img src={RenewalsImg} alt={item.img}/>
                    break;
                case 'ksm-renew-standalone-select-licenses':
                    img = <img src={SelectLicensesImg} alt={item.img}/>
                    break;
                case 'ksm-renew-standalone-download-button':
                    img = <img src={DownloadButtonImg} alt={item.img}/>
                    break;
                default:
                    img = <></>
                    break;
            }
        }

        if (item.substeps && item.substeps.length) {
            children = type === "bullet" ? <ul style={{listStyleType: 'disc'}}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ul> : <ol type={type}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        if (item.img === 'ksm-renew-standalone-download-button') {
            return (
                <li>
                    <div style={imgInlineStyle}>
                        {toHtml(item.step_prefix)}
                        {item.img ? <span className="img-container">
                            {img}
                        </span> : <></>}
                        {toHtml(item.step_suffix)}
                    </div>
                    {children}
                </li>
            )
        }

        return (
            <li>
                <div style={imgInlineStyle}>
                    {toHtml(item.step)}
                    {item.img ? <span className="img-container">
                        {img}
                    </span> : <></>}
                </div>
                {children}
            </li>
        );
    }

    const helpMenuContent = {
        'header': {
            key: renewSoftwareSubscriptionsKey,
            default: mainMessages[siteLanguageDefault][renewSoftwareSubscriptionsKey]
        },
        'topics': [
            {
                key: quickStepsRenewSubscriptionsKey,
                default: mainMessages[siteLanguageDefault][quickStepsRenewSubscriptionsKey],
                hash: "#quick-steps"
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: "#navigation"
            },
            {
                key: renewSoftwareSubscriptionsKey,
                default: mainMessages[siteLanguageDefault][renewSoftwareSubscriptionsKey],
                hash: "#renew-standalone"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handling"
            },
        ],
        'faq': [
            {
                key: licenseRenewalKey,
                default: mainMessages[siteLanguageDefault][licenseRenewalKey],
                hash: "#category-faq"
            }
        ]
    }

    const quickStepsContent = localization.toLanguageString(
        helpRenewStandaloneQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpRenewStandaloneQuickStepsSectionKey]
    );

    const navigationContent = localization.toLanguageString(
        helpRenewStandaloneNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpRenewStandaloneNavigationSectionKey]
    );

    const renewStandaloneContent = localization.toLanguageString(
        helpRenewStandaloneRenewSupportSectionKey,
        mainMessages[siteLanguageDefault][helpRenewStandaloneRenewSupportSectionKey]
    );

    const errorHandlingContent = localization.toLanguageString(
        helpRenewStandaloneErrorHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpRenewStandaloneErrorHandlingSectionKey]
    );

    const categoryQA = localization.toLanguageString(
        helpRenewStandaloneLicenseRenewalQAKey,
        mainMessages[siteLanguageDefault][helpRenewStandaloneLicenseRenewalQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsRenewSubscriptionsKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsRenewSubscriptionsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {quickStepsContent.section_one}
                            </p>
                            <ol>
                                {quickStepsContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {navigationContent.section_one}
                            </p>
                            <ul>
                                {navigationContent.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`navigation-${idx}`}>
                                        {toHtml(bullet)}
                                        {
                                            idx === 1 &&
                                            <img src={NavigationImg} alt={'renew-standalone-navigation'}/>
                                        }
                                    </li>;
                                })}
                            </ul>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="renew-standalone"
                        >
                            <Text
                                textkey={renewSoftwareSubscriptionsKey}
                                textdefault={mainMessages[siteLanguageDefault][renewSoftwareSubscriptionsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(renewStandaloneContent.section_one)}
                            <ol>
                                {renewStandaloneContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {errorHandlingContent.section_one}
                            </p>
                            <ul>
                                {errorHandlingContent.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`error-handling-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>;
                                })}
                            </ul>
                        </div>
                        <div className={"k-h2 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="category-faq">
                            <h3>
                                <Text
                                    textkey={licenseRenewalKey}
                                    textdefault={mainMessages[siteLanguageDefault][licenseRenewalKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {categoryQA ? categoryQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"category-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default RenewStandaloneHelp;

