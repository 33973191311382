import React, {useContext} from 'react';
import UserContext from '../common/UserContext.js';

// kendo react
import {Popover} from "@progress/kendo-react-tooltip";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    redeemTransportableLicensesKey,
    transportableLicensesSavedKey, cloudLicenseMessageKey, cloudLicenseKey,
    mainMessages,
    keysightGenerateLicensesKey,
    specialHandlingKey,
} from '../../assets/text/MultilingualText';


export const HostDetailPopover = (props) => {
    const {
        type,
        showPopover,
        popoverRef
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let popoverClassName;
    let iconClassName;
    let iconColor;
    let title;
    let message;
    let popoverStyle = {
        maxWidth: '35rem'
    };

    switch (type) {
        case 'banked':
            popoverClassName = 'ksm-popover-alert-info';
            iconClassName = 'k-i-information';
            iconColor = 'var(--keysight-purple)';
            title = localization.toLanguageString(
                transportableLicensesSavedKey,
                mainMessages[siteLanguageDefault][transportableLicensesSavedKey]
            );
            message = localization.toLanguageString(
                redeemTransportableLicensesKey,
                mainMessages[siteLanguageDefault][redeemTransportableLicensesKey]
            );
            break;
        case 'cloud':
            popoverClassName = 'ksm-popover-alert-info';
            iconClassName = 'k-i-information';
            iconColor = 'var(--keysight-purple)';
            title = localization.toLanguageString(
                cloudLicenseKey,
                mainMessages[siteLanguageDefault][cloudLicenseKey]
            );
            message = localization.toLanguageString(
                cloudLicenseMessageKey,
                mainMessages[siteLanguageDefault][cloudLicenseMessageKey]
            );
            break;
        default:
            popoverClassName = 'ksm-popover-alert-info';
            iconClassName = 'k-i-information';
            iconColor = 'var(--keysight-purple)';
            title = localization.toLanguageString(
                specialHandlingKey,
                mainMessages[siteLanguageDefault][specialHandlingKey]
            );
            message = localization.toLanguageString(
                keysightGenerateLicensesKey,
                mainMessages[siteLanguageDefault][keysightGenerateLicensesKey]
            );
    }

    return (
        <Popover
            show={showPopover}
            anchor={popoverRef && popoverRef.current}
            position={'left'}
            className={'ksm-popover ' + popoverClassName}
            animate={false}
            style={popoverStyle}
        >
            <div
                style={{
                    display: 'flex'
                }}
            >
                <span
                    className={'k-icon ' + iconClassName}
                    style={{
                        color: iconColor,
                        fontSize: '2.5rem',
                        marginRight: '0.938rem',
                    }}
                />
                <div>
                    <b>{title}</b>
                    <br/>
                    {message}
                </div>
            </div>
        </Popover>
    )
}
