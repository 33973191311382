import React, {useContext, useState} from 'react';
import uuid from 'react-uuid';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// components
import Alert from '../common/Alert.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Upload} from '@progress/kendo-react-upload';
import {Input} from '@progress/kendo-react-inputs';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    enterHostIdKey,
    hostNoExistKey,
    findHostKey,
    contactUsHeaderKey,
    genericErrorTitleKey,
    confirmHostEnteredKey,
    deactivationNotSupportedKey,
    binFileMessageKey,
    invalidFileKey,
    mainMessages,
    addCertDeactivationKey,
    licenseNoAssociationKey,
    noLicenseDeactivationMessageKey,
    noLicenseDeactivationKey,
    orKey,
    addARequestFileKey,
    searchDeactivateKey,
    uploadFileDeactivationKey
} from '../../assets/text/MultilingualText.js';


function EnterHost(props) {
    const {
        setIsLoading,
        changeStepActivation,
        setHostID,
        setSelectedProducts,
        setDeactivateProducts,
        setKeepProducts,
        setHostFile,
        setTransactionID
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [searchHostID, setSearchHostID] = useState('');
    const [errorTitle, setErrorTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const allowedExtensions = {
        allowedExtensions: [".bin"]
    };

    const onChangeHostID = (e) => {
        setSearchHostID(e.value)
    };

    //On add function for the uploader
    const onHostFileUpload = (event) => {
        setErrorTitle("");
        setErrorMessage("");
        if (allowedExtensions.allowedExtensions.includes(event.newState[0].extension)) {
            setIsLoading(true);
            setHostFile(event.target.files[0].getRawFile());
        } else {
            setError('INVALID_FILE');
            setHostFile(null);
        }
    }

    //Gets the return status from the file uploader api call
    const onStatusChange = (event) => {
        const response = event.response.response;
        if (event.response.status === 200) {
            successSearch(response);
        } else {
            errorSearch(response);
        }
    };

    //Search Host ID from the input param
    const uploadHostID = () => {
        if (searchHostID.trim() === '') {
            setError('MOD_DEACT_218');
            return null;
        }

        setIsLoading(true);
        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let formData = new FormData();
        formData.append('HostID', searchHostID.trim());

        axios.post(
            config.deactivate_license.SEARCH_DEACTIVATE,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    successSearch(response.data);
                }
            })
            .catch((error) => {
                errorSearch(error.response);
            });
    }

    //Process successful search api calls
    const successSearch = (body) => {
        if (body['product_deactivating'].length === 0) {
            body['product_keeping'].forEach((item, index) => {
                item.unique_id = index;
                item.qty_redeemed = item.qty_keep;
                item.qty_deactivate = null;
                item.qty_keep = null;
            })
            setSelectedProducts(body['product_keeping']);
            setTransactionID(uuid());
            changeStepActivation(1);
        } else {
            setDeactivateProducts(body['product_deactivating']);
            setTransactionID(body['product_deactivating'][0]["transaction_id"]);
            setKeepProducts(body['product_keeping']);
            changeStepActivation(3);
        }
        setHostID(body['host_id']);
        setIsLoading(false);
    }

    //process error api calls
    const errorSearch = (error) => {
        console.log("ERROR: Failed to search host id", error);

        let errorCode = '';
        if (error.hasOwnProperty('data')) {
            if (error.data.hasOwnProperty('error_code')) {
                errorCode = error.data['error_code'].toUpperCase();
                setError(errorCode);
            }
        }
        setHostFile(null);
        setIsLoading(false);
    }

    //Set error message corresponding to error code
    const setError = (errorCode) => {
        let title = '';
        let message = '';
        setErrorTitle(title);
        setErrorMessage(message);

        switch (errorCode) {
            case "MOD_DEACT_218": //host does not exist
                title = localization.toLanguageString(
                    hostNoExistKey,
                    mainMessages[siteLanguageDefault][hostNoExistKey]
                );
                message = localization.toLanguageString(
                    confirmHostEnteredKey,
                    mainMessages[siteLanguageDefault][confirmHostEnteredKey]
                );
                break;
            case "MOD_DEACT_219": //host is available but licenses are not associated
                title = localization.toLanguageString(
                    licenseNoAssociationKey,
                    mainMessages[siteLanguageDefault][licenseNoAssociationKey]
                );
                message = localization.toLanguageString(
                    addCertDeactivationKey,
                    mainMessages[siteLanguageDefault][addCertDeactivationKey]
                );
                break;
            case "MOD_DEACT_220": //No Licenses available
                title = localization.toLanguageString(
                    noLicenseDeactivationKey,
                    mainMessages[siteLanguageDefault][noLicenseDeactivationKey]
                );
                message = localization.toLanguageString(
                    noLicenseDeactivationMessageKey,
                    mainMessages[siteLanguageDefault][noLicenseDeactivationMessageKey]
                );
                break;
            case "MOD_DEACT_228": //Host not support deactivation
                title = localization.toLanguageString(
                    deactivationNotSupportedKey,
                    mainMessages[siteLanguageDefault][deactivationNotSupportedKey]
                );
                message = localization.toLanguageString(
                    confirmHostEnteredKey,
                    mainMessages[siteLanguageDefault][confirmHostEnteredKey]
                );
                break;
            case "INVALID_FILE": //Non bin file UI validation
            case "MOD_209": //bin file response from backend validation
                title = localization.toLanguageString(
                    invalidFileKey,
                    mainMessages[siteLanguageDefault][invalidFileKey]
                );
                message = localization.toLanguageString(
                    binFileMessageKey,
                    mainMessages[siteLanguageDefault][binFileMessageKey]
                );
                break;
            default:
                title = localization.toLanguageString(
                    genericErrorTitleKey,
                    mainMessages[siteLanguageDefault][genericErrorTitleKey]
                );
                message = localization.toLanguageString(
                    contactUsHeaderKey,
                    mainMessages[siteLanguageDefault][contactUsHeaderKey]
                );
                break;
        }
        setErrorTitle(title);
        setErrorMessage(message);
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '0.938rem',
                    marginBottom: '0.938rem'
                }}
            >
                <div
                    style={{
                        flexGrow: '1',
                    }}
                >
                    <div className={'k-h4'}>
                        {localization.toLanguageString(uploadFileDeactivationKey, mainMessages[siteLanguageDefault][uploadFileDeactivationKey])}
                    </div>
                    <Upload
                        className={'ksm-upload'}
                        multiple={false}
                        restrictions={allowedExtensions}
                        defaultFiles={[]}
                        saveField={'File'}
                        saveMethod={'POST'}
                        saveHeaders={{
                            'Authorization': 'Bearer ' + accessToken,
                        }}
                        onRemove={() => {
                            setErrorTitle('');
                            setErrorMessage('');
                        }}
                        responseType={'json'}
                        withCredentials={false}
                        onAdd={onHostFileUpload}
                        saveUrl={config.deactivate_license.SEARCH_DEACTIVATE}
                        onStatusChange={onStatusChange}
                        selectMessageUI={() =>
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                shape={"rectangle"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                            >
                                {localization.toLanguageString(addARequestFileKey, mainMessages[siteLanguageDefault][addARequestFileKey])}
                            </Button>
                        }
                    />
                </div>
                <div
                    style={{
                        fontWeight: '700',
                        fontSize: '1.125rem',
                        lineHeight: '1.375rem',
                        textAlign: 'center',
                        color: 'var(--keysight-alternate)',
                        width: '4.375rem',
                        marginTop: '3.75rem'
                    }}
                >
                    {localization.toLanguageString(orKey, mainMessages[siteLanguageDefault][orKey])}
                </div>
                <div>
                    <div className={'k-h4'}>
                        {localization.toLanguageString(searchDeactivateKey, mainMessages[siteLanguageDefault][searchDeactivateKey])}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            gap: '0.938rem',
                            marginTop: '1.25rem'
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    fontWeight: '400',
                                    fontSize: '1.125rem',
                                    lineHeight: '1.25rem'
                                }}
                            >
                                {localization.toLanguageString(enterHostIdKey, mainMessages[siteLanguageDefault][enterHostIdKey])}
                            </div>
                            <Input
                                value={searchHostID}
                                onChange={onChangeHostID}
                                style={{
                                    width: '18.75rem',
                                    height: '1.875rem'
                                }}
                            />
                        </div>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"solid"}
                            rounded={"small"}
                            type={"button"}
                            style={{
                                width: '9.375rem'
                            }}
                            onClick={uploadHostID}
                        >
                            {localization.toLanguageString(findHostKey, mainMessages[siteLanguageDefault][findHostKey])}
                        </Button>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {errorTitle !== '' && errorMessage !== '' && (
                    <div
                        style={{
                            maxWidth: '40.813rem'
                        }}
                    >
                        <Alert
                            type={'error'}
                            title={errorTitle}
                            message={errorMessage}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default EnterHost;