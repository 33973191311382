import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// components
import {PanelBarHeader} from './SelectLicense/PanelBarHeader.js';
import {RequestUpdateLicensesGrid} from './SelectLicense/Grid.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Popover} from '@progress/kendo-react-tooltip';
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    backToSoftwareUpdatesKey,
    mainMessages,
    myAssignedProductsKey,
    requestLicenseKey,
    firstRedeemThenUpdateKey,
    updateCannotBeRedeemedKey
} from '../../assets/text/MultilingualText.js';


// context to store popover reference, when to show, and which popover to show
export const PopoverContext = createContext({
    onMouseOver: () => null,
});

function SelectLicense(props) {
    const {
        changeStepActivation,
        hosts,
        setHosts
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let history = useHistory();

    const [showPopover, setShowPopover] = useState(false);
    const [popoverRef, setPopoverRef] = useState(null);
    const [requiredRedemption, setRequiredRedemption] = useState('');
    const [disableRequestLicense, setDisableRequestLicense] = useState(true);

    // sets which popover to show and it's corresponding reference
    const handlePopoverMouseOver = useCallback((event) => {
        if (event.required !== '') {
            setShowPopover(event.show);
            setPopoverRef(event.popoverRef);
            setRequiredRedemption(event.required);
        } else {
            setShowPopover(false);
        }
    }, [setShowPopover, setPopoverRef]);

    // disabled the request license button if no host is checked
    useEffect(() => {
        let selected = false;
        hosts.forEach(host => {
            if (host.selected) {
                selected = true;
            }
        })
        setDisableRequestLicense(!selected);
    }, [hosts])

    return (
        <>
            <div
                className={"k-h4"}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(myAssignedProductsKey, mainMessages[siteLanguageDefault][myAssignedProductsKey])}
            </div>
            <PopoverContext.Provider
                value={{
                    onMouseOver: handlePopoverMouseOver,
                }}
            >
                {hosts.length !== 0 && (hosts.map((host, index) => {
                    return (
                        <PanelBar
                            key={host.transaction_id}
                            expanded={['.0']}
                            isControlled={true}
                            className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                        >
                            <PanelBarItem
                                title={<PanelBarHeader
                                    hosts={hosts}
                                    setHosts={setHosts}
                                    host={host}
                                    hostIndex={index}
                                />}
                            >
                                <RequestUpdateLicensesGrid products={host.products}/>
                            </PanelBarItem>
                        </PanelBar>
                    )
                }))}
            </PopoverContext.Provider>
            <Popover
                show={showPopover}
                anchor={popoverRef && popoverRef.current}
                position={'left'}
                className={'ksm-popover ksm-popover-alert-info'}
                animate={false}
                style={{
                    maxWidth: '35rem'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <span
                        className={'k-icon k-i-information'}
                        style={{
                            color: 'var(--keysight-purple)',
                            fontSize: '2.5rem',
                            marginRight: '0.938rem',
                        }}
                    />
                    <div>
                        <b>{localization.toLanguageString(updateCannotBeRedeemedKey, mainMessages[siteLanguageDefault][updateCannotBeRedeemedKey])}</b>
                        <br/>
                        {localization.toLanguageString(firstRedeemThenUpdateKey, mainMessages[siteLanguageDefault][firstRedeemThenUpdateKey])}
                        <br/>
                        {requiredRedemption}
                    </div>
                </div>
            </Popover>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '0.938rem'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '0.938rem'
                    }}
                >
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        shape={"rectangle"}
                        fillMode={"outline"}
                        rounded={"small"}
                        type={"button"}
                        onClick={() => {
                            let currentParams = (new URL(document.location)).searchParams;
                            const params = `uuid=${currentParams.get('uuid')}`;
                            history.push('/software-updates-detail?' + params);
                        }}
                        style={{
                            width: "16rem"
                        }}
                    >
                        {localization.toLanguageString(backToSoftwareUpdatesKey, mainMessages[siteLanguageDefault][backToSoftwareUpdatesKey])}
                    </Button>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        shape={"rectangle"}
                        fillMode={"solid"}
                        rounded={"small"}
                        type={"button"}
                        disabled={disableRequestLicense}
                        onClick={() => {
                            changeStepActivation(1);
                        }}
                        style={{
                            width: "10.625rem"
                        }}
                    >
                        {localization.toLanguageString(requestLicenseKey, mainMessages[siteLanguageDefault][requestLicenseKey])}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default SelectLicense;