import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../common/UserContext";

//Multilingual
import {assignedKey, mainMessages, unassignedKey} from "../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";

export const StatusCell = (props) => {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();
    const field = props.field;

    const [unAssigned, setUnassigned] = useState(0)
    const [assigned, setAssigned] = useState(0)

    useEffect(() => {
        let unassigned = parseInt(props.dataItem['quantity'])
        let newAssigned = 0
        for (const allocId in props.dataItem[field]) {
            newAssigned += props.dataItem[field][allocId].assign_quantity
        }
        unassigned -= newAssigned
        setAssigned(newAssigned)
        setUnassigned(unassigned)
    }, [props.dataItem, field])

    return (
        <td>
            {unAssigned > 0 && (
                <>
                    {unAssigned} {localization.toLanguageString(unassignedKey, mainMessages[siteLanguageDefault][unassignedKey])}
                </>
            )}
            {unAssigned > 0 && assigned > 0 && <br/>}
            {assigned > 0 && (
                <>
                    {assigned} {localization.toLanguageString(assignedKey, mainMessages[siteLanguageDefault][assignedKey])}
                </>
            )}
        </td>
    );
};