import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../../common/UserContext';

// react strap
import {Col, Row} from 'reactstrap';

// kendo react
import {Tooltip} from '@progress/kendo-react-tooltip';
import {Checkbox} from "@progress/kendo-react-inputs";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    requestLicensesHostKey,
    mainMessages,
    doNotRequestLicenseKey,
} from '../../../assets/text/MultilingualText';


export const RequestPanelBarHeader = (props) => {
    const {
        assignedProducts,
        setAssignedProducts,
        index,
        iconClicked,
    } = props;
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let host = assignedProducts[index]

    const [tooltipMessage, setTooltipMessage] = useState('')
    useEffect(()=>{
        if(host.icons.checked){
            setTooltipMessage(localization.toLanguageString(requestLicensesHostKey, mainMessages[siteLanguageDefault][requestLicensesHostKey]))
        } else {
            setTooltipMessage(localization.toLanguageString(doNotRequestLicenseKey, mainMessages[siteLanguageDefault][doNotRequestLicenseKey]))
        }
    }, [host.icons.checked]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        className={"k-h4"}
                        style={{
                            color: 'white',
                            margin: '0'
                        }}
                    >
                        {host.panel_bar_title}
                    </div>
                </Col>
                <Col>
                </Col>
                <Col>
                    <div
                        style={{
                            float: 'right'
                        }}
                    >
                        <Tooltip
                            anchorElement="pointer"
                            showCallout={false}
                            parentTitle={true}
                            openDelay={0}
                        >
                            <span
                                title={tooltipMessage}
                            >
                            <Checkbox
                                className={'ksm-panelbar-checkbox'}
                                value={host.icons.checked}
                                onClick={(e) => {
                                    iconClicked.current = true
                                    assignedProducts[index].icons.checked = e.target.checked
                                    setAssignedProducts(JSON.parse(JSON.stringify(assignedProducts)))
                                }}
                            />
                                </span>
                        </Tooltip>
                    </div>
                </Col>
            </Row>
        </>
    )
}