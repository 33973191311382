import React, {useContext, useEffect, useRef, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// kendo react
import {Loader} from '@progress/kendo-react-indicators';
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    notGeneratedAvailableRedeemV2Key,
    genericErrorTitleKey,
    mainMessages,
    notGeneratedSupportAgentV2Key
} from '../../../assets/text/MultilingualText.js';
import {Col, Row} from "reactstrap";
import {downloadLicenses} from "../../common/utilities";


// download cell for standalone renewal grid
export const DownloadPanelBarHeader = (props) => {
    const {
        panelBarTitle,
        index,
        renewalLicenses,
        setIsLoading,
        handleBeforeUnload
    } = props;
    const {
        accessToken
    } = useContext(UserContext);

    const anchor = useRef(null);
    const [showPopover, setShowPopover] = useState(false);
    const [downloadIcon, setDownloadIcon] = useState("loading");
    const [transactionID, setTransactionID] = useState("");

    //Show popover if there is an error on mouse over
    const onMouseOver = (ev) => {
        setShowPopover(true);
    };

    //Remove popover on mouse leave
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) setShowPopover(false);
    };

    // update panel bar download icon and transaction id
    useEffect(() => {
        setDownloadIcon(renewalLicenses[index]['download_icon']);
        setTransactionID([renewalLicenses[index]['transaction_id']]);
    }, [renewalLicenses]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div
                className={"k-h4"}
                style={{
                    color: 'white',
                    margin: '0 0 0 2px',
                }}
            >
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Col
                        style={{
                            flex: '0 0 auto',
                            width: 'auto'
                        }}
                    >
                        <div
                            className={"k-h4"}
                            style={{
                                color: 'white',
                                margin: '0'
                            }}
                        >
                            {panelBarTitle}
                        </div>
                    </Col>
                    <Col/>
                    <Col>
                        <div
                            style={{
                                float: 'right'
                            }}
                        >
                            <div>
                                {downloadIcon === "none" && <></>}
                                {downloadIcon === "loading" && <Loader
                                    type={'pulsing'}
                                    style={{
                                        color: '#FFFFFF'
                                    }}
                                />}
                                {downloadIcon === "download" && <span
                                    className="k-icon k-i-download"
                                    style={{
                                        color: 'white',
                                        marginLeft: '0.35rem',
                                        marginRight: '0.35rem',
                                        fontSize: '1.3rem',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        downloadLicenses(transactionID, accessToken, setIsLoading, handleBeforeUnload);
                                    }}
                                />}
                                {(downloadIcon === "error" || downloadIcon === "support-agent-error") && <span
                                    onMouseOver={onMouseOver}
                                    onMouseOut={onMouseOut}
                                >
                                    <DownloadErrorPopover
                                        downloadIcon={downloadIcon}
                                        showPopover={showPopover}
                                        anchor={anchor}
                                    />
                                    <span
                                        className="k-icon k-i-close-outline"
                                        style={{
                                            color: 'var(--keysight-pink)',
                                            marginLeft: '0.35rem',
                                            marginRight: '0.35rem',
                                            fontSize: '1.3rem'
                                        }}
                                        ref={anchor}
                                    />
                                </span>}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const DownloadErrorPopover = (props) => {
    const {
        downloadIcon,
        showPopover,
        anchor
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let title;
    let message;

    if (downloadIcon === "support-agent-error") {
        title = localization.toLanguageString(
            genericErrorTitleKey,
            mainMessages[siteLanguageDefault][genericErrorTitleKey]
        );
        message = localization.toLanguageString(
            notGeneratedSupportAgentV2Key,
            mainMessages[siteLanguageDefault][notGeneratedSupportAgentV2Key]
        );
    } else {
        title = localization.toLanguageString(
            genericErrorTitleKey,
            mainMessages[siteLanguageDefault][genericErrorTitleKey]
        );
        message = localization.toLanguageString(
            notGeneratedAvailableRedeemV2Key,
            mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemV2Key]
        );
    }

    return (
        <Popover
            show={showPopover}
            anchor={anchor.current}
            position={'left'}
            className={'ksm-popover ksm-popover-error'}
            animate={false}
        >
            <div
                style={{
                    display: 'flex',
                    width: 'max-content'
                }}
            >
                <span
                    className={'k-icon k-i-close-outline'}
                    style={{
                        color: 'var(--keysight-primary)',
                        fontSize: '2.5rem',
                        marginRight: '0.938rem',
                    }}
                />
                <div>
                    <b>{title}</b>
                    <br/>
                    {message}
                </div>
            </div>
        </Popover>
    )
}