import React, {useContext, useEffect, useState, useRef} from 'react';
import axios from "axios";
import config from "../../config";

// kendo
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";

// components
import UserContext from '../../components/common/UserContext.js';
import Alert from "../common/Alert";
import {PanelbarHeader} from "./SelectLicenses/PanelbarHeader";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    selectLicensesParenthesisKey,
    selectHostCheckInKey,
    genericErrorTitleKey,
    contactUsHeaderKey,
    continueKey, backKey
} from "../../assets/text/MultilingualText";
import Moment from "moment";
import {LicenseGrid} from "./SelectLicenses/LicenseGrid";
import {Button} from "@progress/kendo-react-buttons";


function SelectLicenses(props) {
    const {
        setIsLoading,
        changeStepActivation,
        stepperItems,
        setStepperItems,
        myHosts,
        hostLicenses,
        setHostLicenses,
    } = props;
    const {
        siteLanguageDefault,
        accessToken,
        timeout
    } = useContext(UserContext);

    const localization = useLocalization();

    const [genericError, setGenericError] = useState(false);
    const [checkInReady, setCheckInReady] = useState(false);

    const getLicenses = () => {
        setIsLoading(true);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        // construct array of selected hostIds and transactionIds
        let selectedHostIds = []
        let selectedTransIds = []
        myHosts.filter((host) => host.selected).forEach((host) => {
            selectedHostIds.push(host.host_id)
            selectedTransIds.push(host.transaction_id)
        })
        selectedHostIds = [...new Set(selectedHostIds)]
        selectedTransIds = [...new Set(selectedTransIds)]
        let payload = {
            "module": "SOFTWARE/ACCESS",
            "sub_module": "CHECKIN-SEARCH",
            "request_from": "",
            "action": "SEARCH",
            "input_json": {
                "host_ids": selectedHostIds,
                "transaction_ids": selectedTransIds
            }
        }
        axios.post(
            config.software_access.SOFTWARE_ACCESS,
            payload,
            {
                headers: headers, timeout: timeout
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    let licenses = response.data;
                    licenses.forEach((license) => {
                        if (license.expiration_date !== null) {
                            license.expiration_date = new Date(Moment(license.expiration_date).format('YYYY-MM-DD 00:00:00'))
                        }
                        if (license.checkout_date !== null) {
                            license.checkout_date = new Date(Moment(license.checkout_date).format('YYYY-MM-DD 00:00:00'))
                        }
                    })
                    let hostIdData = []

                    selectedHostIds.forEach((hostId) => {
                        // find licenses belonging to that hostid
                        let associatedLicenses = licenses.filter((license) => license.host_id === hostId)
                        // create unique id for each license list
                        associatedLicenses.forEach((license, index) => license.unique_id = index)

                        // push hostid and its licenses to final json for panelbars
                        hostIdData.push({
                            host_id: hostId,
                            asset: myHosts.filter((host) => host.host_id === hostId)[0].asset,
                            licenses: associatedLicenses
                            })
                    })
                    setHostLicenses(hostIdData);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Host Type Details", error);
                setIsLoading(false);
                setGenericError(true);
            });
    };

    useEffect(() => {
        setHostLicenses([])
        getLicenses()
    }, [])

    useEffect(() => {
        // enable step 3
        if (checkInReady) {
            let newStepperItems = stepperItems
            stepperItems[2].disabled = false
            setStepperItems(newStepperItems)
        }
    }, [checkInReady])

    return (
        <>
            <div>
                <div className={"k-h4"}>
                    {localization.toLanguageString(selectLicensesParenthesisKey, mainMessages[siteLanguageDefault][selectLicensesParenthesisKey])}
                </div>
                <div className={"k-header-desc k-mb-4"}>
                    {localization.toLanguageString(selectHostCheckInKey, mainMessages[siteLanguageDefault][selectHostCheckInKey])}
                </div>
                <PanelBar
                    className={'ksm-panelbar-default'}
                >
                    {hostLicenses.map((host, hostIndex) =>
                        <PanelBarItem
                            title={
                                <PanelbarHeader
                                    hostJSON={host}
                                />
                            }
                            key={host.host_id}
                            expanded={true}
                        >
                            <LicenseGrid
                                host={host}
                                hostIndex={hostIndex}
                                hostLicenses={hostLicenses}
                                setHostLicenses={setHostLicenses}
                                checkInReady={checkInReady}
                                setCheckInReady={setCheckInReady}
                            />
                        </PanelBarItem>
                    )}
                </PanelBar>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '0.938rem',
                    }}
                >
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        rounded={"small"}
                        type={"button"}
                        onClick={() => {
                            changeStepActivation(0)
                        }}
                    >
                        {localization.toLanguageString(backKey, mainMessages[siteLanguageDefault][backKey])}
                    </Button>
                    {genericError ?
                        <div
                            style={{
                                marginTop: '0.938rem',
                                marginBottom: '0.938rem'
                            }}
                        >
                            <Alert
                                type={'error'}
                                title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                                message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                            />
                        </div>
                        :
                        <div></div>
                    }
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        rounded={"small"}
                        type={"button"}
                        disabled={!checkInReady}
                        onClick={() => {
                            changeStepActivation(2)
                        }}
                    >
                        {localization.toLanguageString(continueKey, mainMessages[siteLanguageDefault][continueKey])}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default SelectLicenses;