import {cloneElement} from "react";

// kendo react custom row render
export const RowRender = (tr, props) => {
    if (props.dataItem.disabled) {
        return cloneElement(
            tr,
            {...tr.props, className: 'k-state-disabled', style: {pointerEvents: 'auto'}},
            tr.props.children
        );
    }
    return tr
};