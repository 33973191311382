import React, {useContext, useRef, useState} from 'react';

//components
import UserContext from "../common/UserContext";
import UnassignedProductsGrid from "./AssignProducts/UnassignedProductsGrid";
import AssignedHostDelete from "./AssignProducts/AssignedHostDelete";
import AssignProductsModal from "./AssignProducts/AssignProductsModal";

//multilingual
import {
    TransportAssignDirections,
    myAssignedProductsKey,
    continueKey,
    mainMessages,
    productsHostKey,
    ProductsAvailableToAssignKey
} from "../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";

//kendo
import {Button} from "@progress/kendo-react-buttons";


export default function AssignProducts(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        changeStepActivation,
        setIsLoading,
        unassigned,
        setUnassigned,
        assigned,
        setAssigned,
        hostDetails,
        xAuthToken
    } = props

    const [selectHeaderCheck, setSelectHeaderCheck] = useState(false);
    const [details, setDetails] = useState({})
    const scrollRef = useRef(null);
    let currentType = useRef(null)


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.938rem',
        }}>
            <div>
                <div className={"k-h4"}>
                    {localization.toLanguageString(productsHostKey, mainMessages[siteLanguageDefault][productsHostKey])}
                </div>
                <div style={{
                    color: '#424242',
                    fontSize: '0.875rem',
                    fontStyle: 'italic',
                }}>
                    {localization.toLanguageString(TransportAssignDirections, mainMessages[siteLanguageDefault][TransportAssignDirections])}
                </div>
            </div>

            {assigned.length !== 0 && (
                <>
                    <div
                        className={"k-h4"}
                    ref={scrollRef}
                    >
                        {localization.toLanguageString(myAssignedProductsKey, mainMessages[siteLanguageDefault][myAssignedProductsKey])}
                    </div>

                    <AssignedHostDelete
                        unassigned={unassigned}
                        setUnassigned={setUnassigned}
                        assigned={assigned}
                        setAssigned={setAssigned}
                    />

                    <div style={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"solid"}
                            type={"button"}
                            rounded={"small"}
                            onClick={() => {
                                changeStepActivation(2)
                            }}
                        >
                            {localization.toLanguageString(continueKey, mainMessages[siteLanguageDefault][continueKey])}
                        </Button>
                    </div>
                </>
            )}

            {unassigned.length !== 0 && (
                <>
                    <div className={"k-h4"}>
                        {localization.toLanguageString(ProductsAvailableToAssignKey, mainMessages[siteLanguageDefault][ProductsAvailableToAssignKey])}
                    </div>

                    <UnassignedProductsGrid
                        unassigned={unassigned}
                        setUnassigned={setUnassigned}
                        currentType={currentType}
                        selectHeaderCheck={selectHeaderCheck}
                        setSelectHeaderCheck={setSelectHeaderCheck}
                    />

                    <div style={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"solid"}
                            type={"button"}
                            rounded={"small"}
                            onClick={() => {
                                const hostIDType = unassigned.filter(item => item.selected)[0]['host_id_type']
                                setDetails(hostDetails[hostIDType])
                            }}
                            disabled={unassigned.filter(item => item.selected).length === 0}
                        >
                            {localization.toLanguageString(productsHostKey, mainMessages[siteLanguageDefault][productsHostKey])}
                        </Button>
                    </div>
                </>
            )}

            {Object.keys(details).length !== 0 && (
                <AssignProductsModal
                    setIsLoading={setIsLoading}
                    scrollRef={scrollRef}
                    details={details}
                    setDetails={setDetails}
                    assigned={assigned}
                    setAssigned={setAssigned}
                    unassigned={unassigned}
                    setUnassigned={setUnassigned}
                    xAuthToken={xAuthToken}
                    currentType={currentType}
                    setSelectHeaderCheck={setSelectHeaderCheck}
                />
            )}
        </div>
    )
}
