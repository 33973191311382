import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import uuid from 'react-uuid';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/RenewEesof.css';

// react router
import {useHistory} from 'react-router-dom';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import KSMStepper from '../components/common/Stepper.js';
import PageHeader from '../components/common/PageHeader.js';
import {Text} from '../components/common/MultilingualText.js';
import Spinner from '../components/common/Spinner.js';
import ReassignLicenses from '../components/RenewEesof/ReassignLicenses.js';
import SelectLicenses from '../components/RenewEesof/SelectLicenses.js';
import LicenseDownload from '../components/RenewEesof/LicenseDownload.js';

// kendo react
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    renewLicensesKey,
    reassignLicensesKey,
    selectLicensesRenewKey,
    licenseDownloadKey,
    navigationAlertKey,
} from '../assets/text/MultilingualText.js';

function RenewEesof(props) {
    const {
        accessToken,
        siteLanguage,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [isLoading, setIsLoading] = useState(false);

    const defaultStepperItems = [
        {
            label: localization.toLanguageString(reassignLicensesKey, mainMessages[siteLanguageDefault][reassignLicensesKey]),
            number: 1,
            disabled: false,
        },
        {
            label: localization.toLanguageString(selectLicensesRenewKey, mainMessages[siteLanguageDefault][selectLicensesRenewKey]),
            number: 2,
            disabled: true,
        },
        {
            label: localization.toLanguageString(licenseDownloadKey, mainMessages[siteLanguageDefault][licenseDownloadKey]),
            number: 3,
            disabled: true,
        }
    ]

    const [stepperItems, setStepperItems] = useState(defaultStepperItems);
    const [stepValue, setStepValue] = useState(0);

    //Change step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {

        if (stepValue === 0) {
            if (stepChange === 1) {
                stepperItems[stepChange].disabled = false;
            }
        }

        if (stepValue === 1) {
            if (stepChange === 2) {
                stepperItems[stepChange].disabled = false;
                stepperItems[1].disabled = true;
            }
        }

        if (stepChange === 0) {
            stepperItems[1].disabled = true;
            stepperItems[2].disabled = true;

            if (stepValue === 2) {
                getRenewals();
            }
        }

        setStepValue(stepChange);
        setStepperItems(stepperItems);
    }

    // on language change, refresh stepper labels
    useEffect(() => {
        setStepperItems(defaultStepperItems);
    }, [siteLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    //Confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const disableUnblock = useRef(false)
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {

        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (disableUnblock.current) {
                return true
            }
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey]))) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, [disableUnblock]); // eslint-disable-line react-hooks/exhaustive-deps


    const [savedHosts, setSavedHosts] = useState([])
    const [modifyHosts, setModifyHosts] = useState([])
    const [requestedHosts, setRequestedHosts] = useState([])
    const [assignMore, setAssignMore] = useState(false)
    const [disableUndoChanges, setDisableUndoChanges] = useState(true)

    async function getRenewals() {
        setSavedHosts([])
        setModifyHosts([])
        setRequestedHosts([])
        setAssignMore(false)
        setDisableUndoChanges(true)
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }

        setIsLoading(true);

        axios.get(
            config.renew_eesof.RENEWALS,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || []

                    let unique_id = 0
                    data.forEach((host) => {
                        host.products.forEach((product) => {
                            product['original_host_id'] = host.host_id;
                            product['new_host_id'] = host.host_id;
                            product['notes'] = '';
                            product['selected'] = true;
                            product['unique_id'] = unique_id;
                            unique_id++
                        });

                        host['transaction_id'] = uuid();
                        host['icons'] = {
                            loading: true,
                            download: false,
                            generalError: false,
                            networkError: false
                        };
                    })

                    data = orderBy(data, [{
                        field: "host_id",
                        dir: "asc"
                    }]);

                    setSavedHosts(JSON.parse(JSON.stringify(data)));
                    setModifyHosts(JSON.parse(JSON.stringify(data)));
                }
            })
            .catch((error) => {
                console.log("Error: Failed to GET EESOF Renewals", error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getRenewals();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <Col>
                        <div className={"k-h2"}>
                            <Text
                                textkey={renewLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][renewLicensesKey]}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div
                            style={{
                                width: "120%"
                            }}
                        >
                            {modifyHosts.length > 0 && (
                                <KSMStepper
                                    stepperItems={stepperItems}
                                    stepValue={stepValue}
                                    changeStepActivation={changeStepActivation}
                                />
                            )}
                        </div>
                    </Col>
                    <Col/>
                </Row>
                {stepValue === 0 && <ReassignLicenses
                    setIsLoading={setIsLoading}
                    changeStepActivation={changeStepActivation}
                    savedHosts={savedHosts}
                    modifyHosts={modifyHosts}
                    setModifyHosts={setModifyHosts}
                    disableUndoChanges={disableUndoChanges}
                    setDisableUndoChanges={setDisableUndoChanges}
                />}
                {stepValue === 1 && <SelectLicenses
                    changeStepActivation={changeStepActivation}
                    modifyHosts={modifyHosts}
                    setModifyHosts={setModifyHosts}
                    setRequestedHosts={setRequestedHosts}
                    setAssignMore={setAssignMore}
                />}
                {stepValue === 2 && <LicenseDownload
                    changeStepActivation={changeStepActivation}
                    modifyHosts={modifyHosts}
                    setModifyHosts={setModifyHosts}
                    requestedHosts={requestedHosts}
                    assignMore={assignMore}
                    setRequestedHosts={setRequestedHosts}
                    handleBeforeUnload={handleBeforeUnload}
                    setIsLoading={setIsLoading}
                    stepperItems={stepperItems}
                    setStepperItems={setStepperItems}
                />}
                <br/>
            </div>
        </>
    );
}

export default RenewEesof;

