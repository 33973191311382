import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMRenewNemoStepNumber from '../assets/img/Help/ksm-renew-nemo-step-number.png';
import KSMRenewNemoRequestRenewal from '../assets/img/Help/ksm-renew-nemo-request-renewal.png';
import KSMRenewNemoSpinner from '../assets/img/Help/ksm-renew-nemo-spinner.png';
import KSMRenewNemoDownloadEmailLicense from '../assets/img/Help/ksm-renew-nemo-email-download-license.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    helpRenewNemoCreatingC2VHeaderKey,
    helpRenewNemoCreatingC2VSectionKey,
    creatingC2vFileKey,
    helpRenewNemoErrorHandlingSectionKey,
    errorHandlingKey,
    frequentlyAskedQuestionsKey,
    helpRenewNemoGeneratingLicensesSectionKey,
    generatingDownloadingKey,
    ksmRenewNemoKey,
    mainMessages,
    helpRenewNemoNavigationSectionKey,
    navigationKey,
    helpRenewNemoQuickStepsSectionKey,
    quickStepsRenewNemoKey,
    renewalKey,
    helpRenewNemoRenewalQAKey,
    helpRenewNemoRequestingRenewalsSectionKey,
    requestingRenewalsKey
} from '../assets/text/MultilingualText.js';


function RenewNemoHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const helpMenuContent = {
        'header': {
            key: ksmRenewNemoKey,
            default: mainMessages[siteLanguageDefault][ksmRenewNemoKey]
        },
        'topics': [
            {
                key: quickStepsRenewNemoKey,
                default: mainMessages[siteLanguageDefault][quickStepsRenewNemoKey],
                hash: "#quick-steps"
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: "#navigation"
            },
            {
                key: creatingC2vFileKey,
                default: mainMessages[siteLanguageDefault][creatingC2vFileKey],
                hash: "#create-c2v"
            },
            {
                key: requestingRenewalsKey,
                default: mainMessages[siteLanguageDefault][requestingRenewalsKey],
                hash: "#request-renewal"
            },
            {
                key: generatingDownloadingKey,
                default: mainMessages[siteLanguageDefault][generatingDownloadingKey],
                hash: "#generate-licenses"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handling"
            }
        ],
        'faq': [
            {
                key: renewalKey,
                default: mainMessages[siteLanguageDefault][renewalKey],
                hash: "#renewal-faq"
            }
        ]
    }

    const helpQuickStepsContent = localization.toLanguageString(
        helpRenewNemoQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpRenewNemoQuickStepsSectionKey]
    );

    const helpNavigationContent = localization.toLanguageString(
        helpRenewNemoNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpRenewNemoNavigationSectionKey]
    );

    const helpCreateC2VContent = localization.toLanguageString(
        helpRenewNemoCreatingC2VSectionKey,
        mainMessages[siteLanguageDefault][helpRenewNemoCreatingC2VSectionKey]
    );

    const helpRequestRenewalsContent = localization.toLanguageString(
        helpRenewNemoRequestingRenewalsSectionKey,
        mainMessages[siteLanguageDefault][helpRenewNemoRequestingRenewalsSectionKey]
    );

    const helpGenerateLicenseContent = localization.toLanguageString(
        helpRenewNemoGeneratingLicensesSectionKey,
        mainMessages[siteLanguageDefault][helpRenewNemoGeneratingLicensesSectionKey]
    );

    const helpErrorHandlingContent = localization.toLanguageString(
        helpRenewNemoErrorHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpRenewNemoErrorHandlingSectionKey]
    );

    const helpRenewalQA = localization.toLanguageString(
        helpRenewNemoRenewalQAKey,
        mainMessages[siteLanguageDefault][helpRenewNemoRenewalQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsRenewNemoKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsRenewNemoKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpQuickStepsContent.section_one}
                            </p>
                            {helpQuickStepsContent.section_one_numbers ? <ol>
                                {Object.values(helpQuickStepsContent.section_one_numbers).map((number, index) => {
                                    if (index === 2) {
                                        return <li key={'quick-steps-' + index}>
                                            {toHtml(number)}
                                            {helpQuickStepsContent.section_one_sub_bullets ? <ol type="a">
                                                {helpQuickStepsContent.section_one_sub_bullets.map((subBullet, index) =>
                                                    <li key={'quick-steps-sub-bullet-' + index}>
                                                        {toHtml(subBullet)}
                                                    </li>
                                                )}
                                            </ol> : <></>}
                                        </li>
                                    } else {
                                        return <li key={'quick-steps-' + index}>{toHtml(number)}</li>
                                    }
                                })}
                            </ol> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpNavigationContent.section_one}
                            </p>
                            {helpNavigationContent.section_one_bullets ? <ul>
                                {helpNavigationContent.section_one_bullets.map((number, index) =>
                                    <div key={'navigation-' + index}>
                                        <li key={'navigation-bullet-' + index}>{toHtml(number)}</li>
                                        {index === 1 ?
                                            <span className="img-container" key={'navigation-img-' + index}>
                                                <img src={KSMRenewNemoStepNumber} alt="ksm-renew-nemo-step-number"/>
                                        </span> : <></>}
                                    </div>)}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="create-c2v"
                        >
                            <Text
                                textkey={helpRenewNemoCreatingC2VHeaderKey}
                                textdefault={mainMessages[siteLanguageDefault][helpRenewNemoCreatingC2VHeaderKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpCreateC2VContent.section_one}
                            </p>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="request-renewal"
                        >
                            <Text
                                textkey={requestingRenewalsKey}
                                textdefault={mainMessages[siteLanguageDefault][requestingRenewalsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpRequestRenewalsContent.section_one}
                            </p>
                            {helpRequestRenewalsContent.section_one_numbers ? <ol>
                                {Object.values(helpRequestRenewalsContent.section_one_numbers).map((number, index) => {
                                    return <li key={'request-renewal-' + index}>{toHtml(number)}</li>
                                })}
                            </ol> : <></>}
                            <span className="img-container">
                                    <img src={KSMRenewNemoRequestRenewal} alt="ksm-renew-nemo-request-renewal"/>
                            </span>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="generate-licenses"
                        >
                            <Text
                                textkey={generatingDownloadingKey}
                                textdefault={mainMessages[siteLanguageDefault][generatingDownloadingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpGenerateLicenseContent.section_one}
                            </p>
                            <span className="img-container">
                                    <img src={KSMRenewNemoSpinner} alt="ksm-renew-nemo-spinner"/>
                            </span>
                            {toHtml(helpGenerateLicenseContent.section_two)}
                            <span className="img-container">
                                    <img src={KSMRenewNemoDownloadEmailLicense}
                                         alt="ksm-renew-nemo-download-email-license"/>
                            </span>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpErrorHandlingContent.section_one}
                            </p>
                            {helpErrorHandlingContent.section_one_bullets ? <ul>
                                {helpErrorHandlingContent.section_one_bullets.map((bullet, index) =>
                                    <li key={'error-handling-bullet-' + index}>{toHtml(bullet)}</li>
                                )}
                            </ul> : <></>}
                            {toHtml(helpErrorHandlingContent.section_two)}
                        </div>
                        <div
                            className={"k-h2 header"}
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div
                            id="renewal-faq"
                            style={{
                                marginBottom: '30vh'
                            }}
                        >
                            <h3>
                                <Text
                                    textkey={renewalKey}
                                    textdefault={mainMessages[siteLanguageDefault][renewalKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {helpRenewalQA ? helpRenewalQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"help-renewal-qa-" + index}>
                                            <li>
                                                {questionAnswer.Q}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default RenewNemoHelp;

