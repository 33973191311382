import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// react router
import {useHistory, useLocation} from 'react-router-dom';

// css
import '../assets/css/RequestLicense.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Spinner from '../components/common/Spinner.js';
import KSMStepper from '../components/common/Stepper.js';
import AssignProducts from '../components/RequestLicense/AssignProducts.js';
import LicensePreview from '../components/RequestLicense/LicensePreview.js';
import LicenseDownload from '../components/RequestLicense/LicenseDownload.js';
import PageHeader from '../components/common/PageHeader.js';

// kendo react
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    mainMessages,
    productsHostsKey,
    licenseDownloadKey,
    navigationAlertKey,
    licensePreviewKey,
    loadingPeriodsKey,
    noLicensesRedeemKey,
    requestNewLicensesKey,
} from '../assets/text/MultilingualText.js';


function RequestLicense(props) {
    const {
        accessToken,
        siteLanguage,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [stepperItems, setStepperItems] = useState([
        {
            label: localization.toLanguageString(productsHostsKey, mainMessages[siteLanguageDefault][productsHostsKey]),
            number: 1,
            disabled: false
        },
        {
            label: localization.toLanguageString(licensePreviewKey, mainMessages[siteLanguageDefault][licensePreviewKey]),
            number: 2,
            disabled: true
        },
        {
            label: localization.toLanguageString(licenseDownloadKey, mainMessages[siteLanguageDefault][licenseDownloadKey]),
            number: 3,
            disabled: true
        }
    ]);
    const [stepValue, setStepValue] = useState(0);

    //Changes and step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {
        //Change from Assign Products to Host step
        if (stepValue === 0) {
            if (stepChange === 1) {
                stepperItems[stepChange].disabled = false;
            }
            stepperItems[2].disabled = true;
        }

        //Change from License Preview Step
        if (stepValue === 1) {
            if (stepChange === 2) {
                stepperItems[stepChange].disabled = false;
                stepperItems[1].disabled = true;
            }
        }

        //Change from License Download Step
        if (stepValue === 2) {
            if (stepChange === 0) {
                if (assignedProducts.length === 0) {
                    getLicenses();
                }
                stepperItems[2].disabled = true;
            }
            if (stepChange === 1) {
                stepperItems[2].disabled = true;
            }
        }
        setStepValue(stepChange);
        setStepperItems(stepperItems);
    }

    const [isLoading, setIsLoading] = useState(true);

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [assignedProducts, setAssignedProducts] = useState([]);
    const [requestedProducts, setRequestedProducts] = useState([]);

    // disable license preview when there are no assigned products
    useEffect(() => {
        if (assignedProducts.length === 0) {
            stepperItems[1].disabled = true
            setStepperItems([...stepperItems])
        }
    }, [assignedProducts])

    // on language change, refresh stepper labels
    useEffect(() => {
        setStepperItems([
            {
                label: localization.toLanguageString(productsHostsKey, mainMessages[siteLanguageDefault][productsHostsKey]),
                number: 1,
                disabled: false
            },
            {
                label: localization.toLanguageString(licensePreviewKey, mainMessages[siteLanguageDefault][licensePreviewKey]),
                number: 2,
                disabled: true
            },
            {
                label: localization.toLanguageString(licenseDownloadKey, mainMessages[siteLanguageDefault][licenseDownloadKey]),
                number: 3,
                disabled: true
            }
        ])
    }, [siteLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    const {search} = useLocation();

    //Set the gird no records message
    const defaultGridMessage = localization.toLanguageString(noLicensesRedeemKey, mainMessages[siteLanguageDefault][noLicensesRedeemKey]);
    const defaultLoadingMessage = localization.toLanguageString(loadingPeriodsKey, mainMessages[siteLanguageDefault][loadingPeriodsKey]);
    const [gridNoRecordsMessage, setGridNoRecordsMessage] = useState(defaultGridMessage);

    //state for assign product grids
    const initialGridState = {
        take: 20, skip: 0,
    };
    const [assignGridState, setAssignGridState] = useState(initialGridState);
    const assignGridStateRef = useRef(initialGridState)
    const [selectHeaderCheck, setSelectHeaderCheck] = useState(false);
    const [enableSortByDisable, setEnableSortByDisable] = useState(false)
    let sortByDisableValue = useRef(null)

    const uniqueID = useRef(0)

    /*
     * getLicenses() gets the licenses
    */
    async function getLicenses() {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        setIsLoading(true);
        setGridNoRecordsMessage(defaultLoadingMessage);
        let query = new URLSearchParams(search);
        const orderID = query.get('orderID');
        const productID = query.get('productID');

        let url = config.request_license.PRODUCT_LICENSES;

        if (orderID || productID) {
            url += search;
        }

        axios.get(
            url,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    data.forEach((product, index) => {
                        // allows Product Row to be edited
                        product.inEdit = true;
                        product.selected = false
                        product.disabled = false

                        // creates a unique id for each row
                        product.unique_id = uniqueID.current + 1
                        uniqueID.current++

                        // sets data type correctly
                        product.qty_available = Number(product.qty_available);
                        if (product.license_expiration_date !== "") {
                            product.license_expiration_date_display = new Date(product.license_expiration_date.replace(/-/g, "/"));
                        } else {
                            product.license_expiration_date_display = null;
                        }
                        if (product.support_expiration_date !== "") {
                            product.support_expiration_date_display = new Date(product.support_expiration_date.replace(/-/g, "/"));
                        } else {
                            product.support_expiration_date_display = null;
                        }

                        // sets qty_requested for each row
                        // note: if product is 1 per host qty_request is always 1
                        if (product.qty_available === 1 || product.redeem_one_per_host === "Y") {
                            product.qty_requested = 1;
                        } else {
                            product.qty_requested = null;
                        }
                        product.qty_available = parseInt(product.qty_available)
                    })

                    //Sort the data by product number
                    let orderByProductNumber = orderBy(data, [{
                        field: "product_number",
                        dir: "asc"
                    }]);

                    setSelectedProducts(orderByProductNumber);

                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Request Licenses Products data", error);
            })
            .finally(() => {
                setIsLoading(false);
                setGridNoRecordsMessage(defaultGridMessage);
            })
    }

    //On load get current available licenses
    useEffect(() => {
        getLicenses();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    //Confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {
        //Returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
            )) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    // useEffect(() => {
    //     console.log('selectedProducts', selectedProducts)
    //     // console.log('selectedProducts', selectedProducts.filter(item => item.selected))
    // }, [selectedProducts])
    //
    // useEffect(() => {
    //     console.log('assignedProducts', assignedProducts)
    // }, [assignedProducts])

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col>
                        <div className={"k-h2"}>
                            <Text
                                textkey={requestNewLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][requestNewLicensesKey]}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div style={{
                            width: "125%"
                        }}>
                            <KSMStepper
                                stepperItems={stepperItems}
                                stepValue={stepValue}
                                changeStepActivation={changeStepActivation}
                            />
                        </div>
                    </Col>
                    <Col/>
                </Row>
                {stepValue === 0 && (
                    <AssignProducts
                        changeStepActivation={changeStepActivation}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        assignedProducts={assignedProducts}
                        setAssignedProducts={setAssignedProducts}
                        gridNoRecordsMessage={gridNoRecordsMessage}
                        initialGridState={initialGridState}
                        assignGridState={assignGridState}
                        setAssignGridState={setAssignGridState}
                        assignGridStateRef={assignGridStateRef}
                        selectHeaderCheck={selectHeaderCheck}
                        setSelectHeaderCheck={setSelectHeaderCheck}
                        enableSortByDisable={enableSortByDisable}
                        setEnableSortByDisable={setEnableSortByDisable}
                        sortByDisableValue={sortByDisableValue}
                    />
                )}
                {stepValue === 1 && (
                    <LicensePreview
                        changeStepActivation={changeStepActivation}
                        assignedProducts={assignedProducts}
                        setAssignedProducts={setAssignedProducts}
                        setRequestedProducts={setRequestedProducts}
                    />
                )}
                {stepValue === 2 && (
                    <LicenseDownload
                        changeStepActivation={changeStepActivation}
                        assignedProducts={assignedProducts}
                        setAssignedProducts={setAssignedProducts}
                        requestedProducts={requestedProducts}
                        setRequestedProducts={setRequestedProducts}
                        handleBeforeUnload={handleBeforeUnload}
                        setIsLoading={setIsLoading}
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                        selectedProducts={selectedProducts}
                    />
                )}
                <br/>
            </div>
        </>
    );
}

export default RequestLicense;
