import React, {cloneElement, useCallback, useContext, useEffect, useRef, useState} from 'react';
import UserContext from '../../common/UserContext.js';

// css
import '../../../assets/css/EARequestLicense.css';

// components
import {ColumnMenu, ColumnSortOnlyMenu, NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';
import {AssignQtyCell} from './AssignQtyCell.js';
import {DateGridContext, EndDateHeaderCell, EndDatePickerCell, StartDatePickerCell} from './DatePickerCell.js';

// kendo react
import {Popover} from '@progress/kendo-react-tooltip';
import {Calendar} from '@progress/kendo-react-dateinputs';
import {Checkbox} from '@progress/kendo-react-inputs';
import {getSelectedState, Grid, GridColumn, GridItemChangeEvent, GridNoRecords} from '@progress/kendo-react-grid';
import {orderBy, process} from '@progress/kendo-data-query';
import {getter} from '@progress/kendo-react-common';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    incompatibleProductsSelectedKey,
    infoKey,
    noDataAvailableKey,
    assignQtyKey,
    descriptionKey,
    endDateKey,
    licenseTypeKey,
    mainMessages,
    productNumberKey,
    qtyKey,
    startDateKey,
} from '../../../assets/text/MultilingualText.js';

// consts for my products table
const DATA_ITEM_KEY = 'ea_alloc_id';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);
const initialGridState = {
    take: 10,
    skip: 0,
};

const MyProductsGrid = (props) => {
    const {
        selectedState,
        setSelectedState,
        selectedProducts,
        setSelectedProducts,
        headerInfo,
        remixType,
        calculateCost,
        minEndDate,
        maxEndDate,
        datePickerEnabled,
        setIsEnabled
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    // sets the grid no records message
    const defaultGridMessage = localization.toLanguageString(noDataAvailableKey, mainMessages[siteLanguageDefault][noDataAvailableKey]);
    const [gridNoRecordsMessage] = useState(defaultGridMessage);
    const [selectHeaderCheck, setSelectHeaderCheck] = useState(false);

    const [anchor, setAnchor] = useState(null);
    const [visible, setVisible] = useState(false);

    const [hostIDTypeNumber, setHostIDTypeNumber] = useState("");
    const [columnEndDate, setColumnEndDate] = useState(null);

    const CustomRowRender = (tr, props) => {
        if (props.dataItem.disabled) {
            return cloneElement(
                tr,
                {...tr.props, className: 'k-state-disabled'},
                tr.props.children
            );
        }
        return tr
    };

    const CustomStartDatePickerCell = (props) => {
        return <StartDatePickerCell
            {...props}
            type={"SELECTED"}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            headerInfo={headerInfo}
            calculateCost={calculateCost}
        />
    }
    const CustomEndDatePickerCell = (props) => {
        return <EndDatePickerCell
            {...props}
            type={"SELECTED"}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            headerInfo={headerInfo}
            calculateCost={calculateCost}
            minEndDate={minEndDate}
            maxEndDate={maxEndDate}
            datePickerEnabled={datePickerEnabled}
            remixType={remixType}
        />
    }

    const SelectCell = (props) => {
        const {siteLanguageDefault} = useContext(UserContext);
        const localization = useLocalization();

        const anchor = useRef(null);
        const [popoverShow, setPopoverShow] = useState(false);

        // show popover if there is an error on mouse over
        const onMouseOver = (ev) => {
            setPopoverShow(true);
        };

        // remove popover on mouse leave
        const onMouseOut = (ev) => {
            let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
            if (!isChildEl) setPopoverShow(false);
        };

        return <td
            ref={anchor}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {props.dataItem.disabled &&
                <Popover
                    show={popoverShow}
                    anchor={anchor.current}
                    position={'right'}
                    className={'ksm-popover ksm-popover-alert-info'}
                    animate={false}
                >
                    <div style={{
                        display: 'flex'
                    }}>
                        <span
                            className="k-icon k-i-information"
                            style={{
                                color: 'var(--keysight-purple)',
                                fontSize: '2.5rem',
                                marginRight: '0.938rem'
                            }}
                        />
                        <div>
                            <b>{localization.toLanguageString(infoKey, mainMessages[siteLanguageDefault][infoKey])}</b>
                            <br/>
                            {localization.toLanguageString(incompatibleProductsSelectedKey, mainMessages[siteLanguageDefault][incompatibleProductsSelectedKey])}
                        </div>
                    </div>
                </Popover>
            }
            <Checkbox
                type="checkbox"
                onChange={props.selectionChange}
                value={props.dataItem[SELECTED_FIELD]}
                disabled={props.dataItem.disabled}
            />
        </td>
    }

    /*
    -- selectedProducts grid variables --
    selectedState: an object containing the selected selectedProducts. (moved to parent component...)
        keys: product's unique id, values: true if selected
        ex. {0: true, 1: false}
    selectedProductGridState: an object containing the grid configurations to be consumed by the KendoReact Grid component for
        pagination
        take: the number of rows to show, skip the number of rows to be skipped by the pager
        ex. {take: 20, skip: 0}
    selectedProductState: an object containing the selectedProducts to be consumed by the KendoReact Grid component.
        data: all a list of all the selectedProducts, total: the length of selectedProducts
        ex. {data: Array(20), total: 20}
    selectedProductsRef: a reference to the selectedProducts (should be equal to selectedProducts)
     */
    const [selectedProductGridState, setSelectedProductGridState] = useState(initialGridState);
    const [selectedProductState, setSelectedProductState] = useState(
        process(
            selectedProducts
                .filter(product => product.unassigned_quantity > 0)
                .map((product) => ({
                        ...product,
                        'selected': selectedState[idGetter(product)] || false,
                    }
                )),
            initialGridState
        )
    );
    const selectedProductsRef = useRef(selectedProducts);
    const selectedProductsGridStateRef = useRef(selectedProductGridState);

    /*
     * setSelectedProduct(selectedProducts) sets the selected flag of a product
     * @param {selectedProducts} the list of selectedProducts
     * @return {selected} the list of selectedProducts wth the selected product's flag set
    */
    const setSelectedProduct = (selectedProducts: any[]) => {
        return selectedProducts.map(product => ({
                ...product,
                'selected': selectedState[idGetter(product)]
            })
        );
    };

    /*
     * onProductSelectionChange(event) sets the product grid state on grid's selection change
     * @param {event} the grid selection change event
    */
    const onProductSelectionChange = useCallback((event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });

        setSelectedState(newSelectedState);
    }, [selectedState]); // eslint-disable-line react-hooks/exhaustive-deps

    /*
     * onHeaderSelectionChange(event) selects all enabled selectedProducts in the license grid
     * @param {event} the header selection change event
    */
    const onHeaderSelectionChange = useCallback(event => {
        const checkboxElement = event.nativeEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};

        let hostIdTypes = selectedProducts
            .filter(product => product.selected)
            .map(product => {
                let licenseType = product.license_type.filter(lt => lt.selected);
                return licenseType[0].hostid_type_pk;
            });
        let size = new Set(hostIdTypes).size;

        let selected = Object.keys(selectedState).filter((product) => selectedState[product]);
        let partials = selectedProducts
            .filter((product) => {
                let license = product.license_type.filter(lt => lt.selected);
                return license && license[0].ea_compatibility === "P"
            })
            .map((product) => product.ea_alloc_id);
        let partialsSelected = selected.every(s => partials.includes(parseInt(s)));

        // allow select all functionality when selected products have the same host id type or
        // are a partially compatible products
        if (size === 1 || (partialsSelected && selected.length)) {
            event.dataItems.forEach(product => {
                // select all rows if products with the same host id type number or
                // are partially compatible products and
                // product is not disabled
                let license = product.license_type.filter(lt => lt.selected);
                let isSameHostID = hostIDTypeNumber && license[0].hostid_type_pk === hostIDTypeNumber;
                let isEACompatible = license[0].ea_compatibility === "P";

                if ((isSameHostID || (isEACompatible)) && !product.disabled) {
                    product.selected = true;
                    newSelectedState[idGetter(product)] = checked;
                }
            });
            // handle deselect
            if (!checked) {
                setSelectedProducts(
                    orderBy(
                        selectedProducts.map((product) => {
                            product.selected = false;
                            product.disabled = false;
                            return product;
                        }),
                        [{field: 'ea_alloc_id', dir: 'asc'}]
                    )
                );
                setHostIDTypeNumber(null);
            }

            setSelectedState(newSelectedState);
        }
    }, [selectedState, hostIDTypeNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    /*
     * onSelectedProductStateChange(event) sets the product grid state and product state on grid's state change
     * @param {event} the grid selection change event
    */
    const onSelectedProductStateChange = useCallback((event: GridDataStateChangeEvent) => {
        // enable the selected row is part of event that sorts and filter
        const newProductState = process(
            selectedProducts
                .filter(product => product.unassigned_quantity > 0)
                .map((product) => ({
                        ...product,
                        'selected': selectedState[idGetter(product)] || false,
                    }
                )),
            event.dataState
        );

        setSelectedProductGridState(event.dataState);
        setSelectedProductState(newProductState);
    }, [selectedProducts]); // eslint-disable-line react-hooks/exhaustive-deps

    // update product state for editable assign qty in license table
    const productItemChange = (e: GridItemChangeEvent) => {
        let copyProducts = selectedProductsRef.current.map((product) => {
            if (product.ea_alloc_id === e.dataItem.ea_alloc_id) {
                let setValue = parseInt(e.value)

                // assign qty cannot be greater than the max qty available
                if (setValue > parseInt(e.dataItem.unassigned_quantity)) {
                    setValue = parseInt(e.dataItem.unassigned_quantity)
                }
                // assign qty cannot be less than 1
                if (setValue < 1) {
                    setValue = null
                }

                product[e.field] = setValue;
            }
            return product;
        });

        setSelectedProducts(copyProducts);
    };

    // process product state whenever selectedProducts changes in my products grid
    useEffect(() => {
        if (selectedProducts.length) {
            setSelectedProductState(
                process(
                    selectedProducts
                        .filter(product => product.unassigned_quantity > 0)
                        .map((product) => ({
                                ...product,
                                'selected': selectedState[idGetter(product)] || false,
                            }
                        )),
                    selectedProductGridState
                )
            );
        } else {
            // case: all selectedProducts are removed in my products grid
            setSelectedProductState(
                process([], selectedProductGridState)
            );
        }

        // selectedProductsRef.current always holds the most updated state of selectedProducts
        selectedProductsRef.current = selectedProducts;
    }, [selectedProducts]); // eslint-disable-line react-hooks/exhaustive-deps

    // update checked state when adding or removing from selectedProducts
    useEffect(() => {
        const newSelectedState = {}
        selectedProducts.forEach(product => {
            newSelectedState[idGetter(product)] = product.selected;
        });
        setSelectedState(newSelectedState);
    }, [selectedProducts.length]); // eslint-disable-line react-hooks/exhaustive-deps

    // set selected flag of selectedProducts if product is selected
    useEffect(() => {
        let selected = Object.keys(selectedState).filter((product) => selectedState[product]);
        let partials = selectedProducts
            .filter((product) => {
                let license = product.license_type.filter(lt => lt.selected);
                return license && license[0].ea_compatibility === "P"
            })
            .map((product) => product.ea_alloc_id);
        let partialsSelected = selected.every(s => partials.includes(parseInt(s)));

        let updates = [...selectedProducts];
        updates.forEach(product => {
            let license = product.license_type.filter(lt => lt.selected);
            if (selected.length && selected.includes(product.ea_alloc_id.toString())) {
                product.selected = true;
                product.assign_quantity = license[0].redeem_one_per_host_flag === "Y" ? 1
                    : product.unassigned_quantity === 1 ? 1
                        : product.assign_quantity;
            } else {
                product.selected = false;
                product.assign_quantity = license[0].redeem_one_per_host_flag === "Y" ? 1
                    : product.unassigned_quantity === 1 ? 1
                        : null;
            }

            if (selected.length && partialsSelected) {
                // case: disable product when selecting partially compatible products
                product.disabled = license[0].ea_compatibility !== "P";
            } else if (selected.length) {
                // case: disable product when selecting products with different host id type numbers and products with partial ea compatibility
                product.disabled = !!((hostIDTypeNumber && license[0].hostid_type_pk !== hostIDTypeNumber) || license[0].ea_compatibility !== "Y");
            } else {
                // case: enable no selects
                product.disabled = false;
            }
        });
        setSelectedProducts(updates);

        // set select header check if all products that are enabled are selected
        let enabled = selectedProducts.filter((product) => !product.disabled && product.unassigned_quantity > 0);
        enabled.length && enabled.every(e => e.selected) ? setSelectHeaderCheck(true) : setSelectHeaderCheck(false);
    }, [selectedState, hostIDTypeNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    // selectedProductsGridStateRef.current always holds the most updated state of selectedProducts grid
    useEffect(() => {
        selectedProductsGridStateRef.current = selectedProductGridState;
    }, [selectedProductGridState]);

    // sets ALL end dates to selectedProducts date in column header calendar
    useEffect(() => {
        if (columnEndDate) {
            let cost = 0;
            let tempSelectedProducts = [...selectedProducts];
            tempSelectedProducts.forEach(product => {
                product.period_end_date = columnEndDate;
            });
            cost = calculateCost(tempSelectedProducts, []);

            // only set ALL end dates in selected product if remaining sub pool
            if (cost <= headerInfo?.remaining_pool) {
                let products = [...selectedProducts]
                products.forEach(product => product.period_end_date = columnEndDate);
                setSelectedProducts(products);
            }
        } else {
            setColumnEndDate(null);
        }
    }, [columnEndDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let hostIdTypes = selectedProducts
            .filter(product => product.selected)
            .map(product => {
                let licenseType = product.license_type.filter(lt => lt.selected);
                return licenseType[0].hostid_type_pk;
            });
        let size = new Set(hostIdTypes).size;

        let selected = selectedProducts.filter(product => product.selected && product.unassigned_quantity > 0);
        let unselected = selectedProducts.filter(product => !product.selected);
        let isValidQty = selected.length && selected.every(product => typeof product.assign_quantity  == "number"  && product.assign_quantity > 0);

        let partials = selectedProducts
            .filter((product) => {
                let license = product.license_type.filter(lt => lt.selected);
                return license && license[0].ea_compatibility === "P"
            })
            .map((product) => product.ea_alloc_id);
        let partialsSelected = selected.every(s => partials.includes(parseInt(s.ea_alloc_id)));

        // enable or disable assign products to host button if products are selected
        selected.length && isValidQty && (size === 1 || partialsSelected) ? setIsEnabled(true) : setIsEnabled(false);

        // handle select all by setting the host id type number if all host ids are the same
        // or if one product is selected
        let hostIds = selectedProducts.map(product => {
            let licenseType = product.license_type.filter(lt => lt.selected);
            return licenseType[0].hostid_type_pk;
        });

        if (hostIds.every((val, i, arr) => val === arr[0])) {
            setHostIDTypeNumber(hostIds[0]);
        }
        if (selected.length === 1) {
            let licenseType = selected[0].license_type.filter(lt => lt.selected);
            setHostIDTypeNumber(licenseType[0].hostid_type_pk);
        }
        // enable all rows when no rows are selected
        if (unselected.length === selectedProducts.length) {
            setHostIDTypeNumber(null);
        }
    }, [selectedProducts]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Popover
                className="my-products-end-date-popover"
                show={visible}
                anchor={anchor}
                position={'bottom'}
                style={{padding: "none"}}
            >
                <Calendar
                    min={minEndDate}
                    max={maxEndDate}
                    disabled={!datePickerEnabled}
                    onChange={(e) => {
                        setColumnEndDate(e.value);
                        setVisible(false);
                    }}
                />
            </Popover>
            <DateGridContext.Provider
                value={{
                    visible,
                    setVisible,
                    setAnchor
                }}>
                <Grid
                    className={'my-products-grid'}
                    scrollable={'none'}
                    sortable={{
                        allowUnsort: true,
                        mode: "single"
                    }}
                    pageable={(selectedProducts.length <= initialGridState.take) ? false : {
                        buttonCount: 5,
                        pageSizes: [10, 20, 50, 100],
                    }}
                    rowRender={CustomRowRender}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: false,
                        cell: false,
                        mode: 'multiple'
                    }}
                    editField="inEdit"
                    total={selectedProductGridState.total}
                    data={(selectedProducts.length >= initialGridState.take) ? selectedProductState : setSelectedProduct(selectedProductState.data)}
                    onDataStateChange={onSelectedProductStateChange}
                    onSelectionChange={onProductSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    onItemChange={productItemChange}
                    {...selectedProductGridState}
                >
                    <GridNoRecords>
                        {gridNoRecordsMessage}
                    </GridNoRecords>
                    <GridColumn
                        field={SELECTED_FIELD}
                        editable={false}
                        headerSelectionValue={selectHeaderCheck}
                        cell={SelectCell}
                    />
                    <GridColumn
                        field="prod_num_display"
                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                        cell={NoWrapCell}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="description"
                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                        columnMenu={ColumnMenu}
                    />
                    <GridColumn
                        field="unassigned_quantity"
                        title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                        cell={TextAlignMiddleCell}
                        filter={'numeric'}
                        columnMenu={ColumnSortOnlyMenu}
                    />
                    <GridColumn
                        field="assign_quantity"
                        title={localization.toLanguageString(assignQtyKey, mainMessages[siteLanguageDefault][assignQtyKey])}
                        filter={'numeric'}
                        cell={AssignQtyCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="period_start_date"
                        title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                        cell={CustomStartDatePickerCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="period_end_date"
                        title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                        headerCell={remixType === "variable" ? EndDateHeaderCell : null}
                        cell={CustomEndDatePickerCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="selected_license_type_text"
                        title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                        columnMenu={ColumnMenu}
                        cell={NoWrapCell}
                    />
                </Grid>
            </DateGridContext.Provider>
        </>
    )
}

export default MyProductsGrid;