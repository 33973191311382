import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMEASummaryMyEnterpriseAgreements from '../assets/img/Help/ksm-ea-summary-my-enterprise-agreements.png';
import KSMEASummaryHamburger from '../assets/img/Help/ksm-ea-summary-hamburger.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    eaAccessKey,
    helpEASummaryEAAccessQAKey,
    typesEAsKey,
    helpEASummaryEATypesSectionKey,
    typesOfEnterpriseAgreementsKey,
    enterpriseAgreementsKey,
    helpEASummaryEnterpriseAgreementsQAKey,
    helpEASummaryErrorHandlingSectionKey,
    errorHandlingKey,
    frequentlyAskedQuestionsKey,
    helpEASummaryGettingStartedSectionKey,
    gettingStartedKey,
    eaSummaryKey,
    hostsKey,
    helpEASummaryHostsQAKey,
    licensesKey,
    helpEASummaryLicensesQAKey,
    mainMessages,
    helpEASummaryMyEnterpriseAgreementsSectionKey,
    myEnterpriseAgreementsKey,
    helpEASummaryNavigationSectionKey,
    navigationKey,
    helpEASummaryQuickStepsSectionKey,
    quickStepsGetLicensesKey
} from '../assets/text/MultilingualText.js';

function EASummaryHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const bulletStyle = {
        paddingTop: 6,
        paddingBottom: 6
    };

    const helpContent = {
        'header': {
            key: eaSummaryKey,
            default: mainMessages[siteLanguageDefault][eaSummaryKey]
        },
        'topics': [
            {
                key: quickStepsGetLicensesKey,
                default: mainMessages[siteLanguageDefault][quickStepsGetLicensesKey],
                hash: '#quick-steps'
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: '#navigation'
            },
            {
                key: typesEAsKey,
                default: mainMessages[siteLanguageDefault][typesEAsKey],
                hash: '#ea-types'
            },
            {
                key: myEnterpriseAgreementsKey,
                default: mainMessages[siteLanguageDefault][myEnterpriseAgreementsKey],
                hash: '#my-enterprise-agreements'
            },
            {
                key: gettingStartedKey,
                default: mainMessages[siteLanguageDefault][gettingStartedKey],
                hash: '#getting-started'
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: '#error-handling'
            }
        ],
        'faq': [
            {
                key: enterpriseAgreementsKey,
                default: mainMessages[siteLanguageDefault][enterpriseAgreementsKey],
                hash: '#enterprise-agreements-faq'
            },
            {
                key: licensesKey,
                default: mainMessages[siteLanguageDefault][licensesKey],
                hash: '#licenses-faq'
            },
            {
                key: hostsKey,
                default: mainMessages[siteLanguageDefault][hostsKey],
                hash: '#hosts-faq'
            },
            {
                key: eaAccessKey,
                default: mainMessages[siteLanguageDefault][eaAccessKey],
                hash: '#ea-access-faq'
            }
        ]
    };

    const quickStepsContent = localization.toLanguageString(
        helpEASummaryQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpEASummaryQuickStepsSectionKey]
    );

    const navigationContent = localization.toLanguageString(
        helpEASummaryNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpEASummaryNavigationSectionKey]
    );

    const eATypesContent = localization.toLanguageString(
        helpEASummaryEATypesSectionKey,
        mainMessages[siteLanguageDefault][helpEASummaryEATypesSectionKey]
    );

    const myEnterpriseAgreementsContent = localization.toLanguageString(
        helpEASummaryMyEnterpriseAgreementsSectionKey,
        mainMessages[siteLanguageDefault][helpEASummaryMyEnterpriseAgreementsSectionKey]
    );

    const gettingStartedContent = localization.toLanguageString(
        helpEASummaryGettingStartedSectionKey,
        mainMessages[siteLanguageDefault][helpEASummaryGettingStartedSectionKey]
    );

    const errorHandlingContent = localization.toLanguageString(
        helpEASummaryErrorHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpEASummaryErrorHandlingSectionKey]
    );

    const enterpriseAgreementsQA = localization.toLanguageString(
        helpEASummaryEnterpriseAgreementsQAKey,
        mainMessages[siteLanguageDefault][helpEASummaryEnterpriseAgreementsQAKey]
    );

    const licensesQA = localization.toLanguageString(
        helpEASummaryLicensesQAKey,
        mainMessages[siteLanguageDefault][helpEASummaryLicensesQAKey]
    );

    const hostsQA = localization.toLanguageString(
        helpEASummaryHostsQAKey,
        mainMessages[siteLanguageDefault][helpEASummaryHostsQAKey]
    );

    const eAAccessQA = localization.toLanguageString(
        helpEASummaryEAAccessQAKey,
        mainMessages[siteLanguageDefault][helpEASummaryEAAccessQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsGetLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsGetLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {quickStepsContent.section_one}
                            </p>
                            {quickStepsContent.section_one_bullets ? <ol>
                                {quickStepsContent.section_one_bullets.map((bullet, index) => <li
                                    key={'ea-summary-quick-steps-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ol> : <></>}
                            <p>
                                {quickStepsContent.section_two}
                            </p>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(navigationContent.section_one)}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="ea-types"
                        >
                            <Text
                                textkey={typesOfEnterpriseAgreementsKey}
                                textdefault={mainMessages[siteLanguageDefault][typesOfEnterpriseAgreementsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {eATypesContent.section_one}
                            </p>
                            {eATypesContent.section_one_bullets ? <ul>
                                {eATypesContent.section_one_bullets.map((bullet, index) => {
                                    if (index === 0) {
                                        return <li key={'ea-summary-ea-types-bullet-' + index}>
                                            <div style={bulletStyle}>
                                                {toHtml(bullet)}
                                            </div>
                                            {eATypesContent.section_one_sub_bullets_one.map((dash, index) => {
                                                if (index === 2) {
                                                    return <div key={'ea-summary-ea-types-sub-bullet-' + index}>
                                                        <div
                                                            className="indent"
                                                            style={{
                                                                ...bulletStyle,
                                                                ...{display: 'flex'}
                                                            }}
                                                            key={'ea-summary-ea-types-dash-bullet-' + index}
                                                        >
                                                            &ndash;&nbsp;{toHtml(dash)}
                                                        </div>
                                                        <ul>
                                                            {eATypesContent.section_one_sub_bullets_one_three.map((sub, index) =>
                                                                <li
                                                                    key={'ea-summary-ea-types-sub-' + index}
                                                                    style={bulletStyle}
                                                                >
                                                                    {toHtml(sub)}
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                } else {
                                                    return <div
                                                        className="indent"
                                                        style={{
                                                            ...bulletStyle,
                                                            ...{display: 'flex'}
                                                        }}
                                                        key={'ea-summary-ea-types-sub-bullet-' + index}
                                                    >
                                                        &ndash;&nbsp;{toHtml(dash)}
                                                    </div>
                                                }
                                            })}
                                        </li>
                                    } else if (index === 1) {
                                        return <li key={'ea-summary-ea-types-bullet-' + index}>
                                            <div style={bulletStyle}>
                                                {toHtml(bullet)}
                                            </div>
                                            {eATypesContent.section_one_sub_bullets_two.map((dash, index) => <div
                                                    className="indent"
                                                    style={{
                                                        ...bulletStyle,
                                                        ...{display: 'flex'}
                                                    }}
                                                    key={'ea-summary-ea-types-sub-bullet-' + index}
                                                >
                                                    {(index === 3) ?
                                                        <>
                                                            &ndash;&nbsp;&nbsp;{toHtml(dash)}
                                                        </> :
                                                        <>
                                                            &ndash;&nbsp;{toHtml(dash)}
                                                        </>
                                                    }
                                                </div>
                                            )}
                                        </li>
                                    } else {
                                        return <></>
                                    }
                                })}
                            </ul> : <></>}
                            <p>
                                <b>{eATypesContent.section_two}</b>
                            </p>
                            <p>
                                {eATypesContent.section_three}
                            </p>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="my-enterprise-agreements"
                        >
                            <Text
                                textkey={myEnterpriseAgreementsKey}
                                textdefault={mainMessages[siteLanguageDefault][myEnterpriseAgreementsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(myEnterpriseAgreementsContent.section_one)}
                            <img src={KSMEASummaryMyEnterpriseAgreements}
                                 alt="ksm-ea-summary-my-enterprise-agreements"/>
                            {toHtml(myEnterpriseAgreementsContent.section_two)}
                            {myEnterpriseAgreementsContent.section_two_bullets ? <ul>
                                {myEnterpriseAgreementsContent.section_two_bullets.map((bullet, index) => <li
                                    key={'ea-summary-my-enterprise-agreements-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                            {toHtml(myEnterpriseAgreementsContent.section_three)}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="getting-started"
                        >
                            <Text
                                textkey={gettingStartedKey}
                                textdefault={mainMessages[siteLanguageDefault][gettingStartedKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <div style={{
                                display: "flex",
                                whiteSpace: "nowrap"
                            }}>
                                {toHtml(gettingStartedContent.section_one_prefix)}
                                <span className="img-container">
                                    <img src={KSMEASummaryHamburger} alt="ksm-ea-summary-hamburger"/>
                                </span>
                                {toHtml(gettingStartedContent.section_one_suffix_one)}
                            </div>
                            {toHtml(gettingStartedContent.section_one_suffix_two)}
                            <p>
                                <b>{gettingStartedContent.section_two}</b>
                            </p>
                            <p>
                                {gettingStartedContent.section_three}
                            </p>
                            {gettingStartedContent.section_three_bullets ? <ul>
                                {gettingStartedContent.section_three_bullets.map((bullet, index) => {
                                    if (index === 0) {
                                        return <li
                                            style={{padding: 0}}
                                            key={'ea-summary-getting-started-bullet-' + index}
                                        >
                                            <div style={bulletStyle}>
                                                {toHtml(bullet)}
                                            </div>
                                            {gettingStartedContent.section_three_sub_bullets_one.map((dash, index) => {
                                                return <div
                                                    className="indent"
                                                    style={{
                                                        ...bulletStyle,
                                                        ...{display: 'flex'}
                                                    }}
                                                    key={'ea-summary-getting-started-sub-bullet-' + index}
                                                >
                                                    &ndash;&nbsp;{toHtml(dash)}
                                                </div>
                                            })}
                                        </li>
                                    } else if (index === 2) {
                                        return <li
                                            style={{padding: 0}}
                                            key={'ea-summary-getting-started-bullet-' + index}
                                        >
                                            <div style={bulletStyle}>
                                                {toHtml(bullet)}
                                            </div>
                                            {gettingStartedContent.section_three_sub_bullets_three.map((dash, index) =>
                                                <div
                                                    className="indent"
                                                    style={{
                                                        ...bulletStyle,
                                                        ...{display: 'flex'}
                                                    }}
                                                    key={'ea-summary-getting-started-sub-bullet-' + index}
                                                >
                                                    {(index === 2) ?
                                                        <>
                                                            &ndash;&nbsp;{toHtml(dash)}
                                                        </> :
                                                        <>
                                                            &ndash;&nbsp;&nbsp;{toHtml(dash)}
                                                        </>
                                                    }
                                                </div>)}
                                            {gettingStartedContent.section_three_sub_three.map((sub, index) => <div
                                                className="indent"
                                                key={'ea-summary-getting-started-sub-' + index}
                                            >
                                                {toHtml(sub)}
                                                <br/>
                                            </div>)}
                                        </li>
                                    } else {
                                        return <li
                                            style={{padding: 0}}
                                            key={'ea-summary-getting-started-bullet-' + index}
                                        >
                                            <div style={bulletStyle}>
                                                {toHtml(bullet)}
                                            </div>
                                            {index === 3 ? <>{toHtml(gettingStartedContent.section_three_sub_four)}</> : <></>}
                                        </li>
                                    }
                                })}
                            </ul> : <></>}
                            <p>
                                <b>{gettingStartedContent.section_four}</b>
                            </p>
                            <p>
                                {gettingStartedContent.section_five}
                            </p>
                            {gettingStartedContent.section_five_bullets ? <ul>
                                {gettingStartedContent.section_five_bullets.map((bullet, index) => <li
                                    key={'ea-summary-getting-started-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {errorHandlingContent.section_one}
                            </p>
                            {errorHandlingContent.section_one_bullets ? <ul>
                                {errorHandlingContent.section_one_bullets.map((bullet, index) => <li
                                    key={'ea-summary-error-handling-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div className={"k-h2 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="enterprise-agreements-faq">
                            <h3>
                                <Text
                                    textkey={enterpriseAgreementsKey}
                                    textdefault={mainMessages[siteLanguageDefault][enterpriseAgreementsKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {enterpriseAgreementsQA ? enterpriseAgreementsQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"enterprise-agreements-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="licenses-faq">
                            <h3>
                                <Text
                                    textkey={licensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][licensesKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {licensesQA ? licensesQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"licenses-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="hosts-faq">
                            <h3>
                                <Text
                                    textkey={hostsKey}
                                    textdefault={mainMessages[siteLanguageDefault][hostsKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {hostsQA ? hostsQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"hosts-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="ea-access-faq">
                            <h3>
                                <Text
                                    textkey={eaAccessKey}
                                    textdefault={mainMessages[siteLanguageDefault][eaAccessKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {eAAccessQA ? eAAccessQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"ea-access-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <div className="indent">
                                                    {toHtml(questionAnswer.A)}
                                                    <br/>
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default EASummaryHelp;

