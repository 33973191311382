import React from 'react';

// kendo react
import {FieldWrapper} from '@progress/kendo-react-form';
import {Error, Label} from '@progress/kendo-react-labels';
import {Input} from '@progress/kendo-react-inputs';

/**
 * The Form Input component uses the Kendo Input and enables users to type into an input field
 * @param props: contains all the props to customize the Form Input which include
 *  id: the unique id of the Form Input
 *  label: the label above the Form Input
 *  validationMessage: the validation message displayed below the Form Input
 **/
const FormInput = props => {
    const {
        id,
        label,
        validationMessage,
        isValid,
        dataValue,
        required,
        ...others
    } = props;
    let labelElem
    if (required) {
        labelElem = <Label
            style={{
                "fontSize": "1.125rem"
            }}
            editorId={id}
        >
            {label}
            <span
                style={{
                    "color": "#E90029",
                    "whiteSpace": "pre"
                }}
            > *</span>
        </Label>
    } else {
        labelElem = <Label
            style={{
                "fontSize": "1.125em"
            }}
            editorId={id}
        >
            {label}
        </Label>
    }

    return (
        <FieldWrapper>
            {labelElem}
            <Input
                {...others}
                value={dataValue}
                valid={isValid}
                validityStyles={!isValid}
            />
            {!isValid && <Error>{validationMessage}</Error>}
        </FieldWrapper>
    );
};

export default FormInput;