import React, {useContext, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// components
import {DateCell, NoWrapCell, TextAlignMiddleCell} from '../common/Grid.js';
import {ReassignModal} from './ReassignLicenses/ReassignModal.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {orderBy} from '@progress/kendo-data-query';
import {Tooltip} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    continueRenewLicensesKey,
    hostKey,
    mainMessages,
    noLicensesRenewalKey,
    productDescriptionKey,
    licenseExpirationDateKey,
    notesKey,
    productNumberKey,
    qtyKey,
    reassignKey,
    rehostThisLicenseKey,
    versionKey,
    reassignBeforeProceedingKey,
    renewableLicensesKey,
    undoHostChangesKey,
} from '../../assets/text/MultilingualText.js';


function ReassignLicenses(props) {
    const {
        setIsLoading,
        changeStepActivation,
        savedHosts,
        modifyHosts,
        setModifyHosts,
        disableUndoChanges,
        setDisableUndoChanges
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [showReassignModal, setShowReassignModal] = useState(false);
    const [reassignProduct, setReassignProduct] = useState({});
    const [hostIDTypeDetails, setHostIDTypeDetails] = useState({});

    const closeReassignModal = () => {
        setShowReassignModal(false);
    }

    const getHostDetails = (product) => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let data = {
            "host_id_type": product.host_id_type
        };

        setIsLoading(true);

        axios.post(
            config.request_license.HOST_TYPE_DETAILS,
            data,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data;
                    setHostIDTypeDetails(data);
                    setReassignProduct(product);
                    setShowReassignModal(true);
                }
            })
            .catch((error) => {
                console.log('ERROR: Failed to POST Host Type Details', error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }


    const reassignCell = (props) => {
        const {dataItem} = props;
        return (
            <td>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Tooltip
                        anchorElement="target"
                        showCallout={false}
                        parentTitle={true}
                        openDelay={0}
                        position="top"
                    >
                        <button
                            title={localization.toLanguageString(rehostThisLicenseKey, mainMessages[siteLanguageDefault][rehostThisLicenseKey])}
                            className={'ksm-icon-button'}
                            onClick={() => {
                                getHostDetails(dataItem);
                            }}
                        >
                            <span
                                className={"k-icon k-i-connector"}
                                style={{
                                    fontSize: '1.5rem'
                                }}
                            />
                        </button>
                    </Tooltip>
                </div>
            </td>
        )
    }

    const defaultNoData = localization.toLanguageString(
        noLicensesRenewalKey,
        mainMessages[siteLanguageDefault][noLicensesRenewalKey]
    );

    return (
        <>
            {modifyHosts.length === 0 && (
                <div>{defaultNoData}</div>
            )}
            {modifyHosts.length > 0 && (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        marginBottom: '0.938rem'
                    }}
                >
                    <div className={"k-h4"}>
                        {localization.toLanguageString(renewableLicensesKey, mainMessages[siteLanguageDefault][renewableLicensesKey])}
                    </div>
                    <div
                        style={{
                            marginLeft: '0.5rem',
                            fontWeight: 300
                        }}
                    >
                        {localization.toLanguageString(reassignBeforeProceedingKey, mainMessages[siteLanguageDefault][reassignBeforeProceedingKey])}
                    </div>
                </div>
                {modifyHosts.map((host) =>
                    <PanelBar
                        key={host.transaction_id}
                        expanded={['.0']}
                        className={'ksm-panelbar-default'}
                    >
                        <PanelBarItem
                            title={<div
                                className={"k-h4"}
                                style={{
                                    color: 'white',
                                    margin: '0 0 0 2px'
                                }}>
                                {localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey])} {host.host_id}
                            </div>}
                        >
                            <Grid
                                className={"reassign-panelbar-grid"}
                                data={orderBy(host.products, [{
                                    field: "product_number",
                                    dir: "desc"
                                }])}
                                scrollable={'none'}
                                sortable={false}
                            >
                                <GridColumn
                                    field="product_number"
                                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                    cell={NoWrapCell}
                                />
                                <GridColumn
                                    field="product_desc"
                                    title={localization.toLanguageString(productDescriptionKey, mainMessages[siteLanguageDefault][productDescriptionKey])}
                                />
                                <GridColumn
                                    field="notes"
                                    title={localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey])}
                                    cell={NoWrapCell}
                                />
                                <GridColumn
                                    field="version"
                                    title={localization.toLanguageString(versionKey, mainMessages[siteLanguageDefault][versionKey])}
                                />
                                <GridColumn
                                    field="lic_exp_date"
                                    title={localization.toLanguageString(licenseExpirationDateKey, mainMessages[siteLanguageDefault][licenseExpirationDateKey])}
                                    cell={DateCell}
                                />
                                <GridColumn
                                    field="qty"
                                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                    cell={TextAlignMiddleCell}
                                />
                                <GridColumn
                                    title={localization.toLanguageString(reassignKey, mainMessages[siteLanguageDefault][reassignKey])}
                                    cell={reassignCell}
                                />
                            </Grid>
                        </PanelBarItem>
                    </PanelBar>
                )}
                <div
                    style={{
                        display: 'flex',
                        gap: '0.938rem'
                    }}
                >
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        rounded={"small"}
                        disabled={disableUndoChanges}
                        onClick={() => {
                            setModifyHosts(JSON.parse(JSON.stringify(savedHosts)));
                            setDisableUndoChanges(true);
                        }}
                    >
                        {localization.toLanguageString(undoHostChangesKey, mainMessages[siteLanguageDefault][undoHostChangesKey])}
                    </Button>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        rounded={"small"}
                        onClick={() => {
                            changeStepActivation(1);
                        }}
                    >
                        {localization.toLanguageString(continueRenewLicensesKey, mainMessages[siteLanguageDefault][continueRenewLicensesKey])}
                    </Button>
                </div>
                {showReassignModal && (
                    <ReassignModal
                        setIsLoading={setIsLoading}
                        siteLanguageDefault={siteLanguageDefault}
                        closeReassignModal={closeReassignModal}
                        product={reassignProduct}
                        hosts={modifyHosts}
                        setHosts={setModifyHosts}
                        setDisableUndoChanges={setDisableUndoChanges}
                        hostIDTypeDetails={hostIDTypeDetails}
                    />
                )}
            </>
        )}
        </>
    );
}

export default ReassignLicenses;