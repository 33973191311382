import React, {createRef, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/ViewProducts.css';

// components
import PageHeader from '../components/common/PageHeader.js';
import ViewByProduct from '../components/ProductView/ViewByProduct.js';
import Spinner from '../components/common/Spinner.js';
import ViewByHost from '../components/ProductView/ViewByHost.js';

// kendo react
import {InputClearValue, RadioGroup, TextBox} from '@progress/kendo-react-inputs';
import {Icon, Typography} from '@progress/kendo-react-common';
import {Button} from '@progress/kendo-react-buttons';
import {ExcelExport, ExcelExportColumn} from '@progress/kendo-react-excel-export';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    certNumberKey,
    quantityKey,
    hostIdKey,
    myHostsKey,
    licenseExpKey,
    mainMessages,
    orderNumberKey,
    poNumberKey,
    supportExpKey,
    availableKey,
    descriptionKey,
    myProductsKey,
    licenseTypeKey,
    viewByKey,
    productNumberKey,
    termKey,
    exportAllExcelKey, productDescriptionKey, purchaseOrderKey, searchForKey, searchKey, resetKey,
} from '../assets/text/MultilingualText.js';
import {DropDownList} from "@progress/kendo-react-dropdowns";

export default function ProductView(props) {
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [eViewTransactionID, setEViewTransactionID] = useState("");
    const [productsData, setProductsData] = useState([]);
    const [userExcelData, setUserExcelData] = useState([]);
    const [excelFileName, setExcelFileName] = useState("");
    const [productViewTypes] = useState([
        {
            label: "Products",
            value: "products",
        },
        {
            label: "Hosts",
            value: "hosts",
        }
    ]);
    const [viewType, setViewType] = useState(productViewTypes[0]?.value);
    const [productsLoading, setProductLoading] = useState(false);
    const [excelReportsLoading, setExcelReportsLoading] = useState(false);
    const [exportingStarted, setExportingStarted] = useState(false);

    // kendo react excel export 
    const _exporter = createRef();

    useEffect(() => {
        // after getting excelData export to XL
        if (userExcelData?.length && _exporter.current && exportingStarted) {
            _exporter.current.save();
            setExportingStarted(false);
        }
    }, [userExcelData?.length, _exporter, exportingStarted]);

    const defaultOption = {
        text: localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey]),
        id: "order_number",
    }

    // get data based on viewType
    useEffect(() => {
        if (viewType === 'products') {
            setSearchOptions([
                {
                    text: localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey]),
                    id: "order_number",
                },
                {
                    text: localization.toLanguageString(purchaseOrderKey, mainMessages[siteLanguageDefault][purchaseOrderKey]),
                    id: "po_number",
                },
            ])
            if (searchOption.id === "product_number" || searchOption.id === "product_desc") {
                setSearchOption(defaultOption)
                setSearchValue('')
                getProducts(defaultOption, '');
            } else {
                getProducts(searchOption, searchValue);
            }
        }
        if (viewType === 'hosts') {
            setSearchOptions([
                {
                    text: localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey]),
                    id: "product_number",
                },
                {
                    text: localization.toLanguageString(productDescriptionKey, mainMessages[siteLanguageDefault][productDescriptionKey]),
                    id: "product_desc",
                },
                {
                    text: localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey]),
                    id: "order_number",
                },
                {
                    text: localization.toLanguageString(purchaseOrderKey, mainMessages[siteLanguageDefault][purchaseOrderKey]),
                    id: "po_number",
                },
            ])
            getProductsForHostView(searchOption, searchValue);
        }
    }, [viewType]); // eslint-disable-line react-hooks/exhaustive-deps

    const search = () => {
        if (viewType === 'products') {
            getProducts(searchOption, searchValue);
        }
        if (viewType === 'hosts') {
            getProductsForHostView(searchOption, searchValue);
        }
    }

    const reset = () => {
        setSearchOption(searchOptions[0])
        setSearchValue('')
        if (viewType === 'products') {
            getProducts(searchOptions[0], '');
        }
        if (viewType === 'hosts') {
            getProductsForHostView(searchOptions[0], '');
        }
    }

    // get data from API
    function getProducts(searchOption, searchValue) {
        setProductLoading(true);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let data = {
            product_number: "",
            product_desc: "",
            order_number: "",
            po_number: ""
        }
        data[searchOption['id']] = searchValue.trim()

        axios.post(
            config.view_by_product.PRODUCT_LIST,
            data,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    setEViewTransactionID(data['transaction_id']);
                    setProductsData(data['result']);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Card Data", error);
            })
            .finally(() => {
                setProductLoading(false);
            });
    }

    // get data from API Host view
    function getProductsForHostView(searchOption, searchValue) {
        setProductLoading(true);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let data = {
            transaction_id: eViewTransactionID,
            product_number: "",
            product_desc: "",
            order_number: "",
            po_number: ""
        };

        data[searchOption['id']] = searchValue.trim()

        axios.post(
            config.view_by_product.HOST_LIST,
            data,
            {headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    setProductsData(data);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Card Data", error);
            })
            .finally(() => {
                setProductLoading(false);
            });
    }

    // get excel data for excel export
    function getExcelData() {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let URL = config.view_by_product.EXPORT_REPORT_RECORDS;
        setExcelReportsLoading(true);
        let sort_type = `${viewType.toLocaleUpperCase()}`;

        let data = {
            transaction_id: eViewTransactionID,
            sort_type: sort_type,
            product_number: "",
            product_desc: "",
            order_number: "",
            po_number: ""
        };

        data[searchOption['id']] = searchValue.trim()

        axios.post(
            URL,
            data,
            {headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    var today = new Date(),
                        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                    setExcelFileName(`MY_KSM_${viewType.toUpperCase()}_` + date + '.xlsx');
                    setUserExcelData(data);
                    setExportingStarted(true)
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Card Data", error);
            })
            .finally(() => {
                setExcelReportsLoading(false);
            });
    }

    // fetch excel data and export to XL
    const exportExcel = () => {
        getExcelData();
        setExcelReportsLoading(true);
    };

    // localization labels
    const viewByProductLabel = localization.toLanguageString(myProductsKey, mainMessages[siteLanguageDefault][myProductsKey]);
    const viewByProductAvailableLabel = localization.toLanguageString(availableKey, mainMessages[siteLanguageDefault][availableKey]);
    const viewByProductOptionLabel = localization.toLanguageString(viewByKey, mainMessages[siteLanguageDefault][viewByKey]);
    let viewCertExcelExport = localization.toLanguageString(exportAllExcelKey, mainMessages[siteLanguageDefault][exportAllExcelKey]);

    // product view titles
    const viewByProductProductTitle = localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey]);
    const viewByproductDescTitle = localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey]);
    const viewByProductTermTitle = localization.toLanguageString(termKey, mainMessages[siteLanguageDefault][termKey]);
    const viewByProductLicensTitle = localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey]);

    // host view titles
    const viewByHostLabelTitle = localization.toLanguageString(myHostsKey, mainMessages[siteLanguageDefault][myHostsKey]);
    const viewByHostIdTitle = localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey]);
    const viewByHostQtyTitle = localization.toLanguageString(quantityKey, mainMessages[siteLanguageDefault][quantityKey]);
    const viewByHostCertificateTitle = localization.toLanguageString(certNumberKey, mainMessages[siteLanguageDefault][certNumberKey]);
    const viewByHostLicenseExpTitle = localization.toLanguageString(licenseExpKey, mainMessages[siteLanguageDefault][licenseExpKey]);
    const viewByHostSupportExpTitle = localization.toLanguageString(supportExpKey, mainMessages[siteLanguageDefault][supportExpKey]);
    const viewByHostOrderNumberTitle = localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey]);
    const viewByHostPurchaseOrderTitle = localization.toLanguageString(poNumberKey, mainMessages[siteLanguageDefault][poNumberKey]);

    const [searchOptions, setSearchOptions] = useState([])

    const [searchOption, setSearchOption] = useState(defaultOption)
    const onChangeOptions = (e) => {
        setSearchOption(e.value)
    }
    const [searchValue, setSearchValue] = useState('')
    const onChangeValue = (e) => {
        setSearchValue(e.value)
    }

    return (
        <>
            <PageHeader/>
            {productsLoading && <Spinner/>}
            <div className={"k-page-container"}>
                <Typography.p fontSize={"xlarge"}>
                    <b>{viewType === "products" ? viewByProductLabel : viewByHostLabelTitle}</b>
                    <span className='k-pl-2'>
                    ({productsData.length} {viewByProductAvailableLabel})
                    </span>
                </Typography.p>
                <div style={{
                    display: 'flex',
                    gap: '1rem',
                    flexDirection: 'column'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem'
                        }}>
                            <div>
                                <b>{viewByProductOptionLabel}</b>
                            </div>
                            <div style={{
                                display: 'flex'
                            }}>
                                <RadioGroup
                                    onChange={(e) => {
                                        setUserExcelData([]);
                                        setViewType(e.value);
                                    }}
                                    value={viewType}
                                    data={productViewTypes}
                                    layout={'horizontal'}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem'
                            }}>
                                <div>
                                    <DropDownList
                                        className={'ksm-dropdownlist'}
                                        data={searchOptions}
                                        textField="text"
                                        dataItemKey="id"
                                        value={searchOption}
                                        onChange={onChangeOptions}
                                        style={{
                                            width: '11.3rem'
                                        }}
                                    />
                                </div>
                                <div>
                                    <TextBox
                                        value={searchValue}
                                        onChange={onChangeValue}
                                        suffix={() => (
                                            <>
                                                {searchValue !== '' && (
                                                    <InputClearValue onClick={() => {
                                                        setSearchValue('')
                                                    }}>
                                                        <Icon name="x"/>
                                                    </InputClearValue>
                                                )}
                                            </>
                                        )}
                                        placeholder={localization.toLanguageString(searchForKey, mainMessages[siteLanguageDefault][searchForKey])}
                                        style={{
                                            width: '13rem',
                                            borderColor: '#AAAAAA'
                                        }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        themeColor={"primary"}
                                        size={"large"}
                                        fillMode={"solid"}
                                        rounded={"small"}
                                        onClick={search}
                                    >
                                        {localization.toLanguageString(searchKey, mainMessages[siteLanguageDefault][searchKey])}
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        themeColor={"primary"}
                                        size={"large"}
                                        fillMode={"outline"}
                                        rounded={"small"}
                                        onClick={reset}
                                    >
                                        {localization.toLanguageString(resetKey, mainMessages[siteLanguageDefault][resetKey])}
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <Button
                                    themeColor={"secondary"}
                                    size={"large"}
                                    fillMode={"solid"}
                                    rounded={"small"}
                                    icon={"file-excel"}
                                    onClick={exportExcel}
                                    disabled={productsData.length === 0}
                                >
                                    {viewCertExcelExport}
                                </Button>
                            </div>
                        </div>
                        {excelReportsLoading ? <Spinner/> :
                            <ExcelExport
                                data={userExcelData}
                                fileName={excelFileName}
                                ref={_exporter}
                            >
                                <ExcelExportColumn
                                    field="product_number"
                                    title={viewByProductProductTitle}
                                />
                                <ExcelExportColumn
                                    field="description"
                                    title={viewByproductDescTitle}
                                />
                                <ExcelExportColumn
                                    field="license_term"
                                    title={viewByProductTermTitle}
                                />
                                <ExcelExportColumn
                                    field="license_type"
                                    title={viewByProductLicensTitle}
                                />
                                <ExcelExportColumn
                                    field="host_id"
                                    title={viewByHostIdTitle}
                                />
                                <ExcelExportColumn
                                    field="quantity"
                                    title={viewByHostQtyTitle}
                                />
                                <ExcelExportColumn
                                    field="license_expiration_date"
                                    title={viewByHostLicenseExpTitle}
                                />
                                <ExcelExportColumn
                                    field="support_expiration_date"
                                    title={viewByHostSupportExpTitle}
                                />
                                <ExcelExportColumn
                                    field="order_number"
                                    title={viewByHostOrderNumberTitle}
                                />
                                <ExcelExportColumn
                                    field="o_certificate_id"
                                    title={viewByHostCertificateTitle}
                                />
                                <ExcelExportColumn
                                    field="purchase_order"
                                    title={viewByHostPurchaseOrderTitle}
                                />
                            </ExcelExport>
                        }
                    </div>
                    {
                        !productsLoading && viewType === 'products' &&
                        <ViewByProduct
                            productsData={productsData}
                            eViewTransactionID={eViewTransactionID}
                            searchOption={searchOption}
                            searchValue={searchValue}
                        />
                    }
                    {
                        !productsLoading && viewType === 'hosts' &&
                        <ViewByHost
                            hostsData={productsData}
                            eViewTransactionID={eViewTransactionID}
                            searchOption={searchOption}
                            searchValue={searchValue}
                        />
                    }
                </div>
            </div>
        </>
    );
}