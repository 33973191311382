import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// css
import '../assets/css/AddCertificate.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Alert from '../components/common/Alert.js';
import PageHeader from '../components/common/PageHeader.js';
import FormInput from '../components/AddCertificate/FormInput.js';
import Spinner from '../components/common/Spinner.js';
import {
    CapitalLettersCell,
    DateCell,
    DateIsExpiredCell,
    NoWrapCell,
    TextAlignMiddleCell
} from '../components/common/Grid.js';

// kendo react
import {Grid, GridColumn as Column, GridNoRecords} from '@progress/kendo-react-grid';
import {Field, Form, FormElement} from '@progress/kendo-react-form';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {AnchorWrap, Text} from '../components/common/MultilingualText.js';
import {
    genericErrorTitleKey,
    contactUsHeaderKey,
    addCertKey,
    addNewCertificatesKey,
    certAssociatedKey,
    certRedeemLicenseKey,
    cancelChangesKey,
    orderDetailsKey,
    certNumberKey,
    certScalableKey,
    addCertEggplantFoundMessageKey1,
    addCertEggplantFoundMessageKey2,
    addCertEggplantFoundMessageKey3,
    enterValidCertKey,
    validOrderProvidedKey,
    findCertKey,
    descriptionKey,
    endDateKey,
    productKey,
    qtyPurchasedKey,
    qtyRemainingKey,
    startDateKey,
    termKey,
    noDataAvailableKey,
    certNoEntitlementKey,
    certNotFoundKey,
    confirmOrderKey,
    orderNumberKey,
    mainMessages,
    addCertAccountKey,
    requestLicensesNowKey,
    addCertSuccessKey,
    clickRequestLicenseNowKey,
    entitlementOnHostTitleKey,
    entitlementOnHostMessageKey,
    profileItarTitleKey,
    profileItarMessageKey,
    profileItarMessageKeyTwo,
    profileItarMessageKeyThree,
    profileItarMessageLACKey
} from '../assets/text/MultilingualText.js';


function AddCertificate(props) {
    const {
        accessToken,
        setAccessToken,
        siteLanguageDefault,
        lacFlag,
        setShowRestrictedPopup,
        restrictedFlag,
    } = useContext(UserContext);
    const {
        getMainMenu
    } = props
    const localization = useLocalization();

    const defaultGridMessage = localization.toLanguageString(
        noDataAvailableKey,
        mainMessages[siteLanguageDefault][noDataAvailableKey]
    );

    const [orderNumber, setOrderNumber] = useState('');
    const [certificateNumber, setCertificateNumber] = useState('');
    const [orderID, setOrderID] = useState('');
    const [isValidOrderNumber, setIsValidOrderNumber] = useState(true);
    const [isValidCertificateNumber, setIsValidCertificateNumber] = useState(true);
    const [error, setError] = useState('');
    const [errorHeader, setErrorHeader] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [entitlementsError, setEntitlementsError] = useState(false);
    const [onHoldError, setOnHoldError] = useState(false);
    const [onPartialHoldError, setOnPartialHoldError] = useState(false);
    const [itarProfileError, setItarProfileError] = useState(false);
    const [addCertificateError, setAddCertificateError] = useState(false);
    const [internalServerError, setInternalServerError] = useState(false);
    const [eggplantError, setEggplantError] = useState(false);
    const [products, setProducts] = useState([]);
    const [isFindCertificateDisabled, setIsFindCertificateDisabled] = useState(false);
    const [isCancelChangesVisible, setIsCancelChangesVisible] = useState(false);
    const [isAddCertificateVisible, setIsAddCertificateVisible] = useState(false);
    const [isAddCertificateDisabled, setIsAddCertificateDisabled] = useState(false);
    const [isRequestLicensesVisible, setIsRequestLicensesVisible] = useState(false);
    const [isAddSuccess, setIsAddSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [gridNoRecordsMessage] = useState(defaultGridMessage);
    const [disableRequestLicense, setDisableRequestLicense] = useState(false)

    const validator = value => value ? "" : "";
    const usNavigationUrl = config.US_NAVIGATION_URL;

    const resetPage = () => {
        setOrderNumber('');
        setCertificateNumber('');
        setIsValidOrderNumber(true);
        setIsValidCertificateNumber(true);
        setProducts([]);
        setIsCancelChangesVisible(false);
        setIsAddCertificateVisible(false);
        setIsRequestLicensesVisible(false);
        setIsAddCertificateDisabled(false);
        setIsFindCertificateDisabled(false);
        setEggplantError(false)
        setIsAddSuccess(false);
        setError('');
        setErrorHeader('');
        setErrorMessage('');
        setAddCertificateError(false);
        setOnHoldError(false)
        setItarProfileError(false)
    }

    const getOrderDetails = () => {
        // disable find certificate button
        setIsFindCertificateDisabled(true);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let data = {
            "module": "ORDER",
            "sub_module": "",
            "action": "VALIDATE",
            "input_json": {
                "order_number": orderNumber.trim(),
                "certificate_number": certificateNumber.trim()
            }
        }
        setIsLoading(true)
//        let queryParams = `${orderNumber.trim()}/${certificateNumber.trim()}/`
        axios.post(
            config.add_certificate.ORDER_DETAILS,
            data,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    if (data) {
                        setProducts(data);
                        setIsCancelChangesVisible(true);
                        setIsAddCertificateVisible(true);
                        setIsAddCertificateDisabled(false);
                        setIsFindCertificateDisabled(false);
                        setOrderID(data[0]['ORDER_ID'])
                    } else {
                        setEntitlementsError(true);
                    }
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Entitlement Data", error);
                setIsFindCertificateDisabled(false);
                if (error.response.data['error_code'] === "MOD_CERT_203") {
                    setError('OrderNotFound');
                } else if (error.response.data['error_code'] === "MOD_CERT_204") {
                    setError('AlreadyAssociated');
                } else if (error.response.data['error_code'] === "MOD_CERT_205") {
                    setEntitlementsError(true);
                } else if (error.response.data['error_code'] === "MOD_CERT_207") {
                    setEggplantError(true)
                } else {
                    setInternalServerError(true);
                }
                setIsLoading(false)
            });
    }

    const findCertificate = () => {
        // clear previous error and content
        let errorFlag = false;
        setError('');
        setEggplantError(false)
        setEntitlementsError(false);
        setInternalServerError(false);
        setIsValidOrderNumber(true);
        setIsValidCertificateNumber(true);
        setProducts([]);
        setIsAddSuccess(false);
        setAddCertificateError(false);
        setIsAddCertificateVisible(false);
        setIsCancelChangesVisible(false);
        setIsRequestLicensesVisible(false);
        setOnHoldError(false)
        setItarProfileError(false)
        // check validations
        if (!orderNumber) {
            setIsValidOrderNumber(false);
            setError('InvalidInput');
            errorFlag = true;
        }
        if (!certificateNumber) {
            setIsValidCertificateNumber(false);
            setError('InvalidInput')
            errorFlag = true;
        }
        // if no validation errors, find certificate
        if (!errorFlag) {
            getOrderDetails();
        }
    }

    /*
     * refresh() refreshes the jwt to get itar warning message update
    */
    const refresh = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let data = {}
        if (lacFlag === "Y") {
            data['lac_flag'] = lacFlag
        }
        axios.post(
            config.sso.JWT_REFRESH_URL,
            data,
            {headers: headers, withCredentials: true}
        )
            .then((response) => {
                if (response.status === 200 && response.data.access) {
                    setAccessToken(response.data.access);
                }
            })
            .catch((error) => {
                console.log("ERROR: JWT Refresh for popup update", error);
            });
    }

    const addCertificate = () => {
        // disable add Certificate button and clear any errors
        setIsAddCertificateDisabled(true);
        setAddCertificateError(false);
        setOnHoldError(false)
        setItarProfileError(false)
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let data = {
            "module": "",
            "sub_module": "PROFILE",
            "action": "SAVE",
            "input_json": {
                "order_number": orderNumber.trim(),
                "certificate_number": certificateNumber.trim()
            }
        }
        setIsLoading(true)
        axios.post(
            config.add_certificate.ADD_CERTIFICATE,
            data,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setIsCancelChangesVisible(false);
                    setIsAddCertificateVisible(false);
                    setIsRequestLicensesVisible(true);
                    // Disable Request License Now button if canrequestlicense is "FALSE"
                    if (response?.data?.data?.canrequestlicense === "FALSE") {
                        setDisableRequestLicense(true)
                    } else {
                        setDisableRequestLicense(false)
                    }
                    if (response?.data?.status_code === "MOD_ERROR_200"){
                        if (response?.data?.code === "MOD_CERT_210") {
                                setItarProfileError(true);
                                refresh();
                            }
                        else if (response?.data?.code === "MOD_CERT_211") {
                                setOnHoldError(true);
                                refresh();
                        }
                    }
                    else {
                        setIsAddSuccess(true);
                    }
                    getMainMenu(accessToken, false)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                setAddCertificateError(true);
                setIsAddCertificateVisible(true);
                setIsAddCertificateDisabled(false);
                setIsRequestLicensesVisible(false)
                setIsLoading(false)
            });
    }

    // when theres an error, get translated error Header and Message texts
    useEffect(() => {
        let errorHeader;
        let errorMessage;
        if (error === "InvalidInput") {
            errorHeader = localization.toLanguageString(
                enterValidCertKey,
                mainMessages[siteLanguageDefault][enterValidCertKey]
            );
            errorMessage = localization.toLanguageString(
                validOrderProvidedKey,
                mainMessages[siteLanguageDefault][validOrderProvidedKey]
            );
        } else if (error === "OrderNotFound") {
            errorHeader = localization.toLanguageString(
                certNotFoundKey,
                mainMessages[siteLanguageDefault][certNotFoundKey]
            );
            errorMessage = localization.toLanguageString(
                confirmOrderKey,
                mainMessages[siteLanguageDefault][confirmOrderKey]
            );
        } else if (error === "AlreadyAssociated") {
            errorHeader = localization.toLanguageString(
                certAssociatedKey,
                mainMessages[siteLanguageDefault][certAssociatedKey]
            );
            errorMessage = localization.toLanguageString(
                certRedeemLicenseKey,
                mainMessages[siteLanguageDefault][certRedeemLicenseKey]
            );
        }
        setErrorHeader(errorHeader);
        setErrorMessage(errorMessage);
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    // at any point if order/certificate numbers change, remove table and buttons
    useEffect(() => {
        setProducts([]);
        setIsAddSuccess(false);
        setIsRequestLicensesVisible(false);
        setError('');
        setEntitlementsError(false);
        setEggplantError(false)
        setInternalServerError(false);
    }, [orderNumber, certificateNumber]);

    let history = useHistory();

    let certificateAlertStyle = {
        'minWidth': '30rem',
        'maxWidth': 'max-content',
    }

    // Adobe Analytics
    useEffect(() => {
        window.adobeDataLayer.push({
            "page": {
                "pageName": "Add Certificate"
            }
        })
    }, [])

    const AnchorText = AnchorWrap(Text);

    // when user accepts itar t&c and is no longer restricted, check if we can enable request license now button
    useEffect(() => {
        let enableButton = false
        if (!restrictedFlag) {
            // remove itar profile alert
            setItarProfileError(false);
            products.forEach((product) => {
                let endDate = new Date(product?.END_DATE)
                let currentDate = new Date()
                if (product?.ENTITLMENT_TYPE === "LICENSE" && Number(product?.QTY_REMAINING) > 0 && ((product?.END_DATE == null) || (currentDate < endDate))) {
                    enableButton = true
                }
            })
        }
        if (enableButton) setDisableRequestLicense(false);
    }, [restrictedFlag])

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <div
                    className={"k-h2"}
                >
                    <Text
                        textkey={addNewCertificatesKey}
                        textdefault={mainMessages[siteLanguageDefault][addNewCertificatesKey]}
                    />
                </div>
                <div
                    className={"k-h4"}
                >
                    <Text
                        textkey={addCertAccountKey}
                        textdefault={mainMessages[siteLanguageDefault][addCertAccountKey]}
                    />
                </div>
                <div
                    style={{
                        "marginBottom": "1.25em"
                    }}
                >
                    <Form
                        render={() => (
                            <FormElement>
                                <Field
                                    id={"order-number"}
                                    name={"order-number"}
                                    label={
                                        <Text
                                            textkey={orderNumberKey}
                                            textdefault={mainMessages[siteLanguageDefault][orderNumberKey]}
                                        />
                                    }
                                    component={FormInput}
                                    required={true}
                                    validator={validator}
                                    isValid={isValidOrderNumber}
                                    dataValue={orderNumber}
                                    onChange={(e) => setOrderNumber(e.value)}
                                    className={"add-cert-field"}
                                />
                                <Field
                                    id={"certificate-number"}
                                    name={"certificate-number"}
                                    label={
                                        <Text
                                            textkey={certNumberKey}
                                            textdefault={mainMessages[siteLanguageDefault][certNumberKey]}
                                        />
                                    }
                                    component={FormInput}
                                    required={true}
                                    validator={validator}
                                    isValid={isValidCertificateNumber}
                                    dataValue={certificateNumber}
                                    onChange={(e) => setCertificateNumber(e.value)}
                                    className={"add-cert-field"}
                                />
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    shape={"rectangle"}
                                    fillMode={"solid"}
                                    rounded={"small"}
                                    type={"button"}
                                    name="Find Certificate"
                                    onClick={findCertificate}
                                    disabled={isFindCertificateDisabled}
                                    className={"add-cert-field"}
                                    style={{
                                        "marginTop": ".9em",
                                        "marginBottom": ".9em"
                                    }}
                                >
                                    <Text
                                        textkey={findCertKey}
                                        textdefault={mainMessages[siteLanguageDefault][findCertKey]}
                                    />
                                </Button>
                                {error && (
                                    <div className={"add-cert-error"}>
                                        <Alert
                                            type={'error'}
                                            title={errorHeader}
                                            message={errorMessage}
                                        />
                                    </div>
                                )}
                                {entitlementsError && (
                                    <div className={"add-cert-error"}>
                                        <Alert
                                            type={'error'}
                                            title={
                                                <Text
                                                    textkey={certNoEntitlementKey}
                                                    textdefault={mainMessages[siteLanguageDefault][certNoEntitlementKey]}
                                                />
                                            }
                                            message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                                        />
                                    </div>
                                )}
                                {eggplantError && (
                                    <div className={"add-cert-error"}>
                                        <Alert
                                            type={'error'}
                                            title={localization.toLanguageString(certScalableKey, mainMessages[siteLanguageDefault][certScalableKey])}
                                            message={
                                                <div>
                                                    {localization.toLanguageString(addCertEggplantFoundMessageKey1, mainMessages[siteLanguageDefault][addCertEggplantFoundMessageKey1])}
                                                    <a href={usNavigationUrl}
                                                       target="_blank"
                                                       rel="noreferrer"
                                                    >
                                                        {localization.toLanguageString(addCertEggplantFoundMessageKey2, mainMessages[siteLanguageDefault][addCertEggplantFoundMessageKey2])}
                                                    </a>
                                                    {localization.toLanguageString(addCertEggplantFoundMessageKey3, mainMessages[siteLanguageDefault][addCertEggplantFoundMessageKey3])}
                                                </div>}
                                        />
                                    </div>
                                )}
                                {internalServerError && (
                                    <div className={"add-cert-error"}>
                                        <Alert
                                            type={'error'}
                                            title={
                                                <Text
                                                    textkey={genericErrorTitleKey}
                                                    textdefault={mainMessages[siteLanguageDefault][genericErrorTitleKey]}
                                                />
                                            }
                                            message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                                        />
                                    </div>
                                )}
                            </FormElement>
                        )}
                    />
                </div>
                {products.length > 0 && (
                    <div>
                        <div
                            className={"k-h4"}
                        >
                            <Text
                                textkey={orderDetailsKey}
                                textdefault={mainMessages[siteLanguageDefault][orderDetailsKey]}
                            />
                        </div>
                        <div
                            style={{
                                "marginBottom": "1rem"
                            }}
                        >
                            <Grid
                                data={products}
                                scrollable={"none"}
                                className={"add-cert-grid"}
                            >
                                <GridNoRecords>
                                    {gridNoRecordsMessage}
                                </GridNoRecords>
                                <Column
                                    field="PROD_NUM"
                                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                                    cell={NoWrapCell}
                                />
                                <Column
                                    field="DESCRIPTION"
                                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                />
                                <Column
                                    field="TERM"
                                    title={localization.toLanguageString(termKey, mainMessages[siteLanguageDefault][termKey])}
                                    cell={CapitalLettersCell}
                                />
                                <Column
                                    field="START_DATE"
                                    title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                                    cell={DateCell}
                                    width={"190px"}
                                />
                                <Column
                                    field="END_DATE"
                                    title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                                    cell={DateIsExpiredCell}
                                    width={"190px"}
                                />
                                <Column
                                    field="QTY_ORDERED"
                                    title={localization.toLanguageString(qtyPurchasedKey, mainMessages[siteLanguageDefault][qtyPurchasedKey])}
                                    cell={TextAlignMiddleCell}
                                />
                                <Column
                                    field="QTY_REMAINING"
                                    title={localization.toLanguageString(qtyRemainingKey, mainMessages[siteLanguageDefault][qtyRemainingKey])}
                                    cell={TextAlignMiddleCell}
                                />
                            </Grid>
                        </div>
                        <Row>
                            <Col>
                                <div
                                    style={{
                                        "marginBottom": "1em"
                                    }}
                                >
                                    {isCancelChangesVisible && (
                                        <Button
                                            themeColor={"primary"}
                                            size={"large"}
                                            shape={"rectangle"}
                                            fillMode={"outline"}
                                            rounded={"small"}
                                            type={"button"}
                                            name="cancel-changes"
                                            onClick={resetPage}
                                            style={{
                                                "marginRight": "1rem"
                                            }}
                                        >
                                            <Text
                                                textkey={cancelChangesKey}
                                                textdefault={mainMessages[siteLanguageDefault][cancelChangesKey]}
                                            />
                                        </Button>
                                    )}
                                    {isAddCertificateVisible && (
                                        <Button
                                            themeColor={"primary"}
                                            size={"large"}
                                            shape={"rectangle"}
                                            fillMode={"solid"}
                                            rounded={"small"}
                                            type={"button"}
                                            name="add-certificate"
                                            disabled={isAddCertificateDisabled}
                                            onClick={addCertificate}
                                        >
                                            <Text
                                                textkey={addCertKey}
                                                textdefault={mainMessages[siteLanguageDefault][addCertKey]}
                                            />
                                        </Button>
                                    )}
                                </div>


                                {isRequestLicensesVisible && (
                                    <div>
                                        <Button
                                            themeColor={"primary"}
                                            size={"large"}
                                            shape={"rectangle"}
                                            fillMode={"solid"}
                                            rounded={"small"}
                                            type={"button"}
                                            name="request-licenses"
                                            disabled={disableRequestLicense}
                                            onClick={() => {
                                                history.push('/request-license?orderID=' + orderID)
                                            }}
                                        >
                                            <Text
                                                textkey={requestLicensesNowKey}
                                                textdefault={mainMessages[siteLanguageDefault][requestLicensesNowKey]}
                                            />
                                        </Button>
                                    </div>
                                )}
                            </Col>
                            <Col>
                                {isAddSuccess && (
                                    <div
                                        style={certificateAlertStyle}
                                    >
                                        <Alert
                                            type={'success'}
                                            title={
                                                <Text
                                                    textkey={addCertSuccessKey}
                                                    textdefault={mainMessages[siteLanguageDefault][addCertSuccessKey]}
                                                />
                                            }
                                            message={
                                                <Text
                                                    textkey={clickRequestLicenseNowKey}
                                                    textdefault={mainMessages[siteLanguageDefault][clickRequestLicenseNowKey]}
                                                />
                                            }
                                        />
                                    </div>
                                )}
                                {addCertificateError && (
                                    <div
                                        style={certificateAlertStyle}
                                    >
                                        <Alert
                                            type={'error'}
                                            showHandler={resetPage}
                                            title={
                                                <Text
                                                    textkey={genericErrorTitleKey}
                                                    textdefault={mainMessages[siteLanguageDefault][genericErrorTitleKey]}
                                                />
                                            }
                                            message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                                        />
                                    </div>
                                )}
                                {itarProfileError && (
                                    <div style={certificateAlertStyle}>
                                        <Alert
                                            type={'warning'}
                                            title={
                                                <Text
                                                    textkey={profileItarTitleKey}
                                                    textdefault={mainMessages[siteLanguageDefault][profileItarTitleKey]}
                                                />
                                            }
                                            message={
                                                lacFlag === "Y" ? (
                                                <div>
                                                    <Text
                                                        textkey={profileItarMessageLACKey}
                                                        textdefault={mainMessages[siteLanguageDefault][profileItarMessageLACKey]}
                                                    />
                                                </div>
                                                ) : (
                                                    <div>
                                                        <Text
                                                            textkey={profileItarMessageKey}
                                                            textdefault={mainMessages[siteLanguageDefault][profileItarMessageKey]}
                                                        />
                                                        <AnchorText
                                                            style={{
                                                                textUnderlineOffset: '0.188rem',
                                                                textDecoration: 'underline'
                                                            }}
                                                            onClick={() => {
                                                                setShowRestrictedPopup(true);
                                                            }}
                                                            textkey={profileItarMessageKeyTwo}
                                                            textdefault={mainMessages[siteLanguageDefault][profileItarMessageKeyTwo]}
                                                        />
                                                        <Text
                                                            textkey={profileItarMessageKeyThree}
                                                            textdefault={mainMessages[siteLanguageDefault][profileItarMessageKeyThree]}
                                                        />
                                                    </div>
                                                )
                                            }
                                        />
                                    </div>
                                )}

                                {onHoldError && (
                                    <div style={certificateAlertStyle}>
                                        <Alert
                                            type={'warning'}
                                            title={
                                                <Text
                                                    textkey={entitlementOnHostTitleKey}
                                                    textdefault={mainMessages[siteLanguageDefault][entitlementOnHostTitleKey]}
                                                />
                                            }
                                            message={
                                                <Text
                                                    textkey={entitlementOnHostMessageKey}
                                                    textdefault={mainMessages[siteLanguageDefault][entitlementOnHostMessageKey]}
                                                />
                                            }
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col/>
                        </Row>
                    </div>
                )}
            </div>
        </>
    );
};

export default AddCertificate;

