import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMViewCertificates from '../assets/img/Help/ksm-view-certificates.png';

// multilingual
import {Text} from '../components/common/MultilingualText.js';
import {useLocalization} from '@progress/kendo-react-intl';
import {
    addingCertificatesKey,
    helpViewCertificateAddingCertificatesQAKey,
    entitlementCertificatesKey,
    helpViewCertificateEntitlementCertificatesQAKey,
    frequentlyAskedQuestionsKey,
    ksmViewCertKey,
    helpViewCertificateLoginRegistrationQAKey,
    mainMessages,
    obtainingLicensesKey,
    helpViewCertificateObtainingLicensesKey,
    helpViewCertificateQuickStepsSectionKey,
    quickStepsObtainingLicensesKey,
    registrationLoginKey,
    helpViewCertificateViewingCertificateSectionKey,
    viewingCertKey,
    homePageKey,
    helpViewCertificateWelcomeQAKey
} from '../assets/text/MultilingualText.js';


function ViewCertificateHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const helpMenuContent = {
        'header': {
            key: ksmViewCertKey,
            default: mainMessages[siteLanguageDefault][ksmViewCertKey]
        },
        'topics': [
            {
                key: viewingCertKey,
                default: mainMessages[siteLanguageDefault][viewingCertKey],
                hash: "#viewing-certificate"
            },
            {
                key: quickStepsObtainingLicensesKey,
                default: mainMessages[siteLanguageDefault][quickStepsObtainingLicensesKey],
                hash: "#quick-steps"
            }
        ],
        'faq': [
            {
                key: registrationLoginKey,
                default: mainMessages[siteLanguageDefault][registrationLoginKey],
                hash: "#registration-login-faq"
            },
            {
                key: homePageKey,
                default: mainMessages[siteLanguageDefault][homePageKey],
                hash: "#welcome-faq"
            },
            {
                key: entitlementCertificatesKey,
                default: mainMessages[siteLanguageDefault][entitlementCertificatesKey],
                hash: "#entitlement-certificates-faq"
            },
            {
                key: addingCertificatesKey,
                default: mainMessages[siteLanguageDefault][addingCertificatesKey],
                hash: "#add-certificates-faq"
            },
            {
                key: obtainingLicensesKey,
                default: mainMessages[siteLanguageDefault][obtainingLicensesKey],
                hash: "#obtain-licences-faq"
            }
        ]
    }

    const helpViewingCertificateContent = localization.toLanguageString(
        helpViewCertificateViewingCertificateSectionKey,
        mainMessages[siteLanguageDefault][helpViewCertificateViewingCertificateSectionKey]
    );

    const helpQuickStepsContent = localization.toLanguageString(
        helpViewCertificateQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpViewCertificateQuickStepsSectionKey]
    );

    const helpRegistrationLoginQA = localization.toLanguageString(
        helpViewCertificateLoginRegistrationQAKey,
        mainMessages[siteLanguageDefault][helpViewCertificateLoginRegistrationQAKey]
    );

    const helpWelcomeQA = localization.toLanguageString(
        helpViewCertificateWelcomeQAKey,
        mainMessages[siteLanguageDefault][helpViewCertificateWelcomeQAKey]
    );

    const helpEntitlementCertificatesQA = localization.toLanguageString(
        helpViewCertificateEntitlementCertificatesQAKey,
        mainMessages[siteLanguageDefault][helpViewCertificateEntitlementCertificatesQAKey]
    );

    const helpAddingCertificatesQA = localization.toLanguageString(
        helpViewCertificateAddingCertificatesQAKey,
        mainMessages[siteLanguageDefault][helpViewCertificateAddingCertificatesQAKey]
    );

    const helpObtainingLicensesQA = localization.toLanguageString(
        helpViewCertificateObtainingLicensesKey,
        mainMessages[siteLanguageDefault][helpViewCertificateObtainingLicensesKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="viewing-certificate"
                        >
                            <Text
                                textkey={viewingCertKey}
                                textdefault={mainMessages[siteLanguageDefault][viewingCertKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpViewingCertificateContent.section_one}
                            </p>
                            {toHtml(helpViewingCertificateContent.section_two)}
                            {helpViewingCertificateContent.section_two_bullets ? <ul>
                                {helpViewingCertificateContent.section_two_bullets.map((bullet, index) => <li
                                    key={'ksm-overview-bullet-' + index}>{toHtml(bullet)}</li>)}
                            </ul> : <></>}
                            <img src={KSMViewCertificates} alt="ksm-view-certificates"/>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsObtainingLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsObtainingLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpQuickStepsContent.section_one}
                            </p>
                            {helpQuickStepsContent.section_one_numbers ? <ol>
                                {Object.values(helpQuickStepsContent.section_one_numbers).map((number, index) => <li
                                    key={'ksm-overview-number-' + index}>{toHtml(number)}</li>)}
                            </ol> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="registration-login-faq">
                            <h3>
                                <Text
                                    textkey={registrationLoginKey}
                                    textdefault={mainMessages[siteLanguageDefault][registrationLoginKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {helpRegistrationLoginQA ? helpRegistrationLoginQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"help-registration-login-qa-" + index}>
                                            <li>
                                                {questionAnswer.Q}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="welcome-faq">
                            <h3>
                                <Text
                                    textkey={homePageKey}
                                    textdefault={mainMessages[siteLanguageDefault][homePageKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {helpWelcomeQA ? helpWelcomeQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"help-welcome-qa-" + index}>
                                            <li>
                                                {questionAnswer.Q}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="entitlement-certificates-faq">
                            <h3>
                                <Text
                                    textkey={entitlementCertificatesKey}
                                    textdefault={mainMessages[siteLanguageDefault][entitlementCertificatesKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {helpEntitlementCertificatesQA ? helpEntitlementCertificatesQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"help-entitlement-certificates-qa-" + index}>
                                            <li>
                                                {questionAnswer.Q}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="add-certificates-faq">
                            <h3>
                                <Text
                                    textkey={addingCertificatesKey}
                                    textdefault={mainMessages[siteLanguageDefault][addingCertificatesKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {helpAddingCertificatesQA ? helpAddingCertificatesQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"help-add-certificates-qa-" + index}>
                                            <li>
                                                {questionAnswer.Q}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div
                            id="obtain-licences-faq"
                            style={{
                                marginBottom: '25vh'
                            }}
                        >
                            <h3>
                                <Text
                                    textkey={obtainingLicensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][obtainingLicensesKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {helpObtainingLicensesQA ? helpObtainingLicensesQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"help-obtain-licenses-qa-" + index}>
                                            <li>
                                                {questionAnswer.Q}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ViewCertificateHelp;

