import React, {useContext, useRef, useState, useEffect} from 'react';
import uuid from 'react-uuid';
import {cloneDeep} from 'lodash';
import axios from 'axios';
import config from '../../../config.js';
import UserContext from '../../common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';
import Alert from '../../common/Alert.js';
import {toHtml} from '../../common/utilities.js';

// kendo react
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Label} from '@progress/kendo-react-labels';
import {Input, TextArea} from '@progress/kendo-react-inputs';
import {AutoComplete} from '@progress/kendo-react-dropdowns';
import {Upload} from '@progress/kendo-react-upload';
import {Popover} from '@progress/kendo-react-tooltip';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {AnchorWrap, Text} from '../../common/MultilingualText.js';
import {
    assignProductDuplicateHostIDMessageErrorKey,
    duplicateHostKey,
    assignProductDuplicateHostUniqueMessageErrorKey,
    invalidFileKey,
    assignProductsGenericMessageIssueErrorKey,
    assignProductsGenericMessagePleaseErrorKey,
    genericErrorTitleKey,
    hostIdFormattingKey,
    invalidHostKey,
    cancelChangesKey,
    descriptionKey,
    productNumberKey,
    saveHostIDKey,
    assignHostKey,
    invalidHostColonKey,
    onePerHostMessageKey,
    assignProductsOnePerHostTitleLimitErrorKey,
    assignProductsOnePerHostTitleProductErrorKey,
    mainMessages,
    serialNumberFormattingKey,
    invalidSerialNumberKey,
    specificHostFileKey,
    contactUsKey,
    aliasKey,
    assetInfoKey,
    cityKey,
    countryKey,
    hostMetaDataKey,
    notesKey,
    stateKey,
    assignQtyKey,
    aliasUniqueKey,
    duplicateAliasKey,
    quantityConsumedSessionKey,
    incorrectNumberImeiKey,
    imeiAssignedQuantityKey,
} from '../../../assets/text/MultilingualText.js';


function AssignProductsModal(props) {
    const {
        isLoading,
        setIsLoading,
        products,
        setProducts,
        assignedProducts,
        setAssignedProducts,
        searchProducts,
        setSearchProducts,
        hostIDType,
        setHostIDType,
        hostModal,
        setHostModal,
        hostIsSerial,
        setHostIsSerial,
        hostIDLabel,
        setHostIDLabel,
        hostIDHint,
        setHostIDHint,
        hostIDError,
        hostIDPatterns,
        setHostIDPatterns,
        hostIDSuggestions,
        serialNumberLabel,
        setSerialNumberLabel,
        serialNumberHint,
        setSerialNumberHint,
        serialNumberError,
        serialNumberPatterns,
        setSerialNumberPatterns,
        isVisible,
        setIsVisible,
        assignedProductsRef,
        setSelectHeaderCheck,
        currentType,
    } = props;
    const {
        accessToken,
        siteLanguageDefault,
        timeout
    } = useContext(UserContext);
    const localization = useLocalization();

    const [hostID, setHostID] = useState("");
    const hostIDAnchor = useRef(null);

    const [serialNumber, setSerialNumber] = useState("");
    const serialNumberAnchor = useRef(null);

    const [multiHost, setMultiHost] = useState("");

    const [hostFile, setHostFile] = useState("");
    const [hostFileExtension, setHostFileExtension] = useState("");

    const [alias, setAlias] = useState("");
    const [assetInformation, setAssetInformation] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [notes, setNotes] = useState("");

    const [errors, setErrors] = useState([]);
    const [isValid, setIsValid] = useState({
        hostID: true,
        serialNumber: true,
        multiHost: true,
    });

    const titleStyle = {
        fontWeight: "normal",
        margin: 0,
        color: "#424242"
    };

    /*
     * validateDuplicates() validates if the hosts inputted are not duplicates
     * @return true if no duplicates are inputted for multi host
    */
    const validateDuplicates = () => {
        // case: validate multi hosts are unique
        let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];
        multiHostData.forEach((host, idx) => {
            return multiHostData[idx] = host?.trim() || ''
        })
        let duplicateHosts = findDuplicates(multiHostData);
        let duplicates = [...new Set(duplicateHosts)];

        if (duplicateHosts.length) {
            // show error for attempting to assign duplicate hosts
            let duplicateHostTitle = localization.toLanguageString(
                duplicateHostKey,
                mainMessages[siteLanguageDefault][duplicateHostKey]
            );
            let duplicateHostIDMessage = localization.toLanguageString(
                assignProductDuplicateHostIDMessageErrorKey,
                mainMessages[siteLanguageDefault][assignProductDuplicateHostIDMessageErrorKey]
            );
            let duplicateHostUniqueMessage = localization.toLanguageString(
                assignProductDuplicateHostUniqueMessageErrorKey,
                mainMessages[siteLanguageDefault][assignProductDuplicateHostUniqueMessageErrorKey]
            );
            let message = duplicates.map(host =>
                <div key={'duplicate-host-error-' + host}>
                    {duplicateHostIDMessage} {host} {duplicateHostUniqueMessage}
                    <br/>
                </div>
            );

            let duplicateHostError = {
                field: "duplicateHost",
                title: duplicateHostTitle,
                message: message
            };
            showError(duplicateHostError);
        }

        return !duplicateHosts.length;
    }

    /*
     * validateDistribution() validates if the assign quantity can be equally distributed across the number of inpuuted hosts
     * @param {selected} a deep copy of selectedProducts
     * @return true if the assign quantity can be equally distributed across the number of inputted hosts
    */
    const validateDistribution = (selected) => {
        // case: validate multi hosts quantities are distributable
        let isDistributable = false;
        let qtyErrors = [];
        let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];
        multiHostData.forEach((host, idx) => {
            return multiHostData[idx] = host?.trim() || ''
        })
        let hostCount = multiHostData.length;

        selected
            .forEach((selectedProduct) => {
                let qty = parseInt(selectedProduct.qty_available);
                let assignQty = parseInt(selectedProduct.qty_requested);
                let distribution = assignQty * hostCount;

                // check if there is enough qty to be distributed
                // and the product's qty will be evenly distributed across each host
                qty - distribution >= 0 && distribution % hostCount === 0 ? isDistributable = true
                    : qtyErrors.push({
                        "product": selectedProduct.prod_num_display
                    })
            });

        if (!isDistributable && qtyErrors.length) {
            // show error for attempting to assign invalid quantities among hosts
            let qtyDistributionTitle = localization.toLanguageString(
                incorrectNumberImeiKey,
                mainMessages[siteLanguageDefault][incorrectNumberImeiKey]
            );
            let qtyDistributionMessage = localization.toLanguageString(
                imeiAssignedQuantityKey,
                mainMessages[siteLanguageDefault][imeiAssignedQuantityKey]
            );

            let qtyDistributionError = {
                field: "qtyDistribution",
                title: qtyDistributionTitle,
                message: qtyDistributionMessage
            }
            showError(qtyDistributionError);
        }

        return (isDistributable && !qtyErrors.length);
    }

    /*
     * validateMultiHost() validates the user multiple hosts or imei's input on the frontend prior to validating the
     * @param {selected} a deep copy of selectedProducts
     * host id || serial number input on the backend
    */
    const validateMultiHost = (selected) => {
        let invalidMultiHosts = [];
        let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];

        multiHostData.forEach((host) => {
            // isValidHost is true if the host matches all regex pattern in hostIDPatterns
            let isValidHost = hostIDPatterns.some(regex => regex.test(host?.trim() || ''));
            if (!isValidHost) invalidMultiHosts.push(host?.trim() || '');
        });

        // validate if the multi host input is not empty and there are no invalidMultiHosts
        if (multiHostData.length && !invalidMultiHosts.length) {
            // validate duplicate multi host input
            if (validateDuplicates()) {
                // validate assign quantity distribution across multi host input
                if (validateDistribution(selected)) {
                    save();
                }
            }
        } else {
            let message;
            let invalidMultiHostSubmessage = invalidMultiHosts.length ? localization.toLanguageString(
                    invalidHostColonKey,
                    mainMessages[siteLanguageDefault][invalidHostColonKey])
                : "";
            message = <>
                {hostIDError} <br/>
                {invalidMultiHostSubmessage} {invalidMultiHosts.length ? invalidMultiHosts.join(', ') : ""}
            </>

            let multiHostError = {
                field: "multi",
                message: message
            };
            showError(multiHostError);
        }
    }

    /*
     * validateFile() validates modal type and file extension are the same
    */
    const validateFile = () => {
        if ((hostModal === "C2V" && hostFileExtension === ".c2v") ||
            (hostModal === "HOST_FILE_BIN" && hostFileExtension === ".bin")) {
            save();
        } else {
            let fileError = {
                field: "file",
                message: hostIDError
            };
            showError(fileError);
        }
    }

    /*
     * validateOnePerHost() validates if the one per host product is not already assigned to host
     * @param {selected} a deep copy of selectedProducts
     * @return true if product is not already assigned to host
    */
    const validateOnePerHost = (selected) => {
        // case: validate one per host product is not already assigned to host
        let unassignable = [];
        let hostIDTrim = hostID?.trim() || '';
        let host = assignedProducts.find(host =>
            host.host_id &&
            [hostIDTrim] &&
            equals(host.host_id, [hostIDTrim])
        )
        if (host) {
            selected
                .forEach(selectedProduct => {
                    let product = host.products.find(product => product.line_id === selectedProduct.line_id);
                    if (selectedProduct.redeem_one_per_host.toUpperCase() === "Y" && product) {
                        unassignable.push(product.product_number);
                    }
                });
        }

        if (unassignable.length) {
            unassignable.forEach((product) => {
                // show error for attempting to assign a product that is one per host
                let onePerHostTitleProduct = localization.toLanguageString(
                    assignProductsOnePerHostTitleProductErrorKey,
                    mainMessages[siteLanguageDefault][assignProductsOnePerHostTitleProductErrorKey]
                );
                let onePerHostTitleLimit = localization.toLanguageString(
                    assignProductsOnePerHostTitleLimitErrorKey,
                    mainMessages[siteLanguageDefault][assignProductsOnePerHostTitleLimitErrorKey]
                );
                let title = onePerHostTitleProduct + product + onePerHostTitleLimit;
                let message = localization.toLanguageString(
                    onePerHostMessageKey,
                    mainMessages[siteLanguageDefault][onePerHostMessageKey]
                );

                let onePerHostError = {
                    field: "onePerHost",
                    title: title,
                    message: message
                };
                showError(onePerHostError);
            })
        }

        return !unassignable.length;
    }

    /*
     * validateHost() validates the user host id || serial number input on the frontend prior to validating the
     * host id || serial number input on the backend
     * @param {selected} a deep copy of selectedProducts
    */
    const validateHost = (selected) => {
        let isValidHostID = !hostID ? false : hostIDPatterns.some(regex => regex.test(hostID?.trim() || ''));
        let isValidSerialNumber = (!serialNumber && hostIsSerial === "Y") ? false : serialNumberPatterns.some(regex => regex.test(serialNumber?.trim() || ''));

        // show errors for invalid host ids and serial numbers
        if (!isValidHostID) {
            let hostError = {
                field: "hostID",
                message: hostIDError
            };
            showError(hostError);
        }
        if (!isValidSerialNumber && (hostIsSerial === "Y" || hostIsSerial === "O")) {
            let serialError = {
                field: "serialNumber",
                message: serialNumberError
            };
            showError(serialError);
        }

        // call regex validation api for the following cases
        if (isValidHostID && isValidSerialNumber && (hostIsSerial === "Y" || hostIsSerial === "O")) {
            if (validateOnePerHost(selected)) {
                save();
            }
        }
        if (isValidHostID && hostIsSerial === "N") {
            if (validateOnePerHost(selected)) {
                save();
            }
        }
    };

    /*
     * validate() validates the assigned host
    */
    const validate = () => {
        // reset errors
        removeErrors();

        let selected = cloneDeep(products.filter((product) => product.selected));

        if (hostModal === "HOST") {
            validateHost(selected);
        } else if (hostModal === "MULTI_HOST") {
            validateMultiHost(selected);
        } else if (hostModal === "C2V") {
            validateFile();
        } else if (hostModal === "HOST_FILE_BIN") {
            validateFile();
        } else if (hostModal === "PARTIAL") {
            save();
        }
    }

    /*
     * resetAssignHost() resets all assign host modal fields
    */
    const resetAssignHost = () => {
        setHostModal("");
        setHostIsSerial("");
        setHostID("");
        setHostIDLabel("");
        setHostIDHint("");
        setHostIDPatterns([]);
        setSerialNumber("");
        setSerialNumberLabel("");
        setSerialNumberHint("");
        setSerialNumberPatterns([]);
        setMultiHost("");
        setHostFile({});
        setAlias("");
        setAssetInformation("");
        setCity("");
        setState("");
        setCountry("");
        setNotes("");
        setErrors([]);
    }

    /*
     * closeAssignHost() closes the assign host modal and reset assign host modal fields
    */
    const closeAssignHost = () => {
        resetAssignHost();
        setIsVisible(isVisible => ({...isVisible, modal: false}));

        // scroll to assigned products
        if (assignedProductsRef.current) assignedProductsRef.current.scrollIntoView({behavior: 'smooth'});
    };

    /*
     * onHostFileUpload() uploads the host file
    */
    const onHostFileUpload = (event) => {
        setHostFileExtension(event.target.files[0].extension);
        setHostFile(event.target.files[0].getRawFile());
    };

    /*
     * showError() appends to errors object and redlines field
     * @param {error} the error object containing the attributes for the Alert dialog and the field to redline
     *  {
     *      field: hostID || serialNumber || multi || file || database || onePerHost || duplicateHost || qtyDistribution
     *      title: error's title (note: only applicable for error.field onePerHost || duplicateHost || qtyDistribution)
     *      message: error's message
     *  }
    */
    const showError = (error) => {
        let genericMessagePlease = localization.toLanguageString(
            assignProductsGenericMessagePleaseErrorKey,
            mainMessages[siteLanguageDefault][assignProductsGenericMessagePleaseErrorKey]
        );
        let genericMessageIssue = localization.toLanguageString(
            assignProductsGenericMessageIssueErrorKey,
            mainMessages[siteLanguageDefault][assignProductsGenericMessageIssueErrorKey]
        );

        let generic = {
            field: "generic",
            message:
                <div>{genericMessagePlease} {contactUs} {genericMessageIssue}</div>
        };

        error = error.field === "database" ? generic : error;

        // if all values in the error object are not null, render the error
        if (!Object.values(error).every(e => !e)) {
            setErrors(errors => [...errors, error]);
            setIsValid(isValid => ({...isValid, [error.field]: false}));
        } else {
            setErrors(errors => [...errors, generic]);
        }
    }

    /*
     * removeErrors() removes all errors and redlines for all fields
    */
    const removeErrors = () => {
        setErrors([]);
        setIsValid({
            hostID: true,
            serialNumber: true,
            multiHost: true,
        });
    };

    // equals compares two js arrays to see if they are equal
    const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

    // finds duplicates in array
    const findDuplicates = arr => arr.filter((e, i) => arr.indexOf(e) !== i);

    /*
     * save() saves product(s) to host(s)
    */
    const save = () => {
        let hostData = [];
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        // send correct host id or multiple hosts data
        hostModal === "HOST" ? hostData = [hostID]
            : hostModal === "MULTI_HOST" && multiHost.length ? hostData = multiHost.split('\n').filter(host => host)
                : hostData = []

        hostData.forEach((host, idx) => {
            return hostData[idx] = host?.trim() || '';
        })


        let inputJSON = {
            "host_id_type": hostIDType?.trim() || '',
            "host_val": hostData,
            "serial_number": serialNumber?.trim() || '',
            "alias": alias?.trim() || '',
            "asset_info": assetInformation?.trim() || '',
            "city": city?.trim() || '',
            "state": state?.trim() || '',
            "country": country?.trim() || '',
            "notes": notes?.trim() || '',
            "source": "SWA",
        }

        let data = {
            module: "SWA",
            sub_module: "hosts",
            action: "SAVE",
            input_json: inputJSON
        }

        let formData = new FormData();
        formData.append('File', hostFile);
        formData.append('Data', JSON.stringify(data));

        setIsLoading(true);

        axios.post(
            config.ea_request_license.HOST,
            formData,
            {headers: headers, timeout: timeout}
        )
            .then((response) => {
                if (response.status === 200) {
                    let hostInfo = response.data
                    saveHost(hostInfo);
                }
                setIsLoading(false);
                // close modal
                closeAssignHost();
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Assignment", error);
                let field;
                let message;
                let status = error.response?.status ?? "";
                let errorCode = error.response?.data?.error_code ?? "";

                if (status === 400) {
                    switch (errorCode) {
                        case "MOD_211":
                        case "MOD_214":
                            field = "hostID";
                            break;
                        case "MOD_212":
                            field = "serialNumber";
                            break;
                        case "MOD_213":
                            field = "multi";
                            break;
                        case "MOD_209":
                        case "MOD_210":
                        case "MOD_LICENSE_226":
                        case "MOD_100":
                            field = "file";
                            break;
                        case "MOD_EA_160":
                            field = "alias";
                            break;
                        case "MOD_EA_161":
                            field = "quantityConsumed";
                            break;
                        case 'MOD_HSMGMNT_1':
                            field = "alias";
                            break;
                        default:
                            field = "generic";
                            break;
                    }
                    message = field === "alias" ? localization.toLanguageString(aliasUniqueKey, mainMessages[siteLanguageDefault][aliasUniqueKey])
                        : field === "quantityConsumed" ? localization.toLanguageString(quantityConsumedSessionKey, mainMessages[siteLanguageDefault][quantityConsumedSessionKey])
                            : error.response.data.display_message;
                }
                else {
                    field = "generic";
                    message = error.response?.data.display_message ?? ""
                }

                let hostError = {
                    field: field,
                    message: message
                };
                showError(hostError);
                setIsLoading(false);
            });
    }

    /*
     * saveHost() saves the assigned host
    * */
    const saveHost = (hostInfo) => {
        let host;
        let hostId;
        let panelBarTitle;

        let selected = cloneDeep(products.filter((product) => product.selected));
        let selectedProducts = cloneDeep(products.filter((product) => product.selected));

        // get host if host already exists in assignedProducts and sets host id and panel bar title for accordions
        if (hostModal === "HOST") {
            const hostIDTrim = hostID?.trim() || '';
            const serialNumberTrim = serialNumber?.trim() || '';

            setHostID(hostIDTrim);
            setSerialNumber(serialNumberTrim);
            hostId = [hostIDTrim];

            panelBarTitle = "Host " + hostIDTrim;

            // add alias to panel par title
            alias ? panelBarTitle += (' "' + alias + '"') : panelBarTitle += "";

            host = assignedProducts.find(host =>
                host.host_id &&
                [hostIDTrim] &&
                equals(host.host_id, [hostIDTrim])
            )
            createHost(panelBarTitle, host, hostId, selected, hostId, selected);
        } else if (hostModal === "MULTI_HOST") {
            // format multiple hosts or imei's to list of string
            let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];

            multiHostData.forEach((multiHost, index) => {
                hostId = [multiHost];
                panelBarTitle = "Host " + multiHost;
                host = assignedProducts.find(host =>
                    host.host_id &&
                    [multiHost] &&
                    equals(host.host_id, [multiHost])
                );
                createHost(panelBarTitle, host, hostId, index, multiHostData, selected);
            })
        } else if (hostModal === "C2V" || hostModal === "HOST_FILE_BIN") {
            let fileHostName = hostInfo.file_name;
            let fileHostID = hostInfo.host_id;
            if (fileHostID.length > 0) {
                // handle c2v or bin files
                hostId = fileHostID
                panelBarTitle = fileHostID[0];
                host = assignedProducts.find(host =>
                    host.host_id &&
                    [fileHostID] &&
                    equals(host.host_id, [fileHostID])
                );
            } else {
                // handle fingerprint files
                hostId = [fileHostName];
                panelBarTitle = fileHostName;
                host = assignedProducts.find(host =>
                    host.host_id &&
                    [fileHostName] &&
                    equals(host.host_id, [fileHostName]) &&
                    host.file.name === hostFile.name &&
                    host.file.size === hostFile.size &&
                    host.file.lastModified === hostFile.lastModified
                );
            }
            createHost(panelBarTitle, host, hostId, selected, [fileHostID], selected);
        }
        else {
            hostId = [];
            panelBarTitle = "";
            host = null;
        }
    }

    /*
     * createHost() creates the accordion for the products assigned to a host
     * @param {panelBarTitle} is the assignedProduct's accordion title
     * @param {host} is the existing assignedProduct object in assignedProducts
     * @param {hostId} is the assignedProduct's host_id
     * @param {hostIndex} is the host's index
     *  note: only applicable for MULTI HOSTS
     * @param {hostList} list of all hostIds
     *  note: only applicable for MULTI HOSTS, else pass in hostId
     * @param {selected} is a deep copy of selectedProducts
     * @param {prod} is a deep copy of products
     * @param {assignedProd} is a deep copy of assignedProducts
     * * @param {searchProd} is a deep copy of searchProducts
    */
    const createHost = (panelBarTitle, host, hostId, hostIndex = 0, hostList, selected) => {
        let transactionID;
        let hostCount = hostList.length;
        let unassignable = [];
        let prod = cloneDeep(products);
        // get unique transaction id for existing hosts
        // or add a unique transaction id for new hosts
        transactionID = host ? host.transaction_id : uuid();

        // case: products that are 1 per host can only be assigned to a single host
        if (host) {
            products.filter(item => item.selected).forEach((selectedProduct) => {
                let product = host.products.find(product => selectedProduct.unique_id === product.unique_id);
                if (selectedProduct["redeem_one_per_host"].toUpperCase() === "Y" && product) {
                    unassignable.push(product.product_number);
                }
            })
        }

        if (!unassignable.length) {
            let hosts = [...assignedProducts];

            // 1a. update assignedProducts for the assigned products accordions for existing host
            if (host) {
                // find index of the host in assignedProducts array
                let hostIndex = hosts.findIndex(hostUpdate => hostUpdate === host);

                //set host file if host file is empty string
                if (hosts[hostIndex].file === "") {
                    hosts[hostIndex].file = hostFile
                }

                products.filter(item => item.selected).forEach(selectedProduct => {
                    let qty;
                    let assignQty;
                    let product = host.products.find(product => selectedProduct.line_id === product.line_id);
                    let productIndex = host.products.findIndex(product => selectedProduct.line_id === product.line_id);

                    // update existing products in the host
                    if (product) {
                        qty = parseInt(selectedProduct.qty_available);
                        assignQty = parseInt(selectedProduct.qty_requested);

                        hosts[hostIndex].products[productIndex]["transaction_id"] = transactionID;
                        hosts[hostIndex].products[productIndex]["qty_available"] =
                            parseInt(product.qty_available) - assignQty;
                        hosts[hostIndex].products[productIndex]["qty_requested"] =
                            parseInt(product.qty_requested) + assignQty;
                    }
                    // add the new product to the host
                    else {
                        let newProduct = cloneDeep(selectedProduct);

                        let selectedProd = selected.find(product => selectedProduct.unique_id === product.unique_id);

                        qty = parseInt(selectedProd.qty_available);
                        assignQty = parseInt(selectedProd.qty_requested);
                        newProduct["transaction_id"] = transactionID;
                        newProduct["qty_available"] = qty - (assignQty * hostCount);
                        newProduct["qty_requested"] = assignQty;
                        hosts[hostIndex].products.push(newProduct);
                    }
                })
                setAssignedProducts(hosts);
            }
            // 1b. update assignedProducts for the assigned products accordions for a new host
            else {
                // note: to add a new object, use a deep copy ie. lodash's cloneDeep()
                let hostProducts = cloneDeep(selected).map((selectedProduct) => {
                    let product = selected.find((product) => product.unique_id === selectedProduct.unique_id);
                    let qty = parseInt(product.qty_available);
                    let assignQty = parseInt(product.qty_requested);

                    selectedProduct['transaction_id'] = transactionID;
                    selectedProduct['qty_available'] = qty - (assignQty * hostCount);
                    selectedProduct['qty_requested'] = assignQty;

                    delete selectedProduct['selected'];
                    delete selectedProduct['disabled'];

                    return selectedProduct
                });

                let assignedProduct = {
                    transaction_id: transactionID,
                    panelBarTitle: panelBarTitle,
                    products: hostProducts,
                    checked: true,
                    icons: {
                        loading: true,
                        download: false,
                        cloudAlert: false,
                        supportError: false,
                        generationError: false,
                        qtyError: false,
                        entitlementError: false,
                    },
                    host_id: hostId,
                    serial_id: serialNumber,
                    file: hostFile,
                    host_id_type: hostIDType,
                    alias: alias,
                    asset_info: assetInformation,
                    city: city,
                    state: state,
                    country: country,
                    notes: notes,
                };

                setAssignedProducts(assignedProducts => [...assignedProducts, assignedProduct]);
            }

            // 2. update the qty available for the selected product across all hosts
            products.filter((product) => product.selected).forEach((selectedProduct) => {
                assignedProducts.forEach((host) => {
                    let index = host.products.findIndex((product) => product.unique_id === selectedProduct.unique_id);
                    let qty = parseInt(selectedProduct.qty_available);
                    let assignQty = parseInt(selectedProduct.qty_requested);

                    if (host.products[index]) {
                        host.products[index]["qty_available"] = qty - (assignQty * hostCount);
                    }
                })
            })

            // 3. update products based on assign qty
            let productUpdates = [...products];
            let searchProductUpdates = [...searchProducts]
            productUpdates.forEach((selectedProduct, i) => {
                if (selectedProduct.selected) {
                    // find index of the selectedProduct in search products array by the unique line_id
                    let searchProductIndex = searchProductUpdates.findIndex((update) => update.line_id === selectedProduct.line_id);

                    let qty = parseInt(selectedProduct.qty_available);
                    let assignQty = parseInt(selectedProduct.qty_requested);

                    // update products for the my products grid
                    if (qty - (assignQty * hostCount) > 0) {
                        // update qty and assign qty for qty > 0
                        selectedProduct["qty_available"] = qty - (assignQty * hostCount);
                        selectedProduct["selected"] = false;

                        if (searchProductIndex >= 0) searchProductUpdates[searchProductIndex]["qty_available"] = qty - (assignQty * hostCount);
                        // handle one per hosts
                        if (selectedProduct["qty_available"] === 1 || selectedProduct["redeem_one_per_host"] === "Y") {
                            selectedProduct["qty_requested"] = 1;
                        }
                            // reset assign qty to empty input if at last element of selected products and assignable products
                        // note: needed for assigning product(s) to multiple existing hosts
                        else if (i === products.filter(item => item.selected).length - 1 && hostIndex === hostCount - 1) {
                            products.filter(item => item.selected).forEach((selectedProduct) => {
                                let indexUpdate = prod.findIndex((product) => product.unique_id === selectedProduct.unique_id);
                                if (typeof productUpdates[indexUpdate] !== 'undefined') {
                                    productUpdates[indexUpdate]["qty_requested"] = null;
                                }
                            })
                        }
                    } else {
                        // remove product from products if qty - (assignQty * hostCount) <= 0
                        productUpdates.splice(i, 1);

                        // remove product from searchProducts
                        // find index of the selectedProduct in products array by the unique_id
                        let product = prod.find((product) => product.unique_id === selectedProduct.unique_id);
                        let index = searchProductUpdates.findIndex((update) => update.line_id === product.line_id);
                        searchProductUpdates.splice(index, 1);
                    }
                }
            })

            setSearchProducts(orderBy(searchProductUpdates, [{
                field: 'unique_id',
                dir: 'asc'
            }]));

            // update the products
            let newProductUpdates = productUpdates.map(product => {
                // handle one per hosts
                if (product["redeem_one_per_host"] === "Y" || product["qty_available"] === 1) {
                    product["qty_requested"] = 1;
                }
                product["disabled"] = false;
                return product;
            })
            // reset selected products from cartProducts
            newProductUpdates.forEach((product) => {
                product.selected = false
            })
            setProducts(orderBy(newProductUpdates, [{
                field: 'unique_id',
                dir: 'asc'
            }]));

            // reset unassigned grid header checkbox
            setSelectHeaderCheck(false);

            // rest host id type
            setHostIDType("");

            // reset currentType ref
            currentType.current = null

        } else {
            unassignable.forEach((product) => {
                // show error for attempting to assign a product that is one per host
                let onePerHostTitleProduct = localization.toLanguageString(
                    assignProductsOnePerHostTitleProductErrorKey,
                    mainMessages[siteLanguageDefault][assignProductsOnePerHostTitleProductErrorKey]
                );
                let onePerHostTitleLimit = localization.toLanguageString(
                    assignProductsOnePerHostTitleLimitErrorKey,
                    mainMessages[siteLanguageDefault][assignProductsOnePerHostTitleLimitErrorKey]
                );
                let title = onePerHostTitleProduct + product + onePerHostTitleLimit;
                let message = localization.toLanguageString(
                    onePerHostMessageKey,
                    mainMessages[siteLanguageDefault][onePerHostMessageKey]
                );

                let onePerHostError = {
                    field: "onePerHost",
                    title: title,
                    message: message
                };
                showError(onePerHostError);
            })
        }
    }

    const AnchorText = AnchorWrap(Text);
    const contactUs = <AnchorText
        className={"header-contact-us"}
        href={config.keysight + "us/en/contact.html"}
        data-trigger="false"
        textkey={contactUsKey}
        textdefault={mainMessages[siteLanguageDefault][contactUsKey]}
    />

    const hostFileButtonAnchor = useRef(null);
    const hostFilePopoverAnchor = useRef(null);
    const onMouseOver = () => {
        setIsVisible(isVisible => ({
            ...isVisible,
            hostIDHint: true
        }))
    }
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) {
            setIsVisible(isVisible => ({
                ...isVisible,
                hostIDHint: false
            }))
        }
    }

    // filter the Host ID suggestion dropdown based on user's input
    const [filteredHostIDs, setFilteredHostIDs] = useState(hostIDSuggestions);
    const filterHostIDs = (value) => {
        let filtered = hostIDSuggestions.filter(s => s.suggestion.toLowerCase().includes(value.toLowerCase()));

        // auto populate on selection of a suggested host id
        if (hostModal === "HOST" && filtered.length === 1 && value === filtered[0].suggestion) {
            let autoPopulate = filtered[0];
            autoPopulate.node_id ? setHostID(autoPopulate.node_id) : setHostID("");
            autoPopulate.serial_number ? setSerialNumber(autoPopulate.serial_number) : setSerialNumber("");
            autoPopulate.alias ? setAlias(autoPopulate.alias) : setAlias("");
            autoPopulate.asset_info ? setAssetInformation(autoPopulate.asset_info) : setAssetInformation("");
            autoPopulate.city ? setCity(autoPopulate.city) : setCity("");
            autoPopulate.state ? setState(autoPopulate.state) : setState("");
            autoPopulate.country ? setCountry(autoPopulate.country) : setCountry("");
            autoPopulate.notes ? setNotes(autoPopulate.notes) : setNotes("");
        } else {
            // setHostConfID(null);
            setHostID(value);
            setSerialNumber("");
            setAlias("");
            setAssetInformation("");
            setCity("");
            setState("");
            setCountry("");
            setNotes("");
        }
        value ? setFilteredHostIDs(filtered) : setFilteredHostIDs(hostIDSuggestions);
    };

    // handle on change for host id input & auto populate with user_profile_tagged_host_ids
    const onHostIDChange = (e) => {
        setIsVisible(isVisible => ({...isVisible, hostIDHint: false}));
        setIsValid(isValid => ({...isValid, hostID: true}));
        filterHostIDs(e.value);
    }

    //set focus to host id input field when host modal is HOST
    useEffect(() => {
        if(hostModal === "HOST"){
            hostIDAnchor.current._input.focus();
        }
    }, []);

    const AssignHostModal = () => {
        let inputs = <></>;
        switch (hostModal) {
            case "HOST":
                inputs = <>
                    <Row>
                        <Col>
                            <Label>{toHtml(hostIDLabel)}</Label>
                            <Popover
                                show={isVisible.hostIDHint}
                                anchor={hostIDAnchor.current && hostIDAnchor.current.element}
                                position={'right'}
                                className={'ksm-popover ksm-popover-warning'}
                                animate={false}
                                style={{maxWidth: 600}}
                            >
                                <Row>
                                    <Col xs={"auto"}>
                                        <span
                                            className="k-icon k-i-warning"
                                            style={{
                                                color: 'var(--keysight-yellow)',
                                                fontSize: '2.5rem'
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <b>
                                            {localization.toLanguageString(hostIdFormattingKey, mainMessages[siteLanguageDefault][hostIdFormattingKey])}
                                        </b>
                                        <br/>
                                        {toHtml(hostIDHint)}
                                    </Col>
                                </Row>
                            </Popover>
                            <div
                                onMouseOver={() => setIsVisible(isVisible => ({
                                    ...isVisible,
                                    hostIDHint: true
                                }))}
                                onMouseOut={() => setIsVisible(isVisible => ({
                                    ...isVisible,
                                    hostIDHint: false
                                }))}
                            >
                                <AutoComplete
                                    ref={hostIDAnchor}
                                    value={hostID}
                                    data={filteredHostIDs}
                                    textField="suggestion"
                                    onChange={onHostIDChange}
                                    onFocus={() => setIsVisible(isVisible => ({
                                        ...isVisible,
                                        hostIDHint: false
                                    }))}
                                    valid={isValid.hostID}
                                    listNoDataRender={(e) => null}
                                />
                            </div>
                        </Col>
                        <Col>
                            {hostIsSerial === "Y" || hostIsSerial === "O" ?
                                <>
                                    <Label>
                                        {hostIsSerial === "O" ? toHtml(serialNumberLabel + " (Optional)") : toHtml(serialNumberLabel)}
                                    </Label>
                                    <Popover
                                        show={isVisible.serialNumberHint}
                                        anchor={serialNumberAnchor.current && serialNumberAnchor.current.element}
                                        position={'left'}
                                        className={'ksm-popover ksm-popover-warning'}
                                        animate={false}
                                        style={{maxWidth: 600}}
                                    >
                                        <Row>
                                            <Col xs={"auto"}>
                                                <span
                                                    className="k-icon k-i-warning"
                                                    style={{
                                                        color: 'var(--keysight-yellow)',
                                                        fontSize: '2.5rem'
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <b>
                                                    {localization.toLanguageString(serialNumberFormattingKey, mainMessages[siteLanguageDefault][serialNumberFormattingKey])}
                                                </b>
                                                <br/>
                                                {toHtml(serialNumberHint)}
                                            </Col>
                                        </Row>
                                    </Popover>
                                    <div
                                        onMouseOver={() => setIsVisible(isVisible => ({
                                            ...isVisible,
                                            serialNumberHint: true
                                        }))}
                                        onMouseOut={() => setIsVisible(isVisible => ({
                                            ...isVisible,
                                            serialNumberHint: false
                                        }))}
                                    >
                                        <AutoComplete
                                            ref={serialNumberAnchor}
                                            value={serialNumber}
                                            onChange={(e) => {
                                                setIsVisible(isVisible => ({
                                                    ...isVisible,
                                                    serialNumberHint: false
                                                }));
                                                setIsValid(isValid => ({
                                                    ...isValid,
                                                    serialNumber: true
                                                }));
                                                setSerialNumber(e.value);
                                            }}
                                            onFocus={() => setIsVisible(isVisible => ({
                                                ...isVisible,
                                                serialNumberHint: false
                                            }))}
                                            valid={isValid.serialNumber}
                                            listNoDataRender={(e) => null}
                                        />
                                    </div>
                                </>
                                :
                                <></>
                            }
                        </Col>
                    </Row>
                    <Row className={"mt-4"}>
                        <div
                            className={"k-h4"}
                        >
                            <Text
                                textkey={hostMetaDataKey}
                                textdefault={mainMessages[siteLanguageDefault][hostMetaDataKey]}
                            />
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Label>
                                        {localization.toLanguageString(aliasKey, mainMessages[siteLanguageDefault][aliasKey])}
                                    </Label>
                                    <Input
                                        value={alias}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setAlias(e.value)
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Label>
                                        {localization.toLanguageString(assetInfoKey, mainMessages[siteLanguageDefault][assetInfoKey])}
                                    </Label>
                                    <Input
                                        value={assetInformation}
                                        maxLength={150}
                                        onChange={(e) => {
                                            setAssetInformation(e.value)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={6}>
                                    <Label>
                                        {localization.toLanguageString(cityKey, mainMessages[siteLanguageDefault][cityKey])}
                                    </Label>
                                    <Input
                                        value={city}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setCity(e.value)
                                        }}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Label>
                                        {localization.toLanguageString(stateKey, mainMessages[siteLanguageDefault][stateKey])}
                                    </Label>
                                    <Input
                                        value={state}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setState(e.value)
                                        }}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <Label>
                                        {localization.toLanguageString(countryKey, mainMessages[siteLanguageDefault][countryKey])}
                                    </Label>
                                    <Input
                                        value={country}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setCountry(e.value)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className={"flex"}>
                            <div className={"flex flex-grow-1"}>
                                <Label>
                                    {localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey])}
                                </Label>
                                <TextArea
                                    className={"flex-grow-1"}
                                    value={notes}
                                    maxLength={500}
                                    onChange={(e) => {
                                        setNotes(e.value)
                                    }}
                                    autoSize={false}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
                break;
            case "MULTI_HOST":
                inputs = <Row>
                    <Col>
                        <Label>{toHtml(hostIDLabel)}</Label>
                        <Popover
                            show={isVisible.hostIDHint}
                            anchor={hostIDAnchor.current && hostIDAnchor.current.element.current}
                            position={'right'}
                            className={'ksm-popover ksm-popover-warning'}
                            animate={false}
                            style={{maxWidth: 600}}
                        >
                            <Row>
                                <Col xs={"auto"}>
                                    <span
                                        className="k-icon k-i-warning"
                                        style={{
                                            color: 'var(--keysight-yellow)',
                                            fontSize: '2.5rem'
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <b>
                                        {localization.toLanguageString(hostIdFormattingKey, mainMessages[siteLanguageDefault][hostIdFormattingKey])}
                                    </b>
                                    <br/>
                                    {toHtml(hostIDHint)}
                                </Col>
                            </Row>
                        </Popover>
                        <div
                            onMouseOver={() => setIsVisible(isVisible => ({
                                ...isVisible,
                                hostIDHint: true
                            }))}
                            onMouseOut={() => setIsVisible(isVisible => ({
                                ...isVisible,
                                hostIDHint: false
                            }))}
                        >
                            <TextArea
                                ref={hostIDAnchor}
                                value={multiHost}
                                rows={10}
                                autoSize={false}
                                onChange={(e) => {
                                    setIsVisible(isVisible => ({
                                        ...isVisible,
                                        hostIDHint: false
                                    }));
                                    setIsValid(isValid => ({
                                        ...isValid,
                                        multiHost: true
                                    }));
                                    setMultiHost(e.value);
                                }}
                                onFocus={() => setIsVisible(isVisible => ({
                                    ...isVisible,
                                    hostIDHint: false
                                }))}
                                valid={isValid.multiHost}
                                autoFocus={true}
                            />
                        </div>
                    </Col>
                    <Col/>
                </Row>
                break;
            case "C2V":
            case "HOST_FILE_BIN":
                let extension = hostModal === "C2V" ? ".c2v"
                    : hostModal === "HOST_FILE_BIN" ? ".bin"
                        : ""
                inputs = <Row>
                    <Col>
                        <span ref={hostFilePopoverAnchor}>
                            <Popover
                                show={isVisible.hostIDHint}
                                anchor={hostFilePopoverAnchor.current}
                                position={'top'}
                                className={'ksm-popover ksm-popover-warning'}
                                animate={false}
                                style={{maxWidth: 300}}
                            >
                            <Row>
                                <Col xs={"auto"}>
                                    <span
                                        className="k-icon k-i-warning"
                                        style={{
                                            color: 'var(--keysight-yellow)',
                                            fontSize: '2.5rem'
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <b>
                                        {localization.toLanguageString(specificHostFileKey, mainMessages[siteLanguageDefault][specificHostFileKey])}
                                    </b>
                                    <br/>
                                    {toHtml(hostIDHint)}
                                </Col>
                            </Row>
                        </Popover>
                            <span onMouseOver={onMouseOver}
                                  onMouseOut={onMouseOut}/>
                        </span>

                        <div
                            onMouseOver={() => {
                                setIsVisible(isVisible => ({
                                    ...isVisible,
                                    hostIDHint: true
                                }))
                            }}
                            onMouseOut={(ev) => {
                                let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
                                if (!isChildEl) {
                                    setIsVisible(isVisible => ({
                                        ...isVisible,
                                        hostIDHint: false
                                    }))
                                }
                            }}
                        >
                            <Upload
                                className="host-file-upload"
                                batch={false}
                                multiple={false}
                                withCredentials={false}
                                defaultFiles={[]}
                                restrictions={{
                                    allowedExtensions: [extension]
                                }}
                                saveUrl={config.utilities.health}
                                onAdd={onHostFileUpload}
                                selectMessageUI={() =>
                                    <Button
                                        ref={hostFileButtonAnchor}
                                        themeColor={"primary"}
                                        size={"large"}
                                        shape={"rectangle"}
                                        fillMode={"solid"}
                                        rounded={"small"}
                                        type={"button"}
                                    >
                                        {hostIDLabel}
                                    </Button>
                                }
                            />
                        </div>
                    </Col>
                </Row>
                break;
            default:
                inputs = <></>
        }

        return (
            <Dialog
                className={"assign-products-modal ksm-dialog"}
                title={<h2 style={titleStyle}>
                    {localization.toLanguageString(assignHostKey, mainMessages[siteLanguageDefault][assignHostKey])}
                </h2>}
                onClose={closeAssignHost}
                width={"64.625rem"}
                height={"90vh"}
            >
                {inputs}
                <Row className="mt-3">
                    <Col>
                        <Grid
                            className="sa-checkout-assign-products-grid"
                            scrollable={(hostModal === "MULTI_HOST" && products.filter(item => item.selected).length >= 5) || (products.filter(item => item.selected).length >= 10) ? "scrollable" : "none"}
                            data={products.filter(item => item.selected)}
                            style={(hostModal === "MULTI_HOST" && products.filter(item => item.selected).length >= 5) ? {maxHeight: "25vh"} : (products.filter(item => item.selected).length >= 10) ? {maxHeight: "40vh"} : {}}
                        >
                            <GridColumn
                                field="product_number"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                editable={false}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="product_desc"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                editable={false}
                            />
                            <GridColumn
                                cell={TextAlignMiddleCell}
                                field="qty_requested"
                                title={localization.toLanguageString(assignQtyKey, mainMessages[siteLanguageDefault][assignQtyKey])}
                                editable={false}
                            />
                        </Grid>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {errors.length ? (errors.map((error, index) => {
                                let field = error.field;
                                let title = error.title;
                                let message = error.message;
                                let errorTitleKey = field === "hostID" ? invalidHostKey
                                    : field === "serialNumber" ? invalidSerialNumberKey
                                        : field === "multi" ? invalidHostKey
                                            : field === "file" ? invalidFileKey
                                                : field === "database" ? genericErrorTitleKey
                                                    : genericErrorTitleKey

                                let errorMultilingualTitle = localization.toLanguageString(
                                    errorTitleKey,
                                    mainMessages[siteLanguageDefault][errorTitleKey]
                                );

                                let errorTitle = (field === "onePerHost" || field === "duplicateHost" || field === "qtyDistribution") ? title
                                    : field === "alias" ? localization.toLanguageString(duplicateAliasKey, mainMessages[siteLanguageDefault][duplicateAliasKey])
                                        : errorMultilingualTitle

                                let errorMessage = (field === "hostID" || field === "multi" || field === "file") ? <>
                                        {toHtml(hostIDLabel)}
                                        {toHtml(message)}
                                    </>
                                    : field === "serialNumber" ? <>
                                            {toHtml(serialNumberLabel)}
                                            {toHtml(message)}
                                        </>
                                        : toHtml(message)
                                return (
                                    <div key={'error-' + index}>
                                        <br/>
                                        <Alert
                                            type={'error'}
                                            title={errorTitle}
                                            message={errorMessage}
                                        />
                                    </div>
                                )
                            }))
                            :
                            <></>
                        }
                    </Col>
                </Row>
                <DialogActionsBar layout="center">
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        shape={"rectangle"}
                        fillMode={"outline"}
                        rounded={"small"}
                        type={"button"}
                        onClick={closeAssignHost}
                    >
                        {localization.toLanguageString(cancelChangesKey, mainMessages[siteLanguageDefault][cancelChangesKey])}
                    </Button>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        shape={"rectangle"}
                        fillMode={"solid"}
                        rounded={"small"}
                        type={"button"}
                        disabled={isLoading}
                        onClick={() => {
                            validate()
                        }}
                    >
                        {localization.toLanguageString(saveHostIDKey, mainMessages[siteLanguageDefault][saveHostIDKey])}
                    </Button>
                </DialogActionsBar>
            </Dialog>
        )
    }

    return (
        <>
            {isVisible.modal && AssignHostModal()}
        </>
    )
}

export default AssignProductsModal;