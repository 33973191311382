import React, {createRef, useContext, useEffect, useState} from 'react';
import moment from 'moment';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// css
import '../../assets/css/EARequestLicense.css';

// components
import MyProductsGrid from './AssignProducts/MyProductsGrid.js';
import HostGrid from './AssignProducts/HostGrid.js';
import AssignProductsModal from '../EARequestLicense/AssignProducts/AssignProductsModal.js';
import Alert from '../common/Alert.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {Tooltip} from '@progress/kendo-react-tooltip';
import {ExcelExport, ExcelExportColumn} from '@progress/kendo-react-excel-export';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../common/MultilingualText.js';
import {
    contactUsHeaderKey,
    genericErrorTitleKey,
    productsHostKey,
    durationIncreaseCostKey,
    poolOverKey,
    deleteHostKey,
    exportSelectionsKey,
    endDateNotPossibleKey,
    endDateExceedPoolKey,
    licensePreviewKey,
    mainMessages,
    myCartKey,
    saveMyWorkKey,
    quantityConsumedSessionKey,
    quantityConsumedKey,
    assignQtyKey,
    costPerUnitKey,
    descriptionKey,
    endDateKey,
    hostIdKey,
    licenseTypeKey,
    productNumberKey,
    startDateKey,
    totalCostKey,
    myAssignedProductsKey,
} from "../../assets/text/MultilingualText.js";
import {multiplyMoney} from "../common/utilities";


const AssignProducts = (props) => {
    const {
        isLoading,
        setIsLoading,
        stepperItems,
        setStepperItems,
        changeStepActivation,
        unsavedAssignedChanges,
        setUnsavedAssignedChanges,
        selectedProducts,
        setSelectedProducts,
        assignedProducts,
        setAssignedProducts,
        remixType,
        scalingFactor,
        headerInfo,
        setHeaderInfo,
        saveAssignments,
        saveRemovals,
        eAMinDuration,
        futureSubPoolValues,
        setFutureSubPoolValues,
        setUnSavedPool,
        showQuantityConsumedError
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);

    const localization = useLocalization();

    const [selectedState, setSelectedState] = useState({});

    const [isEnabled, setIsEnabled] = useState(false);

    const [isVisible, setIsVisible] = useState({
        modal: false,
        hostIDHint: false,
        serialNumberHint: false
    });

    const [genericError, setGenericError] = useState(false);
    const [costMessage, setCostMessage] = useState(false);
    const [costFutureMessage, setCostFutureMessage] = useState(false);

    const [minEndDate, setMinEndDate] = useState(new Date());
    const [maxEndDate, setMaxEndDate] = useState(new Date());
    const [datePickerEnabled, setDatePickerEnabled] = useState(false);

    // kendo react excel export
    const [userExcelData, setUserExcelData] = useState([]);
    const [excelFileName] = useState("KSM_EA_ASSIGNEDHOSTS_" + moment(new Date()).format('YYYYMMDD HHmmss'));

    const _exporter = createRef();
    const exportExcel = () => {
        if (_exporter.current) {
            _exporter.current.save();
        }
    };

    // custom panel bar header for hosts
    const PanelBarHeader = (props) => {
        const {
            title,
            transactionID
        } = props;

        return (
            <>
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Col
                        style={{
                            flex: '0 0 auto',
                            width: 'auto'
                        }}
                    >
                        <div
                            className={"k-h4"}
                            style={{
                                color: 'white',
                                margin: '0'
                            }}
                        >
                            {title}
                        </div>
                    </Col>
                    <Col>
                        <div
                            style={{
                                float: 'right'
                            }}
                        >
                            <Tooltip
                                anchorElement="target"
                                showCallout={false}
                                parentTitle={true}
                                openDelay={0}
                                position="top"
                            >
                                <button
                                    title={localization.toLanguageString(deleteHostKey, mainMessages[siteLanguageDefault][deleteHostKey])}
                                    className={'ksm-icon-button'}
                                    onClick={(e) => {
                                        let hostIndex = assignedProducts.findIndex(a => a.transaction_id === transactionID);
                                        if (assignedProducts[hostIndex]) {
                                            let products = assignedProducts[hostIndex].products;
                                            saveRemovals(products, transactionID);
                                        }
                                    }}
                                >
                                    <span
                                        className={"k-icon k-i-trash"}
                                        style={{
                                            fontSize: '1.5rem'
                                        }}
                                    />
                                </button>
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    // assign host modal variables
    const [hostIDTypeNumber, setHostIDTypeNumber] = useState("");
    const [hostIDType, setHostIDType] = useState("");

    const [hostModal, setHostModal] = useState("");
    const [hostIsSerial, setHostIsSerial] = useState("");

    const [hostIDLabel, setHostIDLabel] = useState("");
    const [hostIDHint, setHostIDHint] = useState("");
    const [hostIDError, setHostIDError] = useState("");
    const [hostIDPatterns, setHostIDPatterns] = useState([]);
    const [hostIDSuggestions, setHostIDSuggestions] = useState([]);

    const [serialNumberLabel, setSerialNumberLabel] = useState("");
    const [serialNumberHint, setSerialNumberHint] = useState("");
    const [serialNumberError, setSerialNumberError] = useState("");
    const [serialNumberPatterns, setSerialNumberPatterns] = useState([]);

    /*
     * getHostDetails() shows and dynamically renders the assign host modal
    */
    const getHostDetails = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        setGenericError(false);
        setIsLoading(true);

        axios.get(
            config.ea_request_license.HOST,
            {
                headers: headers,
                params: {
                    module: "EA",
                    host_id_type_val: hostIDTypeNumber
                }
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = typeof response.data === 'undefined' ? [] : response.data;

                    let modalType = data.hostid_type_popup.toUpperCase() || "";
                    let hostIdType = data.host_id_type.toUpperCase() || "";

                    let isSerial = data.get_serial.toUpperCase() || "";

                    let hostLabel = data.host_id_info.asl_prompt || "";
                    let hostHint = data.host_id_info.input_hint || "";
                    let hostError = data.host_id_info.asl_error_message || "";
                    let hostPatterns = data.host_id_info.patterns || [];
                    let serialLabel = data.serial_number_info.asl_prompt || "";
                    let serialHint = data.serial_number_info.input_hint || "";
                    let serialError = data.serial_number_info.asl_error_message || "";
                    let serialPatterns = data.serial_number_info.patterns || [];

                    // list host id suggestions as "host id, alias"
                    // if alias is the same as host id, omit alias from suggestion
                    let hostSuggestions = data.user_profile_tagged_host_ids.map(profile => {
                        let suggestion = profile.node_id !== profile.alias ? profile.node_id + ', ' + profile.alias
                            : profile.node_id;

                        return {
                            ...profile,
                            suggestion: suggestion
                        }
                    }) || [];

                    // handle partials
                    let selected = selectedProducts.filter(product => product.selected);
                    let partials = selectedProducts
                        .filter((product) => {
                            let license = product.license_type.filter(lt => lt.selected);
                            return license && license[0].ea_compatibility === "P"
                        })
                        .map((product) => product.ea_alloc_id);
                    let partialsSelected = selected.every(s => partials.includes(parseInt(s.ea_alloc_id)));

                    // assign variables from data
                    partialsSelected ? setHostModal("PARTIAL")
                        : modalType ? setHostModal(modalType)
                            : setHostModal("");
                    hostIdType ? setHostIDType(hostIdType) : setHostIDType("");
                    isSerial ? setHostIsSerial(isSerial) : setHostIsSerial("");

                    hostLabel ? setHostIDLabel(hostLabel) : setHostIDLabel("");
                    hostHint ? setHostIDHint(hostHint) : setHostIDHint("");
                    hostError ? setHostIDError(hostError) : setHostIDError("");
                    hostSuggestions ? setHostIDSuggestions(hostSuggestions) : setHostIDSuggestions([]);

                    serialLabel ? setSerialNumberLabel(serialLabel) : setSerialNumberLabel("");
                    serialHint ? setSerialNumberHint(serialHint) : setSerialNumberHint("");
                    serialError ? setSerialNumberError(serialError) : setSerialNumberError("");

                    // cast pattern strings to regex
                    // note: regex casting removes addtional \ character for all regex patterns
                    // ex. ^\\w{1,20}$ becomes ^\w{1,20}$
                    hostPatterns.length ? hostPatterns = hostPatterns.map(pattern => new RegExp(pattern))
                        : hostPatterns = [];
                    serialPatterns.length ? serialPatterns = serialPatterns.map(pattern => new RegExp(pattern))
                        : serialPatterns = [];

                    // if optional serial numbers append empty string pattern to valid serial number patterns
                    if (isSerial === "O") {
                        let emptyPattern = new RegExp("^$");
                        serialPatterns.push(emptyPattern);
                    }

                    setHostIDPatterns(hostPatterns);
                    setSerialNumberPatterns(serialPatterns);

                    // show modal
                    setIsLoading(false);
                    setIsVisible(isVisible => ({...isVisible, modal: true}));
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Host Type Details", error);
                setIsLoading(false);
                setGenericError(true);
            });
    }

    /*
     * getScalingFactor() gets ea scaling factor
     * @param {days} the difference of days between the period end date and start date
    */
    const getScalingFactor = (days) => {
        // sort scaling factors by days in ascending order
        let factors = scalingFactor.sort((a, b) => (a.days > b.days) ? 1 : -1);

        if (factors.length < 1) {
            return 1;
        } else if (factors.length === 1) {
            return factors[0].scaling_factor;
        }

        for (let i = 0; i < factors.length; i++) {
            let prev = factors[i - 1];
            let curr = factors[i];
            let next = factors[i + 1];

            if (prev === undefined) {
                // case: handle first element in scaling factors
                if (days <= curr.days) {
                    return curr.scaling_factor;
                } else if (curr.days < days && days <= next.days) {
                    return next.scaling_factor;
                }
            } else if (prev !== undefined && next !== undefined) {
                // case: general case
                if (days <= prev.days) {
                    return prev.scaling_factor;
                } else if (prev.days < days && days <= curr.days) {
                    return curr.scaling_factor;
                } else if (curr.days < days && days <= next.days) {
                    return next.scaling_factor;
                }
            } else if (next === undefined) {
                // case: handle last element in scaling factors
                if (days <= prev.days) {
                    return prev.sacling_factor
                } else if (prev.days < days && days <= curr.days) {
                    return curr.scaling_factor;
                } else if (days > curr.days) {
                    return curr.scaling_factor;
                }
            }
        }
    }

    /*
     * calculateCost() recalculates the total cost on end date selections
     * @param {selected} the array of objects of selected products
     * @param {assigned} the array of objects of assigned products
    */
    const calculateCost = (selected = [], assigned = []) => {
        let unSavedCost = 0;
        if (headerInfo.variableoversubflag === 'TRUE') {
            for (const year in futureSubPoolValues) {
                futureSubPoolValues[year].unSavedCost = 0
            }
        }

        let selectedProductsCalculations = selected.length ? selected : [...selectedProducts];
        let assignedProductsCalculations = assigned.length ? assigned : [...assignedProducts];

        // get selected products
        selectedProductsCalculations = selectedProductsCalculations.filter(s => s.selected);

        // calculate cost of selected products
        if (selectedProductsCalculations.length && scalingFactor.length) {
            selectedProductsCalculations.forEach(product => {
                let index = selectedProducts.findIndex(p => p.ea_alloc_id === product.ea_alloc_id);
                if (selectedProducts[index]) {
                    if (selectedProducts[index].unassigned_quantity > 0) {
                        let licenseType = product.license_type.filter(lt => lt.selected);
                        let price = licenseType[0].base_price;
                        let newQty = parseFloat(product.assign_quantity) || 0;
                        const savedCost = multiplyMoney(newQty, price) //scaling factor is 1

                        const newStart = new Date(product.period_start_date)
                        const newEnd = new Date(product.period_end_date)
                        const newDays = Math.ceil(Math.abs(newStart - newEnd) / (1000 * 60 * 60 * 24))
                        const newFactor = parseFloat(getScalingFactor(newDays))
                        const newCost = multiplyMoney((newFactor * newQty), price)
                        unSavedCost += newCost - savedCost
                        // console.log('savedFactor', 1, 'newFactor', newFactor, 'newEnd', newEnd)
                        // console.log('selected product: newCost', newCost, 'savedCost', savedCost)

                        if (headerInfo.variableoversubflag === 'TRUE') {
                            let newEndDatePoolYear = headerInfo.subs_year
                            for (const year in futureSubPoolValues) {
                                let endDate = new Date(futureSubPoolValues[year].enddate)
                                let startDate = new Date(futureSubPoolValues[year].startdate)
                                if (startDate <= newEnd && newEnd <= endDate) {
                                    newEndDatePoolYear = year
                                }
                            }

                            const newFutureCost = multiplyMoney((newFactor * newQty), price)
                            for (const year in futureSubPoolValues) {
                                if (newEndDatePoolYear >= year) {
                                    // console.log('selected product', newEndDatePoolYear, year, newEndDatePoolYear >= year)
                                    futureSubPoolValues[year].unSavedCost += newFutureCost
                                }
                            }
                            // console.log('selected product newEndDatePoolYear', newEndDatePoolYear, 'newFutureCost', newFutureCost)
                        }
                    }
                }
            });
        }

        // calculate cost of assigned products
        if (assignedProductsCalculations.length && scalingFactor.length) {
            assignedProductsCalculations.forEach(host => {
                host.products.forEach(product => {

                    let licenseType = product.license_type.filter(lt => lt.selected);
                    let price = licenseType[0].base_price;

                    let newCost
                    let newQty = product.assign_quantity
                    const newStart = new Date(product.period_start_date)
                    const newEnd = new Date(product.period_end_date)
                    const newDays = Math.ceil(Math.abs(newStart - newEnd) / (1000 * 60 * 60 * 24))
                    const newFactor = parseFloat(getScalingFactor(newDays))

                    let savedQty = product.unassigned_quantity
                    const savedStart = new Date(product.saved_period_start_date)
                    const savedEnd = new Date(product.saved_period_end_date)
                    const savedDays = Math.ceil(Math.abs(savedStart - savedEnd) / (1000 * 60 * 60 * 24))
                    const savedFactor = parseFloat(getScalingFactor(savedDays))

                    const unSavedQtyDiff = newQty - savedQty

                    //logic is to remove saved cost and add new cost
                    if (unSavedQtyDiff > 0) {
                        //handles new qty
                        const savedSelectedCost = multiplyMoney(unSavedQtyDiff, price) //scaling factor is 1
                        const newDiffCost = multiplyMoney((newFactor * unSavedQtyDiff), price)
                        unSavedCost += newDiffCost - savedSelectedCost

                        //handles saved qty
                        const savedCost = multiplyMoney((savedFactor * savedQty), price)
                        newCost = multiplyMoney((newFactor * savedQty), price)
                        unSavedCost += newCost - savedCost

                        // console.log('unSavedQtyDiff > 0: newDiffCost', newDiffCost, 'savedCost', savedSelectedCost, 'newCost', newCost, 'savedCost', savedCost, 'price', price)
                    } else if (unSavedQtyDiff === 0) {

                        //handles saved qty
                        const savedCost = multiplyMoney((savedFactor * savedQty), price)
                        newCost = multiplyMoney((newFactor * savedQty), price)
                        unSavedCost += newCost - savedCost

                        // console.log('unSavedQtyDiff === 0: newCost', newCost, 'savedCost', savedCost, 'price', price)
                    } else {
                        const savedCost = multiplyMoney((savedFactor * savedQty), price)
                        newCost = multiplyMoney((newFactor * newQty), price)
                        const newSelectedCost = multiplyMoney(-unSavedQtyDiff, price)
                        unSavedCost += newCost + newSelectedCost - savedCost

                        // console.log('unSavedQtyDiff < 0: newCost', newCost, 'newSelectedCost', newSelectedCost, 'savedCost', savedCost, 'price', price)
                    }


                    if (headerInfo.variableoversubflag === 'TRUE') {
                        let newEndDatePoolYear = headerInfo.subs_year
                        let savedEndDatePoolYear = headerInfo.subs_year
                        for (const year in futureSubPoolValues) {
                            let endDate = new Date(futureSubPoolValues[year].enddate)
                            let startDate = new Date(futureSubPoolValues[year].startdate)
                            if (startDate <= newEnd && newEnd <= endDate) {
                                newEndDatePoolYear = year
                            }
                            if (startDate <= savedEnd && savedEnd <= endDate) {
                                savedEndDatePoolYear = year
                            }
                        }

                        const savedFutureCost = multiplyMoney((savedFactor * savedQty), price)
                        const newFutureCost = multiplyMoney((newFactor * newQty), price)

                        for (const year in futureSubPoolValues) {
                            if (savedEndDatePoolYear >= year) {
                                futureSubPoolValues[year].unSavedCost -= savedFutureCost
                            }

                            if (newEndDatePoolYear >= year) {
                                futureSubPoolValues[year].unSavedCost += newFutureCost
                            }
                        }
                        // console.log('newEndDatePoolYear', newEndDatePoolYear, 'newFutureCost', newFutureCost, 'savedEndDatePoolYear', savedEndDatePoolYear, 'savedFutureCost', savedFutureCost)
                    }

                    // update cost in excel data
                    let index = userExcelData.findIndex(e => e.ea_alloc_id === product.ea_alloc_id);
                    if (index > -1) {
                        userExcelData[index] = {
                            ...userExcelData[index],
                            total_cost: newCost,
                        };
                    }
                })
            });
        }

        if (unSavedCost <= headerInfo?.remaining_pool - headerInfo.selected_sub_pool) {
            stepperItems[0].disabled = false;
            stepperItems[2].disabled = false;
            setStepperItems(stepperItems);
            setCostMessage(false);
        } else {
            stepperItems[0].disabled = true;
            stepperItems[2].disabled = true;
            setStepperItems(stepperItems);
            setCostMessage(true);
        }
        setUnSavedPool(unSavedCost)

        if (headerInfo.variableoversubflag === 'TRUE') {
            let exceed = false
            for (const year in futureSubPoolValues) {
                const remaining = futureSubPoolValues[year].availablesubscriptionpool
                const selected = futureSubPoolValues[year].selectedsubscriptionpool
                const unSaved = futureSubPoolValues[year].unSavedCost
                if (unSaved > remaining - selected) {
                    exceed = true
                }
            }
            // console.table(futureSubPoolValues)
            // console.log('future subpool exceed', exceed)
            if (exceed) {
                stepperItems[0].disabled = true;
                stepperItems[2].disabled = true;
            } else {
                stepperItems[0].disabled = false;
                stepperItems[2].disabled = false;
            }
            setStepperItems(stepperItems);
            setCostFutureMessage(exceed)
        }

        return unSavedCost;
    }

    // update excel data
    useEffect(() => {
        if (assignedProducts.length) {
            let updates = [];
            assignedProducts.forEach(host => {
                host.products.forEach(product => {
                    let index = updates.findIndex(e => e.ea_alloc_id === product.ea_alloc_id);
                    let licenseType = product.license_type.filter(lt => lt.selected);
                    let price = licenseType[0].base_price;
                    let hostId = Array.isArray(host.host_id) ? host.host_id.join(', ')
                        : typeof host.host_id === "string" ? host.host_id
                            : "";
                    if (index === -1) {
                        updates.push({
                            ea_alloc_id: product.ea_alloc_id,
                            prod_num_display: product.prod_num_display,
                            description: product.description,
                            license_type: licenseType[0].license_type,
                            assign_quantity: product.assign_quantity,
                            period_start_date: moment(product.period_start_date).format('MM/DD/YYYY'),
                            period_end_date: moment(product.period_end_date).format('MM/DD/YYYY'),
                            host_id: hostId,
                            cost_per_unit: price
                        });
                    }
                })
            })
            setUserExcelData(updates);
        }
    }, [assignedProducts]);


    //un selected any checked row from selected products and recalculate cost when leaving step 2
    useEffect(() => {
        return () => {
            console.log('set products to false')
            for (const product of selectedProducts) {
                product.selected = false
            }
            setSelectedProducts([...selectedProducts])
            calculateCost()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // initialize start and end dates on page load
    useEffect(() => {
        let selectedProductUpdates = [...selectedProducts];
        let assignedProductUpdates = [...assignedProducts];
        const periodic = ["semi-annual", "annual", "quarterly"]

        // case variable remix: start date is sysdate, start date is disabled, end date is duration of EA
        if (remixType === "variable") {
            selectedProductUpdates.forEach(product => {
                product.period_start_date = new Date();
                product.period_start_date_disabled = true;
            });
            assignedProductUpdates.forEach(host => {
                host.products.forEach(product => {
                    product.period_start_date_disabled = true;
                })
            });
        }
        // case periodic remix: start/end dates are the period and start/end dates must be disabled
        else if (periodic.includes(remixType)) {
            selectedProductUpdates.forEach(product => {
                product.period_start_date_disabled = true;
                product.period_end_date_disabled = true;
            });
            assignedProductUpdates.forEach(host => {
                host.products.forEach(product => {
                    product.period_start_date_disabled = true;
                    product.period_end_date_disabled = true;
                })
            });
        }

        setSelectedProducts(selectedProductUpdates);
        setAssignedProducts(assignedProductUpdates);
    }, [remixType]); // eslint-disable-line react-hooks/exhaustive-deps

    // set host id type number for qsp in getHostDetails api
    useEffect(() => {
        let hostIds = selectedProducts
            .filter(product => product.selected)
            .map(product => {
                let licenseType = product.license_type.filter(lt => lt.selected);
                return licenseType[0].type_val;
            });

        if (hostIds.every((val, i, arr) => val === arr[0])) {
            setHostIDTypeNumber(hostIds[0]);
        }
    }, [selectedProducts]);

    // calculate cost
    useEffect(() => {
        setCostMessage(false);
        if ((selectedProducts.length || assignedProducts.length) && scalingFactor) {
            calculateCost([], []);
        }
    }, [selectedProducts, assignedProducts, futureSubPoolValues, headerInfo]); // eslint-disable-line react-hooks/exhaustive-deps

    // get minimum end date header restriction
    useEffect(() => {
        let eAEndDate = new Date(headerInfo.ea_end_date);
        let min = new Date();
        min.setDate(min.getDate() + eAMinDuration);

        if (min < eAEndDate) {
            // case: today + ea min duration < ea end date,
            //  set minimum restriction to today + ea min duration
            //  set maximum restriction to ea end date
            setMinEndDate(min);
            setMaxEndDate(eAEndDate);
            setDatePickerEnabled(true);
        } else {
            // case: today + ea min duration >= ea end date,
            // disable date picker
            // set maximum restriction to ea end date
            setDatePickerEnabled(false);
            setMaxEndDate(eAEndDate);
        }
    }, [headerInfo, eAMinDuration]);

    return (
        <>
            <div
                className={'k-h4'}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(productsHostKey, mainMessages[siteLanguageDefault][productsHostKey])}
            </div>
            {!!assignedProducts.length && <div
                className={"k-h4"}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(myAssignedProductsKey, mainMessages[siteLanguageDefault][myAssignedProductsKey])}
            </div>}
            {!!assignedProducts.length && <PanelBar
                expandMode={'single'}
                className={'ksm-panelbar-default'}
            >
                {assignedProducts.map((assignedProduct) => {
                    if (assignedProduct.products && assignedProduct.products.length) {
                        return <PanelBarItem
                            title={<PanelBarHeader
                                title={assignedProduct.panel_bar_title}
                                transactionID={assignedProduct.transaction_id}
                            />}
                            key={assignedProduct.transaction_id}
                        >
                            <HostGrid
                                selectedProducts={selectedProducts}
                                assignedProducts={assignedProducts}
                                setAssignedProducts={setAssignedProducts}
                                assignedProduct={assignedProduct}
                                headerInfo={headerInfo}
                                saveRemovals={saveRemovals}
                                remixType={remixType}
                                calculateCost={calculateCost}
                                minEndDate={minEndDate}
                                maxEndDate={maxEndDate}
                                datePickerEnabled={datePickerEnabled}
                                unsavedAssignedChanges={unsavedAssignedChanges}
                                setUnsavedAssignedChanges={setUnsavedAssignedChanges}
                            />
                        </PanelBarItem>
                    } else {
                        return <></>
                    }
                })}
            </PanelBar>}
            {!!assignedProducts.length && <>
                <Row
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <Col>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                flexWrap: 'wrap',
                                gap: '0.938rem'
                            }}
                        >
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"outline"}
                                rounded={"small"}
                                type={"button"}
                                disabled={unsavedAssignedChanges.size === 0 || costMessage || costFutureMessage}
                                onClick={() => {
                                    saveAssignments(1);
                                }}
                                style={{
                                    marginBottom: '2rem'
                                }}
                            >
                                {localization.toLanguageString(saveMyWorkKey, mainMessages[siteLanguageDefault][saveMyWorkKey])}
                            </Button>
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                                disabled={assignedProducts.length === 0 || costMessage || costFutureMessage}
                                onClick={() => {
                                    changeStepActivation(2);
                                }}
                                style={{
                                    marginBottom: '2rem'
                                }}
                            >
                                {localization.toLanguageString(licensePreviewKey, mainMessages[siteLanguageDefault][licensePreviewKey])}
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                flexWrap: 'wrap',
                                gap: '0.938rem'
                            }}
                        >
                            <Button
                                themeColor={"secondary"}
                                size={"large"}
                                shape={"rectangle"}
                                fillMode={"solid"}
                                rounded={"small"}
                                icon={"excel"}
                                onClick={() => {
                                    exportExcel();
                                }}
                            >
                                {localization.toLanguageString(exportSelectionsKey, mainMessages[siteLanguageDefault][exportSelectionsKey])}
                            </Button>
                            <ExcelExport
                                data={userExcelData}
                                fileName={excelFileName}
                                ref={_exporter}
                            >
                                <ExcelExportColumn
                                    field="prod_num_display"
                                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                />
                                <ExcelExportColumn
                                    field="description"
                                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                />
                                <ExcelExportColumn
                                    field="license_type"
                                    title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                                />
                                <ExcelExportColumn
                                    field="assign_quantity"
                                    title={localization.toLanguageString(assignQtyKey, mainMessages[siteLanguageDefault][assignQtyKey])}
                                />
                                <ExcelExportColumn
                                    field="period_start_date"
                                    title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                                />
                                <ExcelExportColumn
                                    field="period_end_date"
                                    title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                                />
                                <ExcelExportColumn
                                    field="host_id"
                                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                                />
                                <ExcelExportColumn
                                    field="cost_per_unit"
                                    title={localization.toLanguageString(costPerUnitKey, mainMessages[siteLanguageDefault][costPerUnitKey])}
                                />
                                <ExcelExportColumn
                                    field="total_cost"
                                    title={localization.toLanguageString(totalCostKey, mainMessages[siteLanguageDefault][totalCostKey])}
                                />
                            </ExcelExport>
                        </div>
                    </Col>
                </Row>
            </>}
            {!!selectedProducts
                .filter((product) => product.unassigned_quantity > 0)
                .length && <>
                <div
                    className={'k-h4'}
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    {localization.toLanguageString(myCartKey, mainMessages[siteLanguageDefault][myCartKey])}
                </div>
                <div
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <MyProductsGrid
                        selectedState={selectedState}
                        setSelectedState={setSelectedState}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        headerInfo={headerInfo}
                        remixType={remixType}
                        calculateCost={calculateCost}
                        minEndDate={minEndDate}
                        maxEndDate={maxEndDate}
                        datePickerEnabled={datePickerEnabled}
                        setIsEnabled={setIsEnabled}
                    />
                </div>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    disabled={!isEnabled || costMessage || costFutureMessage}
                    onClick={() => {
                        getHostDetails();
                    }}
                    style={{
                        marginBottom: "8px"
                    }}
                >
                    <Text
                        textkey={productsHostKey}
                        textdefault={mainMessages[siteLanguageDefault][productsHostKey]}
                    />
                </Button>
            </>}
            {costMessage && <div>
                <br/>
                <Alert
                    type={'error'}
                    title={localization.toLanguageString(poolOverKey, mainMessages[siteLanguageDefault][poolOverKey])}
                    message={localization.toLanguageString(durationIncreaseCostKey, mainMessages[siteLanguageDefault][durationIncreaseCostKey])}
                />
            </div>}
            {costFutureMessage && <div
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                <Alert
                    type={'error'}
                    title={localization.toLanguageString(endDateExceedPoolKey, mainMessages[siteLanguageDefault][endDateExceedPoolKey])}
                    message={localization.toLanguageString(endDateNotPossibleKey, mainMessages[siteLanguageDefault][endDateNotPossibleKey])}
                />
            </div>}
            {showQuantityConsumedError && <div
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                <Alert
                    type={'error'}
                    title={localization.toLanguageString(quantityConsumedKey, mainMessages[siteLanguageDefault][quantityConsumedKey])}
                    message={localization.toLanguageString(quantityConsumedSessionKey, mainMessages[siteLanguageDefault][quantityConsumedSessionKey])}
                />
            </div>}
            {genericError && <div
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                <Alert
                    type={'error'}
                    title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                    message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                />
            </div>}
            {isVisible.modal &&
                <AssignProductsModal
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setHeaderInfo={setHeaderInfo}
                    setSelectedState={setSelectedState}
                    assignedProducts={assignedProducts}
                    setAssignedProducts={setAssignedProducts}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    headerInfo={headerInfo}
                    hostIDType={hostIDType}
                    hostModal={hostModal}
                    setHostModal={setHostModal}
                    hostIsSerial={hostIsSerial}
                    setHostIsSerial={setHostIsSerial}
                    hostIDLabel={hostIDLabel}
                    setHostIDLabel={setHostIDLabel}
                    hostIDHint={hostIDHint}
                    setHostIDHint={setHostIDHint}
                    hostIDError={hostIDError}
                    hostIDPatterns={hostIDPatterns}
                    setHostIDPatterns={setHostIDPatterns}
                    hostIDSuggestions={hostIDSuggestions}
                    serialNumberLabel={serialNumberLabel}
                    setSerialNumberLabel={setSerialNumberLabel}
                    serialNumberHint={serialNumberHint}
                    setSerialNumberHint={setSerialNumberHint}
                    serialNumberError={serialNumberError}
                    serialNumberPatterns={serialNumberPatterns}
                    setSerialNumberPatterns={setSerialNumberPatterns}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    futureSubPoolValues={futureSubPoolValues}
                    setFutureSubPoolValues={setFutureSubPoolValues}
                />
            }
        </>
    )
}

export default AssignProducts