import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMSyncLicensesUpload from '../assets/img/Help/ksm-sync-licenses-upload.png';
import KSMSyncLicensesRequest from '../assets/img/Help/ksm-sync-licenses-request.png';
import KSMSyncLicensesSpinner from '../assets/img/Help/ksm-sync-licenses-spinner.png';
import KSMSyncLicensesAnotherHost from '../assets/img/Help/ksm-sync-licenses-another-host.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    entitlementCertificatesKey,
    helpSyncLicenseErrorHandlingSectionKey,
    errorHandlingKey,
    frequentlyAskedQuestionsKey,
    ksmSyncLicensesKey,
    licensesKey,
    mainMessages,
    helpSyncLicenseNavigationSectionKey,
    navigationKey,
    helpSyncLicensesEntitlementCertificatesQAKey,
    helpSyncLicensesLicensesQAKey,
    helpSyncLicenseSyncingYourLicenseFileSectionKey,
    syncingYourLicenseFileKey
} from '../assets/text/MultilingualText.js';


function SyncLicenseHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;
        let img;

        if (item.img) {
            switch (item.img) {
                case 'ksm-sync-licenses-upload':
                    img = <img src={KSMSyncLicensesUpload} alt={item.img}/>
                    break;
                case 'ksm-sync-licenses-request':
                    img = <img src={KSMSyncLicensesRequest} alt={item.img}/>
                    break;
                case 'ksm-sync-licenses-spinner':
                    img = <img src={KSMSyncLicensesSpinner} alt={item.img}/>
                    break;
                case 'ksm-sync-licenses-another-host':
                    img = <img src={KSMSyncLicensesAnotherHost} alt={item.img}/>
                    break;
                default:
                    img = <></>
                    break;
            }
        }

        if (item.substeps && item.substeps.length) {
            children = <ol type={type}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        return (
            <li>
                {toHtml(item.step)}
                {item.img ? <span className="img-container">
                    {img}
                </span> : <></>}
                {children}
            </li>
        );
    }

    const helpMenuContent = {
        'header': {
            key: ksmSyncLicensesKey,
            default: mainMessages[siteLanguageDefault][ksmSyncLicensesKey]
        },
        'topics': [
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: "#navigation"
            },
            {
                key: syncingYourLicenseFileKey,
                default: mainMessages[siteLanguageDefault][syncingYourLicenseFileKey],
                hash: "#sync-license-file"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handling"
            }
        ],
        'faq': [
            {
                key: entitlementCertificatesKey,
                default: mainMessages[siteLanguageDefault][entitlementCertificatesKey],
                hash: "#entitlement-certificates-faq"
            },
            {
                key: licensesKey,
                default: mainMessages[siteLanguageDefault][licensesKey],
                hash: "#licenses-faq"
            }
        ]
    }

    const helpNavigationContent = localization.toLanguageString(
        helpSyncLicenseNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpSyncLicenseNavigationSectionKey]
    );

    const helpSyncingLicenseFileContent = localization.toLanguageString(
        helpSyncLicenseSyncingYourLicenseFileSectionKey,
        mainMessages[siteLanguageDefault][helpSyncLicenseSyncingYourLicenseFileSectionKey]
    );

    const helpErrorHandlingContent = localization.toLanguageString(
        helpSyncLicenseErrorHandlingSectionKey,
        mainMessages[siteLanguageDefault][helpSyncLicenseErrorHandlingSectionKey]
    );

    const helpEntitlementCertificatesQA = localization.toLanguageString(
        helpSyncLicensesEntitlementCertificatesQAKey,
        mainMessages[siteLanguageDefault][helpSyncLicensesEntitlementCertificatesQAKey]
    );

    const helpLicensesQA = localization.toLanguageString(
        helpSyncLicensesLicensesQAKey,
        mainMessages[siteLanguageDefault][helpSyncLicensesLicensesQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpNavigationContent.section_one}
                            </p>
                            {helpNavigationContent.section_one_bullets ? <ul>
                                {helpNavigationContent.section_one_bullets.map((bullet, index) => <li
                                    key={'navigation-bullet-' + index}>{toHtml(bullet)}</li>)}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="sync-license-file"
                        >
                            <Text
                                textkey={syncingYourLicenseFileKey}
                                textdefault={mainMessages[siteLanguageDefault][syncingYourLicenseFileKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpSyncingLicenseFileContent.section_one}
                            </p>
                            {toHtml(helpSyncingLicenseFileContent.section_two)}
                            <ol>
                                {helpSyncingLicenseFileContent.section_three_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(helpErrorHandlingContent.section_one)}
                        </div>
                        <div
                            className={"k-h2 header"}
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="entitlement-certificates-faq">
                            <h3>
                                <Text
                                    textkey={entitlementCertificatesKey}
                                    textdefault={mainMessages[siteLanguageDefault][entitlementCertificatesKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {helpEntitlementCertificatesQA ? helpEntitlementCertificatesQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"help-entitlement-certificate-qa-" + index}>
                                            <li>
                                                {questionAnswer.Q}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="licenses-faq">
                            <h3>
                                <Text
                                    textkey={licensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][licensesKey]}
                                />
                            </h3>
                            <ul className="indent">
                                {helpLicensesQA ? helpLicensesQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"help-licenses-qa-" + index}>
                                            <li>
                                                {questionAnswer.Q}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default SyncLicenseHelp;

