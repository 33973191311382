import React, {useContext, useRef, useState} from 'react';
import axios from 'axios';
import config from '../../../../config';
import UserContext from '../../../common/UserContext';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import DialogLayout from "./DialogLayout";
import {toHtml} from '../../utilities'

// kendo react
import {Label} from '@progress/kendo-react-labels';
import {TextArea} from '@progress/kendo-react-inputs';
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {
    hostIdFormattingKey,
    mainMessages,
} from "../../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";
import {Button} from "@progress/kendo-react-buttons";


function MultiHostModal(props) {
    const {
        setIsLoading,
        hostDetails,
        assignedProducts,
        selectedProducts,
        onePerHost = 'redeem_one_per_host',
        productNum = 'product_number',
        uniqueID = 'unique_id',
        errors,
        setErrors,
        isValid,
        setIsValid,
        showError,
        createHost,
        resetSelectedProducts,
        closeModal,
        children,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let hostIDType = hostDetails.host_id_type || ""
    let hostIDLabel = hostDetails.host_id_info.asl_prompt || "";
    let hostIDHint = hostDetails.host_id_info.input_hint || "";
    let hostIDPatterns = hostDetails.host_id_info.patterns || [];

    const hostIDPopover = useRef(null);
    const [multiHost, setMultiHost] = useState("");

    const [isVisible, setIsVisible] = useState({
        hostIDHint: false,
    })

    /*
     * validateMultiHost() validates the user multiple hosts or imei's input on the frontend prior to validating the
     * host id || serial number input on the backend
    */
    const validate = () => {
        setErrors([]);
        setIsValid({
            multiHost: true,
        });

        let invalidMultiHosts = [];
        let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];

        multiHostData.forEach((host) => {
            // isValidHost is true if the host matches all regex pattern in hostIDPatterns
            let isValidHost = hostIDPatterns.some(regex => regex.test(host.trim()));
            if (!isValidHost) invalidMultiHosts.push(host.trim());
        });

        if (multiHostData.length && !invalidMultiHosts.length) {
            validateHostRegEx();
        } else { // show error message for no host or any invalid host
            showError('multiHost', invalidMultiHosts);
        }
    }

    /*
     * validateHostRegEx() validates the user host id || serial number input on the backend
    */
    const validateHostRegEx = () => {
        let hostData = [];
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        hostData = multiHost.split('\n').filter(host => host)
        hostData.forEach((host, idx) => {
            return hostData[idx] = host.trim()
        })

        let data = {
            "host_id_type": hostIDType,
            "host_or_imei_values": hostData,
            "serial_id_value": ""
        };

        setIsLoading(true);

        axios.post(
            config.request_license.HOST_VALIDATION_REGEX,
            data,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    setErrors([]);
                    saveHostID();
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Validation Regex", error);
                let status = error?.response?.status

                if (status === 400) {
                    let invalidMultiHosts = error.response.data.error_detail ? error.response.data.error_detail : [];
                    showError('multiHost', invalidMultiHosts);
                } else {
                    showError('generic')
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }


    // Find duplicates in array
    const findDuplicates = arr => arr.filter((e, i) => arr.indexOf(e) !== i);

    /*
     * saveHostID() saves the assigned host
    */
    const saveHostID = () => {
        // format multiple hosts or imei's to list of string
        let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];
        multiHostData.forEach((host, idx) => {
            return multiHostData[idx] = host.trim()
        })

        // case: find duplication of host ids
        let duplicateHosts = findDuplicates(multiHostData);
        if (duplicateHosts.length) {
            showError("duplicateHost", duplicateHosts);
            return null
        }

        // case: check if qty can be evenly distrubted to all hosts
        let qtyErrors = [];
        let hostCount = multiHostData.length;
        selectedProducts.forEach((item) => {
            if (item.selected) {
                let qty = item.qty_available;
                let assignQty = item.qty_requested;
                let distribution = assignQty * hostCount;

                // check if there is enough qty to be distributed
                // and the product's qty will be evenly distributed across each host
                if (!(qty - distribution >= 0 && distribution % hostCount === 0)) {
                    qtyErrors.push({
                        "product": item.product_number,
                        "qty": qty,
                        "qty_needed": hostCount * assignQty,
                        "assign_qty": assignQty,
                        "host_count": hostCount
                    })
                }
            }
        });
        if (qtyErrors.length) {
            showError("qtyDistribution", qtyErrors);
            return null
        }

        // case: products that are 1 per host can only be assigned to a single host
        let foundOnePerHostError = false
        multiHostData.forEach((multiHost) => {
            const hostId = multiHost;
            const host = assignedProducts.find(host =>
                host.host_id &&
                hostId &&
                host.host_id === hostId
            )

            if (host) {
                let unAssignable = [];
                selectedProducts.forEach((item) => {
                    if (item.selected) {
                        let product = host.products.find(product => item[uniqueID] === product[uniqueID]);
                        if (item[onePerHost].toUpperCase() === "Y" && product) {
                            unAssignable.push(product[productNum]);
                        }
                    }
                })

                if (unAssignable.length > 0) {
                    unAssignable.forEach((item) => {
                        showError('onePerHost', item);
                    })
                    foundOnePerHostError = true
                }
            }
        })
        if (foundOnePerHostError) {
            return null
        }

        multiHostData.forEach((multiHost) => {
            const hostId = multiHost;
            const panelBarTitle = "Host: " + multiHost;
            const host = assignedProducts.find(host =>
                host.host_id &&
                hostId &&
                host.host_id === hostId
            )
            createHost(panelBarTitle, host, hostId, "", null, hostCount)
        })
        resetSelectedProducts(hostCount)
    }


    return (
        <DialogLayout
            closeModal={closeModal}
            productTable={children}
            errors={errors}
            validate={validate}
        >
            <Row>
                <Col>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.2rem',
                        lineHeight: '2rem'
                    }}>
                        <Label>{toHtml(hostIDLabel)}</Label>
                        <div>
                            <span
                                onClick={() => {
                                    setIsVisible(isVisible => ({
                                        ...isVisible,
                                        hostIDHint: !isVisible.hostIDHint,
                                    }));
                                }}
                                className={'k-icon k-i-information k-i-info'}
                                style={{
                                    color: 'var(--keysight-blue)',
                                    cursor: 'pointer',
                                    fontSize: '1.3rem',
                                    fontWeight: '550'
                                }}
                            />
                        </div>
                    </div>
                    <Popover
                        show={isVisible.hostIDHint}
                        anchor={hostIDPopover.current}
                        position={'right'}
                        className={'ksm-popover ksm-popover-warning'}
                        animate={false}
                        style={{maxWidth: 600}}
                    >
                        <Row>
                            <Col xs={"auto"}>
                                    <span
                                        className="k-icon k-i-warning"
                                        style={{
                                            color: 'var(--keysight-yellow)',
                                            fontSize: '2.5rem'
                                        }}
                                    />
                            </Col>
                            <Col>
                                <b>
                                    {localization.toLanguageString(hostIdFormattingKey, mainMessages[siteLanguageDefault][hostIdFormattingKey])}
                                </b>
                                <br/>
                                {toHtml(hostIDHint)}
                            </Col>
                            <Col xs={1}>
                                <Button
                                    icon="close"
                                    className={"alert-close-button"}
                                    onClick={() => {
                                        setIsVisible(isVisible => ({
                                            ...isVisible,
                                            hostIDHint: false,
                                        }));
                                    }}
                                />
                            </Col>
                        </Row>
                    </Popover>

                    <div ref={hostIDPopover}>
                        <TextArea
                            value={multiHost}
                            rows={10}
                            autoSize={false}
                            onChange={(e) => {
                                setIsValid(isValid => ({
                                    ...isValid,
                                    multiHost: true
                                }));
                                setMultiHost(e.value);
                            }}
                            valid={isValid.multiHost}
                            autoFocus={true}
                        />
                    </div>
                </Col>
                <Col/>
            </Row>
        </DialogLayout>
    )
}

export default MultiHostModal;