import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/TrialLicense.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {toHtml} from '../common/utilities.js';
import Alert from '../common/Alert.js';

// kendo react
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Label} from '@progress/kendo-react-labels';
import {TextArea} from '@progress/kendo-react-inputs';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../common/MultilingualText.js';
import {
    mainMessages,
    cancelKey,
    submitRequestKey,
    bodyColonKey,
    additionalTrailShareDetailsKey,
    hostIdKey,
    deniedTrailLicenseRequestKey,
    productColonKey,
    subjectColonKey,
    trailLicenseDeniedKey,
    contactUsKey,
    toColonKey,
    keysightTechnologiesKey,
    completeRequiredFieldsKey,
    requiredFieldsMissing,
    trialLicensePrivacyStatementKey
} from '../../assets/text/MultilingualText.js';


function ContactUsModal(props) {
    const {
        body,
        setBody,
        product,
        hostID,
        submitContactUs,
        isVisible,
        setIsVisible
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const [isValid, setIsValid] = useState(true);

    // closes the edit host modal
    const close = () => {
        setIsVisible(isVisible => ({
            ...isVisible,
            contactUsModal: false,
            missingFieldsError: false
        }));
        setIsValid(true);
    };

    /*
     * validateContactUs() validates the body in the contact us modal
    */
    const validateContactUs = () => {
        if (body.length) {
            submitContactUs();
        } else {
            setIsValid(false);
            setIsVisible(isVisible => ({...isVisible, missingFieldsError: true}));
        }
    }

    useEffect(() => {
        setIsVisible(isVisible => ({...isVisible, missingFieldsError: false}));
    }, [body]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Dialog
            className={"contact-information-modal ksm-dialog"}
            title={<h2 className={"contact-information-title"}>
                {localization.toLanguageString(contactUsKey, mainMessages[siteLanguageDefault][contactUsKey])}
            </h2>}
            onClose={close}
            width={"60vw"}
        >
            <Row>
                <Col>
                    <Text
                        textkey={deniedTrailLicenseRequestKey}
                        textdefault={mainMessages[siteLanguageDefault][deniedTrailLicenseRequestKey]}
                    />
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs={1}>
                    <b>
                        <Text
                            textkey={productColonKey}
                            textdefault={mainMessages[siteLanguageDefault][productColonKey]}
                        />
                    </b>
                </Col>
                <Col>{product[0]?.prod_num}</Col>
            </Row>
            <Row>
                <Col xs={1}>
                    <b>
                        <Text
                            textkey={hostIdKey}
                            textdefault={mainMessages[siteLanguageDefault][hostIdKey]}
                        />
                    </b>
                </Col>
                <Col>{hostID}</Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Text
                        textkey={additionalTrailShareDetailsKey}
                        textdefault={mainMessages[siteLanguageDefault][additionalTrailShareDetailsKey]}
                    />
                </Col>
            </Row>
            <br/>
            <Row className="mt-2">
                <Col xs={1}>
                    <b>
                        <Text
                            textkey={toColonKey}
                            textdefault={mainMessages[siteLanguageDefault][toColonKey]}
                        />
                    </b>
                </Col>
                <Col>
                    <Text
                        textkey={keysightTechnologiesKey}
                        textdefault={mainMessages[siteLanguageDefault][keysightTechnologiesKey]}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={1}>
                    <b>
                        <Text
                            textkey={subjectColonKey}
                            textdefault={mainMessages[siteLanguageDefault][subjectColonKey]}
                        />
                    </b>
                </Col>
                <Col>
                    <Text
                        textkey={trailLicenseDeniedKey}
                        textdefault={mainMessages[siteLanguageDefault][trailLicenseDeniedKey]}
                    />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Label>
                        {localization.toLanguageString(bodyColonKey, mainMessages[siteLanguageDefault][bodyColonKey])}
                        <span style={{color: "var(--keysight-red)"}}>*</span>
                    </Label>
                    <TextArea
                        value={body}
                        maxLength={500}
                        rows={5}
                        onChange={(e) => {
                            setIsValid(true);
                            setBody(e.value);
                        }}
                        autoSize={false}
                        valid={isValid}
                        autoFocus={true}
                    />
                </Col>
            </Row>
            <DialogActionsBar layout="end">
                <Row>
                    <Col className="trial-license-privacy-statement">
                        {toHtml(localization.toLanguageString(trialLicensePrivacyStatementKey, mainMessages[siteLanguageDefault][trialLicensePrivacyStatementKey]))}
                    </Col>
                    <Col className="contact-information-modal-buttons">
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"outline"}
                            rounded={"small"}
                            type={"button"}
                            onClick={close}
                        >
                            {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                        </Button>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"solid"}
                            rounded={"small"}
                            type={"button"}
                            onClick={validateContactUs}
                        >
                            {localization.toLanguageString(submitRequestKey, mainMessages[siteLanguageDefault][submitRequestKey])}
                        </Button>
                    </Col>
                </Row>
            </DialogActionsBar>
            {(isVisible.missingFieldsError) && <>
                <br/>
                <Row className="trial-license-alerts">
                    <Col xs={6}>
                        {isVisible.missingFieldsError && <Alert
                            type={'error'}
                            title={localization.toLanguageString(requiredFieldsMissing, mainMessages[siteLanguageDefault][requiredFieldsMissing])}
                            message={localization.toLanguageString(completeRequiredFieldsKey, mainMessages[siteLanguageDefault][completeRequiredFieldsKey])}
                            showHandler={() => setIsVisible(isVisible => ({...isVisible, missingFieldsError: false}))}
                        />}
                    </Col>
                </Row>
            </>}
        </Dialog>
    )
}

export default ContactUsModal;