import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from './UserContext.js';
// css
import '../../assets/css/App.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// timezone
import '@progress/kendo-date-math/tz/all';
import {ZonedDate} from '@progress/kendo-date-math';

// multilingual
import {Text} from './MultilingualText.js';
import {
    downtimeEndKey,
    systemMaintenanceKey,
    mainMessages,
    downtimeStartKey,
    downtimeTextKey
} from '../../assets/text/MultilingualText.js';


const Downtime = (props) => {
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    const downtimeStyle = {
        background: "var(--keysight-light-yellow)",
        border: "0.125rem solid var(--keysight-yellow)",
        boxSizing: "border-box",
        borderRadius: "0.313rem",
        display: "flex",
        justifyContent: "flex-start",
        minWidth: "26.563rem"
    };

    const kendoDateFix = (dateString) => dateString.replace('Dev', 'Dec')

    useEffect(() => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        axios.get(
            config.welcome_screen.DOWNTIME,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    const results = response.data;
                    if (results['start'] !== null && results['end'] !== null) {
                        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                        let startDate = new Date(results['start']);
                        startDate = ZonedDate.fromLocalDate(startDate, timezone).toString().split(' ');
                        startDate.splice(5, 1);
                        setStart(kendoDateFix(startDate.join(' ')));

                        let endDate = new Date(results['end']);
                        endDate = ZonedDate.fromLocalDate(endDate, timezone).toString().split(' ');
                        endDate.splice(5, 1);
                        setEnd(kendoDateFix(endDate.join(' ')));
                    }
                }
            })
            .catch((error) => {
                console.log("ERROR: Get Downtime", error);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {start !== '' && end !== '' && (<Row className="justify-content-md-center">
                <Col xs="7">
                    <div
                        className='k-messagebox'
                        style={downtimeStyle}
                    >
                        <span
                            style={{
                                'fontSize': '3.5rem',
                                'color': 'var(--keysight-yellow)'
                            }}
                            className={'k-icon k-i-gears'}
                        />
                        <span
                            style={{
                                'marginLeft': '0.938rem',
                                'marginRight': '0.938rem'
                            }}
                        >
                            <b>
                                <Text
                                    textkey={systemMaintenanceKey}
                                    textdefault={mainMessages[siteLanguageDefault][systemMaintenanceKey]}
                                />
                            </b>
                            <br/>
                            <Text
                                textkey={downtimeTextKey}
                                textdefault={mainMessages[siteLanguageDefault][downtimeTextKey]}
                            />
                            <br/>
                            <Text
                                textkey={downtimeStartKey}
                                textdefault={mainMessages[siteLanguageDefault][downtimeStartKey]}
                            />
                            {start}
                            <br/>
                            <Text
                                textkey={downtimeEndKey}
                                textdefault={mainMessages[siteLanguageDefault][downtimeEndKey]}
                            />
                            {end}
                            <br/>
                        </span>
                    </div>
                </Col>
            </Row>)
            }
        </>
    );
}

export default Downtime;
