import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// react router
import {useHistory, useLocation} from 'react-router-dom';

// css
import '../assets/css/EaDetailedView.css';

// components
import PageHeader from '../components/common/PageHeader.js';
import Spinner from '../components/common/Spinner.js';
import DetailedViewInfo from '../components/EADetailedView/DetailedViewInfo.js';
import SummaryChart from '../components/EARequestLicense/SummaryChart.js';
import HostPanelBars from '../components/EADetailedView/HostPanelBars.js';
import ProductPanelBars from '../components/EADetailedView/ProductPanelBars.js';
import {convertDateFormat} from '../components/common/Grid.js';
import {formatCurrency} from '../components/common/utilities.js';
import Alert from "../components/common/Alert";

// kendo react
import {orderBy} from '@progress/kendo-data-query';
import {Label} from "@progress/kendo-react-labels";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {RadioGroup} from "@progress/kendo-react-inputs";
import {Switch} from "@progress/kendo-react-inputs";


// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    transportableLicensesSavedKey,
    groupByHostIdKey,
    groupByProductsKey,
    mainMessages,
    hostPendingSpecialKey,
    periodKey,
    showExpiredLicensesKey,
    eaLicenseViewKey,
    enterpriseLicensingKey,
    toKey,
    openKey,
    hostKey,
    genericErrorTitleKey,
    contactUsHeaderKey,
} from '../assets/text/MultilingualText.js';

function EADetailedView(props) {
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();
    let history = useHistory();
    const {search} = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false)
    const [showGenericError, setShowGenericError] = useState(false)

    const [eaID, setEaID] = useState("")
    const [eaDetails, setEaDetails] = useState({
        remix_type: '',
        status: '',
    })
    const [periodProducts, setPeriodProducts] = useState([]) //TODO need a api to update period product data
    const [groupByHosts, setGroupByHosts] = useState([])
    const [groupByHostsNoExpiredProducts, setGroupByHostsNoExpiredProducts] = useState([])
    const [groupByproducts, setGroupByProducts] = useState([])
    const [groupByproductsNoExpired, setGroupByProductsNoExpired] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState({period_status: ''})
    const [periods, setPeriods] = useState([])
    const [subPoolInfo, setSubPoolInfo] = useState({
        selected: 0,
        consumed: 0,
        available: 0
    })

    const groupBy = [
        {
            label: localization.toLanguageString(groupByHostIdKey, mainMessages[siteLanguageDefault][groupByHostIdKey]),
            value: "host",
        },
        {
            label: localization.toLanguageString(groupByProductsKey, mainMessages[siteLanguageDefault][groupByProductsKey]),
            value: "product",
        },
    ]
    const [selectedGroupBy, setSelectedGroupBy] = useState(groupBy[0].value)
    const changeGroupBy = (event) => {
        setSelectedGroupBy(event.value)
    }

    const [showExpired, setShowExpired] = useState(true)
    const changeExpired = (event) => {
        setShowExpired(event.target.value)
    }

    useEffect(() => {
        let query = new URLSearchParams(search);
        const eaID = query.get('ea_id');
        setEaID(eaID)

        const getEADetails = (eaID) => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            setIsLoading(true)
            axios.get(
                config.ea_details.VIEW_DATA + '?ea=' + eaID,
                {headers: headers}
            )
                .then((response) => {
                    if (response.status === 200) {
                        let data = response.data
                        setEaDetails(data['ea_header_list'][0])
                        setPeriodProducts(data['ea_current_period_product_list'])

                        let newPeriods = []
                        data['ea_periods_list'].forEach(period => {
                            const startDate = convertDateFormat(period.period_start_date)
                            const endDate = convertDateFormat(period.period_end_date)
                            period.text = localization.toLanguageString(periodKey, mainMessages[siteLanguageDefault][periodKey]) +
                                ' ' + period.ea_period_id + ' (' + startDate + ' ' +
                                localization.toLanguageString(toKey, mainMessages[siteLanguageDefault][toKey]) +
                                ' ' + endDate

                            if (period['period_status'].toLowerCase() === "current") {
                                period.text += ' ' + localization.toLanguageString(openKey, mainMessages[siteLanguageDefault][openKey]) + ' )'
                                setSelectedPeriod(period)
                                setSubPoolInfo({
                                    selected: period['selected_subscription_pool'],
                                    consumed: period['redeemed_subscription_pool'],
                                    available: period['remain_subscription_pool'],
                                })
                            } else if (period['period_status'].toLowerCase() === "open") {
                                period.text += ' ' + localization.toLanguageString(openKey, mainMessages[siteLanguageDefault][openKey]) + ' )'
                            } else if (period['period_status'].toLowerCase() === "closest") {
                                setSelectedPeriod(period)
                                setSubPoolInfo({
                                    selected: period['selected_subscription_pool'],
                                    consumed: period['redeemed_subscription_pool'],
                                    available: period['remain_subscription_pool'],
                                })
                            } else {
                                period.text += ' )'
                            }

                            newPeriods.push(period)
                        })
                        newPeriods = orderBy(newPeriods, [{field: 'ea_period_id', dir: 'desc'}])
                        setPeriods(newPeriods)
                    }
                })
                .catch((error) => {
                    console.log('Error: Failed to get EA Details', error)
                    history.push('/error');
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }

        getEADetails(eaID)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const addName = (product) => {
        let name = localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey]) + ' ' + product.host_id
        let nameExcel = product.host_id

        let icons = {
            checked: true,
            download: true,
            banked: false,
            cloud: false,
            special: false,
        }

        if (product.alias !== null && product.alias !== '') {
            name += ' "' + product.alias + '"'
        }

        if (product.status.toLowerCase() === 'banked') { //Banked transportable
            name = localization.toLanguageString(transportableLicensesSavedKey, mainMessages[siteLanguageDefault][transportableLicensesSavedKey])
            nameExcel = name
            icons.banked = true
            icons.download = false
        } else if (product.host_id === '' || product.host_id === null) { //Special handling
            name = localization.toLanguageString(hostPendingSpecialKey, mainMessages[siteLanguageDefault][hostPendingSpecialKey])
            nameExcel = name
            icons.special = true
            icons.download = false
        }

        product.name = name
        product.nameExcel = nameExcel
        product.icons = icons
        return product
    }

    // Update group by hosts/products when period products gets updated
    useEffect(() => {
        let newGroupByHosts = []
        let newGroupByHostsNoExpiredProducts = []
        let newGroupByProducts = []
        let newGroupByProductsNoExpired = []

        periodProducts.forEach(product => {

            product = addName(product)
            let name = product.name
            let icons = product.icons

            product.currency_locale = eaDetails.locale
            product.currency_code = eaDetails.currency_code

            let hostIndex = newGroupByHosts.findIndex(host => host.name === name)
            if (hostIndex === -1) {
                newGroupByHosts.push({
                    name: name,
                    host_id: product.host_id,
                    products: [product],
                    icons: icons,
                    expanded: ['.0'],
                })
            } else {
                newGroupByHosts[hostIndex].products.push(product)
            }

            if (!(product.status.toLowerCase() === 'expired')) {
                let hostIndex = newGroupByHostsNoExpiredProducts.findIndex(host => host.name === name)
                if (hostIndex === -1) {
                    newGroupByHostsNoExpiredProducts.push({
                        name: name,
                        host_id: product.host_id,
                        products: [product],
                        icons: icons,
                        expanded: ['.0'],
                    })
                } else {
                    newGroupByHostsNoExpiredProducts[hostIndex].products.push(product)
                }
            }

            let productIndex = newGroupByProducts.findIndex(item => item.prod_num === product.prod_num)
            if (productIndex === -1) {
                newGroupByProducts.push({
                    prod_num: product.prod_num,
                    description: product.description,
                    products: [product],
                })
            } else {
                let foundHost = newGroupByProducts[productIndex].products.findIndex(item => item.name === name)
                if (foundHost === -1) {
                    newGroupByProducts[productIndex].products.push(product)
                }
            }

            if (!(product.status.toLowerCase() === 'expired')) {
                let productIndex = newGroupByProductsNoExpired.findIndex(item => item.prod_num === product.prod_num)
                if (productIndex === -1) {
                    newGroupByProductsNoExpired.push({
                        prod_num: product.prod_num,
                        description: product.description,
                        products: [product],
                    })
                } else {
                    let foundHost = newGroupByProductsNoExpired[productIndex].products.findIndex(item => item.name === name)
                    if (foundHost === -1) {
                        newGroupByProductsNoExpired[productIndex].products.push(product)
                    }
                }
            }
        })

        newGroupByHosts.forEach(host => {
            let canNotDownload = true
            let cloud = false
            let hostTotal = 0
            let assignedProducts = 0
            host.products.forEach(product => {
                if (product.status.toLowerCase() === 'redeemed') {
                    assignedProducts += 1
                    hostTotal += Number(product.total_cost)
                }
                if (product.candownload === 'YES') {
                    canNotDownload = false
                } else if (product.candownload === 'CLOUD') {
                    canNotDownload = true
                    cloud = true
                }
            })

            if (eaDetails.status.toLowerCase() === 'expired') {
                canNotDownload = true
            }

            host.assigned_products = assignedProducts
            host.host_total = formatCurrency(hostTotal, eaDetails.locale, eaDetails.currency_code)
            host.icons.download = !canNotDownload
            host.icons.cloud = cloud

            host.products = orderBy(host.products, [{
                field: "prod_num",
                dir: "asc"
            }])

            let hostIndex = newGroupByHostsNoExpiredProducts.findIndex(otherHost => otherHost.name === host.name)
            if (hostIndex !== -1) {
                newGroupByHostsNoExpiredProducts[hostIndex].host_total = host.host_total
                newGroupByHostsNoExpiredProducts[hostIndex].assigned_products = host.assigned_products
            }
        })

        newGroupByHostsNoExpiredProducts.forEach(host => {
            host.products = orderBy(host.products, [{
                field: "prod_num",
                dir: "asc"
            }])
        })

        newGroupByProducts.forEach(products => {
            let assignedProducts = 0
            products.products.forEach(product => {
                if (product.status.toLowerCase() === 'redeemed') {
                    assignedProducts += 1
                }
            })
            products.assigned_products = assignedProducts

            products.products = orderBy(products.products, [{
                field: "name",
                dir: "asc"
            }])

            let productIndex = newGroupByProductsNoExpired.findIndex(item => item.prod_num === products.prod_num)
            if (productIndex !== -1) {
                newGroupByProductsNoExpired[productIndex].assigned_products = products.assigned_products
            }
        })

        newGroupByProductsNoExpired.forEach(products => {
            products.products = orderBy(products.products, [{
                field: "name",
                dir: "asc"
            }])
        })

        // console.log('newGroupByHosts', newGroupByHosts)
        // console.log('newGroupByHostsNoExpiredProducts', newGroupByHostsNoExpiredProducts)
        setGroupByHosts(orderBy(newGroupByHosts, [{field: 'name', dir: 'asc'}]))
        setGroupByHostsNoExpiredProducts(orderBy(newGroupByHostsNoExpiredProducts, [{field: 'name', dir: 'asc'}]))

        // console.log('newGroupByProducts', newGroupByProducts)
        // console.log('newGroupByProductsNoExpired', newGroupByProductsNoExpired)
        setGroupByProducts(orderBy(newGroupByProducts, [{field: 'prod_num', dir: 'asc'}]))
        setGroupByProductsNoExpired(orderBy(newGroupByProductsNoExpired, [{field: 'prod_num', dir: 'asc'}]))
    }, [periodProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    //Get API to fetch selected period details
    const changePeriodDetails = (event) => {
        setShowGenericError(false)
        setShowEmailSuccess(false)
        const selectedPeriod = event.target.value
        const eaPeriodID = selectedPeriod.ea_period_id
        setIsLoading(true)
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios.get(
            config.ea_details.VIEW_DATA + '?ea=' + eaID + '&period=' + eaPeriodID,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data
                    setPeriodProducts(data)
                    setSelectedPeriod(selectedPeriod)
                    setSubPoolInfo({
                        selected: selectedPeriod['selected_subscription_pool'],
                        consumed: selectedPeriod['redeemed_subscription_pool'],
                        available: selectedPeriod['remain_subscription_pool'],
                    })
                }
            })
            .catch((error) => {
                console.log('ERROR: Failed to get period products', error)
                setShowGenericError(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    };

    const rowRender = (trElement, props) => {
        const expired = props.dataItem.status.toLowerCase() === 'expired' || props.dataItem.status.toLowerCase() === 'disabled' || props.dataItem.status.toLowerCase() === 'pending'
        const expiredStyle = {
            color: "#AAAAAA",
        };
        const trProps = {
            style: expired ? expiredStyle : {},
        };
        return React.cloneElement(
            trElement,
            {...trProps},
            trElement.props.children
        );
    };

    const [radioStyle, setRadioStyle] = useState({
        marginTop: '1.5rem',
    })

    useEffect(()=>{
        if(eaDetails.remix_type.toLowerCase() === 'variable'){
            setRadioStyle({})
        }
    }, [eaDetails])

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>

                <div
                    className={"k-h2"}
                    style={{
                        lineHeight: '2.313rem',
                        marginBottom: '0.938rem'
                    }}
                >
                    {localization.toLanguageString(enterpriseLicensingKey, mainMessages[siteLanguageDefault][enterpriseLicensingKey])}
                </div>
                <div className={'k-h4'} style={{
                    lineHeight: '2.313rem'
                }}>
                    {localization.toLanguageString(eaLicenseViewKey, mainMessages[siteLanguageDefault][eaLicenseViewKey])}
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '-0.938rem',
                }}>
                    <DetailedViewInfo
                        eaDetails={eaDetails}
                    />
                    <SummaryChart
                        totalValue={subPoolInfo.consumed + subPoolInfo.available}
                        data={[
                            {
                                "category": "consumed",
                                "value": subPoolInfo.consumed,
                                "backgroundColor": "#432668",
                                "fontColor": "#FFFFFF",
                                "tooltipBorderColor": "#432668"
                            },
                            {
                                "category": "selected",
                                "value": subPoolInfo.selected,
                                "backgroundColor": "#A895B5",
                                "fontColor": "#FFFFFF",
                                "tooltipBorderColor": "#A895B5"
                            },
                            {
                                "category": "available",
                                "value": subPoolInfo.available - subPoolInfo.selected,
                                "backgroundColor": "#FFFFFF",
                                "fontColor": "#000000",
                                "tooltipBorderColor": "#000000"
                            }
                        ]}
                        currency_code={eaDetails.currency_code}
                        currency_locale={eaDetails.locale}
                    />
                </div>


                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '3.125rem',
                    marginBottom: '0.938rem',
                }}>
                    {eaDetails.remix_type.toLowerCase() !== 'variable' && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.125rem',
                        }}>
                            <Label
                                style={{
                                    fontSize: '1.125rem',
                                    lineHeight: '1.25rem',
                                }}
                                editorId={'eaDetailPeriods'}
                            >
                                {localization.toLanguageString(periodKey, mainMessages[siteLanguageDefault][periodKey])}
                            </Label>
                            <DropDownList
                                id={'eaDetailPeriods'}
                                className={'ksm-dropdownlist'}
                                style={{
                                    width: "22rem",
                                }}
                                data={periods}
                                textField="text"
                                dataItemKey="ea_period_id"
                                onChange={changePeriodDetails}
                                value={selectedPeriod}
                            />
                        </div>

                    )}

                    <div style={radioStyle}>
                        <RadioGroup
                            data={groupBy}
                            layout={'horizontal'}
                            value={selectedGroupBy}
                            onChange={changeGroupBy}
                        />
                    </div>

                    {eaDetails.remix_type.toLowerCase() === 'variable' && (
                        <div style={{
                            display: 'flex',
                            gap: '0.5rem',
                            alignItems: 'center'
                        }}>
                            <Label editorId={'eaDetailExpired'}>
                                {localization.toLanguageString(showExpiredLicensesKey, mainMessages[siteLanguageDefault][showExpiredLicensesKey])}
                            </Label>
                            <Switch
                                id='eaDetailExpired'
                                onChange={changeExpired}
                                checked={showExpired}
                                onLabel={"Yes"}
                                offLabel={"No"}
                            />
                        </div>
                    )}
                </div>

                {showGenericError && (
                    <div style={{
                        width: '33rem'
                    }}>
                        <Alert
                            type={'error'}
                            title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                            message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                        />
                    </div>
                )}


                {selectedGroupBy === 'host' && !showGenericError && (
                    <HostPanelBars
                        eaID={eaID}
                        groupByHosts={groupByHosts}
                        setGroupByHosts={setGroupByHosts}
                        groupByHostsNoExpiredProducts={groupByHostsNoExpiredProducts}
                        setGroupByHostsNoExpiredProducts={setGroupByHostsNoExpiredProducts}
                        showExpired={showExpired}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        eaDetails={eaDetails}
                        periods={periods}
                        selectedPeriod={selectedPeriod}
                        showEmailSuccess={showEmailSuccess}
                        setShowEmailSuccess={setShowEmailSuccess}
                        rowRender={rowRender}
                    />
                )}

                {selectedGroupBy === 'product' && !showGenericError && (
                    <ProductPanelBars
                        groupByProducts={groupByproducts}
                        groupByProductsNoExpired={groupByproductsNoExpired}
                        showExpired={showExpired}
                        eaStatus={eaDetails.status}
                        selectedPeriod={selectedPeriod}
                        eaID={eaID}
                        rowRender={rowRender}
                        isLoading={isLoading}
                    />
                )}

            </div>
        </>
    );
}

export default EADetailedView;

