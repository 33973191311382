import UserContext from "../../common/UserContext";
import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../config";

//reactstrap
import {Col, Row} from "reactstrap";

//components
import {RequestPanelBarHeader} from "./RequestPanelBarHeader";
import RequestDownloadGrid from "./RequestDownloadGrid";
import Alert from "../../common/Alert";

//kendo
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";

//multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    notGeneratedAvailableRedeemKey, errorOccurredRequestKey,
    requestLicensesKey,
    generationConfirmationKey,
    generateLicensesKey,
    mainMessages,
    pleaseCancelKey,
    yesSureKey,

} from "../../../assets/text/MultilingualText";


export const LicensePreview = (props) => {
    const {
        siteLanguageDefault,
        accessToken,
        timeout,
    } = useContext(UserContext);

    const {
        changeStepActivation,
        setIsLoading,
        assignedProducts,
        setAssignedProducts,
        setRequestedLicense,
        setRequestedProducts,
    } = props

    const localization = useLocalization();
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [disableRequestLicense, setDisableRequestLicense] = useState(false)

    useEffect(() => {
        let checkedAssignedProducts = [...assignedProducts]
        for (const host of checkedAssignedProducts) {
            host.icons.checked = true
        }
        setAssignedProducts(checkedAssignedProducts)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //disable request license button if no hosts are checked
    useEffect(() => {
        let disabled = true
        for (const host of assignedProducts) {
            if (host.icons.checked) {
                disabled = false
            }
        }
        setDisableRequestLicense(disabled)

        if (showConfirmation && disabled) {
            setShowConfirmation(false)
        }
    }, [assignedProducts, showConfirmation])

    // Handles panel bar expanding based on whether icons have been clicked or not
    const iconClicked = useRef(false)
    const handleRequestSelect = (event) => {
        let hostIndex = event.target.props.title.props.index
        if (!iconClicked.current) {
            if (assignedProducts[hostIndex].expanded.length === 0) {
                assignedProducts[hostIndex].expanded = ['.0']
            } else {
                assignedProducts[hostIndex].expanded = []
            }
        } else {
            iconClicked.current = false
        }
        setAssignedProducts([...assignedProducts])
    };

    const [showAlert, setShowAlert] = useState(false)
    const alertHandler = () => {
        setShowAlert(false)
    }

    const checkInternalConnection = () => {
        let headers = {
            'Authorization': 'Bearer ' + accessToken,
        };
        setIsLoading(true);
        axios.post(
            config.utilities.health,
            {headers: headers, timeout: timeout}
        )
            .then((response) => {
                let products = []
                for (const host of assignedProducts) {
                    if (host.icons.checked) {
                        let requestedHost = JSON.parse(JSON.stringify(host))
                        requestedHost.icons = {
                            checked: true,
                            loading: true,
                            download: false,
                            cloudAlert: false,
                            specialAlert: false,
                            supportError: false,
                            generationError: false,
                            qtyError: false,
                            entitlementError: false,
                        }
                        products.push(requestedHost)
                    }
                }
                setRequestedProducts(products)
                setRequestedLicense(true)
            })
            .catch((error) => {
                console.log("ERROR: Failed to internet check", error);
                if (!error.response) { //Internet disconnected
                    setShowAlert(true)
                    setIsLoading(false);
                } else {
                    setRequestedLicense(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>

            {assignedProducts.map((host, index) => {
                return (
                    <PanelBar
                        key={index}
                        isControlled={true}
                        expanded={host.expanded}
                        onSelect={handleRequestSelect}
                        className={'ksm-panelbar-default'}
                    >
                        <PanelBarItem
                            title={<RequestPanelBarHeader
                                assignedProducts={assignedProducts}
                                setAssignedProducts={setAssignedProducts}
                                index={index}
                                iconClicked={iconClicked}
                            />}
                        >
                            <RequestDownloadGrid
                                products={host.products}
                            />

                        </PanelBarItem>
                    </PanelBar>
                )
            })}
            <Row>
                <Col>
                    <Button
                        style={{
                            width: '12.25rem'
                        }}
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        rounded={"small"}
                        disabled={showConfirmation || disableRequestLicense}
                        onClick={() => {
                            setShowConfirmation(true)
                        }}
                    >
                        {localization.toLanguageString(requestLicensesKey, mainMessages[siteLanguageDefault][requestLicensesKey])}
                    </Button>
                </Col>
                <Col>
                    {showConfirmation && (
                        <Alert
                            type={'warning'}
                            title={localization.toLanguageString(generateLicensesKey, mainMessages[siteLanguageDefault][generateLicensesKey])}
                            message={localization.toLanguageString(generationConfirmationKey, mainMessages[siteLanguageDefault][generationConfirmationKey])}
                        />
                    )}
                </Col>
                <Col/>
            </Row>
            {showConfirmation && (
                <div style={{
                    marginTop: '0.938rem',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '0.938rem'
                }}>
                    <Button
                        style={{
                            width: '12.25rem'
                        }}
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        rounded={"small"}
                        onClick={() => {
                            changeStepActivation(1)
                        }}
                    >
                        {localization.toLanguageString(pleaseCancelKey, mainMessages[siteLanguageDefault][pleaseCancelKey])}
                    </Button>
                    <Button
                        style={{
                            width: '12.25rem'
                        }}
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        rounded={"small"}
                        onClick={() => {
                            checkInternalConnection()
                        }}
                    >
                        {localization.toLanguageString(yesSureKey, mainMessages[siteLanguageDefault][yesSureKey])}
                    </Button>
                </div>
            )}

            {showAlert && (
                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0.938rem'
                }}>
                    <div style={{
                        maxWidth: '35.813rem',
                    }}>
                        <Alert
                            type={'error'}
                            showHandler={alertHandler}
                            title={localization.toLanguageString(errorOccurredRequestKey, mainMessages[siteLanguageDefault][errorOccurredRequestKey])}
                            message={localization.toLanguageString(notGeneratedAvailableRedeemKey, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemKey])}
                        />
                    </div>

                </Row>
            )}
        </>
    )
}