import React, {useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import uuid from 'react-uuid';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/RequestUpdateLicenses.css';

// react router
import {useHistory} from 'react-router-dom';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import KSMStepper from '../components/common/Stepper.js';
import PageHeader from '../components/common/PageHeader.js';
import SelectLicense from '../components/RequestUpdateLicenses/SelectLicense.js';
import Spinner from '../components/common/Spinner.js';
import LicenseDownload from '../components/RequestUpdateLicenses/LicenseDownload.js';

// kendo react
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    requestUpdateLicensesKey,
    navigationAlertKey,
    selectLicenseKey,
    licenseDownloadKey,
} from '../assets/text/MultilingualText';
import {Text} from '../components/common/MultilingualText.js';

function RequestUpdateLicenses(props) {
    const {
        accessToken,
        siteLanguage,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [isLoading, setIsLoading] = useState(false);

    const defaultStepperItems = [
        {
            label: localization.toLanguageString(selectLicenseKey, mainMessages[siteLanguageDefault][selectLicenseKey]),
            number: 1,
            disabled: false,
        },
        {
            label: localization.toLanguageString(licenseDownloadKey, mainMessages[siteLanguageDefault][licenseDownloadKey]),
            number: 2,
            disabled: true,
        }
    ]

    const [stepperItems, setStepperItems] = useState(defaultStepperItems);
    const [stepValue, setStepValue] = useState(0);

    // changes step activation or deactivation for the stepper
    const changeStepActivation = (stepChange) => {

        if (stepValue === 0) {
            if (stepChange === 1) {
                stepperItems[1].disabled = false
            }
        }

        if (stepChange === 0) {
            stepperItems[1].disabled = true
        }

        if (stepValue === 1) {
            if (stepChange === 0) {
                setHosts([])
                getUpdateHosts()
            }
        }

        setStepValue(stepChange)
        setStepperItems(stepperItems)
    }

    // on language change, refresh stepper labels
    useEffect(() => {
        setStepperItems(defaultStepperItems)
    }, [siteLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

    // add listeners for changing site navigation (back, forward) or unloading resources (close tab, window, or refresh)
    // remove listeners when leaving page
    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // sets the return value for before unload use effect. Return value can be a string.
    // this needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    // confirm exiting page that is not caused by redirect or react router push
    let history = useHistory();
    const currentURL = window.location.pathname + window.location.search

    useEffect(() => {
        // returning false from the callback blocks the navigation transition, returning true allows the transition to go through.
        const unblock = history.block(() => {
            if (window.confirm(
                localization.toLanguageString(navigationAlertKey, mainMessages[siteLanguageDefault][navigationAlertKey])
            )) {
                return true
            } else {
                window.history.replaceState(null, null, currentURL);
                return false
            }
        });

        return () => {
            unblock();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const [hosts, setHosts] = useState([]);

    async function getUpdateHosts() {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let currentParams = (new URL(document.location)).searchParams;
        const params = `module=update-item-license&filter=${currentParams.get('uuid')}`;

        setIsLoading(true)
        axios.get(
            `${config.software_updates.SOFTWARE_SERVICE}?${params}`,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    let responseHosts = []
                    data.forEach(product => {
                        let host_id = product.NODE_ID

                        let added = false
                        responseHosts.forEach(host => {
                            if (host.host_id === host_id) {
                                added = true
                                host.products.push(product)
                            }
                        })
                        if (!added) {
                            responseHosts.push({
                                'host_id': host_id,
                                products: [product]
                            })
                        }
                    })

                    responseHosts.forEach(host => {
                        host.selected = false
                        host.disabled = false
                        host.required_redemption = []
                        orderBy(host.products, [{field: 'UP_PRODUCT_NUM', dir: 'desc'}]);

                        if (host.host_id === null) {
                            host.host_id = 'Unassigned'
                            host.disabled = true
                            host.products.forEach(product => {
                                if (host.required_redemption.length === 0 || host.required_redemption.indexOf(product.ORDER_PRODUCT_NUM) === -1) {
                                    host.required_redemption.push(product.ORDER_PRODUCT_NUM);
                                }
                            })
                        }
                        host.transaction_id = uuid()
                        host.icons = {
                            loading: true,
                            download: false,
                            generalError: false,
                            networkError: false,
                        }
                    })

                    orderBy(responseHosts, [{field: 'host_id', dir: 'asc'}]);
                    setHosts(responseHosts)
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to load host data", error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    // on load get all hosts that are updatable based on uuid
    useEffect(() => {
        getUpdateHosts()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <Col>
                        <div className={"k-h2"}>
                            <Text
                                textkey={requestUpdateLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][requestUpdateLicensesKey]}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            <KSMStepper
                                stepperItems={stepperItems}
                                stepValue={stepValue}
                                changeStepActivation={changeStepActivation}
                            />
                        </div>
                    </Col>
                    <Col/>
                </Row>
                {stepValue === 0 && (
                    <SelectLicense
                        changeStepActivation={changeStepActivation}
                        hosts={hosts}
                        setHosts={setHosts}
                    />
                )}
                {stepValue === 1 && (
                    <LicenseDownload
                        changeStepActivation={changeStepActivation}
                        hosts={hosts}
                        setHosts={setHosts}
                        handleBeforeUnload={handleBeforeUnload}
                        setIsLoading={setIsLoading}
                        stepperItems={stepperItems}
                        setStepperItems={setStepperItems}
                    />
                )}
            </div>
        </>
    );
}

export default RequestUpdateLicenses;

