import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMSACheckinStepper from '../assets/img/Help/ksm-sa-checkin-stepper.png';
import KSMSACheckinMyHosts from '../assets/img/Help/ksm-sa-checkin-my-hosts.png';
import KSMSACheckinAreYouSure from '../assets/img/Help/ksm-sa-checkin-are-you-sure.png';
import KSMSACheckinSuccess from '../assets/img/Help/ksm-sa-checkin-success.png';
import KSMSACheckin from '../assets/img/Help/ksm-sa-checkin.png';
import KSMSACheckinSelectLicenses from '../assets/img/Help/ksm-sa-checkin-select-licenses.png';
import KSMSAArrowButton from '../assets/img/Help/ksm-sa-arrow-button.png'

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    aboutSoftwareAccessKey,
    helpSAAboutSectionKey,
    checkInLicensesKey,
    quickStepsCheckInKey,
    helpSACheckinStepsSectionKey,
    selectHostsParenthesisKey,
    helpSACheckinSelectHostsSectionKey,
    selectLicensesParenthesisKey,
    helpSACheckinSelectLicensesSectionKey,
    checkInKey,
    helpSACheckinCheckinSectionKey,
    navigationKey,
    helpSACheckinNavigationSectionKey,
    frequentlyAskedQuestionsKey,
    helpSACheckinQAKey,
    mainMessages
} from '../assets/text/MultilingualText.js';

function SACheckinHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;

        let img;
        let img2;
        let imgInlineStyle = (item.img === "ksm-sa-arrow-button") ? {display: 'flex', alignItems: 'center'} : {};

        if (item.img) {
            switch (item.img) {
                case 'ksm-sa-checkin':
                    img = <div><img src={KSMSACheckin} alt={item.img}/></div>
                    img2 = <div><img src={KSMSACheckinAreYouSure} alt={item.img2}/></div>
                    break;
                case 'ksm-sa-arrow-button':
                    img = <img src={KSMSAArrowButton} alt={item.img}/>
                    break;
                case 'ksm-sa-checkin-stepper':
                    img = <img src={KSMSACheckinStepper} alt={item.img}/>
                    break;
                case 'ksm-sa-checkin-my-hosts':
                    img = <img src={KSMSACheckinMyHosts} alt={item.img} width={800}/>
                    break;
                case 'ksm-sa-checkin-success':
                    img = <img src={KSMSACheckinSuccess} alt={item.img}/>
                    break;
                case 'ksm-sa-checkin-select-licenses':
                    img = <img src={KSMSACheckinSelectLicenses} alt={item.img} width={1000}/>
                    break;
                default:
                    img = <></>
                    break;
            }
        }

        if (item.substeps && item.substeps.length) {
            children = type === "bullet" ? <ul style={{listStyleType: 'disc'}}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ul> : <ol type={type}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        if (item.img === "ksm-sa-arrow-button") {
            return (
                <li>
                    <div style={imgInlineStyle}>
                        {toHtml(item.step_prefix)}
                        {item.img ? <span className="img-container">
                            {img}
                        </span> : <></>}
                        {toHtml(item.step_suffix)}
                    </div>
                    {children}
                </li>
            )
        }

        return (
            <li>
                <div style={imgInlineStyle}>
                    {toHtml(item.step)}
                    {item.img ? <span>
                        {img}
                    </span> : <></>}
                    {item.img2 ? <span>
                            {img2}
                        </span> : <></>}
                </div>
                {children}
            </li>
        );
    }

    const helpContent = {
        'header': {
            key: checkInLicensesKey,
            default: mainMessages[siteLanguageDefault][checkInLicensesKey]
        },
        'topics': [
            {
                key: aboutSoftwareAccessKey,
                default: mainMessages[siteLanguageDefault][aboutSoftwareAccessKey],
                hash: '#about'
            },
            {
                key: quickStepsCheckInKey,
                default: mainMessages[siteLanguageDefault][quickStepsCheckInKey],
                hash: '#steps'
            },
            {
                key: selectHostsParenthesisKey,
                default: mainMessages[siteLanguageDefault][selectHostsParenthesisKey],
                hash: '#select-hosts'
            },
            {
                key: selectLicensesParenthesisKey,
                default: mainMessages[siteLanguageDefault][selectLicensesParenthesisKey],
                hash: '#select-licenses'
            },
            {
                key: checkInKey,
                default: mainMessages[siteLanguageDefault][checkInKey],
                hash: '#check-in'
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: '#navigation'
            },
        ],
        'faq': [{
            key: frequentlyAskedQuestionsKey,
            default: mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey],
            hash: '#faq'
        }],
    };

    const AboutSectionKey = localization.toLanguageString(
        helpSAAboutSectionKey,
        mainMessages[siteLanguageDefault][helpSAAboutSectionKey]
    );

    const StepsSectionKey = localization.toLanguageString(
        helpSACheckinStepsSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckinStepsSectionKey]
    );

    const NavigationSectionKey = localization.toLanguageString(
        helpSACheckinNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckinNavigationSectionKey]
    );

    const SelectHostsSectionKey = localization.toLanguageString(
        helpSACheckinSelectHostsSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckinSelectHostsSectionKey]
    );

    const SelectLicensesSectionKey = localization.toLanguageString(
        helpSACheckinSelectLicensesSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckinSelectLicensesSectionKey]
    );

    const CheckinSectionKey = localization.toLanguageString(
        helpSACheckinCheckinSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckinCheckinSectionKey]
    );

    const QAKey = localization.toLanguageString(
        helpSACheckinQAKey,
        mainMessages[siteLanguageDefault][helpSACheckinQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="about"
                        >
                            <Text
                                textkey={aboutSoftwareAccessKey}
                                textdefault={mainMessages[siteLanguageDefault][aboutSoftwareAccessKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {AboutSectionKey.section_one}
                            {AboutSectionKey.section_one_bullets ? <ul>
                                {AboutSectionKey.section_one_bullets.map((bullet, index) => <li
                                    key={'sa-about-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="steps"
                        >
                            <Text
                                textkey={quickStepsCheckInKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsCheckInKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(StepsSectionKey.section_one)}
                            <ol type="1">
                                {StepsSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="select-hosts"
                        >
                            <Text
                                textkey={selectHostsParenthesisKey}
                                textdefault={mainMessages[siteLanguageDefault][selectHostsParenthesisKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(SelectHostsSectionKey.section_one)}
                            <ol type="a">
                                {SelectHostsSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="select-licenses"
                        >
                            <Text
                                textkey={selectLicensesParenthesisKey}
                                textdefault={mainMessages[siteLanguageDefault][selectLicensesParenthesisKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(SelectLicensesSectionKey.section_one)}
                            <ol type="a">
                                {SelectLicensesSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="check-in"
                        >
                            <Text
                                textkey={checkInKey}
                                textdefault={mainMessages[siteLanguageDefault][checkInKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(CheckinSectionKey.section_one)}
                            {CheckinSectionKey.section_one_steps ? <ul>
                                {CheckinSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(NavigationSectionKey.section_one)}
                            {NavigationSectionKey.section_one_steps ? <ul>
                                {NavigationSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ul> : <></>}
                        </div>
                        <div className={"k-h2 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="faq">
                            <ul className="indent">
                                {QAKey ? QAKey.map((questionAnswer, index) => {
                                    return (
                                        <div key={"category-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default SACheckinHelp;

