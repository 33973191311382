import React, {useCallback, useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// reactstrap
import {Row} from 'reactstrap';

// components
import {ColumnMenu, NoWrapCell, TextAlignMiddleCell} from '../common/Grid.js';
import {OrderNumberCell} from './SelectSubscription/OrderNumberCell.js';
import {RowRender} from './SelectSubscription/RowRender.js';

// kendo react
import {getSelectedState, Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {orderBy, process} from '@progress/kendo-data-query';
import {getter} from '@progress/kendo-react-common';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../common/MultilingualText.js';
import {
    mainMessages,
    descriptionKey,
    orderNumberKey,
    qtyKey,
    productNumberKey,
    supportSubscriptionKey
} from '../../assets/text/MultilingualText.js';


// consts for standalone subscriptions table
const RENEWAL_DATA_ITEM_KEY = 'order_number';
const SELECTED_FIELD = 'selected';
const renewalIdGetter = getter(RENEWAL_DATA_ITEM_KEY);
const initialGridState = {};
const initialSort = [
    {
        field: "order_number",
        dir: "desc",
    },
];

function SelectSubscription(props) {
    const {
        renewals,
        getStandaloneRenewalLicenses,
        gridNoRecordsMessage
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const [sort, setSort] = useState(initialSort);
    const [renewalSelectedState, setRenewalSelectedState] = useState({});
    const [renewalGridState, setRenewalGridState] = useState(initialGridState);
    const [renewalState, setRenewalState] = useState(
        process(
            renewals.map((renewal) => ({
                ...renewal,
                'selected': renewalSelectedState[renewalIdGetter(renewal)],
            })),
            initialGridState
        )
    );

    /*
     * setSelectedRenewal(renewals) sets the selected flag of a renewal
     * @param {renewals} the list of renewals
     * @return {selected} the list of renewals wth the selected renewal's flag set
    */
    const setSelectedRenewal = (renewals: any[]) => {
        let selected = renewals.map((renewal) => {
            if (renewal.items) {
                return {
                    ...renewal,
                    items: setSelectedRenewal(renewal.items),
                };
            } else {
                return {
                    ...renewal,
                    'selected': renewalSelectedState[renewalIdGetter(renewal)],
                };
            }
        });
        return selected;
    };

    /*
     * onRenewalSelectionChange(event) sets the renewal grid state on grid's selection change
     * @param {event} the grid selection change event
    */
    const onRenewalSelectionChange = useCallback((event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: renewalSelectedState,
            dataItemKey: RENEWAL_DATA_ITEM_KEY,
        });

        setRenewalSelectedState(newSelectedState);
    }, [renewalSelectedState]);

    /*
     * onRenewalStateChange(event) sets the renewal grid state and renewal state on grid's state change
     * @param {event} the grid selection change event
    */
    const onRenewalStateChange = useCallback((event: GridDataStateChangeEvent) => {
        const newRenewalState = process(
            renewals.map((renewal) => ({
                ...renewal,
                'selected': renewalSelectedState[renewalIdGetter(renewal)],
            })),
            event.dataState
        );

        setRenewalGridState(event.dataState);
        setRenewalState(newRenewalState);
    }, [renewals]); // eslint-disable-line react-hooks/exhaustive-deps

    const renewalData = setSelectedRenewal(renewalState.data);

    // handle sort and column filter
    useEffect(() => {
        setRenewalGridState({...renewalGridState, sort: sort});
    }, [sort]); // eslint-disable-line react-hooks/exhaustive-deps

    // initialize my renewals table
    useEffect(() => {
        if (renewals.length) {
            setRenewalState(
                process(
                    renewals.map((renewal) => ({
                        ...renewal,
                        'selected': renewalSelectedState[renewalIdGetter(renewal)],
                    })),
                    initialGridState
                )
            );
        }
    }, [renewals]); // eslint-disable-line react-hooks/exhaustive-deps

    const CustomOrderNumberCell = (props) => <OrderNumberCell
        {...props}
        getStandaloneRenewalLicenses={getStandaloneRenewalLicenses}
    />

    return (
        <>
            <Row>
                <div
                    className={"k-h4"}
                >
                    <Text
                        textkey={supportSubscriptionKey}
                        textdefault={mainMessages[siteLanguageDefault][supportSubscriptionKey]}
                    />
                </div>
            </Row>
            <Grid
                className={"renew-standalone-grid"}
                scrollable={"none"}
                sortable={true}
                sort={sort}
                rowSelection={true}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: 'single',
                }}
                data={orderBy(renewalData, sort)}
                rowRender={RowRender}
                onDataStateChange={onRenewalStateChange}
                onSelectionChange={onRenewalSelectionChange}
                onSortChange={(e) => {
                    setSort(e.sort);
                }}
                {...renewalGridState}
            >
                <GridNoRecords>
                    {gridNoRecordsMessage}
                </GridNoRecords>
                <GridColumn
                    field="order_number"
                    title={localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey])}
                    cell={NoWrapCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="product_number"
                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                    cell={CustomOrderNumberCell}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="description"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="qty"
                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                    sortable={false}
                    cell={TextAlignMiddleCell}
                />
            </Grid>
        </>
    );
}

export default SelectSubscription;
