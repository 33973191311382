import React, {useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import {DownloadCell} from "../components/SADownload/DownloadCell";
import {ColumnMenu, DateCell} from "../components/common/Grid";

// components
import PageHeader from '../components/common/PageHeader.js';
import Spinner from '../components/common/Spinner.js';

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {orderBy, process} from "@progress/kendo-data-query";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    viewAndDownloadActiveLicensesKey,
    myLicensesKey,
    productNumberKey,
    descriptionKey,
    assetKey,
    hostIdKey,
    transactionKey,
    checkoutDateKey,
    aliasKey,
    noLicenseAvailableViewDownloadKey,
} from "../assets/text/MultilingualText";


// consts for my ea table
const initialGridState = {
    take: 25, skip: 0,
};

function SADownload(props) {
    const {
        siteLanguageDefault,
        accessToken
    } = useContext(UserContext);

    const localization = useLocalization();
    const [isLoading, setIsLoading] = useState(false);

    const [downloads, setDownloads] = useState([]);
    const [downloadsGridState, setDownloadsGridState] = useState(initialGridState);
    const [downloadsState, setDownloadsState] = useState(
        process(downloads.map((download) => ({
            ...download,
        })), initialGridState)
    );

    /*
     * onDownloadsStateChange(event) sets the downloads grid state and downloads state on grid's state change
     * @param {event} the grid selection change event
    */
    const onDownloadsStateChange = useCallback((event) => {
        const newDownloadsState = process(
            downloads.map((download) => ({
                ...download,
            })),
            event.dataState
        );
        setDownloadsGridState(event.dataState);
        setDownloadsState(newDownloadsState);
    }, [downloads]);

    //Sets the return value for before unload use effect. Return value can be a string.
    //This needs to be useCallback so React memorizes the function on every rerender
    const handleBeforeUnload = useCallback((e) => {
        e.preventDefault();
        e.returnValue = true;
    }, [])

    const getDownloads = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        setIsLoading(true);
        axios.get(
            `${config.software_access.SOFTWARE_ACCESS}?module=view-download`,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    data.forEach((download, index) => {
                        // sets date correctly for filtering
                        if (download.checkout_date !== null) {
                            download.checkout_date_copy = new Date(download.checkout_date.split(" ")[0].concat(' 00:00:00'));
                        }
                    });

                    // sort the data by product number
                    let orderByProductNum = orderBy(data, [{
                        field: "product_number",
                        dir: "asc"
                    }]);
                    // handle initial load/async data states for downloads
                    setDownloads(orderByProductNum);
                    const newDownloadsState = process(
                        orderByProductNum.map((download) => ({
                            ...download,
                        })),
                        initialGridState
                    );
                    setDownloadsState(newDownloadsState);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Data", error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getDownloads();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <div
                    className={"k-h2"}
                >
                    {localization.toLanguageString(viewAndDownloadActiveLicensesKey, mainMessages[siteLanguageDefault][viewAndDownloadActiveLicensesKey])}
                </div>
                <div
                    className={"k-h4"}
                >
                    {localization.toLanguageString(myLicensesKey, mainMessages[siteLanguageDefault][myLicensesKey])}
                </div>
                <div
                    style={{
                        "marginBottom": "1.25em"
                    }}
                >
                    <Grid
                        className={'SADownload-grid'}
                        scrollable={"none"}
                        sortable={true}
                        pageable={(downloads.length <= 25) ? false : {
                            buttonCount: 5, pageSizes: [10, 25, 50, 100],
                        }}
                        total={downloadsState.total}
                        data={downloadsState}
                        onDataStateChange={onDownloadsStateChange}
                        sort={[{
                            field: "product_number",
                            dir: "asc",
                        }]}
                        {...downloadsGridState}
                    >
                        <GridNoRecords>
                            {localization.toLanguageString(noLicenseAvailableViewDownloadKey, mainMessages[siteLanguageDefault][noLicenseAvailableViewDownloadKey])}
                        </GridNoRecords>
                        <GridColumn
                            field="product_number"
                            title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="description"
                            title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="host_id"
                            title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="alias"
                            title={localization.toLanguageString(aliasKey, mainMessages[siteLanguageDefault][aliasKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="asset"
                            title={localization.toLanguageString(assetKey, mainMessages[siteLanguageDefault][assetKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="transaction"
                            title={localization.toLanguageString(transactionKey, mainMessages[siteLanguageDefault][transactionKey])}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            field="checkout_date_copy"
                            title={localization.toLanguageString(checkoutDateKey, mainMessages[siteLanguageDefault][checkoutDateKey])}
                            cell={DateCell}
                            filter={'date'}
                            columnMenu={ColumnMenu}
                        />
                        <GridColumn
                            cell={(props) =>
                                <DownloadCell
                                    setIsLoading={setIsLoading}
                                    handleBeforeUnload={handleBeforeUnload}
                                    {...props}
                                />
                            }
                            width={"1.25rem"}
                        />
                    </Grid>
                </div>
            </div>
        </>
    );

}

export default SADownload;