import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMSACheckoutStepper from '../assets/img/Help/ksm-sa-checkout-stepper.png';
import KSMSACheckoutSearch from '../assets/img/Help/ksm-sa-checkout-search.png';
import KSMSACheckoutCart from '../assets/img/Help/ksm-sa-checkout-cart.png';
import KSMSACheckoutCartButton from '../assets/img/Help/ksm-sa-checkout-cart-button.png';
import KSMSACheckoutDeleteButton from '../assets/img/Help/ksm-sa-checkout-delete-button.png';
import KSMSACheckoutDelete from '../assets/img/Help/ksm-sa-checkout-delete.png';
import KSMSACheckoutSelected from '../assets/img/Help/ksm-sa-checkout-selected.png';
import KSMSACheckoutAssignHostModal from '../assets/img/Help/ksm-sa-checkout-assign-host-modal.png';
import KSMSACheckoutAssignedProducts from '../assets/img/Help/ksm-sa-checkout-assigned-products.png';
import KSMSACheckoutClientInformation from '../assets/img/Help/ksm-sa-checkout-client-information.png';
import KSMSACheckoutReview from '../assets/img/Help/ksm-sa-checkout-review.png';
import KSMSACheckoutProcessing from '../assets/img/Help/ksm-sa-checkout-processing.png';
import KSMSACheckoutCompleted from '../assets/img/Help/ksm-sa-checkout-completed.png';
import KSMSACheckoutEmailModal from '../assets/img/Help/ksm-sa-checkout-email-modal.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    aboutSoftwareAccessKey,
    helpSAAboutSectionKey,
    checkOutLicensesKey,
    quickStepsToCheckoutKey,
    helpSACheckoutStepsSectionKey,
    navigationKey,
    helpSACheckoutNavigationSectionKey,
    selectingProductsKey,
    helpSACheckoutSelectingProductsSectionKey,
    assigningProductsToHostKey,
    helpSACheckoutAssigningProductsSectionKey,
    clientInfoKey,
    helpSACheckoutClientInformationSectionKey,
    reviewCompleteKey,
    helpSACheckoutReviewSectionKey,
    frequentlyAskedQuestionsKey,
    helpSACheckoutQAKey,
    mainMessages,
} from '../assets/text/MultilingualText.js';


function SACheckoutHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;

        let img;
        let img2;
        let imgInlineStyle = (item.img === "ksm-sa-checkout-cart-button" ||
            item.img === "ksm-sa-checkout-delete-button") ? {display: 'flex', alignItems: 'center'} : {};

        if (item.img) {
            switch (item.img) {
                case 'ksm-sa-checkout-cart-button':
                    img = <img src={KSMSACheckoutCartButton} alt={item.img}/>
                    img2 = <img src={KSMSACheckoutCart} alt={item.img2}/>
                    break;
                case 'ksm-sa-checkout-delete-button':
                    img = <img src={KSMSACheckoutDeleteButton} alt={item.img}/>
                    img2 = <img src={KSMSACheckoutDelete} alt={item.img2}/>
                    break;
                case 'ksm-sa-checkout-stepper':
                    img = <img src={KSMSACheckoutStepper} alt={item.img}/>
                    break;
                case 'ksm-sa-checkout-search':
                    img = <img src={KSMSACheckoutSearch} alt={item.img}/>
                    break;
                case 'ksm-sa-checkout-selected':
                    img = <img src={KSMSACheckoutSelected} alt={item.img} width={1000}/>
                    break;
                case 'ksm-sa-checkout-assign-host-modal':
                    img = <img src={KSMSACheckoutAssignHostModal} alt={item.img}/>
                    break;
                case 'ksm-sa-checkout-assigned-products':
                    img = <img src={KSMSACheckoutAssignedProducts} alt={item.img}/>
                    break;
                default:
                    img = <></>
                    break;
            }
        }

        if (item.substeps && item.substeps.length) {
            children = type === "bullet" ? <ul style={{listStyleType: 'disc'}}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ul> : <ol type={type}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        if (item.img === "ksm-sa-checkout-cart-button" ||
            item.img === "ksm-sa-checkout-delete-button") {
            return (
                <li>
                    <div style={imgInlineStyle}>
                        {toHtml(item.step_prefix)}
                        {item.img ? <span className="img-container">
                            {img}
                        </span> : <></>}
                        {toHtml(item.step_suffix)}
                    </div>
                    {item.img2 ? <span>
                            {img2}
                        </span> : <></>}
                    {children}
                </li>
            )
        }

        return (
            <li>
                <div style={imgInlineStyle}>
                    {toHtml(item.step)}
                    {item.img ? <span>
                        {img}
                    </span> : <></>}
                    {item.img2 ? <span>
                            {img2}
                        </span> : <></>}
                </div>
                {children}
            </li>
        );
    }

    const helpContent = {
        'header': {
            key: checkOutLicensesKey,
            default: mainMessages[siteLanguageDefault][checkOutLicensesKey]
        },
        'topics': [
            {
                key: aboutSoftwareAccessKey,
                default: mainMessages[siteLanguageDefault][aboutSoftwareAccessKey],
                hash: '#about'
            },
            {
                key: quickStepsToCheckoutKey,
                default: mainMessages[siteLanguageDefault][quickStepsToCheckoutKey],
                hash: '#steps'
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: '#navigation'
            },
            {
                key: selectingProductsKey,
                default: mainMessages[siteLanguageDefault][selectingProductsKey],
                hash: '#selecting'
            },
            {
                key: assigningProductsToHostKey,
                default: mainMessages[siteLanguageDefault][assigningProductsToHostKey],
                hash: '#assigning'
            },
            {
                key: clientInfoKey,
                default: mainMessages[siteLanguageDefault][clientInfoKey],
                hash: '#client-info'
            },
            {
                key: reviewCompleteKey,
                default: mainMessages[siteLanguageDefault][reviewCompleteKey],
                hash: '#review'
            },
        ],
        'faq': [{
            key: frequentlyAskedQuestionsKey,
            default: mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey],
            hash: '#faq'
        }],
    };

    const AboutSectionKey = localization.toLanguageString(
        helpSAAboutSectionKey,
        mainMessages[siteLanguageDefault][helpSAAboutSectionKey]
    );

    const StepsSectionKey = localization.toLanguageString(
        helpSACheckoutStepsSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckoutStepsSectionKey]
    );

    const NavigationSectionKey = localization.toLanguageString(
        helpSACheckoutNavigationSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckoutNavigationSectionKey]
    );

    const SelectingProductsSectionKey = localization.toLanguageString(
        helpSACheckoutSelectingProductsSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckoutSelectingProductsSectionKey]
    );

    const AssigningProductsSectionKey = localization.toLanguageString(
        helpSACheckoutAssigningProductsSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckoutAssigningProductsSectionKey]
    );

    const ClientInformationSectionKey = localization.toLanguageString(
        helpSACheckoutClientInformationSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckoutClientInformationSectionKey]
    );

    const ReviewSectionKey = localization.toLanguageString(
        helpSACheckoutReviewSectionKey,
        mainMessages[siteLanguageDefault][helpSACheckoutReviewSectionKey]
    );

    const QAKey = localization.toLanguageString(
        helpSACheckoutQAKey,
        mainMessages[siteLanguageDefault][helpSACheckoutQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="about"
                        >
                            <Text
                                textkey={aboutSoftwareAccessKey}
                                textdefault={mainMessages[siteLanguageDefault][aboutSoftwareAccessKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {AboutSectionKey.section_one}
                            {AboutSectionKey.section_one_bullets ? <ul>
                                {AboutSectionKey.section_one_bullets.map((bullet, index) => <li
                                    key={'sa-about-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="steps"
                        >
                            <Text
                                textkey={quickStepsToCheckoutKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsToCheckoutKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(StepsSectionKey.section_one)}
                            <ol type="1">
                                {StepsSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(NavigationSectionKey.section_one)}
                            {NavigationSectionKey.section_one_steps ? <ul>
                                {NavigationSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="selecting"
                        >
                            <Text
                                textkey={selectingProductsKey}
                                textdefault={mainMessages[siteLanguageDefault][selectingProductsKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(SelectingProductsSectionKey.section_one)}
                            <ol type="1">
                                {SelectingProductsSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="assigning"
                        >
                            <Text
                                textkey={assigningProductsToHostKey}
                                textdefault={mainMessages[siteLanguageDefault][assigningProductsToHostKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(AssigningProductsSectionKey.section_one)}
                            <ol type="1">
                                {AssigningProductsSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="client-info"
                        >
                            <Text
                                textkey={clientInfoKey}
                                textdefault={mainMessages[siteLanguageDefault][clientInfoKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(ClientInformationSectionKey.section_one)}
                            {ClientInformationSectionKey.section_one_steps ? <ul>
                                {ClientInformationSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ul> : <></>}
                            <img src={KSMSACheckoutClientInformation} alt={'ksm-sa-checkout-client-information'}/>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="review"
                        >
                            <Text
                                textkey={reviewCompleteKey}
                                textdefault={mainMessages[siteLanguageDefault][reviewCompleteKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(ReviewSectionKey.section_one)}
                            <img src={KSMSACheckoutReview} alt={'ksm-sa-checkout-review'}/>
                            {toHtml(ReviewSectionKey.section_two)}
                            {ReviewSectionKey.section_two_steps ? <ul>
                                {ReviewSectionKey.section_two_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ul> : <></>}
                        </div>
                        <div>
                            <img src={KSMSACheckoutProcessing} alt={'ksm-sa-checkout-processing'}/>
                        </div>
                        <div>
                            <img src={KSMSACheckoutCompleted} alt={'ksm-sa-checkout-completed'}/>
                        </div>
                        <div>
                            <img src={KSMSACheckoutEmailModal} alt={'ksm-sa-checkout-email-modal'}/>
                        </div>
                        <div className={"k-h2 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="faq">
                            <ul className="indent">
                                {QAKey ? QAKey.map((questionAnswer, index) => {
                                    return (
                                        <div key={"category-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <br/>
                                                <p className="top indent">
                                                    {questionAnswer.A}
                                                </p>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default SACheckoutHelp;

