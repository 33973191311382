import React, {useContext} from 'react';
import config from '../../config.js';
import UserContext from './UserContext.js';

import {useHistory, useLocation} from 'react-router-dom';

// css
import '../../assets/css/App.css';

// kendo react
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Button} from "@progress/kendo-react-buttons";

// multilingual
import {Text} from './MultilingualText.js';
import {
    sessionExpiredKey,
    mainMessages,
    refreshKey
} from '../../assets/text/MultilingualText.js';


function Expired() {
    const {siteLanguageDefault} = useContext(UserContext);
    const expiredStyle = {
        display: "flex",
        verticalAlign: "middle",
        textAlign: "center"
    };

    const iconStyle = {
        fontSize: "5.375rem"
    }

    let location = useLocation();
    const history = useHistory();

    const refresh = () => {
        if (location.pathname === '/trial-license') {
            let prodNum = new URLSearchParams(window.location.search).get("ProdNum");
            window.location.href = config.LEGACY_ASM + "TrialLicense.aspx?ProdNum=" + prodNum;
        } else {
            history.go(0);
        }
    }

    return (
        <Dialog style={expiredStyle}>
            <span className="k-icon k-i-clock" style={iconStyle}></span>
            <h2>
                <Text
                    textkey={sessionExpiredKey}
                    textdefault={mainMessages[siteLanguageDefault][sessionExpiredKey]}
                />
            </h2>
            <DialogActionsBar>
                <Button
                    size={"large"}
                    shape={"rectangle"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={refresh}
                    className={"k-button"}
                >
                    <Text
                        textkey={refreshKey}
                        textdefault={mainMessages[siteLanguageDefault][refreshKey]}
                    />
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default Expired;