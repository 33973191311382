import React, {useCallback, useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// react router
import {useLocation} from 'react-router-dom';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMSoftwareupdateOverview from '../assets/img/Help/ksm-help-software-update-overview.png';
import KSMSoftwareupdateSpecific from '../assets/img/Help/ksm-help-software-update-specific.png'
import KSMSoftwareupdateGettingUpdatedFiles from '../assets/img/Help/ksm-help-software-update-getting-files.png'

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    errorHandlingKey,
    helpSoftwareUpdateErrorHandlingContentKey,
    swUpdatesKey,
    helpSoftwareUpdateFaqQAKey,
    frequentlyAskedQuestionsKey,
    helpSoftwareUpdateGettingFilesContentKey,
    gettingUpdateFilesKey,
    ksmSwUpdatesKey,
    mainMessages,
    helpSoftwareUpdateOverviewContentKey,
    swUpdatesOverview,
    softwareUpdatesOverviewKey,
    helpSoftwareUpdateSearchContentKey,
    searchingSpecificUpdateKey,
} from '../assets/text/MultilingualText.js';


function SoftwareUpdateHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();
    const {hash, key} = useLocation();

    const helpMenuContent = {
        'header': {
            key: ksmSwUpdatesKey,
            default: mainMessages[siteLanguageDefault][ksmSwUpdatesKey]
        },
        'topics': [
            {
                key: swUpdatesOverview,
                default: mainMessages[siteLanguageDefault][swUpdatesOverview],
                hash: "#overview"
            },
            {
                key: searchingSpecificUpdateKey,
                default: mainMessages[siteLanguageDefault][searchingSpecificUpdateKey],
                hash: "#search"
            },
            {
                key: gettingUpdateFilesKey,
                default: mainMessages[siteLanguageDefault][gettingUpdateFilesKey],
                hash: "#update"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handling"
            }
        ],
        'faq': [
            {
                key: swUpdatesKey,
                default: mainMessages[siteLanguageDefault][swUpdatesKey],
                hash: "#faq-update-sw"
            }
        ]
    };

    const helpSoftwareUpdateOverviewContent = localization.toLanguageString(
        helpSoftwareUpdateOverviewContentKey,
        mainMessages[siteLanguageDefault][helpSoftwareUpdateOverviewContentKey]
    );

    const helpSoftwareUpdateSearchContent = localization.toLanguageString(
        helpSoftwareUpdateSearchContentKey,
        mainMessages[siteLanguageDefault][helpSoftwareUpdateSearchContentKey]
    );

    const helpSoftwareUpdateGettingFilesContent = localization.toLanguageString(
        helpSoftwareUpdateGettingFilesContentKey,
        mainMessages[siteLanguageDefault][helpSoftwareUpdateGettingFilesContentKey]
    );

    const helpSoftwareUpdateContent = localization.toLanguageString(
        helpSoftwareUpdateErrorHandlingContentKey,
        mainMessages[siteLanguageDefault][helpSoftwareUpdateErrorHandlingContentKey]
    );

    const helpSoftwareUpdateFaqQA = localization.toLanguageString(
        helpSoftwareUpdateFaqQAKey,
        mainMessages[siteLanguageDefault][helpSoftwareUpdateFaqQAKey]
    );

    // scroll to hash link
    const updateRef = useCallback(node => {
        if (hash === "#update" && key && node !== null) {
            let rect = node.getBoundingClientRect();
            window.scrollTo({
                top: rect.top,
                left: rect.left
            })
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="overview"
                        >
                            <Text
                                textkey={softwareUpdatesOverviewKey}
                                textdefault={mainMessages[siteLanguageDefault][softwareUpdatesOverviewKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(helpSoftwareUpdateOverviewContent.section_one)}
                            <ul>
                                {helpSoftwareUpdateOverviewContent.section_one_bullets?.map((bullet, idx) => (
                                    <li key={`overview-one-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>
                                ))}
                            </ul>
                            <p>
                                {helpSoftwareUpdateOverviewContent.section_two}
                            </p>
                            <ol type="a">
                                {helpSoftwareUpdateOverviewContent.section_two_bullets?.map((bullet, idx) => (
                                    <li key={`overview-second-${idx}`}>
                                        {toHtml(bullet)}
                                        {
                                            idx === 4 && <img src={KSMSoftwareupdateOverview}
                                                              alt={'software-update-overview'}/>
                                        }
                                    </li>
                                ))}
                            </ol>
                        </div>
                        <div
                            className={"k-h2"}
                            id="search"
                        >
                            <Text
                                textkey={searchingSpecificUpdateKey}
                                textdefault={mainMessages[siteLanguageDefault][searchingSpecificUpdateKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(helpSoftwareUpdateSearchContent.section_one)}
                            <img src={KSMSoftwareupdateSpecific} alt={'update specific search'}/>
                        </div>
                        <div
                            className={"k-h2"}
                            id="update"
                            ref={updateRef}
                        >
                            <Text
                                textkey={gettingUpdateFilesKey}
                                textdefault={mainMessages[siteLanguageDefault][gettingUpdateFilesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpSoftwareUpdateGettingFilesContent.section_one}
                            </p>
                            <ul className="update">
                                {helpSoftwareUpdateGettingFilesContent.section_two_bullets?.map((bullet, idx) => {
                                    return (
                                        <li key={`getting-files-${idx}`}>
                                            {toHtml(bullet.bullet_point)}
                                            <ul>
                                                {bullet?.sub_bullter_points?.map((sub_bullet, subIndx) => (
                                                    <li key={`getting-sub-files-${subIndx}`}>
                                                        {toHtml(sub_bullet)}
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    )
                                })}
                            </ul>
                            <img src={KSMSoftwareupdateGettingUpdatedFiles} alt={'getting-updated-files'}/>
                        </div>
                        <div
                            className={"k-h2"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpSoftwareUpdateContent.section_one}
                            </p>
                            <ul>
                                {helpSoftwareUpdateContent.section_one_bullets?.map((bullet, idx) => (
                                    <li key={`quick-steps-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className={"k-h2 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="faq-update-sw">
                            <h3>
                                <Text
                                    textkey={swUpdatesKey}
                                    textdefault={mainMessages[siteLanguageDefault][swUpdatesKey]}
                                />
                            </h3>
                            <ul>
                                {helpSoftwareUpdateFaqQA?.map((questionAnswer, index) => {
                                    return (
                                        <li key={"sw-update-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <div className="top indent paragraph">
                                                {toHtml(questionAnswer?.A)}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default SoftwareUpdateHelp;

