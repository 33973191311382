import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from "reactstrap";

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import mainImg from '../assets/img/Help/ksm-ccl-transport-main.png';
import queryImg from '../assets/img/Help/ksm-ccl-transport-query.png';
import Step1Imag from '../assets/img/Help/ksm-ccl-transport-step-1.png';
import Step2Imag from '../assets/img/Help/ksm-ccl-transport-step-2.png';
import Step3Imag from '../assets/img/Help/ksm-ccl-transport-step-3.png';
import Step4Imag from '../assets/img/Help/ksm-ccl-transport-step-4.png';
import SaveStep1Imag from '../assets/img/Help/ksm-ccl-save-step-1.png';
import SaveStep2Imag from '../assets/img/Help/ksm-ccl-save-step-2.png';


// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    errorHandlingKey,
    frequentlyAskedQuestionsKey,
    mainMessages,
    transportKey,
    transportOverviewKey,
    helpTransportCCLQuickStepsTransport,
    helpTransportCCLQuickStepsSave,
    helpTransportMain,
    helpTransportSaving,
    helpTransportLicenses,
    helpTransportingBanking,
    helpKalTransportErrorHandling,
    helpTransportCCLQuickStepsTransportContent,
    helpTransportCCLQuickStepsSavingContent,
    helpTransportCCLOverviewContent,
    helpTransportCCLMainContent,
    helpTransportCCLLicensesContent,
    helpTransportCCLSavingContent,
    helpTransportCCLQAKey
} from '../assets/text/MultilingualText.js';


function TransportCCLHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;
        let img;
        let imgInlineStyle = {};


        if (item.img) {
            switch (item.img) {
                case 'ksm-ccl-transport-step-1':
                    img = <img src={Step1Imag} alt={item.img}/>
                    break;
                case 'ksm-ccl-transport-step-2':
                    img = <img src={Step2Imag} alt={item.img}/>
                    break;
                case 'ksm-ccl-transport-step-3':
                    img = <img src={Step3Imag} alt={item.img}/>
                    break;
                case 'ksm-ccl-transport-step-4':
                    img = <img src={Step4Imag} alt={item.img}/>
                    break;
                case 'ksm-ccl-save-step-1':
                    img = <img src={SaveStep1Imag} alt={item.img}/>
                    break;
                case 'ksm-ccl-save-step-2':
                    img = <img src={SaveStep2Imag} alt={item.img}/>
                    break;
                default:
                    img = <></>
                    break;
            }
        }

        let className = type === 1 ? 'nested-counter' : ""

        if (item.substeps && item.substeps.length) {
            children = type === "bullet" ? <ul style={{listStyleType: 'disc'}}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ul> : <ol type={type} className={className}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        return (
            <li className={className}>
                <div style={imgInlineStyle}>
                    {toHtml(item.step)}
                    {item.img ? <span className="img-container">
                        {img}
                    </span> : <></>}
                </div>
                {children}
            </li>
        );
    }

    const helpMenuContent = {
        'header': {
            key: transportKey,
            default: mainMessages[siteLanguageDefault][transportKey]
        },
        'topics': [
            {
                key: transportOverviewKey,
                default: mainMessages[siteLanguageDefault][transportOverviewKey],
                hash: "#overview"
            },
            {
                key: helpTransportCCLQuickStepsTransport,
                default: mainMessages[siteLanguageDefault][helpTransportCCLQuickStepsTransport],
                hash: "#quick-steps-transport"
            },
            {
                key: helpTransportCCLQuickStepsSave,
                default: mainMessages[siteLanguageDefault][helpTransportCCLQuickStepsSave],
                hash: "#quick-steps-save-return"
            },
            {
                key: helpTransportMain,
                default: mainMessages[siteLanguageDefault][helpTransportMain],
                hash: "#main-page"
            },
            {
                key: helpTransportLicenses,
                default: mainMessages[siteLanguageDefault][helpTransportLicenses],
                hash: "#transport-licenses"
            },
            {
                key: helpTransportSaving,
                default: mainMessages[siteLanguageDefault][helpTransportSaving],
                hash: "#saving-returning"
            },
            {
                key: errorHandlingKey,
                default: mainMessages[siteLanguageDefault][errorHandlingKey],
                hash: "#error-handling"
            },
        ],
        'faq': [
            {
                key: helpTransportingBanking,
                default: mainMessages[siteLanguageDefault][helpTransportingBanking],
                hash: "#category-faq"
            }
        ]
    }

    const transportOverviewContent = localization.toLanguageString(
        helpTransportCCLOverviewContent,
        mainMessages[siteLanguageDefault][helpTransportCCLOverviewContent]
    );

    const quickStepsTransportContent = localization.toLanguageString(
        helpTransportCCLQuickStepsTransportContent,
        mainMessages[siteLanguageDefault][helpTransportCCLQuickStepsTransportContent]
    );

    const quickStepsSavingContent = localization.toLanguageString(
        helpTransportCCLQuickStepsSavingContent,
        mainMessages[siteLanguageDefault][helpTransportCCLQuickStepsSavingContent]
    );

    const transportMainContent = localization.toLanguageString(
        helpTransportCCLMainContent,
        mainMessages[siteLanguageDefault][helpTransportCCLMainContent]
    );

    const licensesContent = localization.toLanguageString(
        helpTransportCCLLicensesContent,
        mainMessages[siteLanguageDefault][helpTransportCCLLicensesContent]
    );

    const saveContent = localization.toLanguageString(
        helpTransportCCLSavingContent,
        mainMessages[siteLanguageDefault][helpTransportCCLSavingContent]
    );

    const errorHandlingContent = localization.toLanguageString(
        helpKalTransportErrorHandling,
        mainMessages[siteLanguageDefault][helpKalTransportErrorHandling]
    );

    const categoryQA = localization.toLanguageString(
        helpTransportCCLQAKey,
        mainMessages[siteLanguageDefault][helpTransportCCLQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="over-view"
                        >
                            <Text
                                textkey={transportOverviewKey}
                                textdefault={mainMessages[siteLanguageDefault][transportOverviewKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {transportOverviewContent.section_one}
                            </p>
                            <div className="top indent paragraph">
                                {toHtml(transportOverviewContent.section_two)}
                            </div>
                            <div className="top indent paragraph">
                                {toHtml(transportOverviewContent.section_three)}
                            </div>
                            <p>
                                {transportOverviewContent.section_four}
                            </p>
                            <div className={'paragraph'}>
                                {toHtml(transportOverviewContent.section_five)}
                            </div>
                        </div>

                        <div
                            className={"k-h2 header"}
                            id="quick-steps-transport"
                        >
                            <Text
                                textkey={helpTransportCCLQuickStepsTransport}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportCCLQuickStepsTransport]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {quickStepsTransportContent.section_one}
                            </p>
                            <ol>
                                {quickStepsTransportContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>

                        <div
                            className={"k-h2 header"}
                            id="quick-steps-save-return"
                        >
                            <Text
                                textkey={helpTransportCCLQuickStepsSave}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportCCLQuickStepsSave]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {quickStepsSavingContent.section_one}
                            </p>
                            <ol>
                                {quickStepsSavingContent.section_one_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                        </div>


                        <div
                            className={"k-h2 header"}
                            id="main-page"
                        >
                            <Text
                                textkey={helpTransportMain}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportMain]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {transportMainContent.section_one}
                            </p>
                            <p>
                                {transportMainContent.section_two}
                            </p>
                            <p>
                                {transportMainContent.section_three}
                            </p>

                            <span className="img-container">
                                <img src={mainImg} alt={'ksm-ccl-transport-main'}/>
                            </span>

                            <div style={{
                                marginLeft: '5rem'
                            }}>
                                <div className={'paragraph'}>
                                    {toHtml(transportMainContent.section_four)}
                                </div>

                                <div style={{
                                    marginLeft: '2rem'
                                }}>
                                    {transportMainContent.section_four_steps.map((item, index) => {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                gap: '0.5rem',
                                            }}
                                                 key={index}>
                                                <div>
                                                    {item.order + '.'}
                                                </div>
                                                <div>
                                                    {toHtml(item.step)}
                                                </div>

                                            </div>
                                        )
                                    })}

                                    <p>
                                        {transportMainContent.section_four_note}
                                    </p>
                                </div>

                            </div>

                            <div style={{
                                marginLeft: '5rem'
                            }}>
                                <div className={'paragraph'}>
                                    {toHtml(transportMainContent.section_five)}
                                </div>
                                <div style={{
                                    marginLeft: '2rem'
                                }}>
                                    {transportMainContent.section_five_steps.map((item, index) => {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                gap: '0.5rem',
                                            }}
                                                 key={index}>
                                                <div>
                                                    {item.order + '.'}
                                                </div>
                                                <div>
                                                    {toHtml(item.step)}
                                                </div>

                                            </div>
                                        )
                                    })}

                                    <p>
                                        {transportMainContent.section_five_note}
                                    </p>

                                </div>
                            </div>

                            <p>
                                {transportMainContent.section_six}
                            </p>

                            <span className="img-container">
                                <img src={queryImg} alt={'ksm-ccl-transport-main-query'}/>
                            </span>

                            <p>
                                {transportMainContent.section_seven}
                            </p>
                        </div>

                        <div
                            className={"k-h2 header"}
                            id="transport-licenses"
                        >
                            <Text
                                textkey={helpTransportLicenses}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportLicenses]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {licensesContent.section_one}
                            </p>
                            <ol>
                                {licensesContent.section_two_steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>

                        </div>

                        <div
                            className={"k-h2 header"}
                            id="saving-returning"
                        >
                            <Text
                                textkey={helpTransportSaving}
                                textdefault={mainMessages[siteLanguageDefault][helpTransportSaving]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {saveContent.section_one}
                            </p>
                            <ol>
                                {saveContent.section_two_Steps.map(step => (
                                    <OrderedListItem item={step} key={step.order}/>
                                ))}
                            </ol>
                            <div className={'paragraph'}>
                                {toHtml(saveContent.section_three)}
                            </div>
                            <div className={'paragraph'}>
                                {toHtml(saveContent.section_four)}
                            </div>
                        </div>


                        <div
                            className={"k-h2 header"}
                            id="error-handling"
                        >
                            <Text
                                textkey={errorHandlingKey}
                                textdefault={mainMessages[siteLanguageDefault][errorHandlingKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {errorHandlingContent.section_one}
                            </p>
                            <ul>
                                {errorHandlingContent.section_one_bullets?.map((bullet, idx) => {
                                    return <li key={`error-handling-${idx}`}>
                                        {toHtml(bullet)}
                                    </li>;
                                })}
                            </ul>
                        </div>
                        <div className={"k-h2 header"}>
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="category-faq">
                            <h3>
                                <Text
                                    textkey={helpTransportingBanking}
                                    textdefault={mainMessages[siteLanguageDefault][helpTransportingBanking]}
                                />
                            </h3>
                            <ul className="indent">
                                {categoryQA ? categoryQA.map((questionAnswer, index) => {
                                    return (
                                        <div key={"category-qa-" + index}>
                                            <li>
                                                {toHtml(questionAnswer.Q)}
                                                <div className="top indent paragraph">
                                                    {toHtml(questionAnswer.A)}
                                                </div>
                                            </li>
                                        </div>
                                    )
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default TransportCCLHelp;

