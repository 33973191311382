import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// react strap
import {Col, Row} from 'reactstrap';

// components
import Alert from '../common/Alert.js';
import {EmailDialog} from '../common/EmailModal.js';
import {LicenseDownloadPopover} from './LicenseDownload/Popovers.js';
import {PanelBarHeader} from './LicenseDownload/PanelBarHeader.js';
import {downloadLicenses} from '../common/utilities';
import {RequestUpdateLicensesGrid} from './SelectLicense/Grid';

// kendo react
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    emailReceiveKey,
    emailSuccessKey,
    getAnotherLicenseKey,
    backToSoftwareUpdatesKey,
    downloadAllLicensesKey,
    mailAllKey,
    generatedRetrieveLicensesKey,
    requestSuccessfullyKey,
    myUpdatedLicensedProductsKey,
    notGeneratedAvailableRedeemV2Key,
    genericErrorTitleKey,
} from '../../assets/text/MultilingualText.js';


// context to store popover reference, when to show, and which popover to show
export const PopoverContext = createContext({
    onMouseOver: () => null
});

function LicenseDownload(props) {
    const {
        changeStepActivation,
        hosts,
        setHosts,
        handleBeforeUnload,
        setIsLoading,
        stepperItems,
        setStepperItems,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let history = useHistory();

    const [showPopover, setShowPopover] = useState(false);
    const [popoverType, setPopoverType] = useState('generalError');
    const [popoverRef, setPopoverRef] = useState();
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showGeneralSuccess, setShowGeneralSuccess] = useState(true);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [showNetworkError, setShowNetworkError] = useState(false);
    const [disableEmailDownloadButtons, setDisableEmailDownloadButtons] = useState(true);
    const [disableAssignMoreStepperButtons, setDisableAssignMoreStepperButtons] = useState(true);

    // closes the success general alert
    const alertGeneralSuccessHandler = () => {
        setShowGeneralSuccess(false);
    }

    // closes the success email alert
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    // closes the error network alert
    const alertNetworkErrorHandler = () => {
        setShowNetworkError(false);
    }

    // request new license for every host in assign products state
    useEffect(() => {
        async function requestLicense(host, index) {
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            };

            let params = (new URL(document.location)).searchParams;
            let updateId = params.get('uuid');
            let data = {
                "updateId": updateId,
                "host_id": host.host_id,
                "transaction_id": host.transaction_id
            };

            let formData = new FormData();
            formData.append('Data', JSON.stringify(data));

            let savedHost = hosts[index];
            axios.post(
                config.software_updates.SOFTWARE_SERVICE,
                {
                    module: 'SOFTWARE',
                    sub_module: 'license',
                    action: 'SAVE',
                    input_json: JSON.stringify(data)
                },
                {headers: headers}
            )
                .then((response) => {
                    if (response.status === 200) {
                        savedHost['icons']['download'] = true;
                        savedHost['icons']['loading'] = false;
                    }
                    setHosts([...hosts]);
                })
                .catch((error) => {
                    console.log("ERROR: Failed to request license", error);
                    if (!error.response) {
                        savedHost['icons']['networkError'] = true;
                        savedHost['icons']['loading'] = false;
                    } else {
                        savedHost['icons']['generalError'] = true;
                        savedHost['icons']['loading'] = false;
                    }
                    setHosts([...hosts]);
                });
        }

        hosts.forEach((host, index) => {
            if (host.selected) {
                requestLicense(host, index);
            }
        })
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const [downloadableTransactionIDs, setDownloadableTransactionIDs] = useState([]);

    // keeps track of what transaction ids are downloadable
    useEffect(() => {
        let transactionIDs = [];
        hosts.forEach(host => {
            if (host.selected && host.icons.download) {
                transactionIDs.push(host.transaction_id);
            }
        })
        setDownloadableTransactionIDs(transactionIDs);
    }, [hosts])

    // download all license from all host with the download icon
    const downloadAllLicenses = () => {
        downloadLicenses(downloadableTransactionIDs, accessToken, setIsLoading, handleBeforeUnload);
    }

    // show network error if all host have network errors
    useEffect(() => {
        let requiredCount = 0;
        let currentCount = 0;
        hosts.forEach(host => {
            if (host.selected) {
                requiredCount++;
                if (host.icons.networkError) {
                    currentCount++;
                }
            }
        })
        if (requiredCount === currentCount) {
            setShowGeneralSuccess(false);
            setShowNetworkError(true);
        }
    }, [hosts])

// enable or disable email/download selected license button
    useEffect(() => {
        let notFinishedLoading = false;
        let isDownloadable = false;

        hosts.forEach(host => {
            if (host.selected) {
                // enable buttons if all hosts are done loading and at least one downloadable host
                if (host.icons.loading) {
                    notFinishedLoading = true;
                }
                if (host.icons.download) {
                    isDownloadable = true;
                }
            }
        });

        if (notFinishedLoading) {
            setDisableEmailDownloadButtons(true);
        } else {
            if (isDownloadable) {
                setDisableEmailDownloadButtons(false);
            } else {
                setDisableEmailDownloadButtons(true);
            }
        }
    }, [hosts])

// enable or disable stepper and assign more button
    useEffect(() => {
        let notFinishedLoading = false;
        let noUpdatesAvailable = true;

        hosts.forEach(host => {
            if (host.selected) {
                //Enable buttons if all hosts are done loading
                if (host.icons.loading) {
                    notFinishedLoading = true;
                }
            }
            if (!host.selected) {
                noUpdatesAvailable = false;
            }
        })

        let updateStepper = [...stepperItems];
        if (notFinishedLoading || noUpdatesAvailable) {
            setDisableAssignMoreStepperButtons(true);
            updateStepper[0].disabled = true;
        } else {
            setDisableAssignMoreStepperButtons(false);
            updateStepper[0].disabled = false;
        }
        setStepperItems(updateStepper);
    }, [hosts]) // eslint-disable-line react-hooks/exhaustive-deps

// sets which popover to show and it's corresponding reference
    const handlePopoverMouseOver = useCallback((event) => {
        if (event.show) {
            setShowPopover(true);
        } else {
            setShowPopover(false);
        }
        if (event.networkError) {
            setPopoverType('networkError');
        } else {
            setPopoverType('generalError');
        }
        setPopoverRef(event.popoverRef);
    }, [setShowPopover, setPopoverType, setPopoverRef]);

    return (
        <>
            <div
                className={"k-h4"}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(myUpdatedLicensedProductsKey, mainMessages[siteLanguageDefault][myUpdatedLicensedProductsKey])}
            </div>
            <PopoverContext.Provider
                value={{
                    onMouseOver: handlePopoverMouseOver
                }}
            >
                {hosts.length !== 0 && (
                    hosts.map((host) => {
                        if (host.selected) {
                            return (
                                <PanelBar
                                    key={host.transaction_id}
                                    expanded={['.0']}
                                    isControlled={true}
                                    className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                                >
                                    <PanelBarItem
                                        title={<PanelBarHeader
                                            host={host}
                                            setIsLoading={setIsLoading}
                                            handleBeforeUnload={handleBeforeUnload}
                                        />}
                                    >
                                        <RequestUpdateLicensesGrid products={host.products}/>
                                    </PanelBarItem>
                                </PanelBar>
                            )
                        } else {
                            return <></>
                        }
                    })
                )}
            </PopoverContext.Provider>
            <LicenseDownloadPopover
                type={popoverType}
                showPopover={showPopover}
                popoverRef={popoverRef}
            />
            <Row
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '0.938rem'
                        }}
                    >
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"outline"}
                            rounded={"small"}
                            onClick={() => {
                                let currentParams = (new URL(document.location)).searchParams;
                                const params = `uuid=${currentParams.get('uuid')}`;
                                history.push('/software-updates-detail?' + params);
                            }}
                        >
                            {localization.toLanguageString(backToSoftwareUpdatesKey, mainMessages[siteLanguageDefault][backToSoftwareUpdatesKey])}
                        </Button>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"solid"}
                            rounded={"small"}
                            disabled={disableAssignMoreStepperButtons}
                            onClick={() => {
                                changeStepActivation(0);
                            }}
                        >
                            {localization.toLanguageString(getAnotherLicenseKey, mainMessages[siteLanguageDefault][getAnotherLicenseKey])}
                        </Button>
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.938rem'
                        }}
                    >
                        {showGeneralSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={alertGeneralSuccessHandler}
                                title={localization.toLanguageString(requestSuccessfullyKey, mainMessages[siteLanguageDefault][requestSuccessfullyKey])}
                                message={localization.toLanguageString(generatedRetrieveLicensesKey, mainMessages[siteLanguageDefault][generatedRetrieveLicensesKey])}
                            />
                        )}
                        {showEmailSuccess && (
                            <Alert
                                type={'success'}
                                showHandler={alertEmailSuccessHandler}
                                title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                                message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                            />
                        )}
                        {showNetworkError && (
                            <Alert
                                type={'error'}
                                showHandler={alertNetworkErrorHandler}
                                title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                                message={localization.toLanguageString(notGeneratedAvailableRedeemV2Key, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemV2Key])}
                            />
                        )}
                    </div>
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            flexWrap: 'wrap',
                            gap: '0.938rem'
                        }}
                    >
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"solid"}
                            rounded={"small"}
                            icon={"email"}
                            disabled={disableEmailDownloadButtons}
                            onClick={() => {
                                setShowEmailModal(true);
                            }}
                        >
                            {localization.toLanguageString(mailAllKey, mainMessages[siteLanguageDefault][mailAllKey])}
                        </Button>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            shape={"rectangle"}
                            fillMode={"solid"}
                            rounded={"small"}
                            icon={"download"}
                            onClick={downloadAllLicenses}
                            disabled={disableEmailDownloadButtons}
                        >
                            {localization.toLanguageString(downloadAllLicensesKey, mainMessages[siteLanguageDefault][downloadAllLicensesKey])}
                        </Button>
                    </div>
                </Col>
            </Row>
            {showEmailModal && (
                <EmailDialog
                    setShowEmailModal={setShowEmailModal}
                    setShowEmailSuccess={setShowEmailSuccess}
                    transactionIDs={downloadableTransactionIDs}
                    setIsLoading={setIsLoading}
                    request_source='REQ_NEW_LICENSE'
                />
            )}
        </>
    );
}

export default LicenseDownload;