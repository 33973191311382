import React from 'react';

// kendo react
import {Button} from '@progress/kendo-react-buttons';

/**
 * returns styled success, error, and warning alerts
 * @param props
 *      type: success, error, or warning
 *      showHandler: if passed, close button is shown and showHandler will be called on close button onClick.
 *      this custom method should change the show state of the Alert message.
 *      title: bolded top text
 *      message: body text
 * @returns {JSX.Element}
 * @constructor
 */
function Alert(props) {
    let {
        type,
        showHandler,
        title,
        message
    } = props;

    let color;
    let background;
    let icon;
    let fontSize;

    switch (type) {
        case 'success':
            color = 'var(--keysight-green)';
            background = 'var(--keysight-light-green)';
            icon = 'k-icon k-i-check-outline';
            fontSize = '2.725rem';
            break;
        case 'error':
            color = 'var(--keysight-primary)';
            background = 'var(--keysight-pink)';
            icon = 'k-icon k-i-close-outline';
            fontSize = '3.125rem';
            break;
        case 'error2':
            color = 'var(--keysight-primary)';
            background = 'var(--keysight-pink)';
            icon = 'k-icon k-i-warning';
            fontSize = '3.125rem';
            break;
        case 'warning':
            color = 'var(--keysight-yellow)';
            background = 'var(--keysight-light-yellow)';
            icon = 'k-icon k-i-warning';
            fontSize = '3.125rem';
            break;
        default:
            color = 'var(--keysight-purple)';
            background = 'var(--keysight-light-purple)';
            icon = 'k-icon k-i-warning';
            fontSize = '3.125rem';
    }

    return (
        <>
            <div
                style={{
                    'marginTop': '1rem',
                    'background': background,
                    'border': '0.125rem solid var(--keysight-primary)',
                    'borderColor': color,
                    'display': 'flex',
                    'borderRadius': '0.313rem',
                    'minHeight': '5rem',
                    'margin': 0
                }}
                className={"k-messagebox"}
            >
                <div
                    style={{
                        'display': 'flex'
                    }}
                >
                    <div
                        style={{
                            'fontSize': fontSize,
                            'color': color
                        }}
                        className={icon}>
                    </div>
                </div>
                <div
                    style={{
                        'alignItems': 'center',
                        'display': 'flex',
                        'wordBreak': 'normal'
                    }}
                >
                    <div>
                        <div
                            style={{
                                'marginLeft': '0.938rem',
                                'marginRight': '0.938rem',
                                'fontWeight': '700'
                            }}
                        >
                            {title}
                        </div>
                        <div
                            style={{
                                'marginLeft': '0.938rem',
                                'marginRight': '0.938rem'
                            }}
                        >
                            {message}
                        </div>
                    </div>
                </div>
                {showHandler && (
                    <div
                        style={{
                            marginLeft: 'auto',
                            alignSelf: 'center'
                        }}
                    >
                        <Button
                            icon="close"
                            className={"alert-close-button"}
                            onClick={showHandler}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default Alert;