import React, {createContext, useContext, useEffect, useRef, useState} from 'react';

// kendo react
import {DatePicker} from "@progress/kendo-react-dateinputs";
import UserContext from "../../common/UserContext";
import {useLocalization} from "@progress/kendo-react-intl";
import {endDateKey, mainMessages} from "../../../assets/text/MultilingualText";
import {Button} from "@progress/kendo-react-buttons";

export const DatePickerCell = (props) => {
    const {
        value,
        min,
        max
    } = props;

    return (
        <td>
            <DatePicker
                min={min}
                max={max}
                disabled={props.disabled}
                value={value}
                format="MMMM d, y"
                onChange={props.onChange}
            />
        </td>
    );
};

export const StartDatePickerCell = (props) => {
    const {
        type,
        selectedProducts,
        setSelectedProducts,
        transactionID,
        assignedProducts,
        setAssignedProducts,
        headerInfo,
        calculateCost
    } = props;

    const initialStartDate = new Date(props.dataItem.period_start_date);
    const [value, setValue] = useState(initialStartDate);

    /*
     * onProductSelectionChange() onChange for selected products
    */
    const onProductSelectionChange = (ev) => {
        let cost = 0;
        let tempSelectedProducts = [...selectedProducts];
        let tempIndex = tempSelectedProducts.findIndex(p => p.ea_alloc_id === props.dataItem.ea_alloc_id);

        if (tempSelectedProducts[tempIndex]) {
            tempSelectedProducts[tempIndex].period_start_date = ev.value;
            cost = calculateCost(tempSelectedProducts, []);
        }

        // only set start date in selected product if remaining sub pool
        if (cost <= headerInfo?.remaining_pool) {
            let index = selectedProducts.findIndex(p => p.ea_alloc_id === props.dataItem.ea_alloc_id);
            if (selectedProducts[index]) {
                let updates = [...selectedProducts];
                selectedProducts[index].period_start_date = ev.value;
                setSelectedProducts(updates);
                setValue(ev.value);
            }
        }
    }

    /*
     * onAssignedProductsChange() onChange for assigned products
    */
    const onAssignedProductsChange = (ev) => {
        let cost = 0;
        let tempHosts = [...assignedProducts];
        let tempHostIndex = tempHosts.findIndex(host => host.transaction_id === transactionID);

        if (tempHosts[tempHostIndex]) {
            let tempProductIndex = tempHosts[tempHostIndex].products.findIndex(p => p.ea_alloc_id === props.dataItem.ea_alloc_id);
            tempHosts[tempHostIndex].products[tempProductIndex].period_start_date = ev.value;
            cost = calculateCost([], tempHosts);
        }

        // only set start date in host if remaining sub pool
        if (cost <= headerInfo?.remaining_pool) {
            let hosts = [...assignedProducts];
            let hostIndex = assignedProducts.findIndex(host => host.transaction_id === transactionID);

            if (hosts[hostIndex]) {
                let productIndex = hosts[hostIndex].products.findIndex(p => p.ea_alloc_id === props.dataItem.ea_alloc_id);
                hosts[hostIndex].products[productIndex].period_start_date = ev.value;
                setAssignedProducts(hosts);
                setValue(ev.value);
            }
        }
    }

    // set on change method
    const onChange = type === "SELECTED" ? onProductSelectionChange
        : type === "HOST" ? onAssignedProductsChange
            : (ev) => setValue(ev.value);

    return <DatePickerCell
        {...props}
        value={value}
        setValue={setValue}
        disabled={props.dataItem.period_start_date_disabled}
        onChange={onChange}
    />
}

export const EndDatePickerCell = (props) => {
    const {
        type,
        selectedProducts,
        setSelectedProducts,
        transactionID,
        assignedProducts,
        setAssignedProducts,
        headerInfo,
        calculateCost,
        minEndDate,
        maxEndDate,
        datePickerEnabled,
        remixType,
        setUnsavedAssignedChanges
    } = props;

    const initialEndDate = new Date(props.dataItem.period_end_date);
    const [value, setValue] = useState(initialEndDate);

    /*
     * onProductSelectionChange() onChange for selected products
    */
    const onProductSelectionChange = (ev) => {
        let cost = 0;
        let tempSelectedProducts = [...selectedProducts];
        let tempIndex = tempSelectedProducts.findIndex(p => p.ea_alloc_id === props.dataItem.ea_alloc_id);

        if (tempSelectedProducts[tempIndex]) {
            tempSelectedProducts[tempIndex].period_end_date = ev.value;
            cost = calculateCost(tempSelectedProducts, []);
        }

        // only set end date in selected product if remaining sub pool
        if (cost <= headerInfo?.remaining_pool) {
            let index = selectedProducts.findIndex(p => p.ea_alloc_id === props.dataItem.ea_alloc_id);
            if (selectedProducts[index]) {
                let updates = [...selectedProducts];
                selectedProducts[index].period_end_date = ev.value;
                setSelectedProducts(updates);
                setValue(ev.value);
            }
        }
    }

    /*
    * onAssignedProductsChange() onChange for assigned products
   */
    const onAssignedProductsChange = (ev) => {
        let cost = 0;
        let tempHosts = [...assignedProducts];
        let tempHostIndex = tempHosts.findIndex(host => host.transaction_id === transactionID);

        if (tempHosts[tempHostIndex]) {
            let tempProductIndex = tempHosts[tempHostIndex].products.findIndex(p => p.ea_alloc_id === props.dataItem.ea_alloc_id);
            tempHosts[tempHostIndex].products[tempProductIndex].period_end_date = ev.value;
            cost = calculateCost([], tempHosts);
        }

        // only set end date in host if remaining sub pool
        if (cost <= headerInfo?.remaining_pool) {
            let hosts = [...assignedProducts];
            let hostIndex = assignedProducts.findIndex(host => host.transaction_id === transactionID);

            if (hosts[hostIndex]) {
                let productIndex = hosts[hostIndex].products.findIndex(p => p.ea_alloc_id === props.dataItem.ea_alloc_id);
                hosts[hostIndex].products[productIndex].period_end_date = ev.value;
                setAssignedProducts(hosts);

                // update unsaved changes
                setUnsavedAssignedChanges(unsavedAssignedChanges => new Set([
                    ...unsavedAssignedChanges,
                    hosts[hostIndex].products[productIndex].ea_alloc_id
                ]));
                setValue(ev.value);
            }
        }
    }

    // set on change method
    const onChange = type === "SELECTED" ? onProductSelectionChange
        : type === "HOST" ? onAssignedProductsChange
            : (ev) => setValue(ev.value);

    return <DatePickerCell
        {...props}
        min={minEndDate}
        max={maxEndDate}
        // if end date is greater than ea end date set ea end date
        value={maxEndDate && value > maxEndDate &&  type === "HOST" ? maxEndDate : value}
        setValue={setValue}
        disabled={remixType === "variable" ? !datePickerEnabled : props.dataItem.period_end_date_disabled}
        onChange={onChange}
    />
}

export const DateGridContext = createContext({});
export const EndDateHeaderCell = (props) => {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();
    const {
        visible,
        setVisible,
        setAnchor
    } = useContext(DateGridContext);
    const buttonRef = useRef(null);

    useEffect(() => {
        setAnchor(buttonRef.current.element);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <span className="k-cell-inner">
        <span className="k-link">
            <span className="k-column-title">
                {localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
            </span>
            <Button
                className="my-products-end-date-header-btn"
                icon="edit"
                ref={buttonRef}
                onClick={() => setVisible(!visible)}
            />
        </span>
    </span>
};