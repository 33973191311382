import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from "../components/common/utilities.js";

// images
import KSMOverviewDiagram from '../assets/img/Help/ksm-overview-diagram.png';
import KSMOverviewEntitlementNumber from '../assets/img/Help/ksm-overview-entitlement-number.png';

// multilingual
import {Text} from '../components/common/MultilingualText.js';
import {useLocalization} from '@progress/kendo-react-intl';
import {
    entitlementCertificatesKey,
    helpWelcomeEntitlementCertificatesQAKey,
    frequentlyAskedQuestionsKey,
    helpWelcomeHeaderKey,
    ksmOverviewKey,
    helpWelcomeKSMOverviewSectionKey,
    helpWelcomeKSMRegistrationLoginSectionKey,
    helpWelcomeKSMWelcomePageHeaderKey,
    helpWelcomeKSMWelcomePageSectionKey,
    licensesKey,
    helpWelcomeLicensesQAKey,
    loginAndRegistrationKey,
    helpWelcomeLoginRegistrationQAKey,
    mainMessages,
    helpWelcomeQuickStepsSectionKey,
    quickStepsGetLicensesKey,
    registrationLoginKey,
    homePageKey,
    helpWelcomeWelcomePageQAKey,
} from '../assets/text/MultilingualText.js';


function WelcomeHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const helpMenuContent = {
        'header': {
            key: helpWelcomeHeaderKey,
            default: mainMessages[siteLanguageDefault][helpWelcomeHeaderKey]
        },
        'topics': [
            {
                key: quickStepsGetLicensesKey,
                default: mainMessages[siteLanguageDefault][quickStepsGetLicensesKey],
                hash: "#quick-steps"
            },
            {
                key: ksmOverviewKey,
                default: mainMessages[siteLanguageDefault][ksmOverviewKey],
                hash: "#ksm-overview"
            },
            {
                key: registrationLoginKey,
                default: mainMessages[siteLanguageDefault][registrationLoginKey],
                hash: "#registration-login"
            },
            {
                key: homePageKey,
                default: mainMessages[siteLanguageDefault][homePageKey],
                hash: "#welcome"
            }
        ],
        'faq': [
            {
                key: loginAndRegistrationKey,
                default: mainMessages[siteLanguageDefault][loginAndRegistrationKey],
                hash: "#login-registration-faq"
            },
            {
                key: homePageKey,
                default: mainMessages[siteLanguageDefault][homePageKey],
                hash: "#welcome-faq"
            },
            {
                key: entitlementCertificatesKey,
                default: mainMessages[siteLanguageDefault][entitlementCertificatesKey],
                hash: "#entitlement-certificates-faq"
            },
            {
                key: licensesKey,
                default: mainMessages[siteLanguageDefault][licensesKey],
                hash: "#licenses-faq"
            },
        ]
    };

    const helpQuickStepsContent = localization.toLanguageString(
        helpWelcomeQuickStepsSectionKey,
        mainMessages[siteLanguageDefault][helpWelcomeQuickStepsSectionKey]
    );

    const helpKSMOverviewContent = localization.toLanguageString(
        helpWelcomeKSMOverviewSectionKey,
        mainMessages[siteLanguageDefault][helpWelcomeKSMOverviewSectionKey]
    );

    const helpRegistrationLoginContent = localization.toLanguageString(
        helpWelcomeKSMRegistrationLoginSectionKey,
        mainMessages[siteLanguageDefault][helpWelcomeKSMRegistrationLoginSectionKey]
    );

    const helpWelcomeContent = localization.toLanguageString(
        helpWelcomeKSMWelcomePageSectionKey,
        mainMessages[siteLanguageDefault][helpWelcomeKSMWelcomePageSectionKey]
    );

    const helpLoginRegistrationQA = localization.toLanguageString(
        helpWelcomeLoginRegistrationQAKey,
        mainMessages[siteLanguageDefault][helpWelcomeLoginRegistrationQAKey]
    );

    const helpWelcomeQA = localization.toLanguageString(
        helpWelcomeWelcomePageQAKey,
        mainMessages[siteLanguageDefault][helpWelcomeWelcomePageQAKey]
    );

    const helpEntitlementCertificatesQA = localization.toLanguageString(
        helpWelcomeEntitlementCertificatesQAKey,
        mainMessages[siteLanguageDefault][helpWelcomeEntitlementCertificatesQAKey]
    );

    const helpLicensesQA = localization.toLanguageString(
        helpWelcomeLicensesQAKey,
        mainMessages[siteLanguageDefault][helpWelcomeLicensesQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="quick-steps"
                        >
                            <Text
                                textkey={quickStepsGetLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][quickStepsGetLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpQuickStepsContent.section_one}
                            </p>
                            {helpQuickStepsContent.section_one_bullets ? <ol>
                                {helpQuickStepsContent.section_one_bullets.map((bullet, index) => <li
                                    key={'quick-steps-bullet-' + index}>{toHtml(bullet)}</li>)}
                            </ol> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="ksm-overview"
                        >
                            <Text
                                textkey={ksmOverviewKey}
                                textdefault={mainMessages[siteLanguageDefault][ksmOverviewKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpKSMOverviewContent.section_one}
                            </p>
                            {helpKSMOverviewContent.section_one_bullets ? <ul>
                                {helpKSMOverviewContent.section_one_bullets.map((bullet, index) => <li
                                    key={'ksm-overview-bullet-' + index}>{bullet}</li>)}
                            </ul> : <></>}
                            <p>
                                {helpKSMOverviewContent.section_two}
                            </p>
                            <span>
                                <img src={KSMOverviewDiagram} alt="ksm-overview-diagram"/>
                            </span>
                            <p>
                                {helpKSMOverviewContent.section_three}
                            </p>
                            <span>
                                <img src={KSMOverviewEntitlementNumber} alt="ksm-overview-entitlement-number"/>
                            </span>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="registration-login"
                        >
                            <Text
                                textkey={registrationLoginKey}
                                textdefault={mainMessages[siteLanguageDefault][registrationLoginKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {helpRegistrationLoginContent.section_one}
                            </p>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="welcome"
                        >
                            <Text
                                textkey={helpWelcomeKSMWelcomePageHeaderKey}
                                textdefault={mainMessages[siteLanguageDefault][helpWelcomeKSMWelcomePageHeaderKey]}
                            />
                        </div>

                        <hr/>
                        <div className="section">
                            <p>
                                {helpWelcomeContent.section_one}
                            </p>
                            <ul>
                                {Object.entries(helpWelcomeContent.tiles) ?
                                    Object.entries(helpWelcomeContent.tiles).map(([key, value], index) => <li
                                        key={"help-tile-" + index}><b>{key}</b> {value}</li>) : <></>
                                }
                            </ul>
                        </div>
                        <div
                            className={"k-h2 header"}
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="login-registration-faq">
                            <h3>
                                <Text
                                    textkey={loginAndRegistrationKey}
                                    textdefault={mainMessages[siteLanguageDefault][loginAndRegistrationKey]}
                                />
                            </h3>
                            <ul>
                                {helpLoginRegistrationQA ? helpLoginRegistrationQA.map((questionAnswer, index) => {
                                    return (
                                        <li key={"help-licenses-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="welcome-faq">
                            <h3>
                                <Text
                                    textkey={homePageKey}
                                    textdefault={mainMessages[siteLanguageDefault][homePageKey]}
                                />
                            </h3>
                            <ul>
                                {helpWelcomeQA ? helpWelcomeQA.map((questionAnswer, index) => {
                                    return (
                                        <li key={"help-licenses-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="entitlement-certificates-faq">
                            <h3>
                                <Text
                                    textkey={entitlementCertificatesKey}
                                    textdefault={mainMessages[siteLanguageDefault][entitlementCertificatesKey]}
                                />
                            </h3>
                            <ul>
                                {helpEntitlementCertificatesQA ? helpEntitlementCertificatesQA.map((questionAnswer, index) => {
                                    return (
                                        <li key={"help-licenses-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                }) : <></>}
                            </ul>
                        </div>
                        <div id="licenses-faq">
                            <h3>
                                <Text
                                    textkey={licensesKey}
                                    textdefault={mainMessages[siteLanguageDefault][licensesKey]}
                                />
                            </h3>
                            <ul>
                                {helpLicensesQA ? helpLicensesQA.map((questionAnswer, index) => {
                                    return (
                                        <li key={"help-licenses-qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                }) : <></>}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default WelcomeHelp;

