import React, {cloneElement, useCallback, useContext, useEffect, useRef, useState} from 'react';

//components
import UserContext from "../../common/UserContext";
import {ColumnMenu, NoWrapCell} from "../../common/Grid";

//multilingual
import {
    descriptionKey,
    incompatibleProductsSelectedKey,
    infoKey,
    mainMessages,
    productKey,
    qtyKey,
} from "../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";

//kendo
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Checkbox} from "@progress/kendo-react-inputs";
import {filterBy, process} from "@progress/kendo-data-query";
import {Popover} from "@progress/kendo-react-tooltip";

export default function UnassignedProductsGrid(props) {
    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const {
        unassigned,
        setUnassigned,
        currentType,
        selectHeaderCheck,
        setSelectHeaderCheck,
    } = props

    const uniqueID = 'license_id'
    const filterID = 'host_id_type'

    //States and functions for pagination
    const initialGridState = {
        take: 10, skip: 0,
    };
    const [gridState, setGridState] = useState(initialGridState);
    const gridStateRef = useRef(initialGridState)
    const [dataState, setDataState] = useState(
        process(unassigned
            .map((item) => ({
                ...item,
            })), initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        const newDataState = process(
            unassigned
                .map((item) => ({
                    ...item,
                })),
            event.dataState
        );
        gridStateRef.current = event.dataState
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [unassigned]);

    useEffect(() => {
        const newDataState = process(
            unassigned
                .map((item) => ({
                    ...item,
                })),
            gridState
        );
        setDataState(newDataState);
    }, [unassigned]) // eslint-disable-line react-hooks/exhaustive-deps

    //Cell and functions for selection
    const SelectCell = (props) => {
        const anchor = useRef(null);
        const [popoverShow, setPopoverShow] = useState(false);

        // show popover if there is an error on mouse over
        const onMouseOver = (ev) => {
            setPopoverShow(true);
        };

        // remove popover on mouse leave
        const onMouseOut = (ev) => {
            let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
            if (!isChildEl) setPopoverShow(false);
        };

        return <td
            ref={anchor}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {props.dataItem.disabled && //Need CSS pointer-events: auto;
                <Popover
                    show={popoverShow}
                    anchor={anchor.current}
                    position={'right'}
                    className={'ksm-popover ksm-popover-alert-info'}
                    animate={false}
                >
                    <div style={{
                        display: 'flex'
                    }}>
                        <span
                            className="k-icon k-i-information"
                            style={{
                                color: 'var(--keysight-purple)',
                                fontSize: '2.5rem',
                                marginRight: '0.938rem'
                            }}
                        />
                        <div>
                            <b>{localization.toLanguageString(infoKey, mainMessages[siteLanguageDefault][infoKey])}</b>
                            <br/>
                            {localization.toLanguageString(incompatibleProductsSelectedKey, mainMessages[siteLanguageDefault][incompatibleProductsSelectedKey])}
                        </div>
                    </div>
                </Popover>
            }
            <Checkbox
                type="checkbox"
                onChange={props.selectionChange}
                value={props.dataItem.selected}
                disabled={props.dataItem.disabled}
            />
        </td>
    }

    const disableHeaderClass = 'ksm-deactivate-button'
    const [selectHeaderClass, setSelectHeaderClass] = useState(disableHeaderClass)

    //disable or enable header and select all header based on filtered list
    useEffect(() => {
        let filteredData = filterBy(unassigned, gridStateRef.current.filter)
        let selectedCount = filteredData.filter(item => item.selected).length
        let typeCount

        //check all rows has the same host type
        let sameType = true
        if (currentType.current === null) {
            let defaultType = null
            if (filteredData.length > 0) {
                defaultType = filteredData[0][filterID]
                for (const item of filteredData) {
                    if (item[filterID] !== defaultType) {
                        sameType = false
                    }
                }
            } else {
                sameType = false
            }

            if (sameType) {
                currentType.current = defaultType
                setSelectHeaderClass('')
            } else {
                setSelectHeaderClass(disableHeaderClass)
            }
            setSelectHeaderCheck(false)
        } else {
            //count number of types in filtered list and set defaults
            typeCount = filteredData.filter(item => item[filterID] === currentType.current).length
            if (typeCount === 0) {
                typeCount = -1
            }
            //set header checked and disabled value
            setSelectHeaderCheck(selectedCount === typeCount)
            if (typeCount < 1) {
                setSelectHeaderClass(disableHeaderClass)
            } else {
                setSelectHeaderClass('')
            }
        }
    }, [unassigned, dataState]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSelectionChange = useCallback((event) => {
        const checked = event.syntheticEvent.target.checked
        if (currentType.current === null) {
            currentType.current = event.dataItem[filterID]
        }
        let newUnassigned
        let selected = false
        newUnassigned = unassigned.map(item => {
            if (event.dataItem[uniqueID] === item[uniqueID]) {
                item.selected = checked
            }

            item.disabled = item[filterID] !== currentType.current;

            //check if any rows are still selected in whole list
            if (item.selected) {
                selected = true
            }
            return item
        })

        // reset current host type and enable all rows
        if (!selected) {
            currentType.current = null
            newUnassigned = newUnassigned.map(item => {
                item.disabled = false
                return item
            })
        }

        setUnassigned(newUnassigned)
    }, [unassigned]); // eslint-disable-line react-hooks/exhaustive-deps

    const onHeaderSelectionChange = useCallback(event => {
        const checked = event.nativeEvent.target.checked;
        let selected = false

        let newUnassigned = unassigned.map(item => {
            //Change selected based only on the filtered list
            for (const filtered of filterBy(unassigned, gridStateRef.current.filter)) {
                if (item[uniqueID] === filtered[uniqueID]) {
                    item.selected = checked && item[filterID] === currentType.current
                }
            }

            //check if any rows are still selected in whole list
            if (item.selected) {
                selected = true
            }
            return item
        });

        if (!selected) {
            currentType.current = null
        }

        //disable rows based on if no rows have been selected
        newUnassigned = newUnassigned.map(item => {
            if (!selected) {
                item.disabled = false
            } else {
                item.disabled = item[filterID] !== currentType.current
            }
            return item
        })

        setUnassigned(newUnassigned)
    }, [unassigned]); // eslint-disable-line react-hooks/exhaustive-deps

    //Disable rows
    const CustomRowRender = (tr, props) => {
        if (props.dataItem.disabled) {
            return cloneElement(
                tr,
                {...tr.props, className: 'k-state-disabled'},
                tr.props.children
            );
        }
        return tr
    };

    return (
        <Grid
            className={'unassigned-transport-grid'}
            scrollable={'none'}
            data={dataState}
            sortable={true}
            //pagination
            pageable={(unassigned.length <= initialGridState.take) ? false : {
                buttonCount: 5,
                pageSizes: [10, 20, 50, 100],
            }}
            onDataStateChange={onDataStateChange}
            total={dataState.total}
            //select checkboxes
            onHeaderSelectionChange={onHeaderSelectionChange}
            onSelectionChange={onSelectionChange}
            selectedField={'selected'}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: 'multiple'
            }}
            //disable rows
            rowRender={CustomRowRender}
            {...gridState}
        >
            <GridColumn
                field={'selected'}
                cell={SelectCell}
                headerSelectionValue={selectHeaderCheck}
                headerClassName={selectHeaderClass}
            />
            <GridColumn
                field="prod_num"
                title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                columnMenu={ColumnMenu}
                cell={NoWrapCell}
            />
            <GridColumn
                field="prod_desc"
                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                columnMenu={ColumnMenu}
            />
            <GridColumn
                field="qty_redeemed"
                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                columnMenu={ColumnMenu}
                filter={'numeric'}
            />
        </Grid>
    )
}