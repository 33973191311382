import React, {useContext, useEffect, useState, useRef} from 'react';
import axios from "axios";
import config from "../../config";

// components
import UserContext from '../../components/common/UserContext.js';
import UnassignedProductsGrid from "./AssignProducts/UnassignedProductsGrid";
import AssignProductsModal from "./AssignProducts/AssignProductsModal";
import {NoWrapCell, TextAlignMiddleCell} from "../common/Grid";
import {PanelbarHeader} from "./AssignProducts/PanelbarHeader";

// kendo react
import {Button} from "@progress/kendo-react-buttons";
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {orderBy} from "@progress/kendo-data-query";
import {Tooltip} from "@progress/kendo-react-tooltip";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    productsHostKey,
    continueKey,
    myAssignedProductsKey,
    productNumberKey,
    descriptionKey,
    assignQtyKey,
    removeProductKey,
    genericErrorTitleKey,
    contactUsHeaderKey,
    myCartKey,
} from "../../assets/text/MultilingualText";
import Alert from "../common/Alert";


function AssignProducts(props) {
    const {
        siteLanguageDefault,
        accessToken,
        timeout
    } = useContext(UserContext);
    const {
        changeStepActivation,
        isLoading,
        setIsLoading,
        searchProducts,
        setSearchProducts,
        cartProducts,
        setCartProducts,
        assignedProducts,
        setAssignedProducts
    } = props;

    const localization = useLocalization();

    const [genericError, setGenericError] = useState(false);

    // prepare cart products table for assign hosts
    useEffect(() => {
        let newCartProducts = cartProducts
        newCartProducts.forEach((product, index) => {
            // creates a unique id for each row
            product.unique_id = index;
            // reset selected state of rows
            product.selected = false;
            // reset disabled state of rows
            product.disabled = false;
            // sets qty_requested for each row
            // note: if product is 1 per host qty_request is always 1
            if (product.qty_available === 1 || product.redeem_one_per_host === "Y") {
                product.qty_requested = 1;
            } else product.qty_requested = null;
        });
        setCartProducts([...newCartProducts])
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // state to control unassigned products header checkbox
    const [selectHeaderCheck, setSelectHeaderCheck] = useState(false);

    // assign host modal
    const [hostIDType, setHostIDType] = useState("");
    const [hostIDTypePk, setHostIDTypePk] = useState("");
    const [hostModal, setHostModal] = useState("");
    const [hostIsSerial, setHostIsSerial] = useState("");

    const [hostIDLabel, setHostIDLabel] = useState("");
    const [hostIDHint, setHostIDHint] = useState("");
    const [hostIDError, setHostIDError] = useState("");
    const [hostIDPatterns, setHostIDPatterns] = useState([]);
    const [hostIDSuggestions, setHostIDSuggestions] = useState([]);

    const [serialNumberLabel, setSerialNumberLabel] = useState("");
    const [serialNumberHint, setSerialNumberHint] = useState("");
    const [serialNumberError, setSerialNumberError] = useState("");
    const [serialNumberPatterns, setSerialNumberPatterns] = useState([]);

    const assignedProductsRef = useRef(null);
    const productsGridRef = useRef(null);
    let currentType = useRef(null)

    const [isVisible, setIsVisible] = useState({
        modal: false,
        hostIDHint: false,
        serialNumberHint: false
    })

    /*
     * getHostDetails() shows and dynamically renders the assign host modal
    */
    const getHostDetails = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        setGenericError(false);
        setIsLoading(true);

        axios.get(
            config.ea_request_license.HOST,
            {
                headers: headers,
                timeout: timeout,
                params: {
                    module: "SWA",
                    host_id_type_val: cartProducts.filter((product) => product.selected)[0].hostid_type
                }
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = typeof response.data === 'undefined' ? [] : response.data;

                    let modalType = data.hostid_type_popup.toUpperCase() || "";
                    let hostIdType = data.host_id_type.toUpperCase() || "";
                    let hostIdTypePk = data.host_id_type_pk || "";
                    let isSerial = data.get_serial.toUpperCase() || "";

                    let hostLabel = data.host_id_info.asl_prompt || "";
                    let hostHint = data.host_id_info.input_hint || "";
                    let hostError = data.host_id_info.asl_error_message || "";
                    let hostPatterns = data.host_id_info.patterns || [];
                    let serialLabel = data.serial_number_info.asl_prompt || "";
                    let serialHint = data.serial_number_info.input_hint || "";
                    let serialError = data.serial_number_info.asl_error_message || "";
                    let serialPatterns = data.serial_number_info.patterns || [];

                    // list host id suggestions as "host id, alias"
                    // if alias is the same as host id, omit alias from suggestion
                    let hostSuggestions = data.user_profile_tagged_host_ids.map(profile => {
                        let suggestion = profile.node_id !== profile.alias ? profile.node_id + ', ' + profile.alias
                            : profile.node_id;

                        return {
                            ...profile,
                            suggestion: suggestion
                        }
                    }) || [];

                    modalType ? setHostModal(modalType) : setHostModal("");
                    hostIdType ? setHostIDType(hostIdType) : setHostIDType("");
                    hostIdTypePk ? setHostIDTypePk(hostIdTypePk) : setHostIDTypePk("");
                    isSerial ? setHostIsSerial(isSerial) : setHostIsSerial("");

                    hostLabel ? setHostIDLabel(hostLabel) : setHostIDLabel("");
                    hostHint ? setHostIDHint(hostHint) : setHostIDHint("");
                    hostError ? setHostIDError(hostError) : setHostIDError("");
                    hostSuggestions ? setHostIDSuggestions(hostSuggestions) : setHostIDSuggestions([]);

                    serialLabel ? setSerialNumberLabel(serialLabel) : setSerialNumberLabel("");
                    serialHint ? setSerialNumberHint(serialHint) : setSerialNumberHint("");
                    serialError ? setSerialNumberError(serialError) : setSerialNumberError("");

                    // cast pattern strings to regex
                    // note: regex casting removes addtional \ character for all regex patterns
                    // ex. ^\\w{1,20}$ becomes ^\w{1,20}$
                    hostPatterns.length ? hostPatterns = hostPatterns.map(pattern => new RegExp(pattern))
                        : hostPatterns = [];
                    serialPatterns.length ? serialPatterns = serialPatterns.map(pattern => new RegExp(pattern))
                        : serialPatterns = [];

                    // if optional serial numbers append empty string pattern to valid serial number patterns
                    if (isSerial === "O") {
                        let emptyPattern = new RegExp("^$");
                        serialPatterns.push(emptyPattern);
                    }

                    setHostIDPatterns(hostPatterns);
                    setSerialNumberPatterns(serialPatterns);

                    // show modal
                    setIsLoading(false);
                    setIsVisible(isVisible => ({...isVisible, modal: true}));
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Host Type Details", error);
                setIsLoading(false);
                setGenericError(true);
            });
    }

    /*
     * removeAssignedProduct() removes the assigned product from the host and readds the product to the product grid
     * @param {product} the product to be removed from the host
    */
    const removeAssignedProduct = (product) => {
        // update assigned products when removing a product from a host
        let assignedProductUpdates = [...assignedProducts];

        assignedProductUpdates.forEach((host, hostIndex) => {
            if (host.transaction_id === product.transaction_id) {
                // remove product from host's product list
                let productIndex = host.products.findIndex(assignedProduct => assignedProduct.unique_id === product.unique_id);
                host.products.splice(productIndex, 1);

                // delete host is there are no more products
                if (host.products.length === 0) {
                    assignedProductUpdates.splice(hostIndex, 1);
                }
            }

            // add back assigned qty to all the product's qty for each existing host
            host.products.forEach(assignedProduct => {
                if (assignedProduct.line_id === product.line_id) {
                    let qty = parseInt(product.qty_available);
                    let assignQty = parseInt(product.qty_requested);
                    assignedProduct.qty_available = qty + assignQty;
                }
            })
        })

        // update the qty available for the deleted product across all hosts
        assignedProductUpdates.forEach((host, hostIndex) => {
            let productIndex = host.products.findIndex(assignedProduct => assignedProduct.line_id === product.line_id);
            if (productIndex !== -1) {
                let qty = parseInt(product.qty_available);
                let assignQty = parseInt(product.qty_requested);
                assignedProductUpdates[hostIndex].products[productIndex]["qty_available"] = qty + assignQty;
            }
        });

        setAssignedProducts(assignedProductUpdates);

        // update the products for the products grid when removing a product from a host
        let productUpdates = [...cartProducts];
        let index = productUpdates.findIndex(productUpdate => productUpdate.line_id === product.line_id);
        // add assigned qty to the product's qty in the products grid for an existing product
        if (productUpdates[index] && product.line_id === productUpdates[index].line_id) {
            productUpdates[index].qty_available += parseInt(product.qty_requested);
            productUpdates[index].qty_requested = productUpdates[index].qty_available === 1 ? 1 : null;
            if (productUpdates[index].redeem_one_per_host === "Y") {
                productUpdates[index].qty_requested = 1
            }
        }
        // add assigned qty to the product's qty in the products grid for a product that does not exist in products
        else {
            let newProduct = JSON.parse(JSON.stringify(product));

            delete newProduct.transaction_id
            newProduct.qty_available += parseInt(product.qty_requested);
            newProduct.qty_requested = newProduct.qty_available === 1 ? 1 : null;
            if (newProduct.redeem_one_per_host === "Y") {
                newProduct.qty_requested = 1
            }
            productUpdates.push(newProduct);
        }

        setCartProducts(orderBy(productUpdates, [{
            field: 'unique_id',
            dir: 'asc'
        }]));

        // update the products for the search products grid when removing a product from a host
        let searchProductUpdates = [...searchProducts];
        let indexSearch = searchProductUpdates.findIndex(productUpdate => productUpdate.line_id === product.line_id);

        // add assigned qty to the product's qty in the search products grid for an existing product
        if (searchProductUpdates[indexSearch] && product.line_id === searchProductUpdates[indexSearch].line_id) {
            searchProductUpdates[indexSearch].qty_available += parseInt(product.qty_requested);
            searchProductUpdates[indexSearch].qty_requested = searchProductUpdates[indexSearch].qty_available === 1 ? 1 : null;
            if (searchProductUpdates[indexSearch].redeem_one_per_host === "Y") {
                searchProductUpdates[indexSearch].qty_requested = 1
            }
        }
        // add assigned qty to the product's qty in the search products grid for a product that does not exist in products
        else {
            let newProduct = JSON.parse(JSON.stringify(product));

            delete newProduct.transaction_id
            newProduct.qty_available += parseInt(product.qty_requested);
            newProduct.qty_requested = newProduct.qty_available === 1 ? 1 : null;
            if (newProduct.redeem_one_per_host === "Y") {
                newProduct.qty_requested = 1
            }
            searchProductUpdates.push(newProduct);
        }

        setSearchProducts(orderBy(searchProductUpdates, [{
            field: 'unique_id',
            dir: 'asc'
        }]));
    }

    // delete cell with functionality to manipulate assigned products json state
    const deleteCell = (props) => {
        const {
            dataItem
        } = props

        return (
            <td>
                <Tooltip
                    anchorElement="target"
                    showCallout={false}
                    parentTitle={true}
                    openDelay={0}
                    position="top"
                >
                    <button
                        title={localization.toLanguageString(removeProductKey, mainMessages[siteLanguageDefault][removeProductKey])}
                        className={'ksm-icon-button'}
                        onClick={() => removeAssignedProduct(dataItem)}
                    >
                    <span
                        className={"k-icon k-i-delete"}
                        style={{
                            fontSize: '1.25rem'
                        }}
                    />
                    </button>
                </Tooltip>
            </td>
        )
    }

    return (
        <>
            {!!assignedProducts.length && (
                <>
                    <div
                        className={"k-h4"}
                        ref={assignedProductsRef}
                    >
                        {localization.toLanguageString(myAssignedProductsKey, mainMessages[siteLanguageDefault][myAssignedProductsKey])}
                    </div>
                    <PanelBar
                        expandMode={'single'}
                        className={'ksm-panelbar-default'}
                    >
                        {assignedProducts.map((product) =>
                            product ? <PanelBarItem
                                    title={
                                        <PanelbarHeader
                                            hostJSON={product}
                                            cartProducts={cartProducts}
                                            setCartProducts={setCartProducts}
                                            searchProducts={searchProducts}
                                            setSearchProducts={setSearchProducts}
                                            assignedProducts={assignedProducts}
                                            setAssignedProducts={setAssignedProducts}
                                        />
                                    }
                                    key={product.transaction_id}>
                                    <Grid
                                        className={"assign-products-panelbar-grid"}
                                        data={orderBy(product.products, [{ //TODO newly added products need to be sorted too
                                            field: "product_number",
                                            dir: "desc"
                                        }])}
                                        scrollable={'none'}
                                        sortable={false}
                                    >
                                        <GridColumn
                                            cell={deleteCell}
                                            width={"40px"}
                                        />
                                        <GridColumn
                                            field="product_number"
                                            title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                            cell={NoWrapCell}
                                        />
                                        <GridColumn
                                            field="product_desc"
                                            title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                        />
                                        <GridColumn
                                            field="qty_requested"
                                            title={localization.toLanguageString(assignQtyKey, mainMessages[siteLanguageDefault][assignQtyKey])}
                                            cell={TextAlignMiddleCell}
                                        />
                                    </Grid>
                                </PanelBarItem>
                                :
                                <PanelBarItem/>
                        )}
                    </PanelBar>
                    <div
                        className={'k-display-flex k-mt-4 k-mb-8'}
                        style={{
                            justifyContent: 'end',
                        }}
                    >
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"solid"}
                            rounded={"small"}
                            type={"button"}
                            style={{
                                width: '7.188rem'
                            }}
                            onClick={() => {
                                changeStepActivation(2)
                            }}
                        >
                            {localization.toLanguageString(continueKey, mainMessages[siteLanguageDefault][continueKey])}
                        </Button>
                    </div>
                </>
            )}
            {cartProducts.length !== 0 && (
                <div ref={productsGridRef}>
                    <div className={"k-h4"}>
                        {localization.toLanguageString(myCartKey, mainMessages[siteLanguageDefault][myCartKey])}
                    </div>
                    <UnassignedProductsGrid
                        unassigned={cartProducts}
                        setUnassigned={setCartProducts}
                        setHostIDType={setHostIDType}
                        selectHeaderCheck={selectHeaderCheck}
                        setSelectHeaderCheck={setSelectHeaderCheck}
                        currentType={currentType}
                    />
                    <div
                        className={'k-display-flex k-mt-4'}
                        style={{
                            justifyContent: 'end',
                        }}
                    >
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"solid"}
                            rounded={"small"}
                            type={"button"}
                            onClick={getHostDetails}
                            disabled={(cartProducts.filter(item => item.selected).length === 0) || Object.values(cartProducts.filter((product) => product.selected)).every(product => product.qty_requested === null)}
                        >
                            {localization.toLanguageString(productsHostKey, mainMessages[siteLanguageDefault][productsHostKey])}
                        </Button>
                    </div>
                    {genericError && <div
                        style={{
                            marginTop: '0.938rem',
                            marginBottom: '0.938rem'
                        }}
                    >
                        <Alert
                            type={'error'}
                            title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                            message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                        />
                    </div>}
                    {isVisible.modal &&
                        <AssignProductsModal
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            products={cartProducts}
                            setProducts={setCartProducts}
                            assignedProducts={assignedProducts}
                            setAssignedProducts={setAssignedProducts}
                            searchProducts={searchProducts}
                            setSearchProducts={setSearchProducts}
                            setSelectHeaderCheck={setSelectHeaderCheck}
                            hostIDType={hostIDType}
                            setHostIDType={setHostIDType}
                            hostIDTypePk={hostIDTypePk}
                            hostModal={hostModal}
                            setHostModal={setHostModal}
                            hostIsSerial={hostIsSerial}
                            setHostIsSerial={setHostIsSerial}
                            hostIDLabel={hostIDLabel}
                            setHostIDLabel={setHostIDLabel}
                            hostIDHint={hostIDHint}
                            setHostIDHint={setHostIDHint}
                            hostIDError={hostIDError}
                            hostIDPatterns={hostIDPatterns}
                            setHostIDPatterns={setHostIDPatterns}
                            hostIDSuggestions={hostIDSuggestions}
                            serialNumberLabel={serialNumberLabel}
                            setSerialNumberLabel={setSerialNumberLabel}
                            serialNumberHint={serialNumberHint}
                            setSerialNumberHint={setSerialNumberHint}
                            serialNumberError={serialNumberError}
                            serialNumberPatterns={serialNumberPatterns}
                            setSerialNumberPatterns={setSerialNumberPatterns}
                            isVisible={isVisible}
                            setIsVisible={setIsVisible}
                            assignedProductsRef={assignedProductsRef}
                            currentType={currentType}
                        />
                    }
                </div>
            )}
        </>
    );

}

export default AssignProducts;