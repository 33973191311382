import React, {useContext, useRef, useState} from 'react';
import axios from 'axios';
import config from '../../../../config';
import UserContext from '../../UserContext';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {toHtml} from '../../utilities';
import DialogLayout from "./DialogLayout";

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Upload} from '@progress/kendo-react-upload';
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {
    mainMessages,
    specificHostFileKey
} from "../../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";


function FileUploadModal(props) {
    const {
        setIsLoading,
        hostDetails,
        assignedProducts,
        selectedProducts,
        onePerHost = 'redeem_one_per_host',
        productNum = 'product_number',
        uniqueID = 'unique_id',
        errors,
        setErrors,
        showError,
        createHost,
        resetSelectedProducts,
        closeModal,
        children,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let hostModal = hostDetails.hostid_type_popup.toUpperCase() || "";
    let hostIDLabel = hostDetails.host_id_info.asl_prompt || "";
    let hostIDHint = hostDetails.host_id_info.input_hint || "";


    const [hostFile, setHostFile] = useState("");
    const [hostFileExtension, setHostFileExtension] = useState("");

    const [isVisible, setIsVisible] = useState({
        uploadHint: false,
    })

    /*
     * onHostFileUpload() uploads the host file
    */
    const onHostFileUpload = (event) => {
        setErrors([]);
        setHostFileExtension(event.target.files[0].extension);
        setHostFile(event.target.files[0].getRawFile());
    };

    const validate = () => {
        setErrors([]);
        if(!hostFile || !hostFileExtension){
            showError('file')
        }

        if ((hostModal === "C2V" && hostFileExtension === ".c2v") ||
            (hostModal === "HOST_FILE_BIN" && hostFileExtension === ".bin")) {
            validateHostFile();
        }
    }

    /*
     * validate() validates the uploaded .bin or .c2v file
    */
    const validateHostFile = () => {


        let headers = {
            'Authorization': 'Bearer ' + accessToken,
        };

        let formData = new FormData();
        formData.append('File', hostFile);
        formData.append('Output', 'HOSTID');

        setIsLoading(true);

        axios.post(
            config.request_license.HOST_FILE_UPLOAD,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    let fileHostID = data.host_id;
                    let fileHostName = data.file_name;

                    setErrors([]);
                    saveHostID(fileHostID, fileHostName);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Validation File", error);
                let status = error?.response?.status

                if (status === 400) {
                    showError('file')
                } else {
                    showError('generic')
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    /*
     * saveHostID() saves the assigned host
     * @param {fileHostID} is an optional id used for saving hosts for file uploads
     * @param {fileHostName} is an optional name used for saving hosts for file uploads
    */
    const saveHostID = (fileHostID = null, fileHostName = null) => {
        let host;
        let hostId;
        let panelBarTitle;

        if (fileHostName) {
            hostId = fileHostName;
            panelBarTitle = "Host " + fileHostName;
            host = assignedProducts.find(host =>
                host.host_id &&
                hostId &&
                host.host_id === hostId &&
                host.file.name === hostFile.name &&
                host.file.size === hostFile.size &&
                host.file.lastModified === hostFile.lastModified
            );
        } else {
            hostId = fileHostID;
            panelBarTitle = fileHostID;
            host = assignedProducts.find(host =>
                host.host_id &&
                hostId &&
                host.host_id === hostId
            );
        }

        // case: products that are 1 per host can only be assigned to a single host
        if (host) {
            let unAssignable = [];
            selectedProducts.forEach((item) => {
                if (item.selected) {
                    let product = host.products.find(product => item[uniqueID] === product[uniqueID]);
                    if (item[onePerHost].toUpperCase() === "Y" && product) {
                        unAssignable.push(product[productNum]);
                    }
                }
            })

            if (unAssignable.length > 0) {
                unAssignable.forEach((item) => {
                    showError('onePerHost', item);
                })
                return null
            }
        }

        createHost(panelBarTitle, host, hostId, "", hostFile)
        resetSelectedProducts()
    }


    const hostFileButtonAnchor = useRef(null);
    const hostFilePopoverAnchor = useRef(null);
    const onMouseOver = () => {
        setIsVisible(isVisible => ({
            ...isVisible,
            uploadHint: true
        }))
    }
    const onMouseOut = (ev) => {
        let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
        if (!isChildEl) {
            setIsVisible(isVisible => ({
                ...isVisible,
                uploadHint: false
            }))
        }
    }


    let extension = hostModal === "C2V" ? ".c2v"
        : hostModal === "HOST_FILE_BIN" ? ".bin"
            : ""

    return (
        <DialogLayout
            closeModal={closeModal}
            productTable={children}
            errors={errors}
            validate={validate}
        >
            <Row>
                <Col>
                        <span ref={hostFilePopoverAnchor}>
                            <Popover
                                show={isVisible.uploadHint}
                                anchor={hostFilePopoverAnchor.current}
                                position={'top'}
                                className={'ksm-popover ksm-popover-warning'}
                                animate={false}
                                style={{maxWidth: 300}}
                            >
                            <Row>
                                <Col xs={"auto"}>
                                    <span
                                        className="k-icon k-i-warning"
                                        style={{
                                            color: 'var(--keysight-yellow)',
                                            fontSize: '2.5rem'
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <b>
                                        {localization.toLanguageString(specificHostFileKey, mainMessages[siteLanguageDefault][specificHostFileKey])}
                                    </b>
                                    <br/>
                                    {toHtml(hostIDHint)}
                                </Col>
                            </Row>
                        </Popover>
                            <span onMouseOver={onMouseOver}
                                  onMouseOut={onMouseOut}/>
                        </span>

                    <div
                        onMouseOver={() => {
                            setIsVisible(isVisible => ({
                                ...isVisible,
                                uploadHint: true
                            }))
                        }}
                        onMouseOut={(ev) => {
                            let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
                            if (!isChildEl) {
                                setIsVisible(isVisible => ({
                                    ...isVisible,
                                    uploadHint: false
                                }))
                            }
                        }}
                    >
                        <Upload
                            className="host-file-upload"
                            batch={false}
                            multiple={false}
                            withCredentials={false}
                            defaultFiles={[]}
                            restrictions={{
                                allowedExtensions: [extension]
                            }}
                            saveUrl={config.utilities.health}
                            onAdd={onHostFileUpload}
                            selectMessageUI={() =>
                                <Button
                                    ref={hostFileButtonAnchor}
                                    themeColor={"primary"}
                                    size={"large"}
                                    fillMode={"solid"}
                                    rounded={"small"}
                                    type={"button"}
                                >
                                    {hostIDLabel}
                                </Button>
                            }
                        />
                    </div>
                </Col>
            </Row>
        </DialogLayout>
    )
}

export default FileUploadModal;