import React, {useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// components
import {NoWrapCell, TextAlignMiddleCell} from '../common/Grid.js';
import {DeactivateQty} from './SelectLicences/DeactivateQty.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {orderBy} from '@progress/kendo-data-query';

//multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    activationCodeKey,
    cancelKey,
    clearAllQuantitiesKey,
    continueKey,
    currentLicensesKey,
    deactivateAllKey,
    descriptionKey,
    hostIdKey,
    mainMessages,
    productKey,
    qtyCurrentlyAssignedKey,
    qtyDeactivateKey
} from '../../assets/text/MultilingualText.js';


function SelectLicense(props) {
    const {
        changeStepActivation,
        hostID,
        selectedProducts,
        setSelectedProducts
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const [disableClearAll, setDisableClearAll] = useState(true);
    const [disableContinue, setDisableContinue] = useState(true);

    useEffect(() => {
        let enable = false;
        selectedProducts.forEach(item => {
            if (item.qty_deactivate !== null) {
                enable = true;
            }
        })
        setDisableClearAll(!enable);
        setDisableContinue(!enable);
    }, [selectedProducts])

    const clearAll = () => {
        let newData = selectedProducts.map((item) => {
            item.qty_deactivate = null;
            return item;
        });
        setSelectedProducts(newData);
    }

    const deactivateAll = () => {
        let newData = selectedProducts.map((item) => {
            item.qty_deactivate = Number(item['qty_redeemed']);
            return item;
        });
        setSelectedProducts(newData);
    }

    const itemChange = (e) => {
        let newData = selectedProducts.map((item) => {
            if (item.unique_id === e.dataItem.unique_id) {
                if (e.field === 'qty_deactivate') {
                    const qty_redeemed = Number(e.dataItem['qty_redeemed']);
                    if (e.value > qty_redeemed) {
                        e.value = qty_redeemed;
                    }
                    if (e.value < 1) {
                        e.value = null;
                    }
                    item[e.field] = e.value;
                }
            }
            return item;
        });
        setSelectedProducts(newData);
    };

    const sort = [
        {
            field: "product_number",
            dir: "desc"
        }
    ];

    //Used for pagination
    const initialDataState = {
        skip: 0,
        take: 20
    };

    const [page, setPage] = useState(initialDataState);

    const pageChange = (event) => {
        setPage(event.page);
    };

    return (
        <>
            <div
                className={"k-h4"}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])} {hostID}
            </div>
            <div
                className={"k-h4"}
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                {localization.toLanguageString(currentLicensesKey, mainMessages[siteLanguageDefault][currentLicensesKey])}
            </div>
            <Grid
                className={'select-deactivate-license-grid'}
                scrollable={'none'}
                editField="inEdit"
                onItemChange={itemChange}
                data={orderBy(selectedProducts, sort).slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={selectedProducts.length}
                pageable={(selectedProducts.length <= initialDataState.take) ? false : {
                    buttonCount: 5,
                    pageSizes: [10, 20, 50, 100],
                }}
                onPageChange={pageChange}
            >
                <GridColumn
                    field="activation_code"
                    title={localization.toLanguageString(activationCodeKey, mainMessages[siteLanguageDefault][activationCodeKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="product_number"
                    title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="product_desc"
                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                />
                <GridColumn
                    field="qty_redeemed"
                    title={localization.toLanguageString(qtyCurrentlyAssignedKey, mainMessages[siteLanguageDefault][qtyCurrentlyAssignedKey])}
                    cell={TextAlignMiddleCell}
                />
                <GridColumn
                    field="qty_deactivate"
                    title={localization.toLanguageString(qtyDeactivateKey, mainMessages[siteLanguageDefault][qtyDeactivateKey])}
                    cell={DeactivateQty}
                />
            </Grid>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '0.938rem'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '0.938rem'
                    }}
                >
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        shape={"rectangle"}
                        fillMode={"outline"}
                        rounded={"small"}
                        type={"button"}
                        onClick={() => {
                            changeStepActivation(0)
                        }}
                        style={{
                            width: "10.625rem"
                        }}
                    >
                        {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                    </Button>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        shape={"rectangle"}
                        fillMode={"solid"}
                        rounded={"small"}
                        type={"button"}
                        disabled={disableContinue}
                        onClick={() => {
                            changeStepActivation(2)
                        }}
                        style={{
                            width: "10.625rem"
                        }}
                    >
                        {localization.toLanguageString(continueKey, mainMessages[siteLanguageDefault][continueKey])}
                    </Button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '0.938rem'
                    }}
                >
                    <Button
                        themeColor={"secondary"}
                        size={"large"}
                        shape={"rectangle"}
                        fillMode={"solid"}
                        rounded={"small"}
                        type={"button"}
                        onClick={clearAll}
                        disabled={disableClearAll}
                        style={{
                            width: "12.625rem",
                        }}
                    >
                        {localization.toLanguageString(clearAllQuantitiesKey, mainMessages[siteLanguageDefault][clearAllQuantitiesKey])}
                    </Button>
                    <Button
                        themeColor={"secondary"}
                        size={"large"}
                        shape={"rectangle"}
                        fillMode={"solid"}
                        rounded={"small"}
                        type={"button"}
                        onClick={deactivateAll}
                        style={{
                            width: "10.625rem",
                        }}
                    >
                        {localization.toLanguageString(deactivateAllKey, mainMessages[siteLanguageDefault][deactivateAllKey])}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default SelectLicense;