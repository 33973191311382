import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// kendo react
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    notGeneratedSupportAgentV2Key,
    mainMessages,
    notGeneratedAvailableRedeemV2Key,
    genericErrorTitleKey
} from '../../../assets/text/MultilingualText.js';


export const LicenseDownloadPopover = (props) => {
    const {
        type,
        showPopover,
        popoverRef
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let popoverClassName;
    let iconClassName;
    let iconColor;
    let title;
    let message;
    let popoverStyle = {
        maxWidth: '35rem'
    };

    switch (type) {
        case 'networkError':
            popoverClassName = 'ksm-popover-error';
            iconClassName = 'k-i-close-outline';
            iconColor = 'var(--keysight-primary)';
            title = localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey]);
            message = localization.toLanguageString(notGeneratedAvailableRedeemV2Key, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemV2Key]);
            break
        default:
            popoverClassName = 'ksm-popover-error';
            iconClassName = 'k-i-close-outline';
            iconColor = 'var(--keysight-primary)';
            title = localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey]);
            message = localization.toLanguageString(notGeneratedSupportAgentV2Key, mainMessages[siteLanguageDefault][notGeneratedSupportAgentV2Key]);
    }

    return (
        <Popover
            show={showPopover}
            anchor={popoverRef && popoverRef.current}
            position={'left'}
            className={'ksm-popover ' + popoverClassName}
            animate={false}
            style={popoverStyle}
        >
            <div
                style={{
                    display: 'flex',
                }}
            >
                <span
                    className={'k-icon ' + iconClassName}
                    style={{
                        color: iconColor,
                        fontSize: '2.5rem',
                        marginRight: '0.938rem'
                    }}
                />
                <div>
                    <b>{title}</b>
                    <br/>
                    {message}
                </div>
            </div>
        </Popover>
    )
}
