import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KSMSADownloadArrowButton from '../assets/img/Help/ksm-sa-arrow-button.png';
import KSMSADownloadHomeButton from '../assets/img/Help/ksm-sa-home-button.png';
import KSMSADownloadButton from '../assets/img/Help/ksm-sa-download-button.png';
import KSMSAReportFilter from '../assets/img/Help/ksm-sa-report-filter.png';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    softwareUsageReportKey,
    stepsToViewSoftwareUsageReportKey,
    helpSAReportStepsSectionKey,
    columnsDisplayedReportKey,
    helpSAReportColumnsSectionKey,
    aboutSoftwareAccessKey,
    helpSAAboutSectionKey,
    navigationKey,
    helpSANavigationSectionKey,
    mainMessages,
} from '../assets/text/MultilingualText.js';


function SAReportHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    function OrderedListItem({item}) {
        let children = null;
        let type = item.substeps && item.substeps[0] ? item.substeps[0].type : 1;

        let img;
        let imgInlineStyle = (item.img === "ksm-sa-arrow-button" ||
            item.img === "ksm-sa-download-button" ||
            item.img === "ksm-sa-home-button") ? {display: 'flex', alignItems: 'center'} : {};


        if (item.img) {
            switch (item.img) {
                case 'ksm-sa-arrow-button':
                    img = <img src={KSMSADownloadArrowButton} alt={item.img}/>
                    break;
                case 'ksm-sa-download-button':
                    img = <img src={KSMSADownloadButton} alt={item.img}/>
                    break;
                case 'ksm-sa-home-button':
                    img = <img src={KSMSADownloadHomeButton} alt={item.img}/>
                    break;
                case 'ksm-sa-report-filter':
                    img = <img src={KSMSAReportFilter} alt={item.img}/>
                    break;
                default:
                    img = <></>
                    break;
            }
        }

        if (item.substeps && item.substeps.length) {
            children = type === "bullet" ? <ul style={{listStyleType: 'disc'}}>
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ul> : <ol type={type}>s
                {item.substeps.map(i => (
                    <OrderedListItem item={i} key={i.order}/>
                ))}
            </ol>;
        }

        if (item.img === "ksm-sa-arrow-button" ||
            item.img === "ksm-sa-download-button" ||
            item.img === "ksm-sa-home-button") {
            return (
                <li>
                    <div style={imgInlineStyle}>
                        {toHtml(item.step_prefix)}
                        {item.img ? <span className="img-container">
                            {img}
                        </span> : <></>}
                        {toHtml(item.step_suffix)}
                    </div>
                    {children}
                </li>
            )
        }

        return (
            <li>
                <div style={imgInlineStyle}>
                    {toHtml(item.step)}
                    {item.img ? <span className="img-container">
                        {img}
                    </span> : <></>}
                </div>
                {children}
            </li>
        );
    }

    const helpContent = {
        'header': {
            key: softwareUsageReportKey,
            default: mainMessages[siteLanguageDefault][softwareUsageReportKey]
        },
        'topics': [
            {
                key: aboutSoftwareAccessKey,
                default: mainMessages[siteLanguageDefault][aboutSoftwareAccessKey],
                hash: '#about'
            },
            {
                key: stepsToViewSoftwareUsageReportKey,
                default: mainMessages[siteLanguageDefault][stepsToViewSoftwareUsageReportKey],
                hash: '#steps'
            },
            {
                key: columnsDisplayedReportKey,
                default: mainMessages[siteLanguageDefault][columnsDisplayedReportKey],
                hash: '#columns'
            },
            {
                key: navigationKey,
                default: mainMessages[siteLanguageDefault][navigationKey],
                hash: '#navigation'
            },
        ],
        'faq': [],
    };

    const AboutSectionKey = localization.toLanguageString(
        helpSAAboutSectionKey,
        mainMessages[siteLanguageDefault][helpSAAboutSectionKey]
    );

    const NavigationSectionKey = localization.toLanguageString(
        helpSANavigationSectionKey,
        mainMessages[siteLanguageDefault][helpSANavigationSectionKey]
    );

    const StepsSectionKey = localization.toLanguageString(
        helpSAReportStepsSectionKey,
        mainMessages[siteLanguageDefault][helpSAReportStepsSectionKey]
    );

    const ColumnsSectionKey = localization.toLanguageString(
        helpSAReportColumnsSectionKey,
        mainMessages[siteLanguageDefault][helpSAReportColumnsSectionKey]
    );



    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="about"
                        >
                            <Text
                                textkey={aboutSoftwareAccessKey}
                                textdefault={mainMessages[siteLanguageDefault][aboutSoftwareAccessKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {AboutSectionKey.section_one}
                            {AboutSectionKey.section_one_bullets ? <ul>
                                {AboutSectionKey.section_one_bullets.map((bullet, index) => <li
                                    key={'sa-about-bullet-' + index}>
                                    {toHtml(bullet)}
                                </li>)}
                            </ul> : <></>}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="steps"
                        >
                            <Text
                                textkey={stepsToViewSoftwareUsageReportKey}
                                textdefault={mainMessages[siteLanguageDefault][stepsToViewSoftwareUsageReportKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(StepsSectionKey.section_one)}
                            <ol type="1">
                                {StepsSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                            <img src={KSMSAReportFilter} alt="ksm-sa-report-filter"/>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="columns"
                        >
                            <Text
                                textkey={columnsDisplayedReportKey}
                                textdefault={mainMessages[siteLanguageDefault][columnsDisplayedReportKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(ColumnsSectionKey.section_one)}
                            <ol type="1">
                                {ColumnsSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ol>
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="navigation"
                        >
                            <Text
                                textkey={navigationKey}
                                textdefault={mainMessages[siteLanguageDefault][navigationKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {NavigationSectionKey.section_one_steps ? <ul>
                                {NavigationSectionKey.section_one_steps.map(step =>
                                    <OrderedListItem item={step} key={step.order}/>
                                )}
                            </ul> : <></>}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default SAReportHelp;

