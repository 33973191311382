import {useHistory} from "react-router-dom";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import {
    startFromScratchKey,
    ELStartFromScratchWarning1Key,
    ELStartFromScratchWarning2Key,
    ELStartFromScratchWarning3Key,
    cancelKey,
    confirmKey,
    mainMessages
} from "../../assets/text/MultilingualText";

export const StartFromScratchModal = (props) => {
    const {
        localization,
        siteLanguageDefault,
        setShowStartFromScratchModal,
        modalType,
        eaId,
    } = props;

    let history = useHistory();

    const titleStyle = {
        fontWeight: "normal",
        margin: 0,
        color: "#424242"
    };

    const onCloseStartFromScratchModal = () => {
        setShowStartFromScratchModal(false);
    }

    const onClickConfirm = () => {
        let period = (modalType === "currentPeriodStartFromScratch") ?
            "PRODUCTSELECTIONCURRENTPERIOD" : "PRODUCTSELECTIONNEXTPERIOD"
        let params = '?eaid=' + eaId + '&show=' + period
        history.push('/ea-request-license'+params)
    }

    return (
        <Dialog
            className={"start-from-scratch-modal ksm-dialog"}
            title={
                <h2 style={titleStyle}>
                    {localization.toLanguageString(startFromScratchKey, mainMessages[siteLanguageDefault][startFromScratchKey])}
                </h2>
            }
            onClose={onCloseStartFromScratchModal}
            width={"38.25rem"}
        >
            <div
                style={{
                    'display': 'flex'
                }}
            >
                <div
                    style={{
                        'fontSize': "3.125rem",
                        'color': "var(--keysight-yellow)",
                    }}
                    className={"k-icon k-i-warning"}>
                </div>
                <div
                    style={{
                        marginLeft: '1rem',
                        fontSize: '1rem',
                        wordBreak: 'normal',
                    }}
                >
                    <div className={"start-from-scratch-modal-text"}>
                        {localization.toLanguageString(ELStartFromScratchWarning1Key, mainMessages[siteLanguageDefault][ELStartFromScratchWarning1Key])}
                    </div>
                    <div className={"start-from-scratch-modal-text"}>
                        {localization.toLanguageString(ELStartFromScratchWarning2Key, mainMessages[siteLanguageDefault][ELStartFromScratchWarning2Key])}
                    </div>
                    <div>
                        {localization.toLanguageString(ELStartFromScratchWarning3Key, mainMessages[siteLanguageDefault][ELStartFromScratchWarning3Key])}
                    </div>
                </div>
            </div>
            <DialogActionsBar layout="center">
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    shape={"rectangle"}
                    fillMode={"outline"}
                    rounded={"small"}
                    type={"button"}
                    onClick={onCloseStartFromScratchModal}
                >
                    {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                </Button>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    shape={"rectangle"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={onClickConfirm}
                >
                    {localization.toLanguageString(confirmKey, mainMessages[siteLanguageDefault][confirmKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}