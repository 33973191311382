import React, {createRef, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Spinner from '../components/common/Spinner.js';
import PageHeader from '../components/common/PageHeader.js';
import HostConfigGrid from '../components/HostConfig/HostConfigGrid.js';

// kendo react
import {Button} from '@progress/kendo-react-buttons';
import {ExcelExport, ExcelExportColumn} from '@progress/kendo-react-excel-export';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    serialNumberKey,
    exportHostInfoKey,
    aliasKey,
    assetInfoKey,
    cityKey,
    countryKey,
    hostIdKey,
    notesKey,
    stateKey,
    loadingPeriodsKey,
    mainMessages,
    noHostsAvailableKey,
    manageHostsKey,
} from '../assets/text/MultilingualText.js';


// consts for host config table
const initialGridState = {
    skip: 0,
    take: 20
};

function HostConfig(props) {
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let history = useHistory();

    const defaultGridMessage = localization.toLanguageString(
        noHostsAvailableKey,
        mainMessages[siteLanguageDefault][noHostsAvailableKey]
    );
    const defaultLoadingMessage = localization.toLanguageString(
        loadingPeriodsKey,
        mainMessages[siteLanguageDefault][loadingPeriodsKey]
    );

    const [isLoading, setIsLoading] = useState(false);
    const [gridNoRecordsMessage, setGridNoRecordsMessage] = useState(defaultGridMessage);

    const [hosts, setHosts] = useState([]);
    const [hostGridState, setHostGridState] = useState(initialGridState);

    // kendo react excel export
    const [userExcelData, setUserExcelData] = useState([]);
    const [excelFileName, setExcelFileName] = useState("");

    const _exporter = createRef();
    const exportExcel = () => {
        if (_exporter.current) {
            _exporter.current.save();
        }
    };

    /*
    * getHosts() gets all hosts
   */
    const getHosts = () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        setIsLoading(true);
        setGridNoRecordsMessage(defaultLoadingMessage);

        axios.get(
            `${config.host_config.HOSTS}?module=EA`,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.user_hosts_list || [];

                    setIsLoading(false);
                    setHosts(data);
                    setUserExcelData(data);

                    var today = new Date(),
                        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                    setExcelFileName('KSM_HOSTS_' + date + '.xlsx');

                    // reset host grid on edit or save
                    setHostGridState(initialGridState);

                    setGridNoRecordsMessage(defaultGridMessage);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Hosts", error);
                setIsLoading(false);
                setGridNoRecordsMessage(defaultGridMessage);
            });
    }

    // initialize my hosts grid
    useEffect(() => {
        getHosts();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <div className={"k-h2"}>
                        <Text
                            textkey={manageHostsKey}
                            textdefault={mainMessages[siteLanguageDefault][manageHostsKey]}
                        />
                    </div>
                </Row>
                <div
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <HostConfigGrid
                        hosts={hosts}
                        getHosts={getHosts}
                        hostGridState={hostGridState}
                        setHostGridState={setHostGridState}
                        gridNoRecordsMessage={gridNoRecordsMessage}
                        setIsLoading={setIsLoading}
                    />
                </div>
                <Row
                    style={{
                        marginBottom: '0.938rem'
                    }}
                >
                    <Col>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                flexWrap: 'wrap',
                                gap: '0.938rem'
                            }}
                        >
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"solid"}
                                rounded={"small"}
                                icon={"excel"}
                                onClick={exportExcel}
                                disabled={hosts.length === 0}
                            >
                                {localization.toLanguageString(exportHostInfoKey, mainMessages[siteLanguageDefault][exportHostInfoKey])}
                            </Button>
                            <ExcelExport
                                data={userExcelData}
                                fileName={excelFileName}
                                ref={_exporter}
                            >
                                <ExcelExportColumn
                                    field="host_id"
                                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                                />
                                <ExcelExportColumn
                                    field="serial_number"
                                    title={localization.toLanguageString(serialNumberKey, mainMessages[siteLanguageDefault][serialNumberKey])}
                                />
                                <ExcelExportColumn
                                    field="alias"
                                    title={localization.toLanguageString(aliasKey, mainMessages[siteLanguageDefault][aliasKey])}
                                />
                                <ExcelExportColumn
                                    field="asset_info"
                                    title={localization.toLanguageString(assetInfoKey, mainMessages[siteLanguageDefault][assetInfoKey])}
                                />
                                <ExcelExportColumn
                                    field="city"
                                    title={localization.toLanguageString(cityKey, mainMessages[siteLanguageDefault][cityKey])}
                                />
                                <ExcelExportColumn
                                    field="state"
                                    title={localization.toLanguageString(stateKey, mainMessages[siteLanguageDefault][stateKey])}
                                />
                                <ExcelExportColumn
                                    field="country"
                                    title={localization.toLanguageString(countryKey, mainMessages[siteLanguageDefault][countryKey])}
                                />
                                <ExcelExportColumn
                                    field="notes"
                                    title={localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey])}
                                />
                            </ExcelExport>
                        </div>
                    </Col>
                </Row>
                <br/>
            </div>
        </>
    );
}

export default HostConfig;
