import React, {useContext} from 'react';
import UserContext from '../../common/UserContext.js';

// kendo react
import {Popover} from "@progress/kendo-react-tooltip";

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    cloudLicenseMessageKey,
    cloudLicenseKey,
    mainMessages,
    notGeneratedAvailableRedeemKey,
    errorOccurredRequestKey,
    redeemNotAvailableErrorKey,
    cannotGenerateMessageKey,
    cannotGenerateKey,
    notGeneratedSupportAgentKey,
    generationTimeoutKey
} from '../../../assets/text/MultilingualText.js';


export const LicenseDownloadPopover = (props) => {
    const {
        type,
        showPopover,
        popoverRef
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let popoverClassName;
    let iconClassName;
    let iconColor;
    let title;
    let message;
    let popoverStyle = {
        maxWidth: '35rem'
    };

    switch (type) {
        case 'cloudAlert':
            popoverClassName = 'ksm-popover-alert-info';
            iconClassName = 'k-i-information';
            iconColor = 'var(--keysight-purple)';
            title = localization.toLanguageString(
                cloudLicenseKey,
                mainMessages[siteLanguageDefault][cloudLicenseKey]
            );
            message = localization.toLanguageString(
                cloudLicenseMessageKey,
                mainMessages[siteLanguageDefault][cloudLicenseMessageKey]
            );
            break;
        case 'supportError':
            popoverClassName = 'ksm-popover-error';
            iconClassName = 'k-i-close-outline';
            iconColor = 'var(--keysight-primary)';
            title = localization.toLanguageString(
                generationTimeoutKey,
                mainMessages[siteLanguageDefault][generationTimeoutKey]
            );
            message = localization.toLanguageString(
                notGeneratedSupportAgentKey,
                mainMessages[siteLanguageDefault][notGeneratedSupportAgentKey]
            );
            break;
        case 'generationError':
            popoverClassName = 'ksm-popover-error';
            iconClassName = 'k-i-close-outline';
            iconColor = 'var(--keysight-primary)';
            title = localization.toLanguageString(
                errorOccurredRequestKey,
                mainMessages[siteLanguageDefault][errorOccurredRequestKey]
            );
            message = localization.toLanguageString(
                redeemNotAvailableErrorKey,
                mainMessages[siteLanguageDefault][redeemNotAvailableErrorKey]
            );
            break;
        case 'qtyError':
            popoverClassName = 'ksm-popover-error';
            iconClassName = 'k-i-close-outline';
            iconColor = 'var(--keysight-primary)';
            title = localization.toLanguageString(
                cannotGenerateKey,
                mainMessages[siteLanguageDefault][cannotGenerateKey]
            );
            message = localization.toLanguageString(
                cannotGenerateMessageKey,
                mainMessages[siteLanguageDefault][cannotGenerateMessageKey]
            );
            break;
        default:
            popoverClassName = 'ksm-popover-error';
            iconClassName = 'k-i-close-outline';
            iconColor = 'var(--keysight-primary)';
            title = localization.toLanguageString(
                errorOccurredRequestKey,
                mainMessages[siteLanguageDefault][errorOccurredRequestKey]
            );
            message = localization.toLanguageString(
                notGeneratedAvailableRedeemKey,
                mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemKey]
            );
    }

    return (
        <Popover
            show={showPopover}
            anchor={popoverRef && popoverRef.current}
            position={'left'}
            className={'ksm-popover ' + popoverClassName}
            animate={false}
            style={popoverStyle}
        >
            <div
                style={{
                    display: 'flex'
                }}
            >
                <span
                    className={'k-icon ' + iconClassName}
                    style={{
                        color: iconColor,
                        fontSize: '2.5rem',
                        marginRight: '0.938rem',
                    }}
                />
                <div>
                    <b>{title}</b>
                    <br/>
                    {message}
                </div>
            </div>
        </Popover>
    )
}
