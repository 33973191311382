import React, {useCallback, useContext, useRef} from 'react';
import UserContext from '../../common/UserContext.js';

// components
import {PopoverContext} from './SubmitTransport';
import {downloadLicenses} from '../../common/utilities.js';

// react strap
import {Col, Row} from 'reactstrap';

// kendo react
import {Tooltip} from '@progress/kendo-react-tooltip';
import {Loader} from '@progress/kendo-react-indicators';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    downloadLicensesKey,
    newlyAssignedProductsKey,
    loadingGenerationKey
} from '../../../assets/text/MultilingualText.js';


export const PanelbarHeader = (props) => {
    const {
        hostJSON,
        setIsLoading,
        handleBeforeUnload,
        showAssignedCount = true,
        xAuthToken = null,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    //Sets the popover reference based on mouse over
    const popoverRef = useRef(null);
    const {onMouseOver} = useContext(PopoverContext);

    const handleMouseOver = useCallback(() => {
        const icons = hostJSON.icons
        onMouseOver({
            show: true,
            popoverRef: popoverRef,
            cloudAlert: icons.cloudAlert,
            supportError: icons.supportError,
            generationError: icons.generationError,
            qtyError: icons.qtyError,
            entitlementError: icons.entitlementError,
        });
    }, [onMouseOver, hostJSON.icons]);
    const handleMouseOut = useCallback(() => {
        const icons = hostJSON.icons;
        onMouseOver({
            show: false,
            popoverRef: popoverRef,
            cloudAlert: icons.cloudAlert,
            supportError: icons.supportError,
            generationError: icons.generationError,
            qtyError: icons.qtyError,
            entitlementError: icons.entitlementError,
        });
    }, [onMouseOver, hostJSON.icons]);

    return (
        <>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        className={"k-h4"}
                        style={{
                            color: 'white',
                            margin: '0'
                        }}
                    >
                        {hostJSON.panelBarTitle}
                    </div>
                </Col>
                {showAssignedCount && (
                    <Col
                        style={{
                            flex: '0 0 auto',
                            width: 'auto'
                        }}
                    >
                        <div
                            style={{
                                fontSize: '1rem',
                                fontWeight: '300',
                                color: 'white',
                                lineHeight: '1.5rem'
                            }}
                        >
                            {hostJSON.products.length} {localization.toLanguageString(newlyAssignedProductsKey, mainMessages[siteLanguageDefault][newlyAssignedProductsKey])}
                        </div>
                    </Col>
                )}
                <Col>
                    <div
                        style={{
                            float: 'right'
                        }}
                    >
                        <div>
                            {hostJSON.icons.loading && (
                                <Tooltip
                                    anchorElement="pointer"
                                    showCallout={false}
                                    parentTitle={true}
                                    openDelay={0}
                                    filter={(e) => {
                                        const classNames = ['k-loader k-loader-md k-loader-primary k-loader-pulsing-2', 'k-loader-segment', 'k-loader-canvas'];
                                        if (classNames.includes(e.className)) {
                                            return true;
                                        }
                                    }}
                                >
                                    <div
                                        title={localization.toLanguageString(loadingGenerationKey, mainMessages[siteLanguageDefault][loadingGenerationKey])}
                                        style={{
                                            paddingBottom: '1px'
                                        }}
                                    >
                                        <Loader
                                            type={'pulsing'}
                                            style={{
                                                color: 'white'
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {hostJSON.icons.download && (
                                <Tooltip
                                    anchorElement="pointer"
                                    showCallout={false}
                                    parentTitle={true}
                                    openDelay={0}
                                >
                            <span
                                title={localization.toLanguageString(downloadLicensesKey, mainMessages[siteLanguageDefault][downloadLicensesKey])}
                                className="k-icon k-i-download"
                                style={{
                                    color: 'white',
                                    marginLeft: '0.35rem',
                                    marginRight: '0.35rem',
                                    fontSize: '1.3rem',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    downloadLicenses([hostJSON['transaction_id']], xAuthToken, setIsLoading, handleBeforeUnload, 'ONLINE_TRANSPORT_DOWNLOAD', true);
                                }}
                            />
                                </Tooltip>
                            )}
                            {(hostJSON.icons.supportError || hostJSON.icons.generationError || hostJSON.icons.qtyError || hostJSON.icons.entitlementError) &&
                                !hostJSON.icons.download && !hostJSON.icons.cloudAlert && (
                                    <span
                                        className="k-icon k-i-close-outline"
                                        style={{
                                            color: 'var(--keysight-pink)',
                                            marginLeft: '0.35rem',
                                            marginRight: '0.35rem',
                                            fontSize: '1.3rem'
                                        }}
                                        onMouseOver={handleMouseOver}
                                        onMouseOut={handleMouseOut}
                                        ref={popoverRef}
                                    />
                                )}
                            {hostJSON.icons.cloudAlert && (
                                <span
                                    className="k-icon k-i-warning"
                                    style={{
                                        marginLeft: '0.35rem',
                                        marginRight: '0.35rem',
                                        fontSize: '1.3rem'
                                    }}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                    ref={popoverRef}
                                />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}