import React, {cloneElement, useCallback, useEffect, useRef, useState} from 'react';

//kendo
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {filterBy, orderBy, process} from "@progress/kendo-data-query";
import SelectCell from "./SelectCell";

export default function GenericSelectSortGrid(props) {

    const {
        items,
        setItems,
        uniqueID,
        selectID = 'selected',
        initialGridState,
        gridState,
        setGridState,
        gridStateRef,
        selectHeaderCheck,
        setSelectHeaderCheck,
        sortByDisableID,
        sortByDisableValue,
        enableSortByDisable,
        setEnableSortByDisable,
        onItemChange,
        filterOut = () => {
            return true
        },
        children,
        ...otherGridProps
    } = props

    const sortByDisableMin = initialGridState.take

    //States and functions for pagination
    const [dataState, setDataState] = useState(
        process(items
            .filter(filterOut)
            .map((item) => ({
                ...item,
            })), gridState)
    );

    const onDataStateChange = useCallback((event) => {
        let newState = disableSelectSort(event.dataState)
        const newDataState = process(
            items
                .filter(filterOut)
                .map((item) => ({
                    ...item,
                })),
            newState
        );
        gridStateRef.current = newState
        setGridState(newState);
        setDataState(newDataState);
    }, [items]);

    useEffect(() => {
        let newState = disableSelectSort(gridState)

        const newDataState = process(
            items
                .filter(filterOut)
                .map((item) => ({
                    ...item,
                })),
            newState
        );
        setDataState(newDataState);
    }, [items]) // eslint-disable-line react-hooks/exhaustive-deps

    // Sort grid data by disabled and selected based on state
    const sortSelectedUniqueId = useRef(null)
    const disableSelectSort = (state) => {
        if (items.filter(filterOut).length <= sortByDisableMin) {
            return state
        }

        //Add or remove disable from sort array
        if (!state.hasOwnProperty('sort')) {
            state.sort = []
        }
        const selectedIndex = state.sort.findIndex(item => item.field === selectID)
        const disableIndex = state.sort.findIndex(item => item.field === 'disabled')

        if (enableSortByDisable) {
            if (selectedIndex === -1) {
                state.sort.unshift({
                    "field": selectID,
                    "dir": "desc",
                })
            }

            if (disableIndex === -1) {
                state.sort.unshift({
                    "field": "disabled",
                    "dir": "asc",
                })
            }
        } else {
            if (selectedIndex > -1) {
                state.sort.splice(selectedIndex, 1)
            }

            if (disableIndex > -1) {
                state.sort.splice(disableIndex, 1)
            }
        }

        // Move page to the newly selected product
        if (sortSelectedUniqueId.current) {
            let filteredSortData = orderBy(filterBy(items, gridStateRef.current.filter), state.sort)
            const newIndex = filteredSortData.findIndex(item => item[uniqueID] === sortSelectedUniqueId.current) + 1
            state.skip = Math.floor(newIndex / state.take) * state.take
            sortSelectedUniqueId.current = null
        }

        return state
    }

    // override sort class/color in header
    const defaultHeaderClass = 'ksm-default-header'
    const disableHeaderClass = 'ksm-deactivate-header'
    const [selectHeaderClass, setSelectHeaderClass] = useState(disableHeaderClass)

    //disable or enable header and select all header based on filtered list
    useEffect(() => {
        let filteredData = filterBy(items, gridStateRef.current.filter)
        let selectedCount = filteredData.filter(item => item[selectID]).length
        if(selectedCount === 0){
            sortByDisableValue.current = null //handles clear filter
        }
        //check all rows has the same host type
        let typeCount
        let sameType = true
        if (sortByDisableValue.current === null) {
            let defaultType = null
            if (filteredData.length > 0) {
                defaultType = filteredData[0][sortByDisableID]
                for (const item of filteredData) {
                    if (item[sortByDisableID] !== defaultType) {
                        sameType = false
                    }
                }
            } else {
                sameType = false
            }

            if (sameType) {
                sortByDisableValue.current = defaultType
                setSelectHeaderClass(defaultHeaderClass)
            } else {
                setSelectHeaderClass(disableHeaderClass)
            }
            setSelectHeaderCheck(false)
        } else {
            //count number of types in filtered list and set defaults
            typeCount = filteredData.filter(item => item[sortByDisableID] === sortByDisableValue.current).length
            if (typeCount === 0) {
                typeCount = -1
            }
            //set header checked and disabled value
            setSelectHeaderCheck(selectedCount === typeCount)
            if (typeCount < 1) {
                setSelectHeaderClass(defaultHeaderClass)
            } else {
                setSelectHeaderClass('ksm-default-header')
            }
        }

    }, [items, dataState]) // eslint-disable-line react-hooks/exhaustive-deps

    //set current host id type if products are selected
    useEffect(() => {
        let selected = items.filter(item => item[selectID])
        if (selected.length > 0) {
            sortByDisableValue.current = selected[0][sortByDisableID]
            let newItems = items.map(item => {
                item.disabled = item[sortByDisableID] !== sortByDisableValue.current;
                return item
            })
            setItems(newItems)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const newItemSelected = useRef(false)
    const onSelectionChange = useCallback((event) => {
        const checked = event.syntheticEvent.target.checked
        if (sortByDisableValue.current === null) {
            sortByDisableValue.current = event.dataItem[sortByDisableID]
        }
        let newItems
        let oneSelected = false
        let newSortSelectedUniqueId = null
        newItems = items.map((item, index) => {
            if (event.dataItem[uniqueID] === item[uniqueID]) {
                item.selected = checked
                if(checked){
                    newSortSelectedUniqueId = item[uniqueID]
                }
            }

            item.disabled = item[sortByDisableID] !== sortByDisableValue.current;

            //check if any rows are still selected in whole list
            if (item[selectID]) {
                oneSelected = true
            }
            return item
        })

        //Resort grid data based on disable and move page to last selected item
        sortSelectedUniqueId.current = newSortSelectedUniqueId
        if (!oneSelected) {
            sortByDisableValue.current = null
            newItems = newItems.map(item => {
                item.disabled = false
                return item
            })
            setEnableSortByDisable(false)
        } else {
            setEnableSortByDisable(true)
        }

        setItems(newItems)
    }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

    const onHeaderSelectionChange = useCallback(event => {
        const checked = event.nativeEvent.target.checked;
        let oneSelected = false

        let newItems = items.map(item => {
            //Change selected based only on the filtered list
            for (const filtered of filterBy(items, gridStateRef.current.filter)) {

                if (item[uniqueID] === filtered[uniqueID]) {
                    item[selectID] = checked && item[sortByDisableID] === sortByDisableValue.current
                }
            }

            //check if any rows are still selected in whole list
            if (item[selectID]) {
                oneSelected = true
            }
            return item
        });

        if (!oneSelected) {
            sortByDisableValue.current = null
            setEnableSortByDisable(false)
        } else {
            setEnableSortByDisable(true)
        }

        //disable rows based on if no rows have been selected
        newItems = newItems.map(item => {
            if (!oneSelected) {
                item.disabled = false
            } else {
                item.disabled = item[sortByDisableID] !== sortByDisableValue.current
            }
            return item
        })

        setItems(newItems)
    }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

    //Disable rows
    const DisableRowRender = (tr, props) => {
        if (props.dataItem.disabled) {
            return cloneElement(
                tr,
                {...tr.props, className: 'k-state-disabled'},
                tr.props.children
            );
        }
        return tr
    };

    return (
        <Grid
            scrollable={'none'}
            data={dataState}
            sortable={true}
            //pagination
            pageable={(items.filter(filterOut).length <= initialGridState.take) ? false : {
                buttonCount: 5,
                pageSizes: [10, 20, 50, 100],
            }}
            onDataStateChange={onDataStateChange}
            total={dataState.total}
            //select checkboxes
            onHeaderSelectionChange={onHeaderSelectionChange}
            onSelectionChange={onSelectionChange}
            selectedField={selectID}
            //disable rows
            rowRender={DisableRowRender}
            //editing fields
            editField="inEdit"
            onItemChange={onItemChange}
            {...gridState}
            {...otherGridProps}
        >
            <GridColumn
                field={selectID}
                cell={SelectCell}
                headerSelectionValue={selectHeaderCheck}
                headerClassName={selectHeaderClass}
            />
            {children}
        </Grid>
    )
}