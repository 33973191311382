import React, {useContext, useEffect, useState, useCallback, createRef} from 'react';
import axios from 'axios';

// kendo
import {useLocalization} from "@progress/kendo-react-intl";
import {DateRangePicker} from "@progress/kendo-react-dateinputs";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {Grid, GridColumn, GridNoRecords} from "@progress/kendo-react-grid";
import {filterBy, process} from "@progress/kendo-data-query";
import {ExcelExport, ExcelExportColumn} from "@progress/kendo-react-excel-export";

// components
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';
import Spinner from "../components/common/Spinner";
import PageHeader from "../components/common/PageHeader";
import "../assets/css/SAReport.css";

// multilingual
import {
    softwareUsageReportKey,
    generateReportKey,
    selectReportTimeFrameKey,
    allKey,
    checkedOutKey,
    checkedInKey,
    transactionKey,
    productNumberKey,
    descriptionKey,
    hostIdKey,
    aliasKey,
    hostAssetInfoKey,
    clientAssetInfoKey,
    checkoutDateKey,
    checkInDateKey,
    expirationDateKey,
    statusKey,
    createReportDataFormKey,
    genericEmptyGridKey,
    exportKey,
    mainMessages,
    customerKey,
    daysOutKey,
    poNumberKey,
    clientNotesKey
} from "../assets/text/MultilingualText";
import {ColumnMenu, DateCell} from "../components/common/Grid";
import Moment from "moment";
import moment from "moment";

//States and functions for pagination
const initialGridState = {
    take: 10, skip: 0,
};


export default function SAReport(props) {
    const {} = props;
    const {
        siteLanguageDefault,
        accessToken,
        timeout
    } = useContext(UserContext);

    const localization = useLocalization();
    const [isLoading, setIsLoading] = useState(false);
    const [disableGenerateReport, setDisableGenerateReport] = useState(true);
    const [disableExport, setDisableExport] = useState(true);

    const [showRecords, setShowRecords] = useState(false);
    const [allRecords, setAllRecords] = useState([]);
    const [records, setRecords] = useState([]);
    const [excelRecords, setExcelRecords] = useState([]);

    const [excelFileName, setExcelFileName] = useState("");


    const [dateRangeValue, setDateRangeValue] = useState({
        start: null,
        end: null
    });
    const [dateIsValid, setDateIsValid] = useState(true);
    const handleDateChange = (event) => {
        if (!event.value.start || !event.value.end) {
            setDateIsValid(true);
            setDateRangeValue(event.value);
        }
        else {
            if (event.value.start.getTime() <= event.value.end.getTime()) {
                setDateIsValid(true);
                setDateRangeValue(event.value);
            }
            else {
                setDateIsValid(false);
                setDateRangeValue(event.value);
            }
        }
    };

    const [currentFilter, setCurrentFilter] = useState("All")
    const handleFilterClick = (filterName) => {
        setCurrentFilter(filterName);
    };

    const [gridState, setGridState] = useState(initialGridState);
    const [dataState, setDataState] = useState(
        process(records
            .map((item) => ({
                ...item,
            })), initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        const newDataState = process(
            records
                .map((item) => ({
                    ...item,
                })),
            event.dataState
        );
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [records]);

    useEffect(() => {
        const newDataState = process(
            records
                .map((item) => ({
                    ...item,
                })),
            gridState
        );
        setDataState(newDataState);
    }, [records]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let today = new Date();
        ((dateRangeValue?.start && dateRangeValue?.end) &&
            (dateRangeValue?.start <= today && dateRangeValue?.end <= today) &&
            (dateRangeValue?.start <= dateRangeValue?.end)) ? setDisableGenerateReport(false) : setDisableGenerateReport(true)
    }, [dateRangeValue])

    const generateReport = () => {
        // if ()
        setIsLoading(true);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let fromDate = moment(dateRangeValue.start).format("YYYY-MM-DD");
        let toDate = moment(dateRangeValue.end).format("YYYY-MM-DD");

        axios.get(
            `${config.software_access.SOFTWARE_ACCESS}?module=usage-report&from_date=${fromDate}&to_date=${toDate}`,
            {
                headers: headers, timeout: timeout
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data
                    setExcelRecords(data);
                    if (data.length > 0) {
                        data.forEach((record) => {
                            if (record.checkout_date !== null) {
                                record.checkout_date = new Date(Moment(record.checkout_date).format('YYYY-MM-DD 00:00:00'))
                            }
                            if (record.checkin_date !== null) {
                                record.checkin_date = new Date(Moment(record.checkin_date).format('YYYY-MM-DD 00:00:00'))
                            }
                            if (record.exp_date !== null) {
                                record.exp_date = new Date(Moment(record.exp_date).format('YYYY-MM-DD 00:00:00'))
                            }
                        });
                    }
                    setRecords(data);
                    setAllRecords(data);
                    setCurrentFilter("All");
                    setShowRecords(true);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Host Type Details", error);
                setIsLoading(false);
                setShowRecords(true);
            });
    };

    // kendo react excel export
    const _exporter = createRef();
    const exportExcel = () => {
        if (_exporter.current) {
            _exporter.current.save();
        }
    };

    const exportRecords = () => {
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        setExcelFileName('KSM_SWA_Usage_' + date + '.xlsx');
        exportExcel()
    };

    useEffect(() => {
        (records.length > 0) ? setDisableExport(false) : setDisableExport(true);
    }, [records])

    useEffect(() => {
        if (currentFilter === "All") setRecords(allRecords)
        else {
            let newRecords = allRecords.filter((row) => row.status === currentFilter)
            setRecords(newRecords)
        }
    }, [currentFilter])

    return (
        <>
            {isLoading ? <Spinner/> : <></>}
            <PageHeader/>
            <div className={"k-page-container"}>
                <div
                    className={"k-h2"}
                >
                    {localization.toLanguageString(softwareUsageReportKey, mainMessages[siteLanguageDefault][softwareUsageReportKey])}
                </div>
                <div>
                    <div className={"k-h4 k-mt-8"}>
                        {localization.toLanguageString(generateReportKey, mainMessages[siteLanguageDefault][generateReportKey])}
                    </div>
                    <div className={"k-header-desc k-mb-4"}>
                        {localization.toLanguageString(selectReportTimeFrameKey, mainMessages[siteLanguageDefault][selectReportTimeFrameKey])}
                    </div>
                </div>
                <div
                    className={'k-display-flex'}
                    style={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <div className={"k-header-desc k-mr-4"}>
                        {localization.toLanguageString(createReportDataFormKey, mainMessages[siteLanguageDefault][createReportDataFormKey])}
                    </div>
                    <div className={"k-mr-8"}>
                        <DateRangePicker
                            value={dateRangeValue}
                            onChange={handleDateChange}
                            max={new Date()}
                            allowReverse={false}
                            valid={dateIsValid}
                        />
                    </div>
                    <div>
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"solid"}
                            rounded={"small"}
                            type={"button"}
                            onClick={generateReport}
                            disabled={disableGenerateReport}
                        >
                            {localization.toLanguageString(generateReportKey, mainMessages[siteLanguageDefault][generateReportKey])}
                        </Button>
                    </div>
                </div>
                {showRecords && (
                    <>
                        <div className={'k-display-flex k-mt-8 k-mb-4'}>
                            <ButtonGroup>
                                <Button
                                    className={'ksm-btngroup'}
                                    togglable={true}
                                    selected={currentFilter === "All"}
                                    onClick={() => {
                                        handleFilterClick("All")
                                    }}
                                >
                                    {localization.toLanguageString(allKey, mainMessages[siteLanguageDefault][allKey])}
                                </Button>
                                <Button
                                    className={'ksm-btngroup'}
                                    togglable={true}
                                    selected={currentFilter === "Checked Out"}
                                    onClick={() => {
                                        handleFilterClick("Checked Out")
                                    }}
                                >
                                    {localization.toLanguageString(checkedOutKey, mainMessages[siteLanguageDefault][checkedOutKey])}
                                </Button>
                                <Button
                                    className={'ksm-btngroup'}
                                    togglable={true}
                                    selected={currentFilter === "Checked In"}
                                    onClick={() => {
                                        handleFilterClick("Checked In")
                                    }}
                                >
                                    {localization.toLanguageString(checkedInKey, mainMessages[siteLanguageDefault][checkedInKey])}
                                </Button>
                            </ButtonGroup>
                        </div>
                        <Grid
                            className={'sa-report-grid'}
                            scrollable={"none"}
                            sortable={true}
                            pageable={(records.length <= initialGridState.take) ? false : {
                                buttonCount: 5, pageSizes: [10, 20, 50, 100],
                            }}
                            total={dataState.total}
                            data={dataState}
                            onDataStateChange={onDataStateChange}
                            sort={[{
                                field: "transaction",
                                dir: "asc",
                            }]}
                            {...gridState}
                        >
                            <GridNoRecords>
                                {localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey])}
                            </GridNoRecords>
                            <GridColumn
                                field="transaction"
                                title={localization.toLanguageString(transactionKey, mainMessages[siteLanguageDefault][transactionKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="product_number"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="description"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="host_id"
                                title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="alias"
                                title={localization.toLanguageString(aliasKey, mainMessages[siteLanguageDefault][aliasKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="host_asset_information"
                                title={localization.toLanguageString(hostAssetInfoKey, mainMessages[siteLanguageDefault][hostAssetInfoKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="client_asset_information"
                                title={localization.toLanguageString(clientAssetInfoKey, mainMessages[siteLanguageDefault][clientAssetInfoKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="checkout_date"
                                title={localization.toLanguageString(checkoutDateKey, mainMessages[siteLanguageDefault][checkoutDateKey])}
                                columnMenu={ColumnMenu}
                                filter={"date"}
                                cell={DateCell}
                            />
                            <GridColumn
                                field="checkin_date"
                                title={localization.toLanguageString(checkInDateKey, mainMessages[siteLanguageDefault][checkInDateKey])}
                                columnMenu={ColumnMenu}
                                filter={"date"}
                                cell={DateCell}
                            />
                            <GridColumn
                                field="exp_date"
                                title={localization.toLanguageString(expirationDateKey, mainMessages[siteLanguageDefault][expirationDateKey])}
                                columnMenu={ColumnMenu}
                                filter={"date"}
                                cell={DateCell}
                            />
                            <GridColumn
                                field="status"
                                title={localization.toLanguageString(statusKey, mainMessages[siteLanguageDefault][statusKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="days_out"
                                title={localization.toLanguageString(daysOutKey, mainMessages[siteLanguageDefault][daysOutKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="customer"
                                title={localization.toLanguageString(customerKey, mainMessages[siteLanguageDefault][customerKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="po_number"
                                title={localization.toLanguageString(poNumberKey, mainMessages[siteLanguageDefault][poNumberKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="client_notes"
                                title={localization.toLanguageString(clientNotesKey, mainMessages[siteLanguageDefault][clientNotesKey])}
                                columnMenu={ColumnMenu}
                            />
                        </Grid>
                        <div
                            className={'k-display-flex k-mt-4'}
                            style={{justifyContent: "end"}}
                        >
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                                onClick={exportRecords}
                                disabled={disableExport}
                            >
                                {localization.toLanguageString(exportKey, mainMessages[siteLanguageDefault][exportKey])}
                            </Button>
                            <ExcelExport
                                data={filterBy(records, gridState.filter)}
                                fileName={excelFileName}
                                ref={_exporter}
                            >
                                <ExcelExportColumn
                                    field="transaction"
                                    title={localization.toLanguageString(transactionKey, mainMessages[siteLanguageDefault][transactionKey])}
                                />
                                <ExcelExportColumn
                                    field="product_number"
                                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                />
                                <ExcelExportColumn
                                    field="description"
                                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                />
                                <ExcelExportColumn
                                    field="host_id"
                                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                                />
                                <ExcelExportColumn
                                    field="alias"
                                    title={localization.toLanguageString(aliasKey, mainMessages[siteLanguageDefault][aliasKey])}
                                />
                                <ExcelExportColumn
                                    field="host_asset_information"
                                    title={localization.toLanguageString(hostAssetInfoKey, mainMessages[siteLanguageDefault][hostAssetInfoKey])}
                                />
                                <ExcelExportColumn
                                    field="client_asset_information"
                                    title={localization.toLanguageString(clientAssetInfoKey, mainMessages[siteLanguageDefault][clientAssetInfoKey])}
                                />
                                <ExcelExportColumn
                                    field="checkout_date"
                                    title={localization.toLanguageString(checkoutDateKey, mainMessages[siteLanguageDefault][checkoutDateKey])}
                                />
                                <ExcelExportColumn
                                    field="checkin_date"
                                    title={localization.toLanguageString(checkInDateKey, mainMessages[siteLanguageDefault][checkInDateKey])}
                                />
                                <ExcelExportColumn
                                    field="exp_date"
                                    title={localization.toLanguageString(expirationDateKey, mainMessages[siteLanguageDefault][expirationDateKey])}
                                />
                                <ExcelExportColumn
                                    field="status"
                                    title={localization.toLanguageString(statusKey, mainMessages[siteLanguageDefault][statusKey])}
                                />
                                <ExcelExportColumn
                                    field="days_out"
                                    title={localization.toLanguageString(daysOutKey, mainMessages[siteLanguageDefault][daysOutKey])}
                                />
                                <ExcelExportColumn
                                    field="customer"
                                    title={localization.toLanguageString(customerKey, mainMessages[siteLanguageDefault][customerKey])}
                                />
                                <ExcelExportColumn
                                    field="po_number"
                                    title={localization.toLanguageString(poNumberKey, mainMessages[siteLanguageDefault][poNumberKey])}
                                />
                                <ExcelExportColumn
                                    field="client_notes"
                                    title={localization.toLanguageString(clientNotesKey, mainMessages[siteLanguageDefault][clientNotesKey])}
                                />
                            </ExcelExport>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}