import React, {useContext, useState} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/App.css';

// components
import LinkCell from './LinkCell.js';
import SortableLinkCell from './SortableLinkCell.js';
import NotesHtmlCell from './NotesHtmlCell.js';
import {convertDateFormat} from '../common/Grid.js';

// kendo react
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    descriptionKey,
    fileSizeKey,
    mainMessages,
    notesKey,
    releaseDateKey
} from '../../assets/text/MultilingualText.js'


function DocumentationGrid(props) {
    const {
        documentation,
        setIsLoading
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    let items = documentation;

    const TokenizedLinkCell = (props) => {
        return (
            <LinkCell setIsLoading={setIsLoading} {...props}/>
        )
    };

    const SoftwareUpdatesDescription = localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey]);
    const SoftwareUpdatesNotes = localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey]);
    const SoftwareUpdatesReleaseDate = localization.toLanguageString(releaseDateKey, mainMessages[siteLanguageDefault][releaseDateKey]);
    const SoftwareUpdatesFileSize = localization.toLanguageString(fileSizeKey, mainMessages[siteLanguageDefault][fileSizeKey]);

    const initialSort = [
        {
            field: "Description",
            dir: "asc"
        },
    ];

    const [currentSort] = useState(initialSort);

    let allItems = [];

    items.forEach((item, index) => {
        let file_type_arr = item['url'].split('.');
        let file_type = file_type_arr[file_type_arr.length - 1];
        allItems.push({
            "Type": {"link": `${item['url']}`, "type": `${file_type}`},
            "Description": JSON.stringify({
                "text": item['description'],
                "link": `${item['url']}`,
                "accessToken": accessToken
            }),
            "Notes": item['notes'],
            "ReleaseDate": convertDateFormat(typeof item['release_date'] === 'string' ? item['release_date'].replace(/-/g, "/") : item['release_date']),
            "FileSize": item['file_size'],
            "UpdateItemId": item['update_item_id']
        })
    });

    return (
        <Grid
            className={'software-updates-doc-grid'}
            data={orderBy(allItems, currentSort)}
            scrollable={'none'}
            style={{
                "marginTop": ".9em",
                "marginBottom": ".9em"
            }}
        >
            <GridColumn
                field="Type"
                title=" "
                width="40px"
                cell={TokenizedLinkCell}
            />
            <GridColumn
                field="Description"
                title={SoftwareUpdatesDescription}
                cell={(props)=>
                    <SortableLinkCell
                        setIsLoading={setIsLoading}
                        props={props}
                    />
                }
            />
            <GridColumn
                field="Notes"
                title={SoftwareUpdatesNotes}
                sortable={false}
                cell={NotesHtmlCell}
            />
            <GridColumn
                field="ReleaseDate"
                title={SoftwareUpdatesReleaseDate}
                sortable={false}
            />
            <GridColumn
                field="FileSize"
                title={SoftwareUpdatesFileSize}
                sortable={false}
            />
        </Grid>
    );
}

export default DocumentationGrid;