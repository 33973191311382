// kendo react
import {Stepper} from '@progress/kendo-react-layout';

function KSMStepper(props) {
    const {
        stepperItems,
        stepValue,
        changeStepActivation
    } = props

    const handleStepChange = (e) => {
        changeStepActivation(e.value)
    };

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                minWidth: '35rem',
                marginBottom: '0.938rem'
            }}
        >
            <div
                style={{
                    width: 'inherit',
                }}
            >
                <Stepper
                    value={stepValue}
                    onChange={handleStepChange}
                    items={stepperItems}
                />
            </div>
        </div>
    );
}

export default KSMStepper;
