import React, {useContext, useState} from "react";
import UserContext from "../../common/UserContext";

//components
import {NoWrapCell} from "../../common/Grid";
import ClientGrid from "./ClientGrid";

//multilingual
import {
    notGeneratedAvailableRedeemKey,
    errorOccurredRequestKey,
    areYouSureKey,
    generateLicensesMessageKey,
    backKey,
    descriptionKey,
    noKey,
    mainMessages,
    productNumberKey,
    qtyKey,
    requestLicenseKey,
    yesKey,
} from "../../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";

//kendo
import {PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {orderBy} from "@progress/kendo-data-query";
import {Button} from "@progress/kendo-react-buttons";
import {Dialog} from "@progress/kendo-react-dialogs";
import axios from "axios";
import config from "../../../config";
import {Row} from "reactstrap";
import Alert from "../../common/Alert";


export default function Review(props) {
    const {
        timeout,
        siteLanguageDefault,
        accessToken,
    } = useContext(UserContext);
    const {
        setIsLoading,
        assignedProducts,
        setAssignedProducts,
        setRequestedProducts,
        changeStepActivation,
        clientInfo,
        setReview,
    } = props;

    const localization = useLocalization();

    const [showModal, setShowModal] = useState(false)

    const [showAlert, setShowAlert] = useState(false)
    const alertHandler = () => {
        setShowAlert(false)
    }

    const checkInternalConnection = () => {
        let headers = {
            'Authorization': 'Bearer ' + accessToken,
        };
        setIsLoading(true);
        axios.post(
            config.utilities.health,
            {headers: headers, timeout: timeout}
        )
            .then(() => {
                for (const host of assignedProducts) {
                    host.icons = {
                        loading: true,
                        download: false,
                        cloudAlert: false,
                        supportError: false,
                        generationError: false,
                        qtyError: false,
                        entitlementError: false,
                    }
                }
                setAssignedProducts([...assignedProducts])
                setRequestedProducts([...assignedProducts])
                setReview(false)
            })
            .catch((error) => {
                console.log("ERROR: Failed to internet check", error);
                if (!error.response) { //Internet disconnected
                    setShowAlert(true)
                } else {
                    for (const host of assignedProducts) {
                        for (const product of host.products) {
                            product.target_orders = []
                        }
                        host.icons = {
                            loading: true,
                            download: false,
                            cloudAlert: false,
                            supportError: false,
                            generationError: false,
                            qtyError: false,
                            entitlementError: false,
                        }
                    }
                    setAssignedProducts([...assignedProducts])
                    setRequestedProducts([...assignedProducts])
                    setReview(false)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            {(clientInfo.company_info || clientInfo.asset_info || clientInfo.po_number || clientInfo.client_notes) && (
                <ClientGrid
                    clientInfo={clientInfo}
                />
            )}
            {assignedProducts.map((item) => {
                return (
                    <PanelBar
                        key={item.transaction_id}
                        isControlled={false}
                        expanded={['.0']}
                        className={'ksm-panelbar-default'}
                    >
                        <PanelBarItem
                            title={
                                <div
                                    className={"k-h4"}
                                    style={{
                                        color: 'white',
                                        margin: '0 0 0 2px'
                                    }}>
                                    {item.panelBarTitle}
                                </div>
                            }
                        >

                            <Grid
                                className={'assign-host-grid'}
                                scrollable={'none'}
                                data={orderBy(item.products, [{
                                    field: "prod_num",
                                    dir: "desc"
                                }])}
                            >
                                <GridColumn
                                    field="product_number"
                                    title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                    cell={NoWrapCell}
                                />
                                <GridColumn
                                    field="product_desc"
                                    title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                />
                                <GridColumn
                                    field="qty_requested"
                                    title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                />
                            </Grid>
                        </PanelBarItem>
                    </PanelBar>
                )
            })}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    rounded={"small"}
                    type={"button"}
                    onClick={() => {
                        changeStepActivation(2)
                    }}
                >
                    {localization.toLanguageString(backKey, mainMessages[siteLanguageDefault][backKey])}
                </Button>

                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={() => {
                        setShowModal(true)
                    }}
                >
                    {localization.toLanguageString(requestLicenseKey, mainMessages[siteLanguageDefault][requestLicenseKey])}
                </Button>

            </div>

            {showModal && (
                <Dialog
                    className={'ksm-dialog'}
                    width={"35.938rem"}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.938rem',
                    }}>
                        <div style={{
                            fontSize: '2.5rem'
                        }}>
                            {localization.toLanguageString(areYouSureKey, mainMessages[siteLanguageDefault][areYouSureKey])}
                        </div>
                        <div>
                            {localization.toLanguageString(generateLicensesMessageKey, mainMessages[siteLanguageDefault][generateLicensesMessageKey])}
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'end',
                            gap: '0.938rem'
                        }}>
                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"outline"}
                                rounded={"small"}
                                type={"button"}
                                onClick={() => {
                                    setShowModal(false)
                                }}
                            >
                                {localization.toLanguageString(noKey, mainMessages[siteLanguageDefault][noKey])}
                            </Button>

                            <Button
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={"button"}
                                onClick={() => {
                                    checkInternalConnection()
                                }}
                            >
                                {localization.toLanguageString(yesKey, mainMessages[siteLanguageDefault][yesKey])}
                            </Button>
                        </div>
                    </div>
                    {showAlert && (
                        <Row style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '0.938rem'
                        }}>
                            <div style={{
                                maxWidth: '35.813rem',
                            }}>
                                <Alert
                                    type={'error'}
                                    showHandler={alertHandler}
                                    title={localization.toLanguageString(errorOccurredRequestKey, mainMessages[siteLanguageDefault][errorOccurredRequestKey])}
                                    message={localization.toLanguageString(notGeneratedAvailableRedeemKey, mainMessages[siteLanguageDefault][notGeneratedAvailableRedeemKey])}
                                />
                            </div>

                        </Row>
                    )}
                </Dialog>
            )}
        </>
    )
}