import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from "reactstrap";

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    helpTrialLicenseAboutSectionKey,
    aboutKeysightTrailLicensesKey,
    trailLicenseKey,
    helpTrialLicenseHostIDHostInfoSectionKey,
    hostIdHostInfoKey,
    mainMessages,
    helpTrialLicenseProductInfoSectionKey,
    licenseProductInfoKey
} from '../assets/text/MultilingualText.js';


function TrialLicenseHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const helpMenuContent = {
        'header': {
            key: trailLicenseKey,
            default: mainMessages[siteLanguageDefault][trailLicenseKey]
        },
        'topics': [
            {
                key: aboutKeysightTrailLicensesKey,
                default: mainMessages[siteLanguageDefault][aboutKeysightTrailLicensesKey],
                hash: "#about"
            },
            {
                key: licenseProductInfoKey,
                default: mainMessages[siteLanguageDefault][licenseProductInfoKey],
                hash: "#product-info"
            },
            {
                key: hostIdHostInfoKey,
                default: mainMessages[siteLanguageDefault][hostIdHostInfoKey],
                hash: "#host-id-product-info"
            }
        ],
        'faq': []
    }

    const aboutSection = localization.toLanguageString(
        helpTrialLicenseAboutSectionKey,
        mainMessages[siteLanguageDefault][helpTrialLicenseAboutSectionKey]
    );

    const productInfoSection = localization.toLanguageString(
        helpTrialLicenseProductInfoSectionKey,
        mainMessages[siteLanguageDefault][helpTrialLicenseProductInfoSectionKey]
    );

    const hostIDHostInfoSection = localization.toLanguageString(
        helpTrialLicenseHostIDHostInfoSectionKey,
        mainMessages[siteLanguageDefault][helpTrialLicenseHostIDHostInfoSectionKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"k-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu helpContent={helpMenuContent}/>
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h2"}
                            id="about"
                        >
                            <Text
                                textkey={aboutKeysightTrailLicensesKey}
                                textdefault={mainMessages[siteLanguageDefault][aboutKeysightTrailLicensesKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(aboutSection)}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="product-info"
                        >
                            <Text
                                textkey={licenseProductInfoKey}
                                textdefault={mainMessages[siteLanguageDefault][licenseProductInfoKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(productInfoSection)}
                        </div>
                        <div
                            className={"k-h2 header"}
                            id="host-id-product-info"
                        >
                            <Text
                                textkey={hostIdHostInfoKey}
                                textdefault={mainMessages[siteLanguageDefault][hostIdHostInfoKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            {toHtml(hostIDHostInfoSection)}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default TrialLicenseHelp;

