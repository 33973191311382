import React, { useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// components
import AssignProductsModal from './AssignProducts/AssignProductsModal.js';
import Spinner from '../common/Spinner.js';
import {AssignQtyCell} from './AssignProducts/AssignQty.js';
import {
    ColumnMenu,
    ColumnSortOnlyMenu,
    DateCell,
    DateIsExpiredCell,
    NoWrapCell,
    TextAlignMiddleCell
} from '../common/Grid.js';
import {getHostDetails} from "../common/AssignHost/GenericAssignModal/utilities";

// kendo react
import {GridColumn, GridItemChangeEvent, GridNoRecords} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';


// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../common/MultilingualText.js';
import {
    productsHostKey,
    assignQtyKey,
    descriptionKey,
    licenseExpirationDateKey,
    productNumberKey,
    qtyKey,
    supportExpirationDateKey,
    mainMessages,
    licensePreviewKey,
    myAssignedProductsKey,
    myProductsKey,
} from '../../assets/text/MultilingualText';
import AssignedHostDelete from "./AssignProducts/AssignedHostDelete";
import GenericSelectSortGrid from "../common/AssignHost/GenericSelectSortGrid";


const uniqueID = 'unique_id'
const sortByDisableID = 'hostid_type'

function AssignProducts(props) {
    const {
        changeStepActivation,
        selectedProducts,
        setSelectedProducts,
        assignedProducts,
        setAssignedProducts,
        gridNoRecordsMessage,
        initialGridState,
        assignGridState,
        setAssignGridState,
        assignGridStateRef,
        selectHeaderCheck,
        setSelectHeaderCheck,
        enableSortByDisable,
        setEnableSortByDisable,
        sortByDisableValue
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [isLoading, setIsLoading] = useState(false);
    const [disableAssign, setDisableAssign] = useState(false);

    const unAssignedProductGridRef = useRef(null);
    const scrollRef = useRef(null);

    // update product state for editable assign qty in license table
    const onItemChange = (e: GridItemChangeEvent) => {
        let newProducts = selectedProducts.map((product) => {
            if (product.unique_id === e.dataItem.unique_id) {
                let setValue = e.value

                // assign qty cannot be greater than the max qty available
                if (e.value > parseInt(e.dataItem.qty_available)) {
                    setValue = parseInt(e.dataItem.qty_available)
                }
                // assign qty cannot be less than 1
                if (e.value < 1) {
                    setValue = null
                }

                product[e.field] = setValue;
            }
            return product;
        });
        setSelectedProducts(newProducts);
    };

    // enable or disable button if there are selected selectedProducts and there is an assign qty value
    useEffect(() => {
        let selectedCount = selectedProducts.filter(item => item.selected).length
        let assignedCount = selectedProducts.filter(item => item.selected && item.qty_requested > 0).length
        setDisableAssign(!(selectedCount === assignedCount && selectedCount > 0))
    }, [selectedProducts]);

    // assign host modal
    const [hostDetails, setHostDetails] = useState({});
    const assignProducts = () => {
        const hostIDType = selectedProducts.filter(item => item.selected)[0]['hostid_type']
        getHostDetails(accessToken, hostIDType,setHostDetails, setIsLoading)
    }

    return (
        <div>
            {isLoading ? <Spinner/> : <></>}

            {!!assignedProducts.length &&
                <>
                    <div
                        className={"k-h4"}
                        ref={scrollRef}
                        style={{
                            marginBottom: '0.938rem'
                        }}
                    >
                        {localization.toLanguageString(myAssignedProductsKey, mainMessages[siteLanguageDefault][myAssignedProductsKey])}
                    </div>

                    <AssignedHostDelete
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        assignedProducts={assignedProducts}
                        setAssignedProducts={setAssignedProducts}
                    />

                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        rounded={"small"}
                        type={"button"}
                        onClick={() => {
                            changeStepActivation(1)
                        }}
                        style={{
                            marginBottom: '2rem'
                        }}
                    >
                        {localization.toLanguageString(licensePreviewKey, mainMessages[siteLanguageDefault][licensePreviewKey])}
                    </Button>
                </>
            }

            <div ref={unAssignedProductGridRef}>
                <div
                    className={"k-h4"}
                >
                    <Text
                        textkey={myProductsKey}
                        textdefault={mainMessages[siteLanguageDefault][myProductsKey]}
                    />
                </div>
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    disabled={disableAssign}
                    onClick={assignProducts}
                    style={{
                        marginBottom: "8px"
                    }}
                >
                    <Text
                        textkey={productsHostKey}
                        textdefault={mainMessages[siteLanguageDefault][productsHostKey]}
                    />
                </Button>

                <GenericSelectSortGrid
                    className={'license-grid'}
                    items={selectedProducts}
                    setItems={setSelectedProducts}
                    uniqueID={uniqueID}
                    initialGridState={initialGridState}
                    gridState={assignGridState}
                    setGridState={setAssignGridState}
                    gridStateRef={assignGridStateRef}
                    selectHeaderCheck={selectHeaderCheck}
                    setSelectHeaderCheck={setSelectHeaderCheck}
                    sortByDisableID={sortByDisableID}
                    sortByDisableValue={sortByDisableValue}
                    enableSortByDisable={enableSortByDisable}
                    setEnableSortByDisable={setEnableSortByDisable}
                    onItemChange={onItemChange}
                    filterOut={(item)=> item.qty_available > 0}
                >
                    <GridNoRecords>
                        {gridNoRecordsMessage}
                    </GridNoRecords>
                    <GridColumn
                        field="product_number"
                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                        columnMenu={ColumnMenu}
                        editable={false}
                        cell={NoWrapCell}
                    />
                    <GridColumn
                        field="product_desc"
                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                        columnMenu={ColumnMenu}
                        editable={false}
                    />
                    <GridColumn
                        cell={TextAlignMiddleCell}
                        field="qty_available"
                        title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                        filter={'numeric'}
                        columnMenu={ColumnSortOnlyMenu}
                        editable={false}
                    />
                    <GridColumn
                        field="qty_requested"
                        title={localization.toLanguageString(assignQtyKey, mainMessages[siteLanguageDefault][assignQtyKey])}
                        filter={'numeric'}
                        cell={AssignQtyCell}
                        sortable={false}
                    />
                    <GridColumn
                        field="license_expiration_date_display"
                        title={localization.toLanguageString(licenseExpirationDateKey, mainMessages[siteLanguageDefault][licenseExpirationDateKey])}
                        filter={'date'}
                        columnMenu={ColumnMenu}
                        cell={DateCell}
                        editable={false}
                    />
                    <GridColumn
                        field="support_expiration_date_display"
                        title={localization.toLanguageString(supportExpirationDateKey, mainMessages[siteLanguageDefault][supportExpirationDateKey])}
                        filter={'date'}
                        columnMenu={ColumnMenu}
                        cell={DateIsExpiredCell}
                        editable={false}
                    />
                </GenericSelectSortGrid>
            </div>

            {Object.keys(hostDetails).length !== 0 &&
                <AssignProductsModal
                    setIsLoading={setIsLoading}
                    scrollRef={scrollRef}
                    hostDetails={hostDetails}
                    setHostDetails={setHostDetails}
                    assignedProducts={assignedProducts}
                    setAssignedProducts={setAssignedProducts}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    sortByDisableValue={sortByDisableValue}
                    setSelectHeaderCheck={setSelectHeaderCheck}
                    setEnableSortByDisable={setEnableSortByDisable}
                />
            }
        </div>
    );
}

export default AssignProducts;