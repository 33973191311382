import React, {useCallback, useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/App.css';
import '../../assets/css/SoftwareUpdates.css';

// components
import LinkCell from './LinkCell.js';
import {ColumnMenu, DateCell} from '../common/Grid.js';

// kendo react
import {Grid, GridColumn, GridNoRecords} from '@progress/kendo-react-grid';
import {orderBy, process} from '@progress/kendo-data-query';
import {useLocalization} from '@progress/kendo-react-intl';

import {
    mainMessages,
    releaseDateKey,
    softwareFamilyKey,
    updateKey,
    genericEmptyGridKey
} from '../../assets/text/MultilingualText.js'


function SoftwareGrid(props) {
    const {updates} = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    let products = updates;

    const SoftwareUpdatesUpdates = localization.toLanguageString(updateKey, mainMessages[siteLanguageDefault][updateKey]);
    const SoftwareUpdatesReleaseDate = localization.toLanguageString(releaseDateKey, mainMessages[siteLanguageDefault][releaseDateKey]);
    const SoftwareUpdatesFamily = localization.toLanguageString(softwareFamilyKey, mainMessages[siteLanguageDefault][softwareFamilyKey]);

    let orders = [
        {
            "Update": ["Support Bulletins - January 2022", "VSA2020 Update 1"],
            "ReleaseDate": ["January 23, 2022", "September 20, 2020"],
        }
    ];

    const initialSort = [{"field": "PRODUCT_FAMILY", "dir": "asc"}];

    const [currentSort] = useState(initialSort);

    const initialFilter = {
        "filters": [{
            "logic": "and",
            "filters": [{"field": "PRODUCT_FAMILY", "operator": "isnotnull", "value": null}]
        }],
        "logic": "and"
    };

    const [globalExpand, setGlobalExpand] = useState(true);
    const [filter] = useState(initialFilter);

    const [page, setPage] = useState({skip: 0, take: 10});

    const initialDataState = {filter: filter, sort: currentSort, ...page};

    const onDataStateChange = useCallback((e) => {
        setDataState(e.dataState);
        setResultState(process(products, e.dataState));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const pageChange = (event) => {
        setPage(event.page);
        setResultState(process(products, {...dataState, ...event.page}));
    };

    const [dataState, setDataState] = useState(initialDataState);
    const [resultState, setResultState] = useState(
        process(products, initialDataState)
    );
    const [dataResult, setDataResult] = useState(
        process(orders, dataState))
    ;

    const DetailComponent = props => {
        const dataItem = props.dataItem;
        let updates = dataItem['DETAILS'];

        let allItems = [];

        const initialUpdateSort = [
            {
                field: "ReleaseDate",
                dir: "desc",
            },
        ];
        const [currentUpdateSort, setUpdateSort] = useState(initialUpdateSort);

        updates.forEach((update, index) => {
            allItems.push({
                "SoftwareFamily": dataItem["PRODUCT_FAMILY"],
                "Update": {
                    "text": update['DESCRIPTION'],
                    "link": `/software-updates-detail?uuid=${update['UPDATE_TABLE_ID']}`
                },
                "ReleaseDate": new Date(update['ORIGINAL_RELEASE_DATE'].replace(/-/g, "/"))
            })
        });

        const createDataState = (dataState) => {
            return {
                result: process(allItems.slice(0), dataState),
                dataState: dataState
            };
        };

        let initialState = createDataState({});

        const [result, setResult] = useState(initialState.result);
        const [dataState, setDataState] = useState(initialState.dataState);
        const dataStateChange = (event) => {
            let updatedState = createDataState(event.dataState);
            setResult(updatedState.result);
            setDataState(updatedState.dataState);
        };
        const sortChange = (event) => {
            setResult(getProducts(event.sort));
            setUpdateSort(event.sort);
        };
        const getProducts = (currentUpdateSort) => {
            return orderBy(allItems, currentUpdateSort);
        };

        const defaultGridMessage = localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey]);

        return <Grid
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            scrollable={'none'}
            sortable={true}
            sort={currentUpdateSort}
            onSortChange={sortChange}
            className="software-updates-detail-grid k-mt-4 k-mb-4 k-p-0"
        >
            <GridNoRecords>
                {defaultGridMessage}
            </GridNoRecords>
            <GridColumn
                columnMenu={ColumnMenu}
                field="Update.text"
                title={SoftwareUpdatesUpdates}
                cell={LinkCell}
            />
            <GridColumn
                columnMenu={ColumnMenu}
                filter={"date"}
                field="ReleaseDate"
                title={SoftwareUpdatesReleaseDate}
                cell={DateCell}
            />
        </Grid>
    };

    const toggleExpand = () => {
        setGlobalExpand(!globalExpand);
        products = products.forEach((product) => {
            product.expanded = globalExpand;
        })
    }

    const expandHeaderCell = () => {
        return (<div
            onClick={toggleExpand}
            className={!globalExpand ? "k-icon k-i-minus" : "k-icon k-i-plus"}
            style={{
                cursor: "pointer",
                fontSize: "1.25rem",
                color: "#FFFFFF"
            }}
        >
        </div>)
    }

    const customRender = (e) => {
        return (e._owner.memoizedProps?.field === "expanded") ? expandHeaderCell() : e;
    }

    const expandChange = event => {
        const isExpanded = event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setDataResult({
            ...dataResult
        });
    };

    // force re-render of table
    useEffect(() => {
        setTimeout(() => {
            setResultState(process(products, initialDataState));
        }, 300);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return dataState ? <Grid
        data={resultState.data}
        onDataStateChange={onDataStateChange}
        {...dataState}
        pageable={(initialDataState.length <= 20) ? false : {
            buttonCount: 5,
            pageSizes: [10, 20, 50, 100]
        }}
        pageSize={10}
        scrollable={'none'}
        detail={DetailComponent}
        expandField="expanded"
        onExpandChange={expandChange}
        skip={page.skip}
        take={page.take}
        total={resultState.total}
        onPageChange={pageChange}
        headerCellRender={customRender}
        sortable={true}
    >
        {/*<Grid*/}
        {/*    style={{*/}
        {/*        height: "400px"*/}
        {/*    }}*/}
        {/*    //data={products}*/}
        {/*    data={orderBy(products, currentSort)}*/}
        {/*    sortable={true}*/}
        {/*    sort={currentSort}*/}
        {/*    onSortChange={(e) => {setSort(e.sort)}}*/}
        {/*    onDataStateChange={(e) => {setDataState(e.dataState)}}*/}
        {/*    filterable={true}*/}
        {/*    filter={filter}*/}
        {/*    onFilterChange={(e) => setFilter(e.filter)}*/}
        {/*    pageable={true}*/}
        {/*    pageSize={8}*/}
        {/*    scrollable={'none'}*/}
        {/*>*/}
        <GridColumn
            columnMenu={ColumnMenu}
            field="PRODUCT_FAMILY"
            title={SoftwareUpdatesFamily}
        />
    </Grid> : <></>;
}

export default SoftwareGrid;