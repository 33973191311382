import React, {useCallback, useContext, useEffect, useState, useRef} from 'react';
import axios from "axios";
import config from "../../config";

// components
import UserContext from '../../components/common/UserContext.js';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    myHostsKey,
    selectHostBeginCheckInKey,
    infoKey,
    incompatibleProductsSelectedKey,
    hostIdKey,
    companyAssetInfoKey,
    aliasKey,
    transactionKey,
    poNumberKey,
    checkoutDateKey,
    customerKey,
    clientNotesKey,
    continueKey, noLicensesCheckInKey,
} from "../../assets/text/MultilingualText";
import {Grid, GridColumn, GridNoRecords} from "@progress/kendo-react-grid";
import {ColumnMenu, DateCell, NoWrapCell} from "../common/Grid";
import {filterBy, process} from "@progress/kendo-data-query";
import {Popover} from "@progress/kendo-react-tooltip";
import {Checkbox} from "@progress/kendo-react-inputs";
import {useHistory} from "react-router-dom";
import {Button} from "@progress/kendo-react-buttons";
import Moment from "moment";


//States and functions for pagination
const initialGridState = {
    take: 10, skip: 0,
};

function SelectHosts(props) {
    const {
        setIsLoading,
        changeStepActivation,
        myHosts,
        setMyHosts
    } = props;
    const {
        siteLanguageDefault,
        accessToken,
        timeout
    } = useContext(UserContext);

    const localization = useLocalization();
    const history = useHistory();

    const [gridState, setGridState] = useState(initialGridState);
    const gridStateRef = useRef(initialGridState)
    const [dataState, setDataState] = useState(
        process(myHosts
            .map((item) => ({
                ...item,
            })), initialGridState)
    );

    const onDataStateChange = useCallback((event) => {
        const newDataState = process(
            myHosts
                .map((item) => ({
                    ...item,
                })),
            event.dataState
        );
        setSelectHeaderCheck(newDataState.data.filter(item => !item.selected).length === 0)
        gridStateRef.current=event.dataState
        setGridState(event.dataState);
        setDataState(newDataState);
    }, [myHosts]);

    useEffect(() => {
        const newDataState = process(
            myHosts
                .map((item) => ({
                    ...item,
                })),
            gridState
        );
        setDataState(newDataState);
    }, [myHosts]) // eslint-disable-line react-hooks/exhaustive-deps

    // state to control unassigned products header checkbox
    const [selectHeaderCheck, setSelectHeaderCheck] = useState(false);

    //Cell and functions for selection
    const SelectCell = (props) => {
        const anchor = useRef(null);
        const [popoverShow, setPopoverShow] = useState(false);

        // show popover if there is an error on mouse over
        const onMouseOver = (ev) => {
            setPopoverShow(true);
        };

        // remove popover on mouse leave
        const onMouseOut = (ev) => {
            let isChildEl = ev.currentTarget.contains(ev.relatedTarget);
            if (!isChildEl) setPopoverShow(false);
        };

        return <td
            ref={anchor}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {props.dataItem.disabled && //Need CSS pointer-events: auto;
                <Popover
                    show={popoverShow}
                    anchor={anchor.current}
                    position={'right'}
                    className={'ksm-popover ksm-popover-alert-info'}
                    animate={false}
                >
                    <div style={{
                        display: 'flex'
                    }}>
                        <span
                            className="k-icon k-i-information"
                            style={{
                                color: 'var(--keysight-purple)',
                                fontSize: '2.5rem',
                                marginRight: '0.938rem'
                            }}
                        />
                        <div>
                            <b>{localization.toLanguageString(infoKey, mainMessages[siteLanguageDefault][infoKey])}</b>
                            <br/>
                            {localization.toLanguageString(incompatibleProductsSelectedKey, mainMessages[siteLanguageDefault][incompatibleProductsSelectedKey])}
                        </div>
                    </div>
                </Popover>
            }
            <Checkbox
                type="checkbox"
                onChange={props.selectionChange}
                value={props.dataItem.selected}
                disabled={props.dataItem.disabled}
            />
        </td>
    }

    const onSelectionChange = useCallback((event) => {
        const checked = event.syntheticEvent.target.checked
        let newMyHosts = myHosts.map(item => {
            if (event.dataItem.unique_id === item.unique_id) {
                item.selected = checked
            }
            return item
        })

        setSelectHeaderCheck(filterBy(newMyHosts, gridStateRef.current.filter).filter(item => !item.selected).length === 0)
        setMyHosts(newMyHosts)
    }, [myHosts]); // eslint-disable-line react-hooks/exhaustive-deps

    const onHeaderSelectionChange = useCallback(event => {
        const checked = event.nativeEvent.target.checked;
        let newMyHosts = myHosts.map(item => {
            for(const filtered of filterBy(myHosts, gridStateRef.current.filter)){
                if(item.unique_id === filtered.unique_id){
                    item.selected = checked
                }
            }
            return item
        });
        setMyHosts(newMyHosts);
        setSelectHeaderCheck(checked);
    }, [myHosts]); // eslint-disable-line react-hooks/exhaustive-deps

    /*
     * getHosts() fetches hosts user is able to checkin
    */
    const getHosts = () => {
        setIsLoading(true);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let payload = {
            "module": "SOFTWARE/ACCESS",
            "sub_module": "CHECKIN-SEARCH",
            "request_from": "",
            "action": "SEARCH",
            "input_json": {
                "host_ids": []
            }
        }

        axios.post(
            config.software_access.SOFTWARE_ACCESS,
            payload,
            {
                headers: headers, timeout: timeout
            },
        )
            .then((response) => {
                if (response.status === 200) {
                    let hostData = response.data || []
                    hostData.forEach((host, index) => {
                        if (host.checkout_date !== null) {
                            host.checkout_date = new Date(Moment(host.checkout_date).format('YYYY-MM-DD 00:00:00'))
                        }
                        host.unique_id = index
                    });
                    setMyHosts(hostData);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Host Type Details", error);
                setIsLoading(false);
                history.push('/error');
            });
    }

    useEffect(() => {
        getHosts()
    }, [])

    return (
        <>
            <div>
                <div className={"k-h4"}>
                    {localization.toLanguageString(myHostsKey, mainMessages[siteLanguageDefault][myHostsKey])}
                </div>
                <div className={"k-header-desc k-mb-4"}>
                    {localization.toLanguageString(selectHostBeginCheckInKey, mainMessages[siteLanguageDefault][selectHostBeginCheckInKey])}
                </div>
            </div>
            <Grid
                className={'sa-checkin-hosts-grid'}
                scrollable={'none'}
                data={dataState}
                sortable={true}
                //pagination
                pageable={(myHosts.length <= initialGridState.take) ? false : {
                    buttonCount: 5,
                    pageSizes: [10, 20, 50, 100],
                }}
                onDataStateChange={onDataStateChange}
                total={dataState.total}
                //select checkboxes
                onHeaderSelectionChange={onHeaderSelectionChange}
                onSelectionChange={onSelectionChange}
                selectedField={'selected'}
                selectable={{
                    enabled: false,
                    drag: false,
                    cell: false,
                    mode: 'multiple'
                }}
                {...gridState}
            >
                <GridNoRecords>
                    {localization.toLanguageString(noLicensesCheckInKey, mainMessages[siteLanguageDefault][noLicensesCheckInKey])}
                </GridNoRecords>
                <GridColumn
                    field={'selected'}
                    cell={SelectCell}
                    headerSelectionValue={selectHeaderCheck}
                    headerClassName={''}
                />
                <GridColumn
                    field="host_id"
                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                    columnMenu={ColumnMenu}
                    cell={NoWrapCell}
                />
                <GridColumn
                    field="alias"
                    title={localization.toLanguageString(aliasKey, mainMessages[siteLanguageDefault][aliasKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="asset"
                    title={localization.toLanguageString(companyAssetInfoKey, mainMessages[siteLanguageDefault][companyAssetInfoKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="transaction_id"
                    title={localization.toLanguageString(transactionKey, mainMessages[siteLanguageDefault][transactionKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="po_number"
                    title={localization.toLanguageString(poNumberKey, mainMessages[siteLanguageDefault][poNumberKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="checkout_date"
                    title={localization.toLanguageString(checkoutDateKey, mainMessages[siteLanguageDefault][checkoutDateKey])}
                    columnMenu={ColumnMenu}
                    filter={'date'}
                    cell={DateCell}
                />
                <GridColumn
                    field="company_name"
                    title={localization.toLanguageString(customerKey, mainMessages[siteLanguageDefault][customerKey])}
                    columnMenu={ColumnMenu}
                />
                <GridColumn
                    field="notes"
                    title={localization.toLanguageString(clientNotesKey, mainMessages[siteLanguageDefault][clientNotesKey])}
                    columnMenu={ColumnMenu}
                />
            </Grid>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginTop: '0.938rem',
                }}
            >
                <Button
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    disabled={myHosts.filter(item => item.selected).length === 0}
                    onClick={() => {
                        changeStepActivation(1)
                    }}
                >
                    {localization.toLanguageString(continueKey, mainMessages[siteLanguageDefault][continueKey])}
                </Button>
            </div>
        </>
    )
}

export default SelectHosts;