import React, {useContext} from 'react';
import UserContext from "../common/UserContext";

// react router
import {useHistory} from 'react-router-dom';

// reactstrap
import {Col, Row} from 'reactstrap';

//multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    activeKey,
    expiredKey,
    mainMessages
} from "../../assets/text/MultilingualText";

// kendo react
import {Card, CardBody, CardTitle} from '@progress/kendo-react-layout';
import {Tooltip} from '@progress/kendo-react-tooltip';

const WelcomeCard = (props) => {
    let {
        card
    } = props;

    let {
        siteLanguageDefault
    } = useContext(UserContext)

    let history = useHistory();
    const localization = useLocalization();

    const redirect = (actionLink) => {
        if (actionLink.indexOf('http') > -1) {
            window.location.href = actionLink;
        } else {
            history.push(actionLink);
        }
    }

    const cardStyle = {
        width: "100%",
        height: "100%",
        boxShadow: "0.313rem 0.313rem 0.313rem #F7EFEF",
    };

    const titleStyle = {
        fontSize: "1.25rem",
        color: "#000000",
    }

    const counterStyle = {
        display: 'inline-block',
        backgroundColor: 'var(--keysight-blue)',
        color: 'white',
        padding: '4px 5px',
        borderRadius: '14px',
        lineHeight: '0.75rem',
        fontSize: '0.875rem',
        fontWeight: '400',
    }

    return (
        <>
            {/* remove cards with no sub menus */}
            {(card["SUBMENU"].length > 0) ?
                <Card style={cardStyle}>
                    <CardBody>
                        <Row>
                            <Col>
                                {card.icon}
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col>
                                <CardTitle style={titleStyle}>
                                    <strong>
                                        {localization.toLanguageString(card.titleKey, mainMessages[siteLanguageDefault][card.titleKey]).toUpperCase()}
                                    </strong>
                                </CardTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {card["SUBMENU"].map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Tooltip anchorElement="target"
                                                     parentTitle={true}
                                                     position="right">
                                                <button
                                                    className={"ksm-anchor-button"}
                                                    onClick={() => {
                                                        redirect(item.actionLink)
                                                    }}
                                                    title={localization.toLanguageString(item.tooltipKey, mainMessages[siteLanguageDefault][item.tooltipKey])}
                                                >

                                                    <div style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: '0.438rem',
                                                        alignItems: 'center'
                                                    }}>

                                                        {localization.toLanguageString(item.titleKey, mainMessages[siteLanguageDefault][item.titleKey])}

                                                        {item.displayValue && (
                                                            <div style={counterStyle}>
                                                                {item.displayValue}
                                                            </div>
                                                        )}

                                                        {item.activeValue && item.expiredValue && (
                                                            <>
                                                                <div style={counterStyle}>
                                                                    {item.activeValue && (
                                                                        item.activeValue + ' ' + localization.toLanguageString(activeKey, mainMessages[siteLanguageDefault][activeKey])
                                                                    )}
                                                                </div>

                                                                <div style={counterStyle}>
                                                                    {item.expiredValue && (
                                                                        item.expiredValue + ' ' + localization.toLanguageString(expiredKey, mainMessages[siteLanguageDefault][expiredKey])
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </button>
                                                <hr/>
                                            </Tooltip>
                                        </React.Fragment>
                                    );
                                })}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                :
                <></>
            }
        </>
    );
};

export default WelcomeCard;